
// Review [internal|intermittent] (2021-10-31T12:40:36) : Move all px/em/rem/percent values to var/mixin

$margin-normal: 16px;
.card-simple-payment {
    margin: 16px !important;    
    padding: 0px !important;
    border-radius: 16px !important;    
    box-shadow: none !important;
    background: $paxcom-wizard-step-payment-card-bg-color;
    filter: drop-shadow(5px 5px 4px rgba(0, 0, 0, 0.25)) !important;
}