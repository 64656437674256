
.thinScrollBars::-webkit-scrollbar-track
{
	background-color:transparent;
}

.thinScrollBars::-webkit-scrollbar
{
	height: 8px;
	background-color: transparent;
}

.thinScrollBars::-webkit-scrollbar-thumb
{
	background-color: #0000001d;
}

.thinVertScrollBars::-webkit-scrollbar
{
	width: 8px;
	background-color: red;
}
.thinVertScrollBars::-webkit-scrollbar-thumb
{
	background-color: red;
}


