// Review [internal|intermittent] (2021-10-31T12:28:55) :  create seperate theme file for seperate colors and import in styles.css

$paxcom-color-gray-dark : #e5e6e9;
$paxcom-color-gray-chromatic : #eaebee;
$paxcom-color-gray-chromatic-light:  #f2f3f5;
$paxcom-color-gray-tint-x: #f7f8f9;
$paxcom-color-gray-tint-xx: #fafbfb;
$paxcom-color-gray-tint-xxx: #fcfdfd;
$paxcom-color-gray-tint-xxxx: #5798cb;
$paxcom-color-gray-tint-xxxx-c-minus: #69a7dc;
$paxcom-color-gray-tint-xxxxx: #032349;
$paxcom-color-gray-tint-xxxxxx: #8ac8fe;
$paxcom-color-gray-tint-xxxxxxx: #eff7fe;
$paxcom-color-gray-contrast-A: #032349;
$paxcom-color-gray-contrast-A-tint-x: #053b7a;
$paxcom-color-gray-contrast-A-tint-xx: #eff7fe;
$paxcom-color-gray-contrast-B: #88f454;

$paxcom-color-grays-shade-xx:#474747;
$paxcom-color-grays-shade-x:#5e5e5e;
$paxcom-color-grays-tint-x:#777777;
$paxcom-color-grays-tint-xx:#919191;
$paxcom-color-grays-tint-xxx:#b4b4b4;
$paxcom-color-grays-tint-xxxx:#ededed;
$paxcom-color-grays-tint-xxxxxxx:#ffffff;


$paxcom-color-black : #000000;
$paxcom-color-black-transparent-10 : #000000E5;
$paxcom-color-black-transparent-20 : #000000CC;
$paxcom-color-black-transparent-30 : #000000B2;
$paxcom-color-black-transparent-40 : #00000099;
$paxcom-color-black-transparent-50 : #0000007F;
$paxcom-color-black-transparent-60 : #00000066;
$paxcom-color-black-transparent-70 : #0000004C;
$paxcom-color-black-transparent-80 : #00000033;
$paxcom-color-black-transparent-90 : #00000019;

$paxcom-color-white : #ffffff;
$paxcom-color-white-transparent-20 : #ffffffCC;
$paxcom-color-white-transparent-50 : #ffffff7F;
$paxcom-color-white-transparent-60 : #ffffff66;
$paxcom-color-white-transparent-80 : #ffffff33;
$paxcom-color-white-transparent-90 : #ffffff19;


$paxcom-theme-color-primary :	$paxcom-color-gray-contrast-A;
$paxcom-theme-color-chromatic : $paxcom-color-gray-chromatic;
$paxcom-theme-color-chromatic-light: $paxcom-color-gray-chromatic-light  ;
$paxcom-theme-color-tint-x : $paxcom-color-gray-tint-x;
$paxcom-theme-color-tint-xx:	$paxcom-color-gray-tint-xx;
$paxcom-theme-color-tint-xxx: $paxcom-color-gray-tint-xxx;
$paxcom-theme-color-tint-xxxx: $paxcom-color-gray-tint-xxxx;
$paxcom-theme-color-tint-xxxx-c-minus: $paxcom-color-gray-tint-xxxx-c-minus;
$paxcom-theme-color-tint-xxxxx: $paxcom-color-gray-tint-xxxxx;
$paxcom-theme-color-tint-xxxxxx: $paxcom-color-gray-tint-xxxxxx;
$paxcom-theme-color-tint-xxxxxxx: $paxcom-color-gray-tint-xxxxxxx;
$paxcom-theme-color-contrast-A: $paxcom-color-gray-contrast-A;
$paxcom-theme-color-contrast-A-tint-x: $paxcom-color-gray-contrast-A-tint-x;
$paxcom-theme-color-contrast-A-tint-xx: $paxcom-color-gray-contrast-A-tint-xx;
$paxcom-theme-color-contrast-B: $paxcom-color-gray-contrast-B;

//background image
$isBackgroundImageVisible: false;
@function get-opacity($return-opacity) {
    @if $isBackgroundImageVisible{
      @return $return-opacity;
    }
    @return 1;
}

//illustration color
$illustration-color: 'paxcom-blue'; //green | blue | gray | paxcom-blue

//global, toolbar & menus in app component
$content-background-color : rgba($paxcom-color-white, get-opacity(0.8)); // 0.8 (replace 1 with this if you want to show bg)
$mat-toolbar-background-color: rgba($paxcom-color-gray-dark, get-opacity(0.6)); ; // 0.6 (replace 1 with this if you want to show bg)
$toolbar-paxcom-logo-value: 'dark'; //light | dark
$mat-drawer-content-text-color: $paxcom-color-black; //body text color and also color of text fields.
$mat-sidenav-background-color: rgba($paxcom-theme-color-chromatic-light, get-opacity(0.6));

//Controls - Form Field & Input
$mat-form-field-label : $paxcom-color-black-transparent-20;
$mat-form-field-label-focused : $paxcom-color-black-transparent-80;
$mat-form-field-underline : $paxcom-color-black-transparent-80;
$mat-form-field-fill-background-color : $paxcom-color-black-transparent-90;
$mat-form-field-fill-background-color-disabled : $paxcom-color-black-transparent-90;
$input-element-caret-color : $paxcom-color-black;
$input-element-placeholder-color: $paxcom-color-black-transparent-80;
$input-element-text-color:$mat-drawer-content-text-color;

//Controls - Select
$select-panel-background : $paxcom-color-black-transparent-90;
$mat-option-color : $paxcom-color-black-transparent-20;
$mat-option-active-color : $paxcom-color-white;
$mat-option-active-background : $paxcom-color-black;
$mat-option-focus-background : $paxcom-color-black-transparent-80; //$paxcom-color-black;
$mat-select-value-color : $paxcom-color-black-transparent-20;
$mat-select-arrow-color : $paxcom-theme-color-tint-x;

//controls - slide-toggle
$mat-slide-toggle-thumb-color-unselected : $paxcom-color-grays-shade-xx;
$mat-slide-toggle-bg-color-unselected : $paxcom-color-black-transparent-80;

//Control - Button
$paxcom-typography-button-light-text-color: $paxcom-color-white;

//Component - dialog
$paxcom-dialog-content-background-color: $paxcom-theme-color-tint-xxx;
$paxcom-dialog-title-text-color: $paxcom-color-white-transparent-20;
$paxcom-dialog-title-background-color: $paxcom-theme-color-primary;
$paxcom-dialog-content-text-color:  $paxcom-color-black;
$paxcom-dialog-actions-text-color: $paxcom-color-black;
$paxcom-dialog-actions-background-color: $paxcom-color-black-transparent-90;


//Component - wizard steps related colors
$wizard-step-color-pending :$paxcom-color-black-transparent-60;
$wizard-step-color-current : $paxcom-theme-color-contrast-A;
$wizard-step-color-done :$paxcom-theme-color-tint-xxxxx;
$wizard-step-bg-color-current : $content-background-color;
$wizard-step-phone-title-color : $paxcom-color-black-transparent-50;
$paxcom-typography-wizard-toolbar-title-color: $paxcom-color-black;
$paxcom-typography-wizard-title-color: $paxcom-color-black;
$paxcom-typography-wizard-description-color : $paxcom-color-black-transparent-60;
$paxcom-wizard-steps-footer-transparent-background-color: $paxcom-theme-color-primary;
$paxcom-wizard-steps-footer-background: $paxcom-color-black-transparent-20;
$paxcom-wizard-steps-footer-back-button-color: $paxcom-color-black;
$paxcom-wizard-steps-footer-button-color: $paxcom-theme-color-tint-xxxxxxx;

//Component - Card
$card-title-text-color-selected :$paxcom-theme-color-contrast-A-tint-x;
$card-title-text-color-unselected :$paxcom-color-black-transparent-50;
$card-image-background-color : $paxcom-color-gray-tint-x;
$card-image-background-color-selected : $paxcom-theme-color-tint-xxxxxx;
$card-image-toggle-color-selected : $paxcom-theme-color-contrast-A;
$card-input-text-color : $paxcom-color-black;
$card-text-color: $paxcom-color-white-transparent-60;

//Component - Spinner
$store-setup-spinner-text-color: $paxcom-color-white;
$store-setup-spinner-color: $paxcom-color-white;
$app-spinner-color: $paxcom-color-white;

//Component - Search
$search-header-color: $paxcom-color-black-transparent-80;
$search-header-background: rgba($paxcom-theme-color-tint-xxx, get-opacity(0.8));


//Component - Category Selector
$paxcom-selected-category-highlight: $card-image-background-color-selected;

//Screen - welcome
$welcome-title-color: $paxcom-color-black;
$welcome-sub-title-color: $paxcom-color-black-transparent-30;

//Screen - Congratulations
$congratulations-title-color: $paxcom-color-black;
$congratulations-sub-title-color: $paxcom-color-black-transparent-10;

//Screen - Payment Card
$paxcom-wizard-step-payment-card-bg-color: $paxcom-theme-color-tint-xx;