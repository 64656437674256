// Review [internal|intermittent] (2021-10-31T12:43:27) : Move all px/em/rem/percent values to var/mixin
.container-splash{
    padding:16px;
    background-color:transparent;
    align-items: center;
    text-align: center;
    display:flex;
    flex-direction: column;
}

.image-hor-size{
    width: calc(100% - 64px);
}



.progress-container{
    width:100%;
    background: transparent;
    height:2px;
}
.progress{
    width:24px;
    background-color: white;
    height:2px;
}
.progress-catch-up{
    width:24px;
    background-color: white;
    opacity: 0.3;
    height:2px;
    box-shadow: 0 0 5px 10px #555;
    transform: translateX(-5px);
}

.progress-bar-base{
    width: calc(100% - 128px);
    background-color: #4d5257;
    height:2px;
    margin-top:16px;
    overflow: hidden;
}
.paxcom-logo-splash{
    margin-top:124px;
    width: calc(100% - 128px);
}

.paxcom-copyright{
    padding: 16px;
    position:fixed;
    bottom:0;
    text-align: center;
    width: calc(100% - 16px);
    color: white;
    opacity: 0.3;
}


.indeterminent-progress{
    animation: indeterminent-progress 3s infinite both;
}

@keyframes indeterminent-progress {
    0% {
            transform: translateX(-10%);
        }
    50% {
            transform: translateX(100%);
        }
    100% {
            transform: translateX(-10%);
        }
}	

