.demo-drawer--custom {
  @include mdc-drawer-border-color(lightblue);
  @include mdc-drawer-subtitle-ink-color(darkolivegreen);
  @include mdc-drawer-surface-fill-color(black);
  @include mdc-drawer-item-icon-ink-color(darkolivegreen);
  @include mdc-drawer-title-ink-color(darkolivegreen);
  @include mdc-drawer-activated-overlay-color(darkcyan);
  @include mdc-drawer-item-text-ink-color(darkolivegreen);
  @include mdc-drawer-item-activated-text-ink-color(darkcyan);
  @include mdc-drawer-item-activated-icon-ink-color(darkcyan);
}

.demo-drawer--accessible {
  @include mdc-drawer-fill-color-accessible(darkcyan);
  @include mdc-drawer-activated-overlay-color(pink);
  @include mdc-drawer-item-activated-text-ink-color(pink);
  @include mdc-drawer-item-activated-icon-ink-color(pink);
}

.demo-drawer-app {
  border: 1px solid rgba(0, 0, 0, .15);
  min-height: 16.7rem;
  overflow: hidden;
  position: relative;
  z-index: 0;

  .mdc-drawer-scrim, .mdc-drawer--modal {
    position: absolute;
  }
}
