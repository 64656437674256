.demo-icon-custom-colors {
  $color: $material-color-orange-500;

  @include mdc-theme-prop(color, $color);
}

.temporary-workaround-for-text-field-svg {
  top: .90em
}

.mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__icon {
  fill: rgba(0, 0, 0, .54);
}

.demo-svg-custom-color {
  $color: $material-color-orange-500;

  @include mdc-theme-prop(fill, $color);
}
