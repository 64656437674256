
.flex-container {
    display: flex;
    overflow-y: auto;
    // float: left;
    @include media("<=phone") {
        flex-direction: column;
        padding: $padding-normal;
        // width: calc(100% - (#{$padding-normal} * 2))
    }
    @include media(">phone") {
        width: 100%;
        flex-direction: row;
    }

    @include media(">tabs-xxphones", "<=desktop-xx") {
        padding: $padding-02-00x;
        width: calc(100% - (#{$padding-02-00x} * 2))
    }

    @include media(">=desktop-xx") {
        width: 100%;
    }
}

.flex-container-single-col {
    display: flex;
    width: 100%;

    @include media("<=phone") {
        flex-direction: column;
        padding: $padding-normal;
        width: calc(100% - (#{$padding-normal} * 2))
    }
    @include media(">phone") {
        flex-direction: column;
    }

    @include media(">phone", "<desktop-xx") {
        padding: $padding-02-00x;
        // width: 50vw;
        .illustration{
            img{
                max-width: 18vw;
            }
        }
    }

    @include media(">=desktop-xx") {
        // width: 50vw;
        .illustration{
            img{
                max-width: 30vw;
            }
        }
    }
}

