// Review [internal|intermittent] (2021-10-31T12:29:51) :  move all hardcoded px/em/percent values to vars or mixins

.paxcom-logo.xlarge {
    text-align: center;

    &.portrait {
        img {
            width: 80vw;
        }

        @include media("<=phone") {
            padding-top: 5vh;
            display: block;
        }

        @include media(">phone") {
            display: none;
        }
    }

    &.landscape {
        img {
            width: 300px;
        }

        @include media("<=phone") {
            display: none;
        }

        @include media(">phone") {
            padding: $padding-normal;
            padding-top: $padding-06-00x;
            display: block;
        }
    }
}

.paxcom-logo.desktop.toolbar {
    padding: $padding-normal;
    img {
        width: 12vw;
    }
}