// Review [internal|intermittent] (2021-10-31T12:29:27) :  move all hardcoded px/em/percent values to vars or mixins

@mixin illustrationColor($value: 'green') {

    .green,
    .blue,
    .purple,
    .paxcom-blue {
        display: none;
    }

    @if $value=='paxcom-blue' {
        .paxcom-blue {
            display: block;
        }
    }

    @if $value=='green' {
        .green {
            display: block;
        }
    }

    @if $value=='purple' {
        .purple {
            display: block;
        }
    }

    @if $value=='blue' {
        .blue {
            display: block;
        }
    }
}

@include media("<=phone") {
    .illustration {
        img {
            width: 80%;
            height: unset;
            max-height: 25vh;

            @media (max-height: 570px) {
                max-height: 25vh;
            }

            @media (max-height: 640px) and (min-height: 571px) {
                max-height: 30vh;
            }

            @media (max-height: 731px) and (min-height:641px) {
                max-height: 38vh;
            }

            @media (max-height: 820px) and (min-height:732px) {
                max-height: 60vh;
            }

            @media (min-height: 821px) {
                max-height: 70vh;
            }
        }

        @include illustrationColor($illustration-color);
    }
}

@include media(">phone") {
    .illustration {
        img {
            // width: unset;
            // height: 80vh;
        }
    }

    @include illustrationColor($illustration-color);
}