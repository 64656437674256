// Review [internal|intermittent] (2021-10-31T12:44:05) : Move all px/em/rem/percent values to var/mixin

.flex-container,
.flex-container-single-col {
    .illustration {
        flex: 1;
        text-align: center;
        height: 100%;
    }
}

.flex-container,
.flex-container-single-col {
    .welcome {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;

        .welcome-content {
            display: flex;
            flex-direction: column;

            .welcome-title {
                flex: 1;
                text-align: center;

                .title-container {
                    @include media(">=phone") {
                        padding: 16px !important;
                    }

                    .title-xxx-large {
                        @media (max-height: 640px) {
                            font-size: $font-size-02-00x !important;
                        }

                        @media (max-height: 731px) and (min-height:641px) {
                            font-size: $font-size-03-00x !important;
                        }

                        @media (max-height: 823px) and (min-height:732px) {
                            font-size: $font-size-03-00x !important;
                        }
                    }
                }
            }

            .welcome-text {
                flex: 1;
                text-align: center;
                margin-top: 16px;
                
                .body-container{
                    @include media("<=tabs-xxphones") {
                        // max-width: calc(100% - 32px);
                        // width: $max-card-width-small;
                    }
                
                    @include media(">tabs-xxphones", "<=desktop-xx") {
                        width: 100vh !important;
                    }
                
                    @include media(">=desktop-xx") {
                        width: 100vh !important
                    }
                }
            }
        }

        .spacer {
            flex: 1;
        }

        .welcome-footer-container {
            margin-bottom: 32px;
        }
    }
}