
// Review [internal|intermittent] (2021-10-31T12:30:55) :   replace hardcoded colors with pax-theme color
// Review [internal|intermittent] (2021-10-31T12:31:00) :  move all hardcoded px/em/percent values to vars or mixins



$font-size-10-00x: 160px;
$font-size-09-00x: 144px;
$font-size-08-00x: 128px;
$font-size-07-00x: 112px;
$font-size-06-00x: 96px;
$font-size-05-00x: 80px;
$font-size-04-50x: 72px;
$font-size-04-00x: 64px;
$font-size-03-50x: 56px;
$font-size-03-00x: 48px;
$font-size-02-50x: 40px;
$font-size-02-00x: 32px;
$font-size-01-50x: 24px;
$font-size-normal-plus: 20px;
$font-size-normal-plus-minus: 18px;
$font-size-normal: 16px;
$font-size-x-small: 14px;
$font-size-x-tiny: 12px;

$padding-10-00x: 80px;
$padding-09-00x: 72px;
$padding-08-00x: 68px;
$padding-07-00x: 62px;
$padding-06-00x: 56px;
$padding-05-00x: 48px;
$padding-04-50x: 44px;
$padding-04-00x: 40px;
$padding-03-50x: 36px;
$padding-03-00x: 32px;
$padding-02-50x: 28px;
$padding-02-00x: 24px;
$padding-01-50x: 20px;
$padding-normal: 16px;
$padding-x-tiny: 8px;

.title-xxx-large {
  font-family: $title-font-name !important;
  font-weight: $title-font-weight-black !important;
  color: $welcome-title-color !important;
  font-size: $font-size-04-00x !important;

  @include media(">=desktop-xx", "<retina-0") {
    // font-size: $font-size-06-00x;
  }
}

.body-container {
  padding: 16px !important;
}

.body-normal-light {
  font-family: $body-font-name !important;
  font-weight: $body-font-weight-medium !important;
  color: $welcome-sub-title-color !important;
  font-size: $font-size-normal !important;

  @include media(">=desktop-xx", "<retina-0") {
    font-size: $font-size-01-50x !important;
  }
}

@mixin paxcomTypographyButton() {
  font-family: $body-font-name !important;
  font-weight: $body-font-weight-medium !important;
  font-size: $font-size-normal !important;

  // @include media(">=desktop-xx", "<retina-0") {
  //   font-size: $font-size-01-50x !important;
  //   padding: $padding-01-50x !important;
  // }
}

@mixin paxcomTypographyButtonLight() {
  @include paxcomTypographyButton();
  background: $paxcom-theme-color-primary;
  color: $paxcom-typography-button-light-text-color;
  opacity: 0.8;
}


@mixin paxcomTypographyWizardToolbarTitle() {
  font-family: $title-font-name;
  font-weight: $title-font-weight-light;
  color: $paxcom-typography-wizard-toolbar-title-color;
  letter-spacing: .05em;

  @include media(">tabs-xxphones", "<=desktop-xx") {
    height: 1em;
    font-size: $font-size-01-50x;
  }

  @include media(">=desktop-xx") {
    height: 1em;
    font-size: $font-size-03-00x;
  }

}

.card-title {
  font-family: $title-font-name !important;
  font-weight: $title-font-weight-medium;
  color: $card-title-text-color-unselected !important;
  letter-spacing: .05em !important;
  text-overflow: ellipsis !important;
  overflow: hidden !important;
  padding-bottom: 5px !important;

  @include media("<=tabs-xxphones") {
    height: 1.2em !important;
    font-size: $font-size-normal-plus-minus !important;
  }
  
  @include media(">tabs-xxphones", "<=desktop-xx") {
    height:2em !important;
    font-size: $font-size-normal-plus-minus !important;
  }

  @include media(">=desktop-xx") {
    height: 1.2em !important;
    font-size: $font-size-01-50x !important;
  }
}

.card-title-unselected {
  transition: 0.5s;
  color: $card-title-text-color-unselected !important;
}

.card-title-selected {
  transition: 0.5s;
  color: $card-title-text-color-selected !important;
}

.product-card-title-container {
  overflow-y: hidden;
  font-family: $title-font-name !important;
  font-weight: $title-font-weight-light !important;
  padding-bottom: 5px !important;

  @include media("<=tabs-xxphones") {
    font-size: $font-size-normal-plus !important;
    height: 2.3em !important; //for two-cols, changed from max-height to height
  }

  @include media(">tabs-xxphones", "<=desktop-xx") {
    font-size: $font-size-normal-plus-minus !important;
    height: 2.3em !important; //for two-cols, changed from max-height to height
  }

  @include media(">=desktop-xx") {
    font-size: $font-size-01-50x !important;
    height: 2.3em !important; //for two-cols, changed from max-height to height
  }
}

.product-card-price-title {
  font-family: $title-font-name;
  font-weight: $title-font-weight-light;
  letter-spacing: .05em;

  @include media("<=tabs-xxphones") {
    font-size: $font-size-normal-plus !important;
  }

  @include media(">tabs-xxphones", "<=desktop-xx") {
    font-size: $font-size-normal-plus !important;
  }

  @include media(">=desktop-xx") {
    font-size: $font-size-normal-plus !important;
  }
}
.product-card-input-action-buttons{
  color: $card-title-text-color-unselected !important;
}


@mixin paxcomTypographyWizardTitle() {
  font-family: $title-font-name !important;
  font-weight: $title-font-weight-light !important;
  color: $paxcom-typography-wizard-title-color !important;
  letter-spacing: .05em !important;

  @include media(">tabs-xxphones", "<=desktop-xx") {
    // height: 1em !important;
    font-size: $font-size-normal-plus !important;
  }

  @include media(">=desktop-xx") {
    // height: 1em !important;
    font-size: $font-size-normal-plus !important;
  }
}

@mixin paxcomTypographyWizardDescription() {
  font-family: $body-font-name !important;
  color: $paxcom-typography-wizard-description-color;
  padding-top: 8px;
  @include media(">tabs-xxphones", "<=desktop-xx") {
    font-size: $font-size-x-small !important;
    // height: 3em !important;
  }

  @include media(">=desktop-xx") {
    font-size: $font-size-x-small !important;
    // height: 3em !important;
  }

}

.welcome-title {
  
  @include media("<tabs-xxphones") {
    margin-top: 16px
  }

  @include media(">tabs-xxphones") {
    margin-top: 8px
  }

  @include media(">desktop-xx") {
    margin-top: 8px
  }

}

.shop-open-info-text {
  margin:16px;
  margin-top: 32px;
  text-align: center;
  font-family: $body-font-name;
  color: $congratulations-sub-title-color;
  letter-spacing: .05em;
  align-items: center;
  line-height: 32px;
  font-size: $font-size-normal;
  @include media("<tabs-xxphones") {
    font-size: $font-size-normal;
  }

  @include media(">tabs-xxphones","<desktop-xx") {
    font-size: $font-size-normal;
    max-width:60%
  }

  @include media(">=desktop-xx") {
    font-size: $font-size-01-50x;
    max-width:50%
  }
}

.congrats-on-shop-open {
  text-align: center;
  margin-top: 32px;
  font-family: $title-font-name;
  font-weight: $title-font-weight-black;
  align-items: center;
  color: $congratulations-title-color;
  font-size: $font-size-01-50x;
  @include media("<tabs-xxphones") {
    font-size: $font-size-normal-plus;
  }

  @include media(">tabs-xxphones","<desktop-xx") {
    font-size: $font-size-normal-plus;
  }

}