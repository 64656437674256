
// Review [internal|intermittent] (2021-10-31T12:40:36) : Move all px/em/rem/percent values to var/mixin

@import "../../environments/environment.scss";
$assetsBaseUrl:$path;
$margin-normal: 16px;

.card-with-image {
    cursor: pointer !important;    
    margin: 16px 0px 16px 0px !important;
    padding: 0px !important;    
    background-color: transparent !important;    
    border-radius: 16px !important;    
    box-shadow: none !important;    
    filter: drop-shadow(5px 5px 4px rgba(0, 0, 0, 0.25)) !important;
}

.matCardClipperContainer {
    padding: 0px;
    border-radius: 16px;
    mask-image: url($assetsBaseUrl+"assets/Onboard/masks/card-mask.png");
    mask-size: 100% auto;
    mask-repeat: no-repeat;
    mask-position: top;
}

.matCardContainer {
    margin-top: 0px;
    margin-bottom: 0px;
    padding-top: 0px;
}


.card-title-container {
    display: flex;
    flex-direction: row;
    width: 100%;

    .toggle-button-container {
        align-self: center;
        width: unset;
    }
    .card-title {
        flex: 1;
    }    
}

.mat-card-actions, .mat-card-content, .mat-card-subtitle {
    margin-bottom: 0px !important;
}

.card-with-image-input-text{
    color: $card-input-text-color !important;
}

mat-card-content {
    padding: 16px  !important;;
}

.card-bottom-row {
    display: flex;
    justify-content: center;
    align-items: center;
    // margin-top:16px;
}

.card-bottom-row-middle {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
}

.card-bottom-row-left {
    display: flex;
    flex: 1;
    align-items: center;
}

.card-bottom-row-right {
    display: flex;
    flex: 1;
    justify-content: flex-end;
}

.down-button-chavron path {
    fill: white;
}

mat-slide-toggle .mat-slide-toggle-bar {
     background: $mat-slide-toggle-bg-color-unselected;
}

.card-image {
    margin-left: -16px;
    margin-bottom: 0px;
}

.card-bottom-row-left>h2 {
    margin-top: auto;
    margin-bottom: auto;
}

.card-extended-div-collapsed {
    display: none;
    height: 0px;
}

.card-extended-div-extended {
    display: block;
    height: auto;
}

// mat-form-field mat-label {
//     color: white;
//     opacity: 0.7;
// }

.rotate0 {
    animation: rotate0 0.3s linear forwards;
}

@keyframes rotate0 {
    0% {
        transform: rotateZ(180deg);
    }

    100% {
        transform: rotateZ(0deg);
    }
}


.rotate180 {
    animation: rotate180 0.3s linear forwards;
}

@keyframes rotate180 {
    0% {
        transform: rotateZ(0deg);
    }

    100% {
        transform: rotateZ(180deg);
    }
}

.product-card-exapanded-fixed{
    opacity: 1;
    height: calc(100% - 16px - 2px);
}

.slide-out-bottom {
    transition: opacity,height, transform 0.2s cubic-bezier(0, 0, .2, 1);    
    opacity: 1;
    height: calc(100% - 16px - 2px);
}

.slide-in-bottom {
    transition: opacity,height, transform 0.2s cubic-bezier(0, 0, .2, 1);    
    opacity: 0;
    height: 0;
}

.light-travel {
    transition: 0.5s !important;
    transform: translateX(-200px) !important;
    opacity: 0.2 !important;
}

.light-travel-backwards {
    transition: 0.5s !important;
    transform: translateX(200px) !important;
    opacity: 0 !important;
}

.card-z-push {
    transition: 0.5s !important;
    transform: scale(1) translate(2px, 2px) !important;
    filter: drop-shadow(0px 0px 0px rgba(0, 0, 0, 0.25))!important;
    // display: table !important;
}

.card-z-push-back {
    transition: 0.5s !important;
    transform: scale(1) translate(0px, 0px) !important;
    filter: drop-shadow(5px 5px 4px rgba(0, 0, 0, 0.25)) !important;
}
