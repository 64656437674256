.welcomeText{
    color: $store-setup-spinner-text-color;
    min-height:3.5em; 
    flex:1;
    font-size: xx-large; 
    font-family:Arial; 
    font-weight: 900; 
    text-align: center;
    margin : 16px;
    margin-top:64px;
}

.store-creating-progress-illustration{
    max-width:30vw;
    @include media("<=phone") {
        max-width:90vw;
    }
}

.cdk-overlay-dark-backdrop{
    background:rgba(0, 0, 0, 0.8) !important;
}


.disappearAppear {
    animation: spin 2s linear;
}

@keyframes spin {
    0% {
        color: $store-setup-spinner-text-color;
    }

    50% {
        color: transparent;
    }

    100% {
        color: $store-setup-spinner-text-color;
    }
}

.jello-horizontal {
    -webkit-animation: jello-horizontal 2.5s infinite;
    animation: jello-horizontal 2.5s infinite;
}

$box-translate-x-start-pos : -30vw;
$box-translate-x-rest-pos : 0px;
$box-translate-x-end-pos : 130vw;

@-webkit-keyframes jello-horizontal {
    0% {
        -webkit-transform: scale3d(1, 1, 1) translateX($box-translate-x-start-pos);
        transform: scale3d(1, 1, 1) translateX($box-translate-x-start-pos);
    }

    30% {
        -webkit-transform: scale3d(1.04, 0.9, 1)  translateX($box-translate-x-rest-pos);
        transform: scale3d(1.04, 0.9, 1)  translateX($box-translate-x-rest-pos);
        opacity:1;
    }

    35% {
        -webkit-transform: scale3d(0.9, 1.04, 1);
        transform: scale3d(0.9, 1.04, 1);
    }

    40% {
        -webkit-transform: scale3d(1.05, 0.96, 1);
        transform: scale3d(1.05, 0.96, 1);
    }

    55% {
        -webkit-transform: scale3d(0.99, 1.05, 1);
        transform: scale3d(0.99, 1.05, 1);
    }

    65% {
        -webkit-transform: scale3d(1.01, 0.99, 1) translateX($box-translate-x-rest-pos);
        transform: scale3d(1.01, 0.99, 1) translateX($box-translate-x-rest-pos);
        opacity:1;
    }

    85% {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
        opacity:0;
    }
    
    100% {
        -webkit-transform: scale3d(1, 1, 1) translateX($box-translate-x-end-pos);
        transform: scale3d(1, 1, 1) translateX($box-translate-x-end-pos);
        opacity:0;
    }
}

@keyframes jello-horizontal {
    0% {
        -webkit-transform: scale3d(1, 1, 1) translateX($box-translate-x-start-pos);
        transform: scale3d(1, 1, 1) translateX($box-translate-x-start-pos);
    }

    20% {
        -webkit-transform: scale3d(1.04, 0.9, 1)  translateX($box-translate-x-rest-pos);
        transform: scale3d(1.04, 0.9, 1)  translateX($box-translate-x-rest-pos);
        opacity:1;
    }

    30% {
        -webkit-transform: scale3d(0.9, 1.04, 1);
        transform: scale3d(0.9, 1.04, 1);
    }

    40% {
        -webkit-transform: scale3d(1.05, 0.96, 1);
        transform: scale3d(1.05, 0.96, 1);
    }

    55% {
        -webkit-transform: scale3d(0.99, 1.05, 1);
        transform: scale3d(0.99, 1.05, 1);
    }

    65% {
        -webkit-transform: scale3d(1.01, 0.99, 1) translateX($box-translate-x-rest-pos);
        transform: scale3d(1.01, 0.99, 1) translateX($box-translate-x-rest-pos);
        opacity:1;
    }

    75% {
        -webkit-transform: scale3d(1, 1, 1) translateX($box-translate-x-end-pos);
        transform: scale3d(1, 1, 1) translateX($box-translate-x-end-pos);
        opacity:0;
    }
    100% {
        -webkit-transform: scale3d(1, 1, 1) translateX($box-translate-x-end-pos);
        transform: scale3d(1, 1, 1) translateX($box-translate-x-end-pos);
        opacity:0;
    }
}