.custom-snackbar--shape-radius {
  @include mdc-snackbar-shape-radius(10px);
}

.custom-snackbar--fill-color {
  @include mdc-snackbar-fill-color($material-color-red-500);
}

.custom-snackbar--label-ink-color {
  @include mdc-snackbar-label-ink-color($material-color-yellow-500);
}

.custom-snackbar--viewport-margin {
  @include mdc-snackbar-viewport-margin(50px);
}

.custom-snackbar--elevation {
  @include mdc-snackbar-elevation(16);
}

.custom-snackbar--max-width {
  @include mdc-snackbar-max-width(200px);
}

.custom-snackbar--min-width {
  @include mdc-snackbar-min-width(800px);
}
