$custom-switch-color: $material-color-red-500;

.demo-switch--custom {
  @include mdc-switch-toggled-on-color($custom-switch-color);
}

.custom-switch--thumb-color {
  @include mdc-switch-toggled-on-thumb-color($custom-switch-color);
  @include mdc-switch-toggled-off-thumb-color($custom-switch-color);
}

.custom-switch--track-color {
  @include mdc-switch-toggled-on-track-color($custom-switch-color);
  @include mdc-switch-toggled-off-track-color($custom-switch-color);
}

.custom-switch__label-left-margin {
  .mdc-switch + label {
    margin-left: 10px;
  }
}
