.demo-checkbox--custom-all {
  $color: $material-color-red-500;

  @include mdc-checkbox-focus-indicator-color($color);
  @include mdc-states($color);
  @include mdc-checkbox-container-colors(
    $unmarked-stroke-color: $color,
    $unmarked-fill-color: rgba($color, .25),
    $marked-fill-color: $color,
    $generate-keyframes: false);
}

.demo-checkbox--custom-stroke-and-fill {
  @include mdc-checkbox-container-colors(
    $unmarked-stroke-color: $material-color-blue-500,
    $marked-fill-color: $material-color-purple-500,
    $generate-keyframes: false);
}
