@import '~highlight.js/styles/atom-one-dark.css';
@import "./app/mat-theme-custom.css";
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,500,700,900);
body {
  margin: 0;
}

::-ms-clear {
  display: none;
}

::-ms-reveal {
  display: none;
}

.mdc-button {
  font-family: Roboto, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 0.875rem;
  line-height: 2.25rem;
  font-weight: 500;
  letter-spacing: 0.0892857143em;
  text-decoration: none;
  text-transform: uppercase;
  padding: 0 8px 0 8px;
  display: inline-flex;
  position: relative;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  min-width: 64px;
  height: 36px;
  border: none;
  outline: none;
  /* @alternate */
  line-height: inherit;
  user-select: none;
  -webkit-appearance: none;
  overflow: hidden;
  vertical-align: middle;
  border-radius: 4px;
}
.mdc-button::-moz-focus-inner {
  padding: 0;
  border: 0;
}
.mdc-button:active {
  outline: none;
}
.mdc-button:hover {
  cursor: pointer;
}
.mdc-button:disabled {
  background-color: transparent;
  color: rgba(0, 0, 0, 0.37);
  cursor: default;
  pointer-events: none;
}
.mdc-button.mdc-button--dense {
  border-radius: 4px;
}
.mdc-button:not(:disabled) {
  background-color: transparent;
}
.mdc-button .mdc-button__icon {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 8px;
  display: inline-block;
  width: 18px;
  height: 18px;
  font-size: 18px;
  vertical-align: top;
}
[dir=rtl] .mdc-button .mdc-button__icon, .mdc-button .mdc-button__icon[dir=rtl] {
  /* @noflip */
  margin-left: 8px;
  /* @noflip */
  margin-right: 0;
}
.mdc-button:not(:disabled) {
  color: #808080;
  /* @alternate */
  color: var(--mdc-theme-primary, #808080);
}

.mdc-button__label + .mdc-button__icon {
  /* @noflip */
  margin-left: 8px;
  /* @noflip */
  margin-right: 0;
}
[dir=rtl] .mdc-button__label + .mdc-button__icon, .mdc-button__label + .mdc-button__icon[dir=rtl] {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 8px;
}

svg.mdc-button__icon {
  fill: currentColor;
}

.mdc-button--raised .mdc-button__icon,
.mdc-button--unelevated .mdc-button__icon,
.mdc-button--outlined .mdc-button__icon {
  /* @noflip */
  margin-left: -4px;
  /* @noflip */
  margin-right: 8px;
}
[dir=rtl] .mdc-button--raised .mdc-button__icon, .mdc-button--raised .mdc-button__icon[dir=rtl],
[dir=rtl] .mdc-button--unelevated .mdc-button__icon,
.mdc-button--unelevated .mdc-button__icon[dir=rtl],
[dir=rtl] .mdc-button--outlined .mdc-button__icon,
.mdc-button--outlined .mdc-button__icon[dir=rtl] {
  /* @noflip */
  margin-left: 8px;
  /* @noflip */
  margin-right: -4px;
}
.mdc-button--raised .mdc-button__label + .mdc-button__icon,
.mdc-button--unelevated .mdc-button__label + .mdc-button__icon,
.mdc-button--outlined .mdc-button__label + .mdc-button__icon {
  /* @noflip */
  margin-left: 8px;
  /* @noflip */
  margin-right: -4px;
}
[dir=rtl] .mdc-button--raised .mdc-button__label + .mdc-button__icon, .mdc-button--raised .mdc-button__label + .mdc-button__icon[dir=rtl],
[dir=rtl] .mdc-button--unelevated .mdc-button__label + .mdc-button__icon,
.mdc-button--unelevated .mdc-button__label + .mdc-button__icon[dir=rtl],
[dir=rtl] .mdc-button--outlined .mdc-button__label + .mdc-button__icon,
.mdc-button--outlined .mdc-button__label + .mdc-button__icon[dir=rtl] {
  /* @noflip */
  margin-left: -4px;
  /* @noflip */
  margin-right: 8px;
}

.mdc-button--raised,
.mdc-button--unelevated {
  padding: 0 16px 0 16px;
}
.mdc-button--raised:disabled,
.mdc-button--unelevated:disabled {
  background-color: rgba(0, 0, 0, 0.12);
  color: rgba(0, 0, 0, 0.37);
}
.mdc-button--raised:not(:disabled),
.mdc-button--unelevated:not(:disabled) {
  background-color: #808080;
}
@supports not (-ms-ime-align: auto) {
  .mdc-button--raised:not(:disabled),
.mdc-button--unelevated:not(:disabled) {
    /* @alternate */
    background-color: var(--mdc-theme-primary, #808080);
  }
}
.mdc-button--raised:not(:disabled),
.mdc-button--unelevated:not(:disabled) {
  color: #fff;
  /* @alternate */
  color: var(--mdc-theme-on-primary, #fff);
}

.mdc-button--raised {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
}
.mdc-button--raised:hover, .mdc-button--raised:focus {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.mdc-button--raised:active {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.mdc-button--raised:disabled {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mdc-button--outlined {
  border-style: solid;
  padding: 0 15px 0 15px;
  border-width: 1px;
}
.mdc-button--outlined:disabled {
  border-color: rgba(0, 0, 0, 0.37);
}
.mdc-button--outlined:not(:disabled) {
  border-color: #808080;
  /* @alternate */
  border-color: var(--mdc-theme-primary, #808080);
}

.mdc-button--dense {
  height: 32px;
  font-size: 0.8125rem;
}

@keyframes mdc-ripple-fg-radius-in {
  from {
    animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transform: translate(var(--mdc-ripple-fg-translate-start, 0)) scale(1);
  }
  to {
    transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1));
  }
}
@keyframes mdc-ripple-fg-opacity-in {
  from {
    animation-timing-function: linear;
    opacity: 0;
  }
  to {
    opacity: var(--mdc-ripple-fg-opacity, 0);
  }
}
@keyframes mdc-ripple-fg-opacity-out {
  from {
    animation-timing-function: linear;
    opacity: var(--mdc-ripple-fg-opacity, 0);
  }
  to {
    opacity: 0;
  }
}
.mdc-ripple-surface--test-edge-var-bug {
  --mdc-ripple-surface-test-edge-var: 1px solid #000;
  visibility: hidden;
}
.mdc-ripple-surface--test-edge-var-bug::before {
  border: var(--mdc-ripple-surface-test-edge-var);
}

.mdc-button {
  --mdc-ripple-fg-size: 0;
  --mdc-ripple-left: 0;
  --mdc-ripple-top: 0;
  --mdc-ripple-fg-scale: 1;
  --mdc-ripple-fg-translate-end: 0;
  --mdc-ripple-fg-translate-start: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.mdc-button::before, .mdc-button::after {
  position: absolute;
  border-radius: 50%;
  opacity: 0;
  pointer-events: none;
  content: "";
}
.mdc-button::before {
  transition: opacity 15ms linear, background-color 15ms linear;
  z-index: 1;
}
.mdc-button.mdc-ripple-upgraded::before {
  transform: scale(var(--mdc-ripple-fg-scale, 1));
}
.mdc-button.mdc-ripple-upgraded::after {
  top: 0;
  /* @noflip */
  left: 0;
  transform: scale(0);
  transform-origin: center center;
}
.mdc-button.mdc-ripple-upgraded--unbounded::after {
  top: var(--mdc-ripple-top, 0);
  /* @noflip */
  left: var(--mdc-ripple-left, 0);
}
.mdc-button.mdc-ripple-upgraded--foreground-activation::after {
  animation: mdc-ripple-fg-radius-in 225ms forwards, mdc-ripple-fg-opacity-in 75ms forwards;
}
.mdc-button.mdc-ripple-upgraded--foreground-deactivation::after {
  animation: mdc-ripple-fg-opacity-out 150ms;
  transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1));
}
.mdc-button::before, .mdc-button::after {
  top: calc(50% - 100%);
  /* @noflip */
  left: calc(50% - 100%);
  width: 200%;
  height: 200%;
}
.mdc-button.mdc-ripple-upgraded::after {
  width: var(--mdc-ripple-fg-size, 100%);
  height: var(--mdc-ripple-fg-size, 100%);
}
.mdc-button::before, .mdc-button::after {
  background-color: #808080;
}
@supports not (-ms-ime-align: auto) {
  .mdc-button::before, .mdc-button::after {
    /* @alternate */
    background-color: var(--mdc-theme-primary, #808080);
  }
}
.mdc-button:hover::before {
  opacity: 0.04;
}
.mdc-button:not(.mdc-ripple-upgraded):focus::before, .mdc-button.mdc-ripple-upgraded--background-focused::before {
  transition-duration: 75ms;
  opacity: 0.12;
}
.mdc-button:not(.mdc-ripple-upgraded)::after {
  transition: opacity 150ms linear;
}
.mdc-button:not(.mdc-ripple-upgraded):active::after {
  transition-duration: 75ms;
  opacity: 0.12;
}
.mdc-button.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: 0.12;
}

.mdc-button--raised::before, .mdc-button--raised::after,
.mdc-button--unelevated::before,
.mdc-button--unelevated::after {
  background-color: #fff;
}
@supports not (-ms-ime-align: auto) {
  .mdc-button--raised::before, .mdc-button--raised::after,
.mdc-button--unelevated::before,
.mdc-button--unelevated::after {
    /* @alternate */
    background-color: var(--mdc-theme-on-primary, #fff);
  }
}
.mdc-button--raised:hover::before,
.mdc-button--unelevated:hover::before {
  opacity: 0.08;
}
.mdc-button--raised:not(.mdc-ripple-upgraded):focus::before, .mdc-button--raised.mdc-ripple-upgraded--background-focused::before,
.mdc-button--unelevated:not(.mdc-ripple-upgraded):focus::before,
.mdc-button--unelevated.mdc-ripple-upgraded--background-focused::before {
  transition-duration: 75ms;
  opacity: 0.24;
}
.mdc-button--raised:not(.mdc-ripple-upgraded)::after,
.mdc-button--unelevated:not(.mdc-ripple-upgraded)::after {
  transition: opacity 150ms linear;
}
.mdc-button--raised:not(.mdc-ripple-upgraded):active::after,
.mdc-button--unelevated:not(.mdc-ripple-upgraded):active::after {
  transition-duration: 75ms;
  opacity: 0.24;
}
.mdc-button--raised.mdc-ripple-upgraded,
.mdc-button--unelevated.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: 0.24;
}

.mdc-card {
  border-radius: 4px;
  background-color: #fff;
  /* @alternate */
  background-color: var(--mdc-theme-surface, #fff);
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
}

.mdc-card--outlined {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  border-width: 1px;
  border-style: solid;
  border-color: #e0e0e0;
}

.mdc-card__media {
  position: relative;
  box-sizing: border-box;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.mdc-card__media::before {
  display: block;
  content: "";
}

.mdc-card__media:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}

.mdc-card__media:last-child {
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
}

.mdc-card__media--square::before {
  margin-top: 100%;
}

.mdc-card__media--16-9::before {
  margin-top: 56.25%;
}

.mdc-card__media-content {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  box-sizing: border-box;
}

.mdc-card__primary-action {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  position: relative;
  outline: none;
  color: inherit;
  text-decoration: none;
  cursor: pointer;
  overflow: hidden;
}

.mdc-card__primary-action:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}

.mdc-card__primary-action:last-child {
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
}

.mdc-card__actions {
  display: flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
  min-height: 52px;
  padding: 8px;
}

.mdc-card__actions--full-bleed {
  padding: 0;
}

.mdc-card__action-buttons,
.mdc-card__action-icons {
  display: flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
}

.mdc-card__action-icons {
  color: rgba(0, 0, 0, 0.6);
  flex-grow: 1;
  justify-content: flex-end;
}

.mdc-card__action-buttons + .mdc-card__action-icons {
  /* @noflip */
  margin-left: 16px;
  /* @noflip */
  margin-right: 0;
}
[dir=rtl] .mdc-card__action-buttons + .mdc-card__action-icons, .mdc-card__action-buttons + .mdc-card__action-icons[dir=rtl] {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 16px;
}

.mdc-card__action {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
  justify-content: center;
  cursor: pointer;
  user-select: none;
}
.mdc-card__action:focus {
  outline: none;
}

.mdc-card__action--button {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 8px;
  padding: 0 8px;
}
[dir=rtl] .mdc-card__action--button, .mdc-card__action--button[dir=rtl] {
  /* @noflip */
  margin-left: 8px;
  /* @noflip */
  margin-right: 0;
}
.mdc-card__action--button:last-child {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 0;
}
[dir=rtl] .mdc-card__action--button:last-child, .mdc-card__action--button:last-child[dir=rtl] {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 0;
}

.mdc-card__actions--full-bleed .mdc-card__action--button {
  justify-content: space-between;
  width: 100%;
  height: auto;
  max-height: none;
  margin: 0;
  padding: 8px 16px;
  /* @noflip */
  text-align: left;
}
[dir=rtl] .mdc-card__actions--full-bleed .mdc-card__action--button, .mdc-card__actions--full-bleed .mdc-card__action--button[dir=rtl] {
  /* @noflip */
  text-align: right;
}

.mdc-card__action--icon {
  margin: -6px 0;
  padding: 12px;
}

.mdc-card__action--icon:not(:disabled) {
  color: rgba(0, 0, 0, 0.6);
}

.mdc-card__primary-action {
  --mdc-ripple-fg-size: 0;
  --mdc-ripple-left: 0;
  --mdc-ripple-top: 0;
  --mdc-ripple-fg-scale: 1;
  --mdc-ripple-fg-translate-end: 0;
  --mdc-ripple-fg-translate-start: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.mdc-card__primary-action::before, .mdc-card__primary-action::after {
  position: absolute;
  border-radius: 50%;
  opacity: 0;
  pointer-events: none;
  content: "";
}
.mdc-card__primary-action::before {
  transition: opacity 15ms linear, background-color 15ms linear;
  z-index: 1;
}
.mdc-card__primary-action.mdc-ripple-upgraded::before {
  transform: scale(var(--mdc-ripple-fg-scale, 1));
}
.mdc-card__primary-action.mdc-ripple-upgraded::after {
  top: 0;
  /* @noflip */
  left: 0;
  transform: scale(0);
  transform-origin: center center;
}
.mdc-card__primary-action.mdc-ripple-upgraded--unbounded::after {
  top: var(--mdc-ripple-top, 0);
  /* @noflip */
  left: var(--mdc-ripple-left, 0);
}
.mdc-card__primary-action.mdc-ripple-upgraded--foreground-activation::after {
  animation: mdc-ripple-fg-radius-in 225ms forwards, mdc-ripple-fg-opacity-in 75ms forwards;
}
.mdc-card__primary-action.mdc-ripple-upgraded--foreground-deactivation::after {
  animation: mdc-ripple-fg-opacity-out 150ms;
  transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1));
}
.mdc-card__primary-action::before, .mdc-card__primary-action::after {
  top: calc(50% - 100%);
  /* @noflip */
  left: calc(50% - 100%);
  width: 200%;
  height: 200%;
}
.mdc-card__primary-action.mdc-ripple-upgraded::after {
  width: var(--mdc-ripple-fg-size, 100%);
  height: var(--mdc-ripple-fg-size, 100%);
}
.mdc-card__primary-action::before, .mdc-card__primary-action::after {
  background-color: #000;
}
.mdc-card__primary-action:hover::before {
  opacity: 0.04;
}
.mdc-card__primary-action:not(.mdc-ripple-upgraded):focus::before, .mdc-card__primary-action.mdc-ripple-upgraded--background-focused::before {
  transition-duration: 75ms;
  opacity: 0.12;
}
.mdc-card__primary-action:not(.mdc-ripple-upgraded)::after {
  transition: opacity 150ms linear;
}
.mdc-card__primary-action:not(.mdc-ripple-upgraded):active::after {
  transition-duration: 75ms;
  opacity: 0.12;
}
.mdc-card__primary-action.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: 0.12;
}

@keyframes mdc-checkbox-unchecked-checked-checkmark-path {
  0%, 50% {
    stroke-dashoffset: 29.7833385;
  }
  50% {
    animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
  }
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes mdc-checkbox-unchecked-indeterminate-mixedmark {
  0%, 68.2% {
    transform: scaleX(0);
  }
  68.2% {
    animation-timing-function: cubic-bezier(0, 0, 0, 1);
  }
  100% {
    transform: scaleX(1);
  }
}
@keyframes mdc-checkbox-checked-unchecked-checkmark-path {
  from {
    animation-timing-function: cubic-bezier(0.4, 0, 1, 1);
    opacity: 1;
    stroke-dashoffset: 0;
  }
  to {
    opacity: 0;
    stroke-dashoffset: -29.7833385;
  }
}
@keyframes mdc-checkbox-checked-indeterminate-checkmark {
  from {
    animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
    transform: rotate(0deg);
    opacity: 1;
  }
  to {
    transform: rotate(45deg);
    opacity: 0;
  }
}
@keyframes mdc-checkbox-indeterminate-checked-checkmark {
  from {
    animation-timing-function: cubic-bezier(0.14, 0, 0, 1);
    transform: rotate(45deg);
    opacity: 0;
  }
  to {
    transform: rotate(360deg);
    opacity: 1;
  }
}
@keyframes mdc-checkbox-checked-indeterminate-mixedmark {
  from {
    animation-timing-function: mdc-animation-deceleration-curve-timing-function;
    transform: rotate(-45deg);
    opacity: 0;
  }
  to {
    transform: rotate(0deg);
    opacity: 1;
  }
}
@keyframes mdc-checkbox-indeterminate-checked-mixedmark {
  from {
    animation-timing-function: cubic-bezier(0.14, 0, 0, 1);
    transform: rotate(0deg);
    opacity: 1;
  }
  to {
    transform: rotate(315deg);
    opacity: 0;
  }
}
@keyframes mdc-checkbox-indeterminate-unchecked-mixedmark {
  0% {
    animation-timing-function: linear;
    transform: scaleX(1);
    opacity: 1;
  }
  32.8%, 100% {
    transform: scaleX(0);
    opacity: 0;
  }
}
.mdc-checkbox {
  display: inline-block;
  position: relative;
  flex: 0 0 18px;
  box-sizing: content-box;
  width: 18px;
  height: 18px;
  padding: 11px;
  line-height: 0;
  white-space: nowrap;
  cursor: pointer;
  vertical-align: bottom;
}

.mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate) ~ .mdc-checkbox__background {
  border-color: rgba(0, 0, 0, 0.54);
  background-color: transparent;
}

.mdc-checkbox__native-control:enabled:checked ~ .mdc-checkbox__background,
.mdc-checkbox__native-control:enabled:indeterminate ~ .mdc-checkbox__background {
  border-color: #1A73E8;
  /* @alternate */
  border-color: var(--mdc-theme-secondary, #1A73E8);
  background-color: #1A73E8;
  /* @alternate */
  background-color: var(--mdc-theme-secondary, #1A73E8);
}

@keyframes mdc-checkbox-fade-in-background-0 {
  0% {
    border-color: rgba(0, 0, 0, 0.54);
    background-color: transparent;
  }
  50% {
    border-color: #1A73E8;
    /* @alternate */
    border-color: var(--mdc-theme-secondary, #1A73E8);
    background-color: #1A73E8;
    /* @alternate */
    background-color: var(--mdc-theme-secondary, #1A73E8);
  }
}
@keyframes mdc-checkbox-fade-out-background-0 {
  0%, 80% {
    border-color: #1A73E8;
    /* @alternate */
    border-color: var(--mdc-theme-secondary, #1A73E8);
    background-color: #1A73E8;
    /* @alternate */
    background-color: var(--mdc-theme-secondary, #1A73E8);
  }
  100% {
    border-color: rgba(0, 0, 0, 0.54);
    background-color: transparent;
  }
}
.mdc-checkbox--anim-unchecked-checked .mdc-checkbox__native-control:enabled ~ .mdc-checkbox__background, .mdc-checkbox--anim-unchecked-indeterminate .mdc-checkbox__native-control:enabled ~ .mdc-checkbox__background {
  animation-name: mdc-checkbox-fade-in-background-0;
}
.mdc-checkbox--anim-checked-unchecked .mdc-checkbox__native-control:enabled ~ .mdc-checkbox__background, .mdc-checkbox--anim-indeterminate-unchecked .mdc-checkbox__native-control:enabled ~ .mdc-checkbox__background {
  animation-name: mdc-checkbox-fade-out-background-0;
}

.mdc-checkbox__checkmark {
  color: #fff;
}

.mdc-checkbox__mixedmark {
  border-color: #fff;
}

.mdc-checkbox__background::before {
  background-color: #1A73E8;
}
@supports not (-ms-ime-align: auto) {
  .mdc-checkbox__background::before {
    /* @alternate */
    background-color: var(--mdc-theme-secondary, #1A73E8);
  }
}

.mdc-checkbox__native-control[disabled]:not(:checked):not(:indeterminate) ~ .mdc-checkbox__background {
  border-color: rgba(0, 0, 0, 0.26);
}

.mdc-checkbox__native-control[disabled]:checked ~ .mdc-checkbox__background,
.mdc-checkbox__native-control[disabled]:indeterminate ~ .mdc-checkbox__background {
  border-color: transparent;
  background-color: rgba(0, 0, 0, 0.26);
}

@media screen and (-ms-high-contrast: active) {
  .mdc-checkbox__mixedmark {
    margin: 0 1px;
  }
}
.mdc-checkbox--disabled {
  cursor: default;
  pointer-events: none;
}

.mdc-checkbox__background {
  /* @noflip */
  left: 11px;
  /* @noflip */
  right: initial;
  display: inline-flex;
  position: absolute;
  top: 11px;
  bottom: 0;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  width: 45%;
  height: 45%;
  border: 2px solid currentColor;
  border-radius: 2px;
  background-color: transparent;
  pointer-events: none;
  will-change: background-color, border-color;
  transition: background-color 90ms 0ms cubic-bezier(0.4, 0, 0.6, 1), border-color 90ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
}
.mdc-checkbox[dir=rtl] .mdc-checkbox__background, [dir=rtl] .mdc-checkbox .mdc-checkbox__background {
  /* @noflip */
  left: initial;
  /* @noflip */
  right: 11px;
}

.mdc-checkbox__checkmark {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  opacity: 0;
  transition: opacity 180ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
}
.mdc-checkbox--upgraded .mdc-checkbox__checkmark {
  opacity: 1;
}

.mdc-checkbox__checkmark-path {
  transition: stroke-dashoffset 180ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
  stroke: currentColor;
  stroke-width: 3.12px;
  stroke-dashoffset: 29.7833385;
  stroke-dasharray: 29.7833385;
}

.mdc-checkbox__mixedmark {
  width: 100%;
  height: 0;
  transform: scaleX(0) rotate(0deg);
  border-width: 1px;
  border-style: solid;
  opacity: 0;
  transition: opacity 90ms 0ms cubic-bezier(0.4, 0, 0.6, 1), transform 90ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
}

.mdc-checkbox--upgraded .mdc-checkbox__background,
.mdc-checkbox--upgraded .mdc-checkbox__checkmark,
.mdc-checkbox--upgraded .mdc-checkbox__checkmark-path,
.mdc-checkbox--upgraded .mdc-checkbox__mixedmark {
  transition: none !important;
}

.mdc-checkbox--anim-unchecked-checked .mdc-checkbox__background, .mdc-checkbox--anim-unchecked-indeterminate .mdc-checkbox__background, .mdc-checkbox--anim-checked-unchecked .mdc-checkbox__background, .mdc-checkbox--anim-indeterminate-unchecked .mdc-checkbox__background {
  animation-duration: 180ms;
  animation-timing-function: linear;
}
.mdc-checkbox--anim-unchecked-checked .mdc-checkbox__checkmark-path {
  animation: mdc-checkbox-unchecked-checked-checkmark-path 180ms linear 0s;
  transition: none;
}
.mdc-checkbox--anim-unchecked-indeterminate .mdc-checkbox__mixedmark {
  animation: mdc-checkbox-unchecked-indeterminate-mixedmark 90ms linear 0s;
  transition: none;
}
.mdc-checkbox--anim-checked-unchecked .mdc-checkbox__checkmark-path {
  animation: mdc-checkbox-checked-unchecked-checkmark-path 90ms linear 0s;
  transition: none;
}
.mdc-checkbox--anim-checked-indeterminate .mdc-checkbox__checkmark {
  animation: mdc-checkbox-checked-indeterminate-checkmark 90ms linear 0s;
  transition: none;
}
.mdc-checkbox--anim-checked-indeterminate .mdc-checkbox__mixedmark {
  animation: mdc-checkbox-checked-indeterminate-mixedmark 90ms linear 0s;
  transition: none;
}
.mdc-checkbox--anim-indeterminate-checked .mdc-checkbox__checkmark {
  animation: mdc-checkbox-indeterminate-checked-checkmark 500ms linear 0s;
  transition: none;
}
.mdc-checkbox--anim-indeterminate-checked .mdc-checkbox__mixedmark {
  animation: mdc-checkbox-indeterminate-checked-mixedmark 500ms linear 0s;
  transition: none;
}
.mdc-checkbox--anim-indeterminate-unchecked .mdc-checkbox__mixedmark {
  animation: mdc-checkbox-indeterminate-unchecked-mixedmark 300ms linear 0s;
  transition: none;
}

.mdc-checkbox__native-control:checked ~ .mdc-checkbox__background,
.mdc-checkbox__native-control:indeterminate ~ .mdc-checkbox__background {
  transition: border-color 90ms 0ms cubic-bezier(0, 0, 0.2, 1), background-color 90ms 0ms cubic-bezier(0, 0, 0.2, 1);
}
.mdc-checkbox__native-control:checked ~ .mdc-checkbox__background .mdc-checkbox__checkmark-path,
.mdc-checkbox__native-control:indeterminate ~ .mdc-checkbox__background .mdc-checkbox__checkmark-path {
  stroke-dashoffset: 0;
}

.mdc-checkbox__background::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform: scale(0, 0);
  border-radius: 50%;
  opacity: 0;
  pointer-events: none;
  content: "";
  will-change: opacity, transform;
  transition: opacity 90ms 0ms cubic-bezier(0.4, 0, 0.6, 1), transform 90ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
}

.mdc-checkbox__native-control:focus ~ .mdc-checkbox__background::before {
  transform: scale(2.75, 2.75);
  opacity: 0.12;
  transition: opacity 80ms 0ms cubic-bezier(0, 0, 0.2, 1), transform 80ms 0ms cubic-bezier(0, 0, 0.2, 1);
}

.mdc-checkbox__native-control {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  opacity: 0;
  cursor: inherit;
}
.mdc-checkbox__native-control:disabled {
  cursor: default;
  pointer-events: none;
}

.mdc-checkbox__native-control:checked ~ .mdc-checkbox__background .mdc-checkbox__checkmark {
  transition: opacity 180ms 0ms cubic-bezier(0, 0, 0.2, 1), transform 180ms 0ms cubic-bezier(0, 0, 0.2, 1);
  opacity: 1;
}
.mdc-checkbox__native-control:checked ~ .mdc-checkbox__background .mdc-checkbox__mixedmark {
  transform: scaleX(1) rotate(-45deg);
}

.mdc-checkbox__native-control:indeterminate ~ .mdc-checkbox__background .mdc-checkbox__checkmark {
  transform: rotate(45deg);
  opacity: 0;
  transition: opacity 90ms 0ms cubic-bezier(0.4, 0, 0.6, 1), transform 90ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
}
.mdc-checkbox__native-control:indeterminate ~ .mdc-checkbox__background .mdc-checkbox__mixedmark {
  transform: scaleX(1) rotate(0deg);
  opacity: 1;
}

.mdc-checkbox {
  --mdc-ripple-fg-size: 0;
  --mdc-ripple-left: 0;
  --mdc-ripple-top: 0;
  --mdc-ripple-fg-scale: 1;
  --mdc-ripple-fg-translate-end: 0;
  --mdc-ripple-fg-translate-start: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.mdc-checkbox::before, .mdc-checkbox::after {
  position: absolute;
  border-radius: 50%;
  opacity: 0;
  pointer-events: none;
  content: "";
}
.mdc-checkbox::before {
  transition: opacity 15ms linear, background-color 15ms linear;
  z-index: 1;
}
.mdc-checkbox.mdc-ripple-upgraded::before {
  transform: scale(var(--mdc-ripple-fg-scale, 1));
}
.mdc-checkbox.mdc-ripple-upgraded::after {
  top: 0;
  /* @noflip */
  left: 0;
  transform: scale(0);
  transform-origin: center center;
}
.mdc-checkbox.mdc-ripple-upgraded--unbounded::after {
  top: var(--mdc-ripple-top, 0);
  /* @noflip */
  left: var(--mdc-ripple-left, 0);
}
.mdc-checkbox.mdc-ripple-upgraded--foreground-activation::after {
  animation: mdc-ripple-fg-radius-in 225ms forwards, mdc-ripple-fg-opacity-in 75ms forwards;
}
.mdc-checkbox.mdc-ripple-upgraded--foreground-deactivation::after {
  animation: mdc-ripple-fg-opacity-out 150ms;
  transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1));
}
.mdc-checkbox::before, .mdc-checkbox::after {
  background-color: #1A73E8;
}
@supports not (-ms-ime-align: auto) {
  .mdc-checkbox::before, .mdc-checkbox::after {
    /* @alternate */
    background-color: var(--mdc-theme-secondary, #1A73E8);
  }
}
.mdc-checkbox:hover::before {
  opacity: 0.04;
}
.mdc-checkbox:not(.mdc-ripple-upgraded):focus::before, .mdc-checkbox.mdc-ripple-upgraded--background-focused::before {
  transition-duration: 75ms;
  opacity: 0.12;
}
.mdc-checkbox:not(.mdc-ripple-upgraded)::after {
  transition: opacity 150ms linear;
}
.mdc-checkbox:not(.mdc-ripple-upgraded):active::after {
  transition-duration: 75ms;
  opacity: 0.12;
}
.mdc-checkbox.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: 0.12;
}
.mdc-checkbox::before, .mdc-checkbox::after {
  top: calc(50% - 50%);
  /* @noflip */
  left: calc(50% - 50%);
  width: 100%;
  height: 100%;
}
.mdc-checkbox.mdc-ripple-upgraded::before, .mdc-checkbox.mdc-ripple-upgraded::after {
  top: var(--mdc-ripple-top, calc(50% - 50%));
  /* @noflip */
  left: var(--mdc-ripple-left, calc(50% - 50%));
  width: var(--mdc-ripple-fg-size, 100%);
  height: var(--mdc-ripple-fg-size, 100%);
}
.mdc-checkbox.mdc-ripple-upgraded::after {
  width: var(--mdc-ripple-fg-size, 100%);
  height: var(--mdc-ripple-fg-size, 100%);
}

.mdc-ripple-upgraded--background-focused .mdc-checkbox__background::before {
  content: none;
}

.mdc-chip__icon--leading {
  color: rgba(0, 0, 0, 0.54);
}

.mdc-chip__icon--trailing {
  color: rgba(0, 0, 0, 0.54);
}
.mdc-chip__icon--trailing:hover {
  color: rgba(0, 0, 0, 0.62);
}
.mdc-chip__icon--trailing:focus {
  color: rgba(0, 0, 0, 0.87);
}

.mdc-chip__icon.mdc-chip__icon--leading:not(.mdc-chip__icon--leading-hidden) {
  width: 20px;
  height: 20px;
  font-size: 20px;
}

.mdc-chip__icon.mdc-chip__icon--trailing {
  width: 18px;
  height: 18px;
  font-size: 18px;
}

.mdc-chip__icon--trailing {
  margin: 0 -4px 0 4px;
}

.mdc-chip {
  border-radius: 16px;
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.87);
  font-family: Roboto, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 400;
  letter-spacing: 0.0178571429em;
  text-decoration: inherit;
  text-transform: inherit;
  height: 32px;
  display: inline-flex;
  position: relative;
  align-items: center;
  box-sizing: border-box;
  padding: 7px 12px;
  outline: none;
  cursor: pointer;
  overflow: hidden;
}
.mdc-chip:hover {
  color: rgba(0, 0, 0, 0.87);
}
.mdc-chip.mdc-chip--selected .mdc-chip__checkmark,
.mdc-chip .mdc-chip__icon--leading:not(.mdc-chip__icon--leading-hidden) {
  /* @noflip */
  margin-left: -4px;
  /* @noflip */
  margin-right: 4px;
  margin-top: -4px;
  margin-bottom: -4px;
}
[dir=rtl] .mdc-chip.mdc-chip--selected .mdc-chip__checkmark, .mdc-chip.mdc-chip--selected .mdc-chip__checkmark[dir=rtl],
[dir=rtl] .mdc-chip .mdc-chip__icon--leading:not(.mdc-chip__icon--leading-hidden),
.mdc-chip .mdc-chip__icon--leading:not(.mdc-chip__icon--leading-hidden)[dir=rtl] {
  /* @noflip */
  margin-left: 4px;
  /* @noflip */
  margin-right: -4px;
}
.mdc-chip:hover {
  color: #000;
  /* @alternate */
  color: var(--mdc-theme-on-surface, #000);
}

.mdc-chip--exit {
  transition: opacity 75ms cubic-bezier(0.4, 0, 0.2, 1), width 150ms cubic-bezier(0, 0, 0.2, 1), padding 100ms linear, margin 100ms linear;
  opacity: 0;
}

.mdc-chip__text {
  white-space: nowrap;
}

.mdc-chip__icon {
  border-radius: 50%;
  outline: none;
  vertical-align: middle;
}

.mdc-chip__checkmark {
  height: 20px;
}

.mdc-chip__checkmark-path {
  transition: stroke-dashoffset 150ms 50ms cubic-bezier(0.4, 0, 0.6, 1);
  stroke-width: 2px;
  stroke-dashoffset: 29.7833385;
  stroke-dasharray: 29.7833385;
}

.mdc-chip--selected .mdc-chip__checkmark-path {
  stroke-dashoffset: 0;
}

.mdc-chip-set--choice .mdc-chip.mdc-chip--selected {
  color: #808080;
  /* @alternate */
  color: var(--mdc-theme-primary, #808080);
}
.mdc-chip-set--choice .mdc-chip.mdc-chip--selected .mdc-chip__icon--leading {
  color: rgba(128, 128, 128, 0.54);
}
.mdc-chip-set--choice .mdc-chip.mdc-chip--selected:hover {
  color: #808080;
  /* @alternate */
  color: var(--mdc-theme-primary, #808080);
}
.mdc-chip-set--choice .mdc-chip .mdc-chip__checkmark-path {
  stroke: #808080;
  /* @alternate */
  stroke: var(--mdc-theme-primary, #808080);
}
.mdc-chip-set--choice .mdc-chip--selected {
  background-color: #fff;
  /* @alternate */
  background-color: var(--mdc-theme-surface, #fff);
}

.mdc-chip__checkmark-svg {
  width: 0;
  height: 20px;
  transition: width 150ms cubic-bezier(0.4, 0, 0.2, 1);
}

.mdc-chip--selected .mdc-chip__checkmark-svg {
  width: 20px;
}

.mdc-chip-set--filter .mdc-chip__icon--leading {
  transition: opacity 75ms linear;
  transition-delay: -50ms;
  opacity: 1;
}
.mdc-chip-set--filter .mdc-chip__icon--leading + .mdc-chip__checkmark {
  transition: opacity 75ms linear;
  transition-delay: 80ms;
  opacity: 0;
}
.mdc-chip-set--filter .mdc-chip__icon--leading + .mdc-chip__checkmark .mdc-chip__checkmark-svg {
  transition: width 0ms;
}
.mdc-chip-set--filter .mdc-chip--selected .mdc-chip__icon--leading {
  opacity: 0;
}
.mdc-chip-set--filter .mdc-chip--selected .mdc-chip__icon--leading + .mdc-chip__checkmark {
  width: 0;
  opacity: 1;
}
.mdc-chip-set--filter .mdc-chip__icon--leading-hidden.mdc-chip__icon--leading {
  width: 0;
  opacity: 0;
}
.mdc-chip-set--filter .mdc-chip__icon--leading-hidden.mdc-chip__icon--leading + .mdc-chip__checkmark {
  width: 20px;
}

.mdc-chip {
  --mdc-ripple-fg-size: 0;
  --mdc-ripple-left: 0;
  --mdc-ripple-top: 0;
  --mdc-ripple-fg-scale: 1;
  --mdc-ripple-fg-translate-end: 0;
  --mdc-ripple-fg-translate-start: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.mdc-chip::before, .mdc-chip::after {
  position: absolute;
  border-radius: 50%;
  opacity: 0;
  pointer-events: none;
  content: "";
}
.mdc-chip::before {
  transition: opacity 15ms linear, background-color 15ms linear;
  z-index: 1;
}
.mdc-chip.mdc-ripple-upgraded::before {
  transform: scale(var(--mdc-ripple-fg-scale, 1));
}
.mdc-chip.mdc-ripple-upgraded::after {
  top: 0;
  /* @noflip */
  left: 0;
  transform: scale(0);
  transform-origin: center center;
}
.mdc-chip.mdc-ripple-upgraded--unbounded::after {
  top: var(--mdc-ripple-top, 0);
  /* @noflip */
  left: var(--mdc-ripple-left, 0);
}
.mdc-chip.mdc-ripple-upgraded--foreground-activation::after {
  animation: mdc-ripple-fg-radius-in 225ms forwards, mdc-ripple-fg-opacity-in 75ms forwards;
}
.mdc-chip.mdc-ripple-upgraded--foreground-deactivation::after {
  animation: mdc-ripple-fg-opacity-out 150ms;
  transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1));
}
.mdc-chip::before, .mdc-chip::after {
  top: calc(50% - 100%);
  /* @noflip */
  left: calc(50% - 100%);
  width: 200%;
  height: 200%;
}
.mdc-chip.mdc-ripple-upgraded::after {
  width: var(--mdc-ripple-fg-size, 100%);
  height: var(--mdc-ripple-fg-size, 100%);
}
.mdc-chip::before, .mdc-chip::after {
  background-color: rgba(0, 0, 0, 0.87);
}
.mdc-chip:hover::before {
  opacity: 0.04;
}
.mdc-chip:not(.mdc-ripple-upgraded):focus::before, .mdc-chip.mdc-ripple-upgraded--background-focused::before {
  transition-duration: 75ms;
  opacity: 0.12;
}
.mdc-chip:not(.mdc-ripple-upgraded)::after {
  transition: opacity 150ms linear;
}
.mdc-chip:not(.mdc-ripple-upgraded):active::after {
  transition-duration: 75ms;
  opacity: 0.12;
}
.mdc-chip.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: 0.12;
}

.mdc-chip-set--choice .mdc-chip.mdc-chip--selected::before {
  opacity: 0.08;
}
.mdc-chip-set--choice .mdc-chip.mdc-chip--selected::before, .mdc-chip-set--choice .mdc-chip.mdc-chip--selected::after {
  background-color: #808080;
}
@supports not (-ms-ime-align: auto) {
  .mdc-chip-set--choice .mdc-chip.mdc-chip--selected::before, .mdc-chip-set--choice .mdc-chip.mdc-chip--selected::after {
    /* @alternate */
    background-color: var(--mdc-theme-primary, #808080);
  }
}
.mdc-chip-set--choice .mdc-chip.mdc-chip--selected:hover::before {
  opacity: 0.12;
}
.mdc-chip-set--choice .mdc-chip.mdc-chip--selected:not(.mdc-ripple-upgraded):focus::before, .mdc-chip-set--choice .mdc-chip.mdc-chip--selected.mdc-ripple-upgraded--background-focused::before {
  transition-duration: 75ms;
  opacity: 0.2;
}
.mdc-chip-set--choice .mdc-chip.mdc-chip--selected:not(.mdc-ripple-upgraded)::after {
  transition: opacity 150ms linear;
}
.mdc-chip-set--choice .mdc-chip.mdc-chip--selected:not(.mdc-ripple-upgraded):active::after {
  transition-duration: 75ms;
  opacity: 0.2;
}
.mdc-chip-set--choice .mdc-chip.mdc-chip--selected.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: 0.2;
}

@keyframes mdc-chip-entry {
  from {
    transform: scale(0.8);
    opacity: 0.4;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}
.mdc-chip-set {
  padding: 4px;
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
}
.mdc-chip-set .mdc-chip {
  margin: 4px;
}

.mdc-chip-set--input .mdc-chip {
  animation: mdc-chip-entry 100ms cubic-bezier(0, 0, 0.2, 1);
}

.mdc-dialog,
.mdc-dialog__scrim {
  position: fixed;
  top: 0;
  left: 0;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
}

.mdc-dialog {
  display: none;
  z-index: 7;
}
.mdc-dialog .mdc-dialog__surface {
  background-color: #fff;
  /* @alternate */
  background-color: var(--mdc-theme-surface, #fff);
}
.mdc-dialog .mdc-dialog__scrim {
  background-color: rgba(0, 0, 0, 0.32);
}
.mdc-dialog .mdc-dialog__title {
  color: rgba(0, 0, 0, 0.87);
}
.mdc-dialog .mdc-dialog__content {
  color: rgba(0, 0, 0, 0.6);
}
.mdc-dialog.mdc-dialog--scrollable .mdc-dialog__title, .mdc-dialog.mdc-dialog--scrollable .mdc-dialog__actions {
  border-color: rgba(0, 0, 0, 0.12);
}
.mdc-dialog .mdc-dialog__surface {
  min-width: 280px;
}
@media (max-width: 592px) {
  .mdc-dialog .mdc-dialog__surface {
    max-width: calc(100vw - 32px);
  }
}
@media (min-width: 592px) {
  .mdc-dialog .mdc-dialog__surface {
    max-width: 560px;
  }
}
.mdc-dialog .mdc-dialog__surface {
  max-height: calc(100vh - 32px);
}
.mdc-dialog .mdc-dialog__surface {
  border-radius: 4px;
}

.mdc-dialog__scrim {
  opacity: 0;
  z-index: -1;
}

.mdc-dialog__container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  box-sizing: border-box;
  transform: scale(0.8);
  opacity: 0;
}

.mdc-dialog__surface {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  display: flex;
  flex-direction: column;
  flex-grow: 0;
  flex-shrink: 0;
  box-sizing: border-box;
  max-width: 100%;
  max-height: 100%;
}
.mdc-dialog[dir=rtl] .mdc-dialog__surface, [dir=rtl] .mdc-dialog .mdc-dialog__surface {
  text-align: right;
}

.mdc-dialog__title {
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
  font-family: Roboto, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 1.25rem;
  line-height: 2rem;
  font-weight: 500;
  letter-spacing: 0.0125em;
  text-decoration: inherit;
  text-transform: inherit;
  display: block;
  position: relative;
  flex-shrink: 0;
  box-sizing: border-box;
  margin: 0;
  padding: 0 24px 9px;
  border-bottom: 1px solid transparent;
}
.mdc-dialog__title::before {
  display: inline-block;
  width: 0;
  height: 40px;
  content: "";
  vertical-align: 0;
}
.mdc-dialog[dir=rtl] .mdc-dialog__title, [dir=rtl] .mdc-dialog .mdc-dialog__title {
  text-align: right;
}

.mdc-dialog--scrollable .mdc-dialog__title {
  padding-bottom: 15px;
}

.mdc-dialog__content {
  font-family: Roboto, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 400;
  letter-spacing: 0.03125em;
  text-decoration: inherit;
  text-transform: inherit;
  flex-grow: 1;
  box-sizing: border-box;
  margin: 0;
  padding: 20px 24px;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}
.mdc-dialog__content > :first-child {
  margin-top: 0;
}
.mdc-dialog__content > :last-child {
  margin-bottom: 0;
}

.mdc-dialog__title + .mdc-dialog__content {
  padding-top: 0;
}

.mdc-dialog--scrollable .mdc-dialog__content {
  padding-top: 8px;
  padding-bottom: 8px;
}

.mdc-dialog__content .mdc-list:first-child:last-child {
  padding: 6px 0 0;
}

.mdc-dialog--scrollable .mdc-dialog__content .mdc-list:first-child:last-child {
  padding: 0;
}

.mdc-dialog__actions {
  display: flex;
  position: relative;
  flex-shrink: 0;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  box-sizing: border-box;
  min-height: 52px;
  margin: 0;
  padding: 8px;
  border-top: 1px solid transparent;
}
.mdc-dialog--stacked .mdc-dialog__actions {
  flex-direction: column;
  align-items: flex-end;
}

.mdc-dialog__button {
  /* @noflip */
  margin-left: 8px;
  /* @noflip */
  margin-right: 0;
  max-width: 100%;
  text-align: right;
}
[dir=rtl] .mdc-dialog__button, .mdc-dialog__button[dir=rtl] {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 8px;
}
.mdc-dialog__button:first-child {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 0;
}
[dir=rtl] .mdc-dialog__button:first-child, .mdc-dialog__button:first-child[dir=rtl] {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 0;
}
.mdc-dialog[dir=rtl] .mdc-dialog__button, [dir=rtl] .mdc-dialog .mdc-dialog__button {
  text-align: left;
}

.mdc-dialog--stacked .mdc-dialog__button:not(:first-child) {
  margin-top: 12px;
}

.mdc-dialog--open,
.mdc-dialog--opening,
.mdc-dialog--closing {
  display: flex;
}

.mdc-dialog--opening .mdc-dialog__scrim {
  transition: opacity 150ms linear;
}
.mdc-dialog--opening .mdc-dialog__container {
  transition: opacity 75ms linear, transform 150ms 0ms cubic-bezier(0, 0, 0.2, 1);
}

.mdc-dialog--closing .mdc-dialog__scrim,
.mdc-dialog--closing .mdc-dialog__container {
  transition: opacity 75ms linear;
}
.mdc-dialog--closing .mdc-dialog__container {
  transform: scale(1);
}

.mdc-dialog--open .mdc-dialog__scrim {
  opacity: 1;
}
.mdc-dialog--open .mdc-dialog__container {
  transform: scale(1);
  opacity: 1;
}

.mdc-dialog-scroll-lock {
  overflow: hidden;
}

.mdc-drawer {
  border-color: rgba(0, 0, 0, 0.12);
  background-color: #fff;
  /* @noflip */
  border-radius: 0 0 0 0;
  z-index: 6;
  width: 256px;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  box-sizing: border-box;
  height: 100%;
  transition-property: transform;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  /* @noflip */
  border-right-width: 1px;
  /* @noflip */
  border-right-style: solid;
  overflow: hidden;
}
.mdc-drawer .mdc-drawer__title {
  color: rgba(0, 0, 0, 0.87);
}
.mdc-drawer .mdc-list-group__subheader {
  color: rgba(0, 0, 0, 0.6);
}
.mdc-drawer .mdc-drawer__subtitle {
  color: rgba(0, 0, 0, 0.6);
}
.mdc-drawer .mdc-list-item__graphic {
  color: rgba(0, 0, 0, 0.6);
}
.mdc-drawer .mdc-list-item {
  color: rgba(0, 0, 0, 0.87);
}
.mdc-drawer .mdc-list-item--activated .mdc-list-item__graphic {
  color: gray;
}
.mdc-drawer .mdc-list-item--activated {
  color: rgba(128, 128, 128, 0.87);
}
[dir=rtl] .mdc-drawer, .mdc-drawer[dir=rtl] {
  /* @noflip */
  border-radius: 0 0 0 0;
}
.mdc-drawer .mdc-list-item {
  border-radius: 4px;
}
.mdc-drawer.mdc-drawer--open:not(.mdc-drawer--closing) + .mdc-drawer-app-content {
  /* @noflip */
  margin-left: 256px;
  /* @noflip */
  margin-right: 0;
}
[dir=rtl] .mdc-drawer.mdc-drawer--open:not(.mdc-drawer--closing) + .mdc-drawer-app-content, .mdc-drawer.mdc-drawer--open:not(.mdc-drawer--closing) + .mdc-drawer-app-content[dir=rtl] {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 256px;
}
[dir=rtl] .mdc-drawer, .mdc-drawer[dir=rtl] {
  /* @noflip */
  border-right-width: 0;
  /* @noflip */
  border-left-width: 1px;
  /* @noflip */
  border-right-style: none;
  /* @noflip */
  border-left-style: solid;
}
.mdc-drawer .mdc-list-item {
  font-family: Roboto, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 0.875rem;
  line-height: 1.375rem;
  font-weight: 500;
  letter-spacing: 0.0071428571em;
  text-decoration: inherit;
  text-transform: inherit;
  height: calc(48px - 2 * 4px);
  margin: 8px 8px;
  padding: 0 8px;
}
.mdc-drawer .mdc-list-item:nth-child(1) {
  margin-top: 2px;
}
.mdc-drawer .mdc-list-item:nth-last-child(1) {
  margin-bottom: 0;
}
.mdc-drawer .mdc-list-group__subheader {
  font-family: Roboto, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 400;
  letter-spacing: 0.0178571429em;
  text-decoration: inherit;
  text-transform: inherit;
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
  margin: 0;
  padding: 0 16px;
}
.mdc-drawer .mdc-list-group__subheader::before {
  display: inline-block;
  width: 0;
  height: 24px;
  content: "";
  vertical-align: 0;
}
.mdc-drawer .mdc-list-divider {
  margin: 3px 0 4px 0;
}
.mdc-drawer .mdc-list-item__text,
.mdc-drawer .mdc-list-item__graphic {
  pointer-events: none;
}

.mdc-drawer--animate {
  transform: translateX(-100%);
}
[dir=rtl] .mdc-drawer--animate, .mdc-drawer--animate[dir=rtl] {
  transform: translateX(100%);
}

.mdc-drawer--opening {
  transform: translateX(0);
  transition-duration: 250ms;
}
[dir=rtl] .mdc-drawer--opening, .mdc-drawer--opening[dir=rtl] {
  transform: translateX(0);
}

.mdc-drawer--closing {
  transform: translateX(-100%);
  transition-duration: 200ms;
}
[dir=rtl] .mdc-drawer--closing, .mdc-drawer--closing[dir=rtl] {
  transform: translateX(100%);
}

.mdc-drawer__header {
  flex-shrink: 0;
  box-sizing: border-box;
  min-height: 64px;
  padding: 0 16px 4px;
}

.mdc-drawer__title {
  font-family: Roboto, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 1.25rem;
  line-height: 2rem;
  font-weight: 500;
  letter-spacing: 0.0125em;
  text-decoration: inherit;
  text-transform: inherit;
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
  margin-bottom: -20px;
}
.mdc-drawer__title::before {
  display: inline-block;
  width: 0;
  height: 36px;
  content: "";
  vertical-align: 0;
}
.mdc-drawer__title::after {
  display: inline-block;
  width: 0;
  height: 20px;
  content: "";
  vertical-align: -20px;
}

.mdc-drawer__subtitle {
  font-family: Roboto, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 400;
  letter-spacing: 0.0178571429em;
  text-decoration: inherit;
  text-transform: inherit;
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
  margin-bottom: 0;
}
.mdc-drawer__subtitle::before {
  display: inline-block;
  width: 0;
  height: 20px;
  content: "";
  vertical-align: 0;
}

.mdc-drawer__content {
  height: 100%;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.mdc-drawer--dismissible {
  /* @noflip */
  left: 0;
  /* @noflip */
  right: initial;
  display: none;
  position: absolute;
}
[dir=rtl] .mdc-drawer--dismissible, .mdc-drawer--dismissible[dir=rtl] {
  /* @noflip */
  left: initial;
  /* @noflip */
  right: 0;
}
.mdc-drawer--dismissible.mdc-drawer--open {
  display: flex;
}

.mdc-drawer-app-content {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 0;
  position: relative;
}
[dir=rtl] .mdc-drawer-app-content, .mdc-drawer-app-content[dir=rtl] {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 0;
}

.mdc-drawer--modal {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  /* @noflip */
  left: 0;
  /* @noflip */
  right: initial;
  display: none;
  position: fixed;
}
.mdc-drawer--modal + .mdc-drawer-scrim {
  background-color: rgba(0, 0, 0, 0.32);
}
[dir=rtl] .mdc-drawer--modal, .mdc-drawer--modal[dir=rtl] {
  /* @noflip */
  left: initial;
  /* @noflip */
  right: 0;
}
.mdc-drawer--modal.mdc-drawer--open {
  display: flex;
}

.mdc-drawer-scrim {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition-property: opacity;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  z-index: 5;
}
.mdc-drawer--open + .mdc-drawer-scrim {
  display: block;
}
.mdc-drawer--animate + .mdc-drawer-scrim {
  opacity: 0;
}
.mdc-drawer--opening + .mdc-drawer-scrim {
  transition-duration: 250ms;
  opacity: 1;
}
.mdc-drawer--closing + .mdc-drawer-scrim {
  transition-duration: 200ms;
  opacity: 0;
}

.mdc-elevation--z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mdc-elevation--z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

.mdc-elevation--z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.mdc-elevation--z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}

.mdc-elevation--z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.mdc-elevation--z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}

.mdc-elevation--z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}

.mdc-elevation--z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}

.mdc-elevation--z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.mdc-elevation--z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}

.mdc-elevation--z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}

.mdc-elevation--z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}

.mdc-elevation--z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}

.mdc-elevation--z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}

.mdc-elevation--z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}

.mdc-elevation--z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}

.mdc-elevation--z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}

.mdc-elevation--z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}

.mdc-elevation--z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}

.mdc-elevation--z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}

.mdc-elevation--z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}

.mdc-elevation--z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}

.mdc-elevation--z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}

.mdc-elevation--z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}

.mdc-elevation--z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}

.mdc-elevation-transition {
  transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
  will-change: box-shadow;
}

.mdc-fab {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  display: inline-flex;
  position: relative;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  width: 56px;
  height: 56px;
  padding: 0;
  border: none;
  fill: currentColor;
  cursor: pointer;
  user-select: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  overflow: hidden;
  transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1), opacity 15ms linear 30ms, transform 270ms 0ms cubic-bezier(0, 0, 0.2, 1);
  background-color: #1A73E8;
  color: #fff;
  /* @alternate */
  color: var(--mdc-theme-on-secondary, #fff);
}
.mdc-fab:not(.mdc-fab--extended) {
  border-radius: 50%;
}
.mdc-fab::-moz-focus-inner {
  padding: 0;
  border: 0;
}
.mdc-fab:hover, .mdc-fab:focus {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.mdc-fab:active {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.mdc-fab:active, .mdc-fab:focus {
  outline: none;
}
.mdc-fab:hover {
  cursor: pointer;
}
.mdc-fab > svg {
  width: 100%;
}
@supports not (-ms-ime-align: auto) {
  .mdc-fab {
    /* @alternate */
    background-color: var(--mdc-theme-secondary, #1A73E8);
  }
}
.mdc-fab .mdc-fab__icon {
  width: 24px;
  height: 24px;
  font-size: 24px;
}

.mdc-fab--mini {
  width: 40px;
  height: 40px;
}

.mdc-fab--extended {
  font-family: Roboto, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 0.875rem;
  line-height: 2.25rem;
  font-weight: 500;
  letter-spacing: 0.0892857143em;
  text-decoration: none;
  text-transform: uppercase;
  border-radius: 24px;
  padding: 0 20px;
  width: auto;
  max-width: 100%;
  height: 48px;
}
.mdc-fab--extended .mdc-fab__icon {
  /* @noflip */
  margin-left: -8px;
  /* @noflip */
  margin-right: 12px;
}
[dir=rtl] .mdc-fab--extended .mdc-fab__icon, .mdc-fab--extended .mdc-fab__icon[dir=rtl] {
  /* @noflip */
  margin-left: 12px;
  /* @noflip */
  margin-right: -8px;
}
.mdc-fab--extended .mdc-fab__label + .mdc-fab__icon {
  /* @noflip */
  margin-left: 12px;
  /* @noflip */
  margin-right: -8px;
}
[dir=rtl] .mdc-fab--extended .mdc-fab__label + .mdc-fab__icon, .mdc-fab--extended .mdc-fab__label + .mdc-fab__icon[dir=rtl] {
  /* @noflip */
  margin-left: -8px;
  /* @noflip */
  margin-right: 12px;
}

.mdc-fab__label {
  justify-content: flex-start;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.mdc-fab__icon {
  transition: transform 180ms 90ms cubic-bezier(0, 0, 0.2, 1);
  fill: currentColor;
  will-change: transform;
}

.mdc-fab .mdc-fab__icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.mdc-fab--exited {
  transform: scale(0);
  opacity: 0;
  transition: opacity 15ms linear 150ms, transform 180ms 0ms cubic-bezier(0.4, 0, 1, 1);
}
.mdc-fab--exited .mdc-fab__icon {
  transform: scale(0);
  transition: transform 135ms 0ms cubic-bezier(0.4, 0, 1, 1);
}

.mdc-fab {
  --mdc-ripple-fg-size: 0;
  --mdc-ripple-left: 0;
  --mdc-ripple-top: 0;
  --mdc-ripple-fg-scale: 1;
  --mdc-ripple-fg-translate-end: 0;
  --mdc-ripple-fg-translate-start: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.mdc-fab::before, .mdc-fab::after {
  position: absolute;
  border-radius: 50%;
  opacity: 0;
  pointer-events: none;
  content: "";
}
.mdc-fab::before {
  transition: opacity 15ms linear, background-color 15ms linear;
  z-index: 1;
}
.mdc-fab.mdc-ripple-upgraded::before {
  transform: scale(var(--mdc-ripple-fg-scale, 1));
}
.mdc-fab.mdc-ripple-upgraded::after {
  top: 0;
  /* @noflip */
  left: 0;
  transform: scale(0);
  transform-origin: center center;
}
.mdc-fab.mdc-ripple-upgraded--unbounded::after {
  top: var(--mdc-ripple-top, 0);
  /* @noflip */
  left: var(--mdc-ripple-left, 0);
}
.mdc-fab.mdc-ripple-upgraded--foreground-activation::after {
  animation: mdc-ripple-fg-radius-in 225ms forwards, mdc-ripple-fg-opacity-in 75ms forwards;
}
.mdc-fab.mdc-ripple-upgraded--foreground-deactivation::after {
  animation: mdc-ripple-fg-opacity-out 150ms;
  transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1));
}
.mdc-fab::before, .mdc-fab::after {
  top: calc(50% - 100%);
  /* @noflip */
  left: calc(50% - 100%);
  width: 200%;
  height: 200%;
}
.mdc-fab.mdc-ripple-upgraded::after {
  width: var(--mdc-ripple-fg-size, 100%);
  height: var(--mdc-ripple-fg-size, 100%);
}
.mdc-fab::before, .mdc-fab::after {
  background-color: #fff;
}
@supports not (-ms-ime-align: auto) {
  .mdc-fab::before, .mdc-fab::after {
    /* @alternate */
    background-color: var(--mdc-theme-on-secondary, #fff);
  }
}
.mdc-fab:hover::before {
  opacity: 0.08;
}
.mdc-fab:not(.mdc-ripple-upgraded):focus::before, .mdc-fab.mdc-ripple-upgraded--background-focused::before {
  transition-duration: 75ms;
  opacity: 0.24;
}
.mdc-fab:not(.mdc-ripple-upgraded)::after {
  transition: opacity 150ms linear;
}
.mdc-fab:not(.mdc-ripple-upgraded):active::after {
  transition-duration: 75ms;
  opacity: 0.24;
}
.mdc-fab.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: 0.24;
}

.mdc-floating-label {
  font-family: Roboto, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 1rem;
  line-height: 1.75rem;
  font-weight: 400;
  letter-spacing: 0.009375em;
  text-decoration: inherit;
  text-transform: inherit;
  position: absolute;
  /* @noflip */
  left: 0;
  /* @noflip */
  transform-origin: left top;
  transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1), color 150ms cubic-bezier(0.4, 0, 0.2, 1);
  /* @alternate */
  line-height: 1.15rem;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: text;
  overflow: hidden;
  will-change: transform;
}
[dir=rtl] .mdc-floating-label, .mdc-floating-label[dir=rtl] {
  /* @noflip */
  right: 0;
  /* @noflip */
  left: auto;
  /* @noflip */
  transform-origin: right top;
  /* @noflip */
  text-align: right;
}

.mdc-floating-label--float-above {
  cursor: auto;
}

.mdc-floating-label--float-above {
  transform: translateY(-50%) scale(0.75);
}

.mdc-floating-label--shake {
  animation: mdc-floating-label-shake-float-above-standard 250ms 1;
}

@keyframes mdc-floating-label-shake-float-above-standard {
  /* clean-css ignore:start */
  0% {
    transform: translateX(calc(0 - 0%)) translateY(-50%) scale(0.75);
  }
  33% {
    animation-timing-function: cubic-bezier(0.5, 0, 0.701732, 0.495819);
    transform: translateX(calc(4% - 0%)) translateY(-50%) scale(0.75);
  }
  66% {
    animation-timing-function: cubic-bezier(0.302435, 0.381352, 0.55, 0.956352);
    transform: translateX(calc(-4% - 0%)) translateY(-50%) scale(0.75);
  }
  100% {
    transform: translateX(calc(0 - 0%)) translateY(-50%) scale(0.75);
  }
  /* clean-css ignore:end */
}
.mdc-form-field {
  font-family: Roboto, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 400;
  letter-spacing: 0.0178571429em;
  text-decoration: inherit;
  text-transform: inherit;
  color: rgba(0, 0, 0, 0.87);
  /* @alternate */
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
  display: inline-flex;
  align-items: center;
  vertical-align: middle;
}
.mdc-form-field > label {
  order: 0;
  /* @noflip */
  margin-right: auto;
  /* @noflip */
  padding-left: 4px;
}
[dir=rtl] .mdc-form-field > label, .mdc-form-field[dir=rtl] > label {
  /* @noflip */
  margin-left: auto;
  /* @noflip */
  padding-right: 4px;
}

.mdc-form-field--align-end > label {
  order: -1;
  /* @noflip */
  margin-left: auto;
  /* @noflip */
  padding-right: 4px;
}
[dir=rtl] .mdc-form-field--align-end > label, .mdc-form-field--align-end[dir=rtl] > label {
  /* @noflip */
  margin-right: auto;
  /* @noflip */
  padding-left: 4px;
}

.mdc-icon-button {
  width: 48px;
  height: 48px;
  padding: 12px;
  font-size: 24px;
  display: inline-block;
  position: relative;
  box-sizing: border-box;
  border: none;
  outline: none;
  background-color: transparent;
  fill: currentColor;
  color: inherit;
  text-decoration: none;
  cursor: pointer;
  user-select: none;
}
.mdc-icon-button svg,
.mdc-icon-button img {
  width: 24px;
  height: 24px;
}
.mdc-icon-button:disabled {
  color: rgba(0, 0, 0, 0.38);
  /* @alternate */
  color: var(--mdc-theme-text-disabled-on-light, rgba(0, 0, 0, 0.38));
  cursor: default;
  pointer-events: none;
}

.mdc-icon-button__icon {
  display: inline-block;
}
.mdc-icon-button__icon.mdc-icon-button__icon--on {
  display: none;
}

.mdc-icon-button--on .mdc-icon-button__icon {
  display: none;
}
.mdc-icon-button--on .mdc-icon-button__icon.mdc-icon-button__icon--on {
  display: inline-block;
}

.mdc-icon-button {
  --mdc-ripple-fg-size: 0;
  --mdc-ripple-left: 0;
  --mdc-ripple-top: 0;
  --mdc-ripple-fg-scale: 1;
  --mdc-ripple-fg-translate-end: 0;
  --mdc-ripple-fg-translate-start: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.mdc-icon-button::before, .mdc-icon-button::after {
  position: absolute;
  border-radius: 50%;
  opacity: 0;
  pointer-events: none;
  content: "";
}
.mdc-icon-button::before {
  transition: opacity 15ms linear, background-color 15ms linear;
  z-index: 1;
}
.mdc-icon-button.mdc-ripple-upgraded::before {
  transform: scale(var(--mdc-ripple-fg-scale, 1));
}
.mdc-icon-button.mdc-ripple-upgraded::after {
  top: 0;
  /* @noflip */
  left: 0;
  transform: scale(0);
  transform-origin: center center;
}
.mdc-icon-button.mdc-ripple-upgraded--unbounded::after {
  top: var(--mdc-ripple-top, 0);
  /* @noflip */
  left: var(--mdc-ripple-left, 0);
}
.mdc-icon-button.mdc-ripple-upgraded--foreground-activation::after {
  animation: mdc-ripple-fg-radius-in 225ms forwards, mdc-ripple-fg-opacity-in 75ms forwards;
}
.mdc-icon-button.mdc-ripple-upgraded--foreground-deactivation::after {
  animation: mdc-ripple-fg-opacity-out 150ms;
  transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1));
}
.mdc-icon-button::before, .mdc-icon-button::after {
  top: calc(50% - 50%);
  /* @noflip */
  left: calc(50% - 50%);
  width: 100%;
  height: 100%;
}
.mdc-icon-button.mdc-ripple-upgraded::before, .mdc-icon-button.mdc-ripple-upgraded::after {
  top: var(--mdc-ripple-top, calc(50% - 50%));
  /* @noflip */
  left: var(--mdc-ripple-left, calc(50% - 50%));
  width: var(--mdc-ripple-fg-size, 100%);
  height: var(--mdc-ripple-fg-size, 100%);
}
.mdc-icon-button.mdc-ripple-upgraded::after {
  width: var(--mdc-ripple-fg-size, 100%);
  height: var(--mdc-ripple-fg-size, 100%);
}
.mdc-icon-button::before, .mdc-icon-button::after {
  background-color: #000;
}
.mdc-icon-button:hover::before {
  opacity: 0.04;
}
.mdc-icon-button:not(.mdc-ripple-upgraded):focus::before, .mdc-icon-button.mdc-ripple-upgraded--background-focused::before {
  transition-duration: 75ms;
  opacity: 0.12;
}
.mdc-icon-button:not(.mdc-ripple-upgraded)::after {
  transition: opacity 150ms linear;
}
.mdc-icon-button:not(.mdc-ripple-upgraded):active::after {
  transition-duration: 75ms;
  opacity: 0.12;
}
.mdc-icon-button.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: 0.12;
}

.mdc-image-list {
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;
  padding: 0;
}

.mdc-image-list__item,
.mdc-image-list__image-aspect-container {
  position: relative;
  box-sizing: border-box;
}

.mdc-image-list__item {
  list-style-type: none;
}

.mdc-image-list__image {
  width: 100%;
}

.mdc-image-list__image-aspect-container .mdc-image-list__image {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.mdc-image-list__image-aspect-container {
  padding-bottom: calc(100% / 1);
}

.mdc-image-list__image {
  border-radius: 0;
}

.mdc-image-list--with-text-protection .mdc-image-list__supporting {
  border-radius: 0 0 0 0;
}

.mdc-image-list__supporting {
  color: rgba(0, 0, 0, 0.87);
  /* @alternate */
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 8px 0;
  line-height: 24px;
}

.mdc-image-list__label {
  font-family: Roboto, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 1rem;
  line-height: 1.75rem;
  font-weight: 400;
  letter-spacing: 0.009375em;
  text-decoration: inherit;
  text-transform: inherit;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.mdc-image-list--with-text-protection .mdc-image-list__supporting {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 48px;
  padding: 0 16px;
  background: rgba(0, 0, 0, 0.6);
  color: #fff;
}

.mdc-image-list--masonry {
  display: block;
}
.mdc-image-list--masonry .mdc-image-list__item {
  break-inside: avoid-column;
}
.mdc-image-list--masonry .mdc-image-list__image {
  display: block;
  height: auto;
}

:root {
  --mdc-layout-grid-margin-desktop: 24px;
  --mdc-layout-grid-gutter-desktop: 24px;
  --mdc-layout-grid-column-width-desktop: 72px;
  --mdc-layout-grid-margin-tablet: 16px;
  --mdc-layout-grid-gutter-tablet: 16px;
  --mdc-layout-grid-column-width-tablet: 72px;
  --mdc-layout-grid-margin-phone: 16px;
  --mdc-layout-grid-gutter-phone: 16px;
  --mdc-layout-grid-column-width-phone: 72px;
}

@media (min-width: 840px) {
  .mdc-layout-grid {
    box-sizing: border-box;
    margin: 0 auto;
    padding: 24px;
    padding: var(--mdc-layout-grid-margin-desktop, 24px);
  }
}
@media (min-width: 480px) and (max-width: 839px) {
  .mdc-layout-grid {
    box-sizing: border-box;
    margin: 0 auto;
    padding: 16px;
    padding: var(--mdc-layout-grid-margin-tablet, 16px);
  }
}
@media (max-width: 479px) {
  .mdc-layout-grid {
    box-sizing: border-box;
    margin: 0 auto;
    padding: 16px;
    padding: var(--mdc-layout-grid-margin-phone, 16px);
  }
}

@media (min-width: 840px) {
  .mdc-layout-grid__inner {
    display: flex;
    flex-flow: row wrap;
    align-items: stretch;
    margin: -12px;
    margin: calc(var(--mdc-layout-grid-gutter-desktop, 24px) / 2 * -1);
  }
  @supports (display: grid) {
    .mdc-layout-grid__inner {
      display: grid;
      margin: 0;
      grid-gap: 24px;
      grid-gap: var(--mdc-layout-grid-gutter-desktop, 24px);
      grid-template-columns: repeat(12, minmax(0, 1fr));
    }
  }
}
@media (min-width: 480px) and (max-width: 839px) {
  .mdc-layout-grid__inner {
    display: flex;
    flex-flow: row wrap;
    align-items: stretch;
    margin: -8px;
    margin: calc(var(--mdc-layout-grid-gutter-tablet, 16px) / 2 * -1);
  }
  @supports (display: grid) {
    .mdc-layout-grid__inner {
      display: grid;
      margin: 0;
      grid-gap: 16px;
      grid-gap: var(--mdc-layout-grid-gutter-tablet, 16px);
      grid-template-columns: repeat(8, minmax(0, 1fr));
    }
  }
}
@media (max-width: 479px) {
  .mdc-layout-grid__inner {
    display: flex;
    flex-flow: row wrap;
    align-items: stretch;
    margin: -8px;
    margin: calc(var(--mdc-layout-grid-gutter-phone, 16px) / 2 * -1);
  }
  @supports (display: grid) {
    .mdc-layout-grid__inner {
      display: grid;
      margin: 0;
      grid-gap: 16px;
      grid-gap: var(--mdc-layout-grid-gutter-phone, 16px);
      grid-template-columns: repeat(4, minmax(0, 1fr));
    }
  }
}

@media (min-width: 840px) {
  .mdc-layout-grid__cell {
    width: calc(33.3333333333% - 24px);
    width: calc(33.3333333333% - var(--mdc-layout-grid-gutter-desktop, 24px));
    box-sizing: border-box;
    margin: 12px;
    margin: calc(var(--mdc-layout-grid-gutter-desktop, 24px) / 2);
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell {
      width: auto;
      grid-column-end: span 4;
    }
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell {
      margin: 0;
    }
  }
  .mdc-layout-grid__cell--span-1,
.mdc-layout-grid__cell--span-1-desktop {
    width: calc(8.3333333333% - 24px);
    width: calc(8.3333333333% - var(--mdc-layout-grid-gutter-desktop, 24px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-1,
.mdc-layout-grid__cell--span-1-desktop {
      width: auto;
      grid-column-end: span 1;
    }
  }

  .mdc-layout-grid__cell--span-2,
.mdc-layout-grid__cell--span-2-desktop {
    width: calc(16.6666666667% - 24px);
    width: calc(16.6666666667% - var(--mdc-layout-grid-gutter-desktop, 24px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-2,
.mdc-layout-grid__cell--span-2-desktop {
      width: auto;
      grid-column-end: span 2;
    }
  }

  .mdc-layout-grid__cell--span-3,
.mdc-layout-grid__cell--span-3-desktop {
    width: calc(25% - 24px);
    width: calc(25% - var(--mdc-layout-grid-gutter-desktop, 24px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-3,
.mdc-layout-grid__cell--span-3-desktop {
      width: auto;
      grid-column-end: span 3;
    }
  }

  .mdc-layout-grid__cell--span-4,
.mdc-layout-grid__cell--span-4-desktop {
    width: calc(33.3333333333% - 24px);
    width: calc(33.3333333333% - var(--mdc-layout-grid-gutter-desktop, 24px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-4,
.mdc-layout-grid__cell--span-4-desktop {
      width: auto;
      grid-column-end: span 4;
    }
  }

  .mdc-layout-grid__cell--span-5,
.mdc-layout-grid__cell--span-5-desktop {
    width: calc(41.6666666667% - 24px);
    width: calc(41.6666666667% - var(--mdc-layout-grid-gutter-desktop, 24px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-5,
.mdc-layout-grid__cell--span-5-desktop {
      width: auto;
      grid-column-end: span 5;
    }
  }

  .mdc-layout-grid__cell--span-6,
.mdc-layout-grid__cell--span-6-desktop {
    width: calc(50% - 24px);
    width: calc(50% - var(--mdc-layout-grid-gutter-desktop, 24px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-6,
.mdc-layout-grid__cell--span-6-desktop {
      width: auto;
      grid-column-end: span 6;
    }
  }

  .mdc-layout-grid__cell--span-7,
.mdc-layout-grid__cell--span-7-desktop {
    width: calc(58.3333333333% - 24px);
    width: calc(58.3333333333% - var(--mdc-layout-grid-gutter-desktop, 24px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-7,
.mdc-layout-grid__cell--span-7-desktop {
      width: auto;
      grid-column-end: span 7;
    }
  }

  .mdc-layout-grid__cell--span-8,
.mdc-layout-grid__cell--span-8-desktop {
    width: calc(66.6666666667% - 24px);
    width: calc(66.6666666667% - var(--mdc-layout-grid-gutter-desktop, 24px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-8,
.mdc-layout-grid__cell--span-8-desktop {
      width: auto;
      grid-column-end: span 8;
    }
  }

  .mdc-layout-grid__cell--span-9,
.mdc-layout-grid__cell--span-9-desktop {
    width: calc(75% - 24px);
    width: calc(75% - var(--mdc-layout-grid-gutter-desktop, 24px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-9,
.mdc-layout-grid__cell--span-9-desktop {
      width: auto;
      grid-column-end: span 9;
    }
  }

  .mdc-layout-grid__cell--span-10,
.mdc-layout-grid__cell--span-10-desktop {
    width: calc(83.3333333333% - 24px);
    width: calc(83.3333333333% - var(--mdc-layout-grid-gutter-desktop, 24px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-10,
.mdc-layout-grid__cell--span-10-desktop {
      width: auto;
      grid-column-end: span 10;
    }
  }

  .mdc-layout-grid__cell--span-11,
.mdc-layout-grid__cell--span-11-desktop {
    width: calc(91.6666666667% - 24px);
    width: calc(91.6666666667% - var(--mdc-layout-grid-gutter-desktop, 24px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-11,
.mdc-layout-grid__cell--span-11-desktop {
      width: auto;
      grid-column-end: span 11;
    }
  }

  .mdc-layout-grid__cell--span-12,
.mdc-layout-grid__cell--span-12-desktop {
    width: calc(100% - 24px);
    width: calc(100% - var(--mdc-layout-grid-gutter-desktop, 24px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-12,
.mdc-layout-grid__cell--span-12-desktop {
      width: auto;
      grid-column-end: span 12;
    }
  }
}
@media (min-width: 480px) and (max-width: 839px) {
  .mdc-layout-grid__cell {
    width: calc(50% - 16px);
    width: calc(50% - var(--mdc-layout-grid-gutter-tablet, 16px));
    box-sizing: border-box;
    margin: 8px;
    margin: calc(var(--mdc-layout-grid-gutter-tablet, 16px) / 2);
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell {
      width: auto;
      grid-column-end: span 4;
    }
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell {
      margin: 0;
    }
  }
  .mdc-layout-grid__cell--span-1,
.mdc-layout-grid__cell--span-1-tablet {
    width: calc(12.5% - 16px);
    width: calc(12.5% - var(--mdc-layout-grid-gutter-tablet, 16px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-1,
.mdc-layout-grid__cell--span-1-tablet {
      width: auto;
      grid-column-end: span 1;
    }
  }

  .mdc-layout-grid__cell--span-2,
.mdc-layout-grid__cell--span-2-tablet {
    width: calc(25% - 16px);
    width: calc(25% - var(--mdc-layout-grid-gutter-tablet, 16px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-2,
.mdc-layout-grid__cell--span-2-tablet {
      width: auto;
      grid-column-end: span 2;
    }
  }

  .mdc-layout-grid__cell--span-3,
.mdc-layout-grid__cell--span-3-tablet {
    width: calc(37.5% - 16px);
    width: calc(37.5% - var(--mdc-layout-grid-gutter-tablet, 16px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-3,
.mdc-layout-grid__cell--span-3-tablet {
      width: auto;
      grid-column-end: span 3;
    }
  }

  .mdc-layout-grid__cell--span-4,
.mdc-layout-grid__cell--span-4-tablet {
    width: calc(50% - 16px);
    width: calc(50% - var(--mdc-layout-grid-gutter-tablet, 16px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-4,
.mdc-layout-grid__cell--span-4-tablet {
      width: auto;
      grid-column-end: span 4;
    }
  }

  .mdc-layout-grid__cell--span-5,
.mdc-layout-grid__cell--span-5-tablet {
    width: calc(62.5% - 16px);
    width: calc(62.5% - var(--mdc-layout-grid-gutter-tablet, 16px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-5,
.mdc-layout-grid__cell--span-5-tablet {
      width: auto;
      grid-column-end: span 5;
    }
  }

  .mdc-layout-grid__cell--span-6,
.mdc-layout-grid__cell--span-6-tablet {
    width: calc(75% - 16px);
    width: calc(75% - var(--mdc-layout-grid-gutter-tablet, 16px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-6,
.mdc-layout-grid__cell--span-6-tablet {
      width: auto;
      grid-column-end: span 6;
    }
  }

  .mdc-layout-grid__cell--span-7,
.mdc-layout-grid__cell--span-7-tablet {
    width: calc(87.5% - 16px);
    width: calc(87.5% - var(--mdc-layout-grid-gutter-tablet, 16px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-7,
.mdc-layout-grid__cell--span-7-tablet {
      width: auto;
      grid-column-end: span 7;
    }
  }

  .mdc-layout-grid__cell--span-8,
.mdc-layout-grid__cell--span-8-tablet {
    width: calc(100% - 16px);
    width: calc(100% - var(--mdc-layout-grid-gutter-tablet, 16px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-8,
.mdc-layout-grid__cell--span-8-tablet {
      width: auto;
      grid-column-end: span 8;
    }
  }

  .mdc-layout-grid__cell--span-9,
.mdc-layout-grid__cell--span-9-tablet {
    width: calc(100% - 16px);
    width: calc(100% - var(--mdc-layout-grid-gutter-tablet, 16px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-9,
.mdc-layout-grid__cell--span-9-tablet {
      width: auto;
      grid-column-end: span 8;
    }
  }

  .mdc-layout-grid__cell--span-10,
.mdc-layout-grid__cell--span-10-tablet {
    width: calc(100% - 16px);
    width: calc(100% - var(--mdc-layout-grid-gutter-tablet, 16px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-10,
.mdc-layout-grid__cell--span-10-tablet {
      width: auto;
      grid-column-end: span 8;
    }
  }

  .mdc-layout-grid__cell--span-11,
.mdc-layout-grid__cell--span-11-tablet {
    width: calc(100% - 16px);
    width: calc(100% - var(--mdc-layout-grid-gutter-tablet, 16px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-11,
.mdc-layout-grid__cell--span-11-tablet {
      width: auto;
      grid-column-end: span 8;
    }
  }

  .mdc-layout-grid__cell--span-12,
.mdc-layout-grid__cell--span-12-tablet {
    width: calc(100% - 16px);
    width: calc(100% - var(--mdc-layout-grid-gutter-tablet, 16px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-12,
.mdc-layout-grid__cell--span-12-tablet {
      width: auto;
      grid-column-end: span 8;
    }
  }
}
@media (max-width: 479px) {
  .mdc-layout-grid__cell {
    width: calc(100% - 16px);
    width: calc(100% - var(--mdc-layout-grid-gutter-phone, 16px));
    box-sizing: border-box;
    margin: 8px;
    margin: calc(var(--mdc-layout-grid-gutter-phone, 16px) / 2);
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell {
      width: auto;
      grid-column-end: span 4;
    }
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell {
      margin: 0;
    }
  }
  .mdc-layout-grid__cell--span-1,
.mdc-layout-grid__cell--span-1-phone {
    width: calc(25% - 16px);
    width: calc(25% - var(--mdc-layout-grid-gutter-phone, 16px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-1,
.mdc-layout-grid__cell--span-1-phone {
      width: auto;
      grid-column-end: span 1;
    }
  }

  .mdc-layout-grid__cell--span-2,
.mdc-layout-grid__cell--span-2-phone {
    width: calc(50% - 16px);
    width: calc(50% - var(--mdc-layout-grid-gutter-phone, 16px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-2,
.mdc-layout-grid__cell--span-2-phone {
      width: auto;
      grid-column-end: span 2;
    }
  }

  .mdc-layout-grid__cell--span-3,
.mdc-layout-grid__cell--span-3-phone {
    width: calc(75% - 16px);
    width: calc(75% - var(--mdc-layout-grid-gutter-phone, 16px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-3,
.mdc-layout-grid__cell--span-3-phone {
      width: auto;
      grid-column-end: span 3;
    }
  }

  .mdc-layout-grid__cell--span-4,
.mdc-layout-grid__cell--span-4-phone {
    width: calc(100% - 16px);
    width: calc(100% - var(--mdc-layout-grid-gutter-phone, 16px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-4,
.mdc-layout-grid__cell--span-4-phone {
      width: auto;
      grid-column-end: span 4;
    }
  }

  .mdc-layout-grid__cell--span-5,
.mdc-layout-grid__cell--span-5-phone {
    width: calc(100% - 16px);
    width: calc(100% - var(--mdc-layout-grid-gutter-phone, 16px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-5,
.mdc-layout-grid__cell--span-5-phone {
      width: auto;
      grid-column-end: span 4;
    }
  }

  .mdc-layout-grid__cell--span-6,
.mdc-layout-grid__cell--span-6-phone {
    width: calc(100% - 16px);
    width: calc(100% - var(--mdc-layout-grid-gutter-phone, 16px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-6,
.mdc-layout-grid__cell--span-6-phone {
      width: auto;
      grid-column-end: span 4;
    }
  }

  .mdc-layout-grid__cell--span-7,
.mdc-layout-grid__cell--span-7-phone {
    width: calc(100% - 16px);
    width: calc(100% - var(--mdc-layout-grid-gutter-phone, 16px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-7,
.mdc-layout-grid__cell--span-7-phone {
      width: auto;
      grid-column-end: span 4;
    }
  }

  .mdc-layout-grid__cell--span-8,
.mdc-layout-grid__cell--span-8-phone {
    width: calc(100% - 16px);
    width: calc(100% - var(--mdc-layout-grid-gutter-phone, 16px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-8,
.mdc-layout-grid__cell--span-8-phone {
      width: auto;
      grid-column-end: span 4;
    }
  }

  .mdc-layout-grid__cell--span-9,
.mdc-layout-grid__cell--span-9-phone {
    width: calc(100% - 16px);
    width: calc(100% - var(--mdc-layout-grid-gutter-phone, 16px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-9,
.mdc-layout-grid__cell--span-9-phone {
      width: auto;
      grid-column-end: span 4;
    }
  }

  .mdc-layout-grid__cell--span-10,
.mdc-layout-grid__cell--span-10-phone {
    width: calc(100% - 16px);
    width: calc(100% - var(--mdc-layout-grid-gutter-phone, 16px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-10,
.mdc-layout-grid__cell--span-10-phone {
      width: auto;
      grid-column-end: span 4;
    }
  }

  .mdc-layout-grid__cell--span-11,
.mdc-layout-grid__cell--span-11-phone {
    width: calc(100% - 16px);
    width: calc(100% - var(--mdc-layout-grid-gutter-phone, 16px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-11,
.mdc-layout-grid__cell--span-11-phone {
      width: auto;
      grid-column-end: span 4;
    }
  }

  .mdc-layout-grid__cell--span-12,
.mdc-layout-grid__cell--span-12-phone {
    width: calc(100% - 16px);
    width: calc(100% - var(--mdc-layout-grid-gutter-phone, 16px));
  }
  @supports (display: grid) {
    .mdc-layout-grid__cell--span-12,
.mdc-layout-grid__cell--span-12-phone {
      width: auto;
      grid-column-end: span 4;
    }
  }
}
.mdc-layout-grid__cell--order-1 {
  order: 1;
}
.mdc-layout-grid__cell--order-2 {
  order: 2;
}
.mdc-layout-grid__cell--order-3 {
  order: 3;
}
.mdc-layout-grid__cell--order-4 {
  order: 4;
}
.mdc-layout-grid__cell--order-5 {
  order: 5;
}
.mdc-layout-grid__cell--order-6 {
  order: 6;
}
.mdc-layout-grid__cell--order-7 {
  order: 7;
}
.mdc-layout-grid__cell--order-8 {
  order: 8;
}
.mdc-layout-grid__cell--order-9 {
  order: 9;
}
.mdc-layout-grid__cell--order-10 {
  order: 10;
}
.mdc-layout-grid__cell--order-11 {
  order: 11;
}
.mdc-layout-grid__cell--order-12 {
  order: 12;
}
.mdc-layout-grid__cell--align-top {
  align-self: flex-start;
}
@supports (display: grid) {
  .mdc-layout-grid__cell--align-top {
    align-self: start;
  }
}
.mdc-layout-grid__cell--align-middle {
  align-self: center;
}
.mdc-layout-grid__cell--align-bottom {
  align-self: flex-end;
}
@supports (display: grid) {
  .mdc-layout-grid__cell--align-bottom {
    align-self: end;
  }
}

@media (min-width: 840px) {
  .mdc-layout-grid--fixed-column-width {
    width: 1176px;
    width: calc( var(--mdc-layout-grid-column-width-desktop, 72px) * 12 + var(--mdc-layout-grid-gutter-desktop, 24px) * 11 + var(--mdc-layout-grid-margin-desktop, 24px) * 2 );
  }
}
@media (min-width: 480px) and (max-width: 839px) {
  .mdc-layout-grid--fixed-column-width {
    width: 720px;
    width: calc( var(--mdc-layout-grid-column-width-tablet, 72px) * 8 + var(--mdc-layout-grid-gutter-tablet, 16px) * 7 + var(--mdc-layout-grid-margin-tablet, 16px) * 2 );
  }
}
@media (max-width: 479px) {
  .mdc-layout-grid--fixed-column-width {
    width: 368px;
    width: calc( var(--mdc-layout-grid-column-width-phone, 72px) * 4 + var(--mdc-layout-grid-gutter-phone, 16px) * 3 + var(--mdc-layout-grid-margin-phone, 16px) * 2 );
  }
}

.mdc-layout-grid--align-left {
  margin-right: auto;
  margin-left: 0;
}

.mdc-layout-grid--align-right {
  margin-right: 0;
  margin-left: auto;
}

.mdc-line-ripple {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  transform: scaleX(0);
  transition: transform 180ms cubic-bezier(0.4, 0, 0.2, 1), opacity 180ms cubic-bezier(0.4, 0, 0.2, 1);
  opacity: 0;
  z-index: 2;
}

.mdc-line-ripple--active {
  transform: scaleX(1);
  opacity: 1;
}

.mdc-line-ripple--deactivating {
  opacity: 0;
}

@keyframes primary-indeterminate-translate {
  0% {
    transform: translateX(0);
  }
  20% {
    animation-timing-function: cubic-bezier(0.5, 0, 0.701732, 0.495819);
    transform: translateX(0);
  }
  59.15% {
    animation-timing-function: cubic-bezier(0.302435, 0.381352, 0.55, 0.956352);
    transform: translateX(83.67142%);
  }
  100% {
    transform: translateX(200.611057%);
  }
}
@keyframes primary-indeterminate-scale {
  0% {
    transform: scaleX(0.08);
  }
  36.65% {
    animation-timing-function: cubic-bezier(0.334731, 0.12482, 0.785844, 1);
    transform: scaleX(0.08);
  }
  69.15% {
    animation-timing-function: cubic-bezier(0.06, 0.11, 0.6, 1);
    transform: scaleX(0.661479);
  }
  100% {
    transform: scaleX(0.08);
  }
}
@keyframes secondary-indeterminate-translate {
  0% {
    animation-timing-function: cubic-bezier(0.15, 0, 0.515058, 0.409685);
    transform: translateX(0);
  }
  25% {
    animation-timing-function: cubic-bezier(0.31033, 0.284058, 0.8, 0.733712);
    transform: translateX(37.651913%);
  }
  48.35% {
    animation-timing-function: cubic-bezier(0.4, 0.627035, 0.6, 0.902026);
    transform: translateX(84.386165%);
  }
  100% {
    transform: translateX(160.277782%);
  }
}
@keyframes secondary-indeterminate-scale {
  0% {
    animation-timing-function: cubic-bezier(0.205028, 0.057051, 0.57661, 0.453971);
    transform: scaleX(0.08);
  }
  19.15% {
    animation-timing-function: cubic-bezier(0.152313, 0.196432, 0.648374, 1.004315);
    transform: scaleX(0.457104);
  }
  44.15% {
    animation-timing-function: cubic-bezier(0.257759, -0.003163, 0.211762, 1.38179);
    transform: scaleX(0.72796);
  }
  100% {
    transform: scaleX(0.08);
  }
}
@keyframes buffering {
  to {
    transform: translateX(-10px);
  }
}
@keyframes primary-indeterminate-translate-reverse {
  0% {
    transform: translateX(0);
  }
  20% {
    animation-timing-function: cubic-bezier(0.5, 0, 0.701732, 0.495819);
    transform: translateX(0);
  }
  59.15% {
    animation-timing-function: cubic-bezier(0.302435, 0.381352, 0.55, 0.956352);
    transform: translateX(-83.67142%);
  }
  100% {
    transform: translateX(-200.611057%);
  }
}
@keyframes secondary-indeterminate-translate-reverse {
  0% {
    animation-timing-function: cubic-bezier(0.15, 0, 0.515058, 0.409685);
    transform: translateX(0);
  }
  25% {
    animation-timing-function: cubic-bezier(0.31033, 0.284058, 0.8, 0.733712);
    transform: translateX(-37.651913%);
  }
  48.35% {
    animation-timing-function: cubic-bezier(0.4, 0.627035, 0.6, 0.902026);
    transform: translateX(-84.386165%);
  }
  100% {
    transform: translateX(-160.277782%);
  }
}
@keyframes buffering-reverse {
  to {
    transform: translateX(10px);
  }
}
.mdc-linear-progress {
  position: relative;
  width: 100%;
  height: 4px;
  transform: translateZ(0);
  transition: opacity 250ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
  overflow: hidden;
}
.mdc-linear-progress__bar {
  position: absolute;
  width: 100%;
  height: 100%;
  animation: none;
  transform-origin: top left;
  transition: transform 250ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
}
.mdc-linear-progress__bar-inner {
  display: inline-block;
  position: absolute;
  width: 100%;
  height: 100%;
  animation: none;
}
.mdc-linear-progress__buffering-dots {
  position: absolute;
  width: 100%;
  height: 100%;
  animation: buffering 250ms infinite linear;
  background-repeat: repeat-x;
  background-size: 10px 4px;
}
.mdc-linear-progress__buffer {
  position: absolute;
  width: 100%;
  height: 100%;
  transform-origin: top left;
  transition: transform 250ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
}
.mdc-linear-progress__primary-bar {
  transform: scaleX(0);
}
.mdc-linear-progress__secondary-bar {
  visibility: hidden;
}
.mdc-linear-progress--indeterminate .mdc-linear-progress__bar {
  transition: none;
}
.mdc-linear-progress--indeterminate .mdc-linear-progress__primary-bar {
  left: -145.166611%;
  animation: primary-indeterminate-translate 2s infinite linear;
}
.mdc-linear-progress--indeterminate .mdc-linear-progress__primary-bar > .mdc-linear-progress__bar-inner {
  animation: primary-indeterminate-scale 2s infinite linear;
}
.mdc-linear-progress--indeterminate .mdc-linear-progress__secondary-bar {
  left: -54.888891%;
  animation: secondary-indeterminate-translate 2s infinite linear;
  visibility: visible;
}
.mdc-linear-progress--indeterminate .mdc-linear-progress__secondary-bar > .mdc-linear-progress__bar-inner {
  animation: secondary-indeterminate-scale 2s infinite linear;
}
.mdc-linear-progress--reversed .mdc-linear-progress__bar,
.mdc-linear-progress--reversed .mdc-linear-progress__buffer {
  right: 0;
  transform-origin: center right;
}
.mdc-linear-progress--reversed .mdc-linear-progress__primary-bar {
  animation-name: primary-indeterminate-translate-reverse;
}
.mdc-linear-progress--reversed .mdc-linear-progress__secondary-bar {
  animation-name: secondary-indeterminate-translate-reverse;
}
.mdc-linear-progress--reversed .mdc-linear-progress__buffering-dots {
  animation: buffering-reverse 250ms infinite linear;
}
.mdc-linear-progress--closed {
  opacity: 0;
}

.mdc-linear-progress__bar-inner {
  background-color: #808080;
  /* @alternate */
  background-color: var(--mdc-theme-primary, #808080);
}

.mdc-linear-progress__buffering-dots {
  background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' enable-background='new 0 0 5 2' xml:space='preserve' viewBox='0 0 5 2' preserveAspectRatio='none slice'%3E%3Ccircle cx='1' cy='1' r='1' fill='%23e6e6e6'/%3E%3C/svg%3E");
}

.mdc-linear-progress__buffer {
  background-color: #e6e6e6;
}

.mdc-linear-progress--indeterminate.mdc-linear-progress--reversed .mdc-linear-progress__primary-bar {
  right: -145.166611%;
  left: auto;
}
.mdc-linear-progress--indeterminate.mdc-linear-progress--reversed .mdc-linear-progress__secondary-bar {
  right: -54.888891%;
  left: auto;
}

.mdc-list {
  font-family: Roboto, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 1rem;
  line-height: 1.75rem;
  font-weight: 400;
  letter-spacing: 0.009375em;
  text-decoration: inherit;
  text-transform: inherit;
  /* @alternate */
  line-height: 1.5rem;
  margin: 0;
  padding: 8px 0;
  list-style-type: none;
  color: rgba(0, 0, 0, 0.87);
  /* @alternate */
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.mdc-list:focus {
  outline: none;
}

.mdc-list-item__secondary-text {
  color: rgba(0, 0, 0, 0.54);
  /* @alternate */
  color: var(--mdc-theme-text-secondary-on-background, rgba(0, 0, 0, 0.54));
}

.mdc-list-item__graphic {
  background-color: transparent;
}

.mdc-list-item__graphic {
  color: rgba(0, 0, 0, 0.38);
  /* @alternate */
  color: var(--mdc-theme-text-icon-on-background, rgba(0, 0, 0, 0.38));
}

.mdc-list-item__meta {
  color: rgba(0, 0, 0, 0.38);
  /* @alternate */
  color: var(--mdc-theme-text-hint-on-background, rgba(0, 0, 0, 0.38));
}

.mdc-list-group__subheader {
  color: rgba(0, 0, 0, 0.87);
  /* @alternate */
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}

.mdc-list--dense {
  padding-top: 4px;
  padding-bottom: 4px;
  font-size: 0.812rem;
}

.mdc-list-item {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: flex-start;
  height: 48px;
  padding: 0 16px;
  overflow: hidden;
}
.mdc-list-item:focus {
  outline: none;
}

.mdc-list-item--selected,
.mdc-list-item--activated {
  color: #808080;
  /* @alternate */
  color: var(--mdc-theme-primary, #808080);
}
.mdc-list-item--selected .mdc-list-item__graphic,
.mdc-list-item--activated .mdc-list-item__graphic {
  color: #808080;
  /* @alternate */
  color: var(--mdc-theme-primary, #808080);
}

.mdc-list-item--disabled {
  color: rgba(0, 0, 0, 0.38);
  /* @alternate */
  color: var(--mdc-theme-text-disabled-on-background, rgba(0, 0, 0, 0.38));
}

.mdc-list-item__graphic {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 32px;
  width: 24px;
  height: 24px;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  fill: currentColor;
}
.mdc-list-item[dir=rtl] .mdc-list-item__graphic, [dir=rtl] .mdc-list-item .mdc-list-item__graphic {
  /* @noflip */
  margin-left: 32px;
  /* @noflip */
  margin-right: 0;
}

.mdc-list .mdc-list-item__graphic {
  display: inline-flex;
}

.mdc-list-item__meta {
  /* @noflip */
  margin-left: auto;
  /* @noflip */
  margin-right: 0;
}
.mdc-list-item__meta:not(.material-icons) {
  font-family: Roboto, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 0.75rem;
  line-height: 1.25rem;
  font-weight: 400;
  letter-spacing: 0.0333333333em;
  text-decoration: inherit;
  text-transform: inherit;
}
.mdc-list-item[dir=rtl] .mdc-list-item__meta, [dir=rtl] .mdc-list-item .mdc-list-item__meta {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: auto;
}

.mdc-list-item__text {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.mdc-list-item__text[for] {
  pointer-events: none;
}

.mdc-list-item__primary-text {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
  margin-bottom: -20px;
  display: block;
}
.mdc-list-item__primary-text::before {
  display: inline-block;
  width: 0;
  height: 32px;
  content: "";
  vertical-align: 0;
}
.mdc-list-item__primary-text::after {
  display: inline-block;
  width: 0;
  height: 20px;
  content: "";
  vertical-align: -20px;
}
.mdc-list--dense .mdc-list-item__primary-text {
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
  margin-bottom: -20px;
}
.mdc-list--dense .mdc-list-item__primary-text::before {
  display: inline-block;
  width: 0;
  height: 24px;
  content: "";
  vertical-align: 0;
}
.mdc-list--dense .mdc-list-item__primary-text::after {
  display: inline-block;
  width: 0;
  height: 20px;
  content: "";
  vertical-align: -20px;
}

.mdc-list-item__secondary-text {
  font-family: Roboto, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 400;
  letter-spacing: 0.0178571429em;
  text-decoration: inherit;
  text-transform: inherit;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
  display: block;
}
.mdc-list-item__secondary-text::before {
  display: inline-block;
  width: 0;
  height: 20px;
  content: "";
  vertical-align: 0;
}
.mdc-list--dense .mdc-list-item__secondary-text {
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
  font-size: inherit;
}
.mdc-list--dense .mdc-list-item__secondary-text::before {
  display: inline-block;
  width: 0;
  height: 20px;
  content: "";
  vertical-align: 0;
}

.mdc-list--dense .mdc-list-item {
  height: 40px;
}

.mdc-list--dense .mdc-list-item__graphic {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 36px;
  width: 20px;
  height: 20px;
}
.mdc-list-item[dir=rtl] .mdc-list--dense .mdc-list-item__graphic, [dir=rtl] .mdc-list-item .mdc-list--dense .mdc-list-item__graphic {
  /* @noflip */
  margin-left: 36px;
  /* @noflip */
  margin-right: 0;
}

.mdc-list--avatar-list .mdc-list-item {
  height: 56px;
}

.mdc-list--avatar-list .mdc-list-item__graphic {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 16px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
}
.mdc-list-item[dir=rtl] .mdc-list--avatar-list .mdc-list-item__graphic, [dir=rtl] .mdc-list-item .mdc-list--avatar-list .mdc-list-item__graphic {
  /* @noflip */
  margin-left: 16px;
  /* @noflip */
  margin-right: 0;
}

.mdc-list--two-line .mdc-list-item__text {
  align-self: flex-start;
}

.mdc-list--two-line .mdc-list-item {
  height: 72px;
}

.mdc-list--two-line.mdc-list--dense .mdc-list-item,
.mdc-list--avatar-list.mdc-list--dense .mdc-list-item {
  height: 60px;
}

.mdc-list--avatar-list.mdc-list--dense .mdc-list-item__graphic {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 20px;
  width: 36px;
  height: 36px;
}
.mdc-list-item[dir=rtl] .mdc-list--avatar-list.mdc-list--dense .mdc-list-item__graphic, [dir=rtl] .mdc-list-item .mdc-list--avatar-list.mdc-list--dense .mdc-list-item__graphic {
  /* @noflip */
  margin-left: 20px;
  /* @noflip */
  margin-right: 0;
}

:not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item {
  cursor: pointer;
}

a.mdc-list-item {
  color: inherit;
  text-decoration: none;
}

.mdc-list-divider {
  height: 0;
  margin: 0;
  border: none;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}

.mdc-list-divider {
  border-bottom-color: rgba(0, 0, 0, 0.12);
}

.mdc-list-divider--padded {
  margin: 0 16px;
}

.mdc-list-divider--inset {
  /* @noflip */
  margin-left: 72px;
  /* @noflip */
  margin-right: 0;
  width: calc(100% - 72px);
}
.mdc-list-group[dir=rtl] .mdc-list-divider--inset, [dir=rtl] .mdc-list-group .mdc-list-divider--inset {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 72px;
}

.mdc-list-divider--inset.mdc-list-divider--padded {
  width: calc(100% - 72px - 16px);
}

.mdc-list-group .mdc-list {
  padding: 0;
}

.mdc-list-group__subheader {
  font-family: Roboto, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 1rem;
  line-height: 1.75rem;
  font-weight: 400;
  letter-spacing: 0.009375em;
  text-decoration: inherit;
  text-transform: inherit;
  margin: 0.75rem 16px;
}

:not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item {
  --mdc-ripple-fg-size: 0;
  --mdc-ripple-left: 0;
  --mdc-ripple-top: 0;
  --mdc-ripple-fg-scale: 1;
  --mdc-ripple-fg-translate-end: 0;
  --mdc-ripple-fg-translate-start: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
:not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item::before, :not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item::after {
  position: absolute;
  border-radius: 50%;
  opacity: 0;
  pointer-events: none;
  content: "";
}
:not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item::before {
  transition: opacity 15ms linear, background-color 15ms linear;
  z-index: 1;
}
:not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item.mdc-ripple-upgraded::before {
  transform: scale(var(--mdc-ripple-fg-scale, 1));
}
:not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item.mdc-ripple-upgraded::after {
  top: 0;
  /* @noflip */
  left: 0;
  transform: scale(0);
  transform-origin: center center;
}
:not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item.mdc-ripple-upgraded--unbounded::after {
  top: var(--mdc-ripple-top, 0);
  /* @noflip */
  left: var(--mdc-ripple-left, 0);
}
:not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item.mdc-ripple-upgraded--foreground-activation::after {
  animation: mdc-ripple-fg-radius-in 225ms forwards, mdc-ripple-fg-opacity-in 75ms forwards;
}
:not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item.mdc-ripple-upgraded--foreground-deactivation::after {
  animation: mdc-ripple-fg-opacity-out 150ms;
  transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1));
}
:not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item::before, :not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item::after {
  top: calc(50% - 100%);
  /* @noflip */
  left: calc(50% - 100%);
  width: 200%;
  height: 200%;
}
:not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item.mdc-ripple-upgraded::after {
  width: var(--mdc-ripple-fg-size, 100%);
  height: var(--mdc-ripple-fg-size, 100%);
}
:not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item::before, :not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item::after {
  background-color: #000;
}
:not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item:hover::before {
  opacity: 0.04;
}
:not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item:not(.mdc-ripple-upgraded):focus::before, :not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item.mdc-ripple-upgraded--background-focused::before {
  transition-duration: 75ms;
  opacity: 0.12;
}
:not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item:not(.mdc-ripple-upgraded)::after {
  transition: opacity 150ms linear;
}
:not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item:not(.mdc-ripple-upgraded):active::after {
  transition-duration: 75ms;
  opacity: 0.12;
}
:not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: 0.12;
}
:not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item--activated::before {
  opacity: 0.12;
}
:not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item--activated::before, :not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item--activated::after {
  background-color: #808080;
}
@supports not (-ms-ime-align: auto) {
  :not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item--activated::before, :not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item--activated::after {
    /* @alternate */
    background-color: var(--mdc-theme-primary, #808080);
  }
}
:not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item--activated:hover::before {
  opacity: 0.16;
}
:not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item--activated:not(.mdc-ripple-upgraded):focus::before, :not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item--activated.mdc-ripple-upgraded--background-focused::before {
  transition-duration: 75ms;
  opacity: 0.24;
}
:not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item--activated:not(.mdc-ripple-upgraded)::after {
  transition: opacity 150ms linear;
}
:not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item--activated:not(.mdc-ripple-upgraded):active::after {
  transition-duration: 75ms;
  opacity: 0.24;
}
:not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item--activated.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: 0.24;
}
:not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item--selected::before {
  opacity: 0.08;
}
:not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item--selected::before, :not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item--selected::after {
  background-color: #808080;
}
@supports not (-ms-ime-align: auto) {
  :not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item--selected::before, :not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item--selected::after {
    /* @alternate */
    background-color: var(--mdc-theme-primary, #808080);
  }
}
:not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item--selected:hover::before {
  opacity: 0.12;
}
:not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item--selected:not(.mdc-ripple-upgraded):focus::before, :not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item--selected.mdc-ripple-upgraded--background-focused::before {
  transition-duration: 75ms;
  opacity: 0.2;
}
:not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item--selected:not(.mdc-ripple-upgraded)::after {
  transition: opacity 150ms linear;
}
:not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item--selected:not(.mdc-ripple-upgraded):active::after {
  transition-duration: 75ms;
  opacity: 0.2;
}
:not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item--selected.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: 0.2;
}

:not(.mdc-list--non-interactive) > .mdc-list-item--disabled {
  --mdc-ripple-fg-size: 0;
  --mdc-ripple-left: 0;
  --mdc-ripple-top: 0;
  --mdc-ripple-fg-scale: 1;
  --mdc-ripple-fg-translate-end: 0;
  --mdc-ripple-fg-translate-start: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
:not(.mdc-list--non-interactive) > .mdc-list-item--disabled::before, :not(.mdc-list--non-interactive) > .mdc-list-item--disabled::after {
  position: absolute;
  border-radius: 50%;
  opacity: 0;
  pointer-events: none;
  content: "";
}
:not(.mdc-list--non-interactive) > .mdc-list-item--disabled::before {
  transition: opacity 15ms linear, background-color 15ms linear;
  z-index: 1;
}
:not(.mdc-list--non-interactive) > .mdc-list-item--disabled.mdc-ripple-upgraded::before {
  transform: scale(var(--mdc-ripple-fg-scale, 1));
}
:not(.mdc-list--non-interactive) > .mdc-list-item--disabled.mdc-ripple-upgraded::after {
  top: 0;
  /* @noflip */
  left: 0;
  transform: scale(0);
  transform-origin: center center;
}
:not(.mdc-list--non-interactive) > .mdc-list-item--disabled.mdc-ripple-upgraded--unbounded::after {
  top: var(--mdc-ripple-top, 0);
  /* @noflip */
  left: var(--mdc-ripple-left, 0);
}
:not(.mdc-list--non-interactive) > .mdc-list-item--disabled.mdc-ripple-upgraded--foreground-activation::after {
  animation: mdc-ripple-fg-radius-in 225ms forwards, mdc-ripple-fg-opacity-in 75ms forwards;
}
:not(.mdc-list--non-interactive) > .mdc-list-item--disabled.mdc-ripple-upgraded--foreground-deactivation::after {
  animation: mdc-ripple-fg-opacity-out 150ms;
  transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1));
}
:not(.mdc-list--non-interactive) > .mdc-list-item--disabled::before, :not(.mdc-list--non-interactive) > .mdc-list-item--disabled::after {
  top: calc(50% - 100%);
  /* @noflip */
  left: calc(50% - 100%);
  width: 200%;
  height: 200%;
}
:not(.mdc-list--non-interactive) > .mdc-list-item--disabled.mdc-ripple-upgraded::after {
  width: var(--mdc-ripple-fg-size, 100%);
  height: var(--mdc-ripple-fg-size, 100%);
}
:not(.mdc-list--non-interactive) > .mdc-list-item--disabled::before, :not(.mdc-list--non-interactive) > .mdc-list-item--disabled::after {
  background-color: #000;
}
:not(.mdc-list--non-interactive) > .mdc-list-item--disabled:not(.mdc-ripple-upgraded):focus::before, :not(.mdc-list--non-interactive) > .mdc-list-item--disabled.mdc-ripple-upgraded--background-focused::before {
  transition-duration: 75ms;
  opacity: 0.12;
}

.mdc-menu {
  min-width: 112px;
}
.mdc-menu .mdc-list-item__meta {
  color: rgba(0, 0, 0, 0.87);
}
.mdc-menu .mdc-list-item__graphic {
  color: rgba(0, 0, 0, 0.87);
}
.mdc-menu .mdc-list {
  color: rgba(0, 0, 0, 0.87);
}
.mdc-menu .mdc-list-divider {
  margin: 8px 0;
}
.mdc-menu .mdc-list-item {
  user-select: none;
}
.mdc-menu .mdc-list-item--disabled {
  cursor: auto;
}
.mdc-menu a.mdc-list-item .mdc-list-item__text,
.mdc-menu a.mdc-list-item .mdc-list-item__graphic {
  pointer-events: none;
}

.mdc-menu__selection-group {
  padding: 0;
  fill: currentColor;
}
.mdc-menu__selection-group .mdc-list-item {
  /* @noflip */
  padding-left: 56px;
  /* @noflip */
  padding-right: 16px;
}
[dir=rtl] .mdc-menu__selection-group .mdc-list-item, .mdc-menu__selection-group .mdc-list-item[dir=rtl] {
  /* @noflip */
  padding-left: 16px;
  /* @noflip */
  padding-right: 56px;
}
.mdc-menu__selection-group .mdc-menu__selection-group-icon {
  /* @noflip */
  left: 16px;
  /* @noflip */
  right: initial;
  display: none;
  position: absolute;
}
[dir=rtl] .mdc-menu__selection-group .mdc-menu__selection-group-icon, .mdc-menu__selection-group .mdc-menu__selection-group-icon[dir=rtl] {
  /* @noflip */
  left: initial;
  /* @noflip */
  right: 16px;
}

.mdc-menu-item--selected .mdc-menu__selection-group-icon {
  display: inline;
}

.mdc-menu-surface {
  display: none;
  position: absolute;
  box-sizing: border-box;
  max-width: calc(100vw - 32px);
  max-height: calc(100vh - 32px);
  margin: 0;
  padding: 0;
  transform: scale(1);
  transform-origin: top left;
  opacity: 0;
  overflow: auto;
  will-change: transform, opacity;
  z-index: 8;
  transition: opacity 0.03s linear, transform 0.12s cubic-bezier(0, 0, 0.2, 1);
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  background-color: #fff;
  /* @alternate */
  background-color: var(--mdc-theme-surface, #fff);
  color: #000;
  /* @alternate */
  color: var(--mdc-theme-on-surface, #000);
  border-radius: 4px;
  /* @noflip */
  transform-origin-left: top left;
  /* @noflip */
  transform-origin-right: top right;
}
.mdc-menu-surface:focus {
  outline: none;
}
.mdc-menu-surface--open {
  display: inline-block;
  transform: scale(1);
  opacity: 1;
}
.mdc-menu-surface--animating-open {
  display: inline-block;
  transform: scale(0.8);
  opacity: 0;
}
.mdc-menu-surface--animating-closed {
  display: inline-block;
  opacity: 0;
  transition: opacity 0.075s linear;
}
[dir=rtl] .mdc-menu-surface, .mdc-menu-surface[dir=rtl] {
  /* @noflip */
  transform-origin-left: top right;
  /* @noflip */
  transform-origin-right: top left;
}

.mdc-menu-surface--anchor {
  position: relative;
  overflow: visible;
}

.mdc-menu-surface--fixed {
  position: fixed;
}

.mdc-notched-outline {
  display: flex;
  position: absolute;
  right: 0;
  left: 0;
  box-sizing: border-box;
  width: 100%;
  max-width: 100%;
  height: 100%;
  /* @noflip */
  text-align: left;
  pointer-events: none;
}
[dir=rtl] .mdc-notched-outline, .mdc-notched-outline[dir=rtl] {
  /* @noflip */
  text-align: right;
}
.mdc-notched-outline__leading, .mdc-notched-outline__notch, .mdc-notched-outline__trailing {
  box-sizing: border-box;
  height: 100%;
  transition: border 150ms cubic-bezier(0.4, 0, 0.2, 1);
  border-top: 1px solid;
  border-bottom: 1px solid;
  pointer-events: none;
}
.mdc-notched-outline__leading {
  /* @noflip */
  border-left: 1px solid;
  /* @noflip */
  border-right: none;
  width: 12px;
}
[dir=rtl] .mdc-notched-outline__leading, .mdc-notched-outline__leading[dir=rtl] {
  /* @noflip */
  border-left: none;
  /* @noflip */
  border-right: 1px solid;
}
.mdc-notched-outline__trailing {
  /* @noflip */
  border-left: none;
  /* @noflip */
  border-right: 1px solid;
  flex-grow: 1;
}
[dir=rtl] .mdc-notched-outline__trailing, .mdc-notched-outline__trailing[dir=rtl] {
  /* @noflip */
  border-left: 1px solid;
  /* @noflip */
  border-right: none;
}
.mdc-notched-outline__notch {
  flex: 0 0 auto;
  width: auto;
  max-width: calc(100% - 12px * 2);
}
.mdc-notched-outline .mdc-floating-label {
  display: inline-block;
  position: relative;
  top: 17px;
  bottom: auto;
  max-width: 100%;
}
.mdc-notched-outline .mdc-floating-label--float-above {
  text-overflow: clip;
}
.mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  max-width: calc(100% / .75);
}

.mdc-notched-outline--notched .mdc-notched-outline__notch {
  /* @noflip */
  padding-left: 0;
  /* @noflip */
  padding-right: 8px;
  border-top: none;
}
[dir=rtl] .mdc-notched-outline--notched .mdc-notched-outline__notch, .mdc-notched-outline--notched .mdc-notched-outline__notch[dir=rtl] {
  /* @noflip */
  padding-left: 8px;
  /* @noflip */
  padding-right: 0;
}

.mdc-notched-outline--no-label .mdc-notched-outline__notch {
  padding: 0;
}

.mdc-radio {
  display: inline-block;
  position: relative;
  flex: 0 0 auto;
  box-sizing: border-box;
  width: 40px;
  height: 40px;
  padding: 10px;
  cursor: pointer;
  /* @alternate */
  will-change: opacity, transform, border-color, color;
}
.mdc-radio .mdc-radio__native-control:enabled:not(:checked) + .mdc-radio__background .mdc-radio__outer-circle {
  border-color: rgba(0, 0, 0, 0.54);
}
.mdc-radio .mdc-radio__native-control:enabled:checked + .mdc-radio__background .mdc-radio__outer-circle {
  border-color: #1A73E8;
  /* @alternate */
  border-color: var(--mdc-theme-secondary, #1A73E8);
}
.mdc-radio .mdc-radio__native-control:enabled + .mdc-radio__background .mdc-radio__inner-circle {
  border-color: #1A73E8;
  /* @alternate */
  border-color: var(--mdc-theme-secondary, #1A73E8);
}
.mdc-radio .mdc-radio__background::before {
  background-color: #1A73E8;
}
@supports not (-ms-ime-align: auto) {
  .mdc-radio .mdc-radio__background::before {
    /* @alternate */
    background-color: var(--mdc-theme-secondary, #1A73E8);
  }
}
.mdc-radio__background {
  display: inline-block;
  position: absolute;
  left: 10px;
  box-sizing: border-box;
  width: 50%;
  height: 50%;
}
.mdc-radio__background::before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform: scale(0, 0);
  border-radius: 50%;
  opacity: 0;
  pointer-events: none;
  content: "";
  transition: opacity 120ms 0ms cubic-bezier(0.4, 0, 0.6, 1), transform 120ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
}
.mdc-radio__outer-circle {
  position: absolute;
  top: 0;
  left: 0;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  border-width: 2px;
  border-style: solid;
  border-radius: 50%;
  transition: border-color 120ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
}
.mdc-radio__inner-circle {
  position: absolute;
  top: 0;
  left: 0;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  transform: scale(0, 0);
  border-width: 10px;
  border-style: solid;
  border-radius: 50%;
  transition: transform 120ms 0ms cubic-bezier(0.4, 0, 0.6, 1), border-color 120ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
}
.mdc-radio__native-control {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  opacity: 0;
  cursor: inherit;
  z-index: 1;
}

.mdc-radio__native-control:checked + .mdc-radio__background,
.mdc-radio__native-control:disabled + .mdc-radio__background {
  transition: opacity 120ms 0ms cubic-bezier(0, 0, 0.2, 1), transform 120ms 0ms cubic-bezier(0, 0, 0.2, 1);
}
.mdc-radio__native-control:checked + .mdc-radio__background .mdc-radio__outer-circle,
.mdc-radio__native-control:disabled + .mdc-radio__background .mdc-radio__outer-circle {
  transition: border-color 120ms 0ms cubic-bezier(0, 0, 0.2, 1);
}
.mdc-radio__native-control:checked + .mdc-radio__background .mdc-radio__inner-circle,
.mdc-radio__native-control:disabled + .mdc-radio__background .mdc-radio__inner-circle {
  transition: transform 120ms 0ms cubic-bezier(0, 0, 0.2, 1), border-color 120ms 0ms cubic-bezier(0, 0, 0.2, 1);
}

.mdc-radio--disabled {
  cursor: default;
  pointer-events: none;
}

.mdc-radio__native-control:checked + .mdc-radio__background .mdc-radio__inner-circle {
  transform: scale(0.5);
  transition: transform 120ms 0ms cubic-bezier(0, 0, 0.2, 1), border-color 120ms 0ms cubic-bezier(0, 0, 0.2, 1);
}

.mdc-radio__native-control:disabled + .mdc-radio__background,
[aria-disabled=true] .mdc-radio__native-control + .mdc-radio__background {
  cursor: default;
}
.mdc-radio__native-control:disabled + .mdc-radio__background .mdc-radio__outer-circle,
[aria-disabled=true] .mdc-radio__native-control + .mdc-radio__background .mdc-radio__outer-circle {
  border-color: rgba(0, 0, 0, 0.26);
}
.mdc-radio__native-control:disabled + .mdc-radio__background .mdc-radio__inner-circle,
[aria-disabled=true] .mdc-radio__native-control + .mdc-radio__background .mdc-radio__inner-circle {
  border-color: rgba(0, 0, 0, 0.26);
}

.mdc-radio__native-control:focus + .mdc-radio__background::before {
  transform: scale(2, 2);
  opacity: 0.12;
  transition: opacity 120ms 0ms cubic-bezier(0, 0, 0.2, 1), transform 120ms 0ms cubic-bezier(0, 0, 0.2, 1);
}

.mdc-radio {
  --mdc-ripple-fg-size: 0;
  --mdc-ripple-left: 0;
  --mdc-ripple-top: 0;
  --mdc-ripple-fg-scale: 1;
  --mdc-ripple-fg-translate-end: 0;
  --mdc-ripple-fg-translate-start: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.mdc-radio::before, .mdc-radio::after {
  position: absolute;
  border-radius: 50%;
  opacity: 0;
  pointer-events: none;
  content: "";
}
.mdc-radio::before {
  transition: opacity 15ms linear, background-color 15ms linear;
  z-index: 1;
}
.mdc-radio.mdc-ripple-upgraded::before {
  transform: scale(var(--mdc-ripple-fg-scale, 1));
}
.mdc-radio.mdc-ripple-upgraded::after {
  top: 0;
  /* @noflip */
  left: 0;
  transform: scale(0);
  transform-origin: center center;
}
.mdc-radio.mdc-ripple-upgraded--unbounded::after {
  top: var(--mdc-ripple-top, 0);
  /* @noflip */
  left: var(--mdc-ripple-left, 0);
}
.mdc-radio.mdc-ripple-upgraded--foreground-activation::after {
  animation: mdc-ripple-fg-radius-in 225ms forwards, mdc-ripple-fg-opacity-in 75ms forwards;
}
.mdc-radio.mdc-ripple-upgraded--foreground-deactivation::after {
  animation: mdc-ripple-fg-opacity-out 150ms;
  transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1));
}
.mdc-radio::before, .mdc-radio::after {
  top: calc(50% - 50%);
  /* @noflip */
  left: calc(50% - 50%);
  width: 100%;
  height: 100%;
}
.mdc-radio.mdc-ripple-upgraded::before, .mdc-radio.mdc-ripple-upgraded::after {
  top: var(--mdc-ripple-top, calc(50% - 50%));
  /* @noflip */
  left: var(--mdc-ripple-left, calc(50% - 50%));
  width: var(--mdc-ripple-fg-size, 100%);
  height: var(--mdc-ripple-fg-size, 100%);
}
.mdc-radio.mdc-ripple-upgraded::after {
  width: var(--mdc-ripple-fg-size, 100%);
  height: var(--mdc-ripple-fg-size, 100%);
}
.mdc-radio::before, .mdc-radio::after {
  background-color: #1A73E8;
}
@supports not (-ms-ime-align: auto) {
  .mdc-radio::before, .mdc-radio::after {
    /* @alternate */
    background-color: var(--mdc-theme-secondary, #1A73E8);
  }
}
.mdc-radio:hover::before {
  opacity: 0.04;
}
.mdc-radio:not(.mdc-ripple-upgraded):focus::before, .mdc-radio.mdc-ripple-upgraded--background-focused::before {
  transition-duration: 75ms;
  opacity: 0.12;
}
.mdc-radio:not(.mdc-ripple-upgraded)::after {
  transition: opacity 150ms linear;
}
.mdc-radio:not(.mdc-ripple-upgraded):active::after {
  transition-duration: 75ms;
  opacity: 0.12;
}
.mdc-radio.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: 0.12;
}
.mdc-radio.mdc-ripple-upgraded--background-focused .mdc-radio__background::before {
  content: none;
}

.mdc-ripple-surface {
  --mdc-ripple-fg-size: 0;
  --mdc-ripple-left: 0;
  --mdc-ripple-top: 0;
  --mdc-ripple-fg-scale: 1;
  --mdc-ripple-fg-translate-end: 0;
  --mdc-ripple-fg-translate-start: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  position: relative;
  outline: none;
  overflow: hidden;
}
.mdc-ripple-surface::before, .mdc-ripple-surface::after {
  position: absolute;
  border-radius: 50%;
  opacity: 0;
  pointer-events: none;
  content: "";
}
.mdc-ripple-surface::before {
  transition: opacity 15ms linear, background-color 15ms linear;
  z-index: 1;
}
.mdc-ripple-surface.mdc-ripple-upgraded::before {
  transform: scale(var(--mdc-ripple-fg-scale, 1));
}
.mdc-ripple-surface.mdc-ripple-upgraded::after {
  top: 0;
  /* @noflip */
  left: 0;
  transform: scale(0);
  transform-origin: center center;
}
.mdc-ripple-surface.mdc-ripple-upgraded--unbounded::after {
  top: var(--mdc-ripple-top, 0);
  /* @noflip */
  left: var(--mdc-ripple-left, 0);
}
.mdc-ripple-surface.mdc-ripple-upgraded--foreground-activation::after {
  animation: mdc-ripple-fg-radius-in 225ms forwards, mdc-ripple-fg-opacity-in 75ms forwards;
}
.mdc-ripple-surface.mdc-ripple-upgraded--foreground-deactivation::after {
  animation: mdc-ripple-fg-opacity-out 150ms;
  transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1));
}
.mdc-ripple-surface::before, .mdc-ripple-surface::after {
  background-color: #000;
}
.mdc-ripple-surface:hover::before {
  opacity: 0.04;
}
.mdc-ripple-surface:not(.mdc-ripple-upgraded):focus::before, .mdc-ripple-surface.mdc-ripple-upgraded--background-focused::before {
  transition-duration: 75ms;
  opacity: 0.12;
}
.mdc-ripple-surface:not(.mdc-ripple-upgraded)::after {
  transition: opacity 150ms linear;
}
.mdc-ripple-surface:not(.mdc-ripple-upgraded):active::after {
  transition-duration: 75ms;
  opacity: 0.12;
}
.mdc-ripple-surface.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: 0.12;
}
.mdc-ripple-surface::before, .mdc-ripple-surface::after {
  top: calc(50% - 100%);
  /* @noflip */
  left: calc(50% - 100%);
  width: 200%;
  height: 200%;
}
.mdc-ripple-surface.mdc-ripple-upgraded::after {
  width: var(--mdc-ripple-fg-size, 100%);
  height: var(--mdc-ripple-fg-size, 100%);
}
.mdc-ripple-surface[data-mdc-ripple-is-unbounded] {
  overflow: visible;
}
.mdc-ripple-surface[data-mdc-ripple-is-unbounded]::before, .mdc-ripple-surface[data-mdc-ripple-is-unbounded]::after {
  top: calc(50% - 50%);
  /* @noflip */
  left: calc(50% - 50%);
  width: 100%;
  height: 100%;
}
.mdc-ripple-surface[data-mdc-ripple-is-unbounded].mdc-ripple-upgraded::before, .mdc-ripple-surface[data-mdc-ripple-is-unbounded].mdc-ripple-upgraded::after {
  top: var(--mdc-ripple-top, calc(50% - 50%));
  /* @noflip */
  left: var(--mdc-ripple-left, calc(50% - 50%));
  width: var(--mdc-ripple-fg-size, 100%);
  height: var(--mdc-ripple-fg-size, 100%);
}
.mdc-ripple-surface[data-mdc-ripple-is-unbounded].mdc-ripple-upgraded::after {
  width: var(--mdc-ripple-fg-size, 100%);
  height: var(--mdc-ripple-fg-size, 100%);
}
.mdc-ripple-surface--primary::before, .mdc-ripple-surface--primary::after {
  background-color: #808080;
}
@supports not (-ms-ime-align: auto) {
  .mdc-ripple-surface--primary::before, .mdc-ripple-surface--primary::after {
    /* @alternate */
    background-color: var(--mdc-theme-primary, #808080);
  }
}
.mdc-ripple-surface--primary:hover::before {
  opacity: 0.04;
}
.mdc-ripple-surface--primary:not(.mdc-ripple-upgraded):focus::before, .mdc-ripple-surface--primary.mdc-ripple-upgraded--background-focused::before {
  transition-duration: 75ms;
  opacity: 0.12;
}
.mdc-ripple-surface--primary:not(.mdc-ripple-upgraded)::after {
  transition: opacity 150ms linear;
}
.mdc-ripple-surface--primary:not(.mdc-ripple-upgraded):active::after {
  transition-duration: 75ms;
  opacity: 0.12;
}
.mdc-ripple-surface--primary.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: 0.12;
}
.mdc-ripple-surface--accent::before, .mdc-ripple-surface--accent::after {
  background-color: #1A73E8;
}
@supports not (-ms-ime-align: auto) {
  .mdc-ripple-surface--accent::before, .mdc-ripple-surface--accent::after {
    /* @alternate */
    background-color: var(--mdc-theme-secondary, #1A73E8);
  }
}
.mdc-ripple-surface--accent:hover::before {
  opacity: 0.04;
}
.mdc-ripple-surface--accent:not(.mdc-ripple-upgraded):focus::before, .mdc-ripple-surface--accent.mdc-ripple-upgraded--background-focused::before {
  transition-duration: 75ms;
  opacity: 0.12;
}
.mdc-ripple-surface--accent:not(.mdc-ripple-upgraded)::after {
  transition: opacity 150ms linear;
}
.mdc-ripple-surface--accent:not(.mdc-ripple-upgraded):active::after {
  transition-duration: 75ms;
  opacity: 0.12;
}
.mdc-ripple-surface--accent.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: 0.12;
}

@keyframes mdc-select-float-native-control {
  0% {
    transform: translateY(8px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
.mdc-select--with-leading-icon:not(.mdc-select--disabled) .mdc-select__icon {
  color: #000;
  /* @alternate */
  color: var(--mdc-theme-on-surface, #000);
}
.mdc-select--with-leading-icon .mdc-select__icon {
  display: inline-block;
  position: absolute;
  bottom: 16px;
  box-sizing: border-box;
  width: 24px;
  height: 24px;
  border: none;
  background-color: transparent;
  fill: currentColor;
  opacity: 0.54;
  text-decoration: none;
  cursor: pointer;
  user-select: none;
}

.mdc-select__icon:not([tabindex]),
.mdc-select__icon[tabindex="-1"] {
  cursor: default;
  pointer-events: none;
}

.mdc-select-helper-text {
  font-family: Roboto, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 0.75rem;
  line-height: 1.25rem;
  font-weight: 400;
  letter-spacing: 0.0333333333em;
  text-decoration: inherit;
  text-transform: inherit;
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
  margin: 0;
  transition: opacity 180ms cubic-bezier(0.4, 0, 0.2, 1);
  opacity: 0;
  will-change: opacity;
}
.mdc-select-helper-text::before {
  display: inline-block;
  width: 0;
  height: 16px;
  content: "";
  vertical-align: 0;
}

.mdc-select-helper-text--persistent {
  transition: none;
  opacity: 1;
  will-change: initial;
}

.mdc-select {
  --mdc-ripple-fg-size: 0;
  --mdc-ripple-left: 0;
  --mdc-ripple-top: 0;
  --mdc-ripple-fg-scale: 1;
  --mdc-ripple-fg-translate-end: 0;
  --mdc-ripple-fg-translate-start: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  display: inline-flex;
  position: relative;
  box-sizing: border-box;
  height: 56px;
  overflow: hidden;
  /* @alternate */
  will-change: opacity, transform, color;
}
.mdc-select:not(.mdc-select--disabled) {
  background-color: whitesmoke;
}
.mdc-select::before, .mdc-select::after {
  position: absolute;
  border-radius: 50%;
  opacity: 0;
  pointer-events: none;
  content: "";
}
.mdc-select::before {
  transition: opacity 15ms linear, background-color 15ms linear;
  z-index: 1;
}
.mdc-select.mdc-ripple-upgraded::before {
  transform: scale(var(--mdc-ripple-fg-scale, 1));
}
.mdc-select.mdc-ripple-upgraded::after {
  top: 0;
  /* @noflip */
  left: 0;
  transform: scale(0);
  transform-origin: center center;
}
.mdc-select.mdc-ripple-upgraded--unbounded::after {
  top: var(--mdc-ripple-top, 0);
  /* @noflip */
  left: var(--mdc-ripple-left, 0);
}
.mdc-select.mdc-ripple-upgraded--foreground-activation::after {
  animation: mdc-ripple-fg-radius-in 225ms forwards, mdc-ripple-fg-opacity-in 75ms forwards;
}
.mdc-select.mdc-ripple-upgraded--foreground-deactivation::after {
  animation: mdc-ripple-fg-opacity-out 150ms;
  transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1));
}
.mdc-select::before, .mdc-select::after {
  top: calc(50% - 100%);
  /* @noflip */
  left: calc(50% - 100%);
  width: 200%;
  height: 200%;
}
.mdc-select.mdc-ripple-upgraded::after {
  width: var(--mdc-ripple-fg-size, 100%);
  height: var(--mdc-ripple-fg-size, 100%);
}
.mdc-select::before, .mdc-select::after {
  background-color: rgba(0, 0, 0, 0.87);
}
.mdc-select:hover::before {
  opacity: 0.04;
}
.mdc-select:not(.mdc-ripple-upgraded):focus::before, .mdc-select.mdc-ripple-upgraded--background-focused::before {
  transition-duration: 75ms;
  opacity: 0.12;
}
.mdc-select:not(.mdc-select--disabled) .mdc-select__native-control,
.mdc-select:not(.mdc-select--disabled) .mdc-select__selected-text {
  color: rgba(0, 0, 0, 0.87);
}
.mdc-select:not(.mdc-select--disabled) .mdc-floating-label {
  color: rgba(0, 0, 0, 0.6);
}
.mdc-select:not(.mdc-select--disabled) .mdc-select__native-control,
.mdc-select:not(.mdc-select--disabled) .mdc-select__selected-text {
  border-bottom-color: rgba(0, 0, 0, 0.42);
}
.mdc-select:not(.mdc-select--disabled) + .mdc-select-helper-text {
  color: rgba(0, 0, 0, 0.6);
}
.mdc-select, .mdc-select__native-control {
  border-radius: 4px 4px 0 0;
}
.mdc-select:not(.mdc-select--disabled).mdc-select--focused .mdc-line-ripple {
  background-color: #808080;
  /* @alternate */
  background-color: var(--mdc-theme-primary, #808080);
}
.mdc-select:not(.mdc-select--disabled).mdc-select--focused .mdc-floating-label {
  color: rgba(128, 128, 128, 0.87);
}
.mdc-select:not(.mdc-select--disabled) .mdc-select__native-control:hover {
  border-bottom-color: rgba(0, 0, 0, 0.87);
}
.mdc-select .mdc-floating-label--float-above {
  transform: translateY(-70%) scale(0.75);
}
.mdc-select .mdc-floating-label {
  /* @noflip */
  left: 16px;
  /* @noflip */
  right: initial;
  top: 21px;
  pointer-events: none;
}
[dir=rtl] .mdc-select .mdc-floating-label, .mdc-select .mdc-floating-label[dir=rtl] {
  /* @noflip */
  left: initial;
  /* @noflip */
  right: 16px;
}
.mdc-select.mdc-select--with-leading-icon .mdc-floating-label {
  /* @noflip */
  left: 48px;
  /* @noflip */
  right: initial;
}
[dir=rtl] .mdc-select.mdc-select--with-leading-icon .mdc-floating-label, .mdc-select.mdc-select--with-leading-icon .mdc-floating-label[dir=rtl] {
  /* @noflip */
  left: initial;
  /* @noflip */
  right: 48px;
}
.mdc-select.mdc-select--outlined .mdc-floating-label {
  /* @noflip */
  left: 4px;
  /* @noflip */
  right: initial;
  top: 17px;
}
[dir=rtl] .mdc-select.mdc-select--outlined .mdc-floating-label, .mdc-select.mdc-select--outlined .mdc-floating-label[dir=rtl] {
  /* @noflip */
  left: initial;
  /* @noflip */
  right: 4px;
}
.mdc-select.mdc-select--outlined.mdc-select--with-leading-icon .mdc-floating-label {
  /* @noflip */
  left: 36px;
  /* @noflip */
  right: initial;
}
[dir=rtl] .mdc-select.mdc-select--outlined.mdc-select--with-leading-icon .mdc-floating-label, .mdc-select.mdc-select--outlined.mdc-select--with-leading-icon .mdc-floating-label[dir=rtl] {
  /* @noflip */
  left: initial;
  /* @noflip */
  right: 36px;
}
.mdc-select.mdc-select--outlined.mdc-select--with-leading-icon .mdc-floating-label--float-above {
  /* @noflip */
  left: 36px;
  /* @noflip */
  right: initial;
}
[dir=rtl] .mdc-select.mdc-select--outlined.mdc-select--with-leading-icon .mdc-floating-label--float-above, .mdc-select.mdc-select--outlined.mdc-select--with-leading-icon .mdc-floating-label--float-above[dir=rtl] {
  /* @noflip */
  left: initial;
  /* @noflip */
  right: 36px;
}
.mdc-select__dropdown-icon {
  background: url("data:image/svg+xml,%3Csvg%20width%3D%2210px%22%20height%3D%225px%22%20viewBox%3D%227%2010%2010%205%22%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%3E%0A%20%20%20%20%3Cpolygon%20id%3D%22Shape%22%20stroke%3D%22none%22%20fill%3D%22%23000%22%20fill-rule%3D%22evenodd%22%20opacity%3D%220.54%22%20points%3D%227%2010%2012%2015%2017%2010%22%3E%3C%2Fpolygon%3E%0A%3C%2Fsvg%3E") no-repeat center;
  /* @noflip */
  left: auto;
  /* @noflip */
  right: 8px;
  position: absolute;
  bottom: 16px;
  width: 24px;
  height: 24px;
  transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1);
  pointer-events: none;
}
[dir=rtl] .mdc-select__dropdown-icon, .mdc-select__dropdown-icon[dir=rtl] {
  /* @noflip */
  left: 8px;
  /* @noflip */
  right: auto;
}
.mdc-select--focused .mdc-select__dropdown-icon {
  background: url("data:image/svg+xml,%3Csvg%20width%3D%2210px%22%20height%3D%225px%22%20viewBox%3D%227%2010%2010%205%22%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%3E%0A%20%20%20%20%3Cpolygon%20id%3D%22Shape%22%20stroke%3D%22none%22%20fill%3D%22%23808080%22%20fill-rule%3D%22evenodd%22%20opacity%3D%221%22%20points%3D%227%2010%2012%2015%2017%2010%22%3E%3C%2Fpolygon%3E%0A%3C%2Fsvg%3E") no-repeat center;
}
.mdc-select--activated .mdc-select__dropdown-icon {
  transform: rotate(180deg) translateY(-5px);
  transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1);
}
.mdc-select__native-control {
  padding-top: 20px;
}
.mdc-select.mdc-select--focused .mdc-line-ripple::after {
  transform: scale(1, 2);
  opacity: 1;
}

.mdc-select + .mdc-select-helper-text {
  margin-right: 12px;
  margin-left: 12px;
}
.mdc-select--outlined + .mdc-select-helper-text {
  margin-right: 16px;
  margin-left: 16px;
}

.mdc-select--focused + .mdc-select-helper-text:not(.mdc-select-helper-text--validation-msg) {
  opacity: 1;
}

.mdc-select__selected-text {
  min-width: 200px;
  padding-top: 22px;
}

.mdc-select__native-control,
.mdc-select__selected-text {
  /* @noflip */
  padding-left: 16px;
  /* @noflip */
  padding-right: 52px;
  font-family: Roboto, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 1rem;
  line-height: 1.75rem;
  font-weight: 400;
  letter-spacing: 0.009375em;
  text-decoration: inherit;
  text-transform: inherit;
  box-sizing: border-box;
  width: 100%;
  height: 56px;
  padding-top: 20px;
  padding-bottom: 4px;
  border: none;
  border-bottom: 1px solid;
  outline: none;
  background-color: transparent;
  color: inherit;
  white-space: nowrap;
  cursor: pointer;
  appearance: none;
}
[dir=rtl] .mdc-select__native-control, .mdc-select__native-control[dir=rtl],
[dir=rtl] .mdc-select__selected-text,
.mdc-select__selected-text[dir=rtl] {
  /* @noflip */
  padding-left: 52px;
  /* @noflip */
  padding-right: 16px;
}
.mdc-select__native-control::-ms-expand,
.mdc-select__selected-text::-ms-expand {
  display: none;
}
.mdc-select__native-control::-ms-value,
.mdc-select__selected-text::-ms-value {
  background-color: transparent;
  color: inherit;
}
@-moz-document url-prefix("") {
  .mdc-select__native-control,
.mdc-select__selected-text {
    text-indent: -2px;
  }
}

.mdc-select--outlined {
  border: none;
  overflow: visible;
}
.mdc-select--outlined:not(.mdc-select--disabled) {
  background-color: transparent;
}
.mdc-select--outlined:not(.mdc-select--disabled) .mdc-notched-outline__leading,
.mdc-select--outlined:not(.mdc-select--disabled) .mdc-notched-outline__notch,
.mdc-select--outlined:not(.mdc-select--disabled) .mdc-notched-outline__trailing {
  border-color: rgba(0, 0, 0, 0.24);
}
.mdc-select--outlined:not(.mdc-select--disabled):not(.mdc-select--focused) .mdc-select__native-control:hover ~ .mdc-notched-outline .mdc-notched-outline__leading,
.mdc-select--outlined:not(.mdc-select--disabled):not(.mdc-select--focused) .mdc-select__native-control:hover ~ .mdc-notched-outline .mdc-notched-outline__notch,
.mdc-select--outlined:not(.mdc-select--disabled):not(.mdc-select--focused) .mdc-select__native-control:hover ~ .mdc-notched-outline .mdc-notched-outline__trailing, .mdc-select--outlined:not(.mdc-select--disabled):not(.mdc-select--focused) .mdc-select__selected-text:hover ~ .mdc-notched-outline .mdc-notched-outline__leading,
.mdc-select--outlined:not(.mdc-select--disabled):not(.mdc-select--focused) .mdc-select__selected-text:hover ~ .mdc-notched-outline .mdc-notched-outline__notch,
.mdc-select--outlined:not(.mdc-select--disabled):not(.mdc-select--focused) .mdc-select__selected-text:hover ~ .mdc-notched-outline .mdc-notched-outline__trailing {
  border-color: rgba(0, 0, 0, 0.87);
}
.mdc-select--outlined:not(.mdc-select--disabled).mdc-select--focused .mdc-notched-outline .mdc-notched-outline__leading,
.mdc-select--outlined:not(.mdc-select--disabled).mdc-select--focused .mdc-notched-outline .mdc-notched-outline__notch,
.mdc-select--outlined:not(.mdc-select--disabled).mdc-select--focused .mdc-notched-outline .mdc-notched-outline__trailing {
  border-width: 2px;
}
.mdc-select--outlined:not(.mdc-select--disabled).mdc-select--focused .mdc-notched-outline .mdc-notched-outline__leading,
.mdc-select--outlined:not(.mdc-select--disabled).mdc-select--focused .mdc-notched-outline .mdc-notched-outline__notch,
.mdc-select--outlined:not(.mdc-select--disabled).mdc-select--focused .mdc-notched-outline .mdc-notched-outline__trailing {
  border-color: #808080;
  /* @alternate */
  border-color: var(--mdc-theme-primary, #808080);
}
.mdc-select--outlined .mdc-floating-label--shake {
  animation: mdc-floating-label-shake-float-above-text-field-outlined 250ms 1;
}
.mdc-select--outlined .mdc-notched-outline .mdc-notched-outline__leading {
  /* @noflip */
  border-radius: 4px 0 0 4px;
}
[dir=rtl] .mdc-select--outlined .mdc-notched-outline .mdc-notched-outline__leading, .mdc-select--outlined .mdc-notched-outline .mdc-notched-outline__leading[dir=rtl] {
  /* @noflip */
  border-radius: 0 4px 4px 0;
}
.mdc-select--outlined .mdc-notched-outline .mdc-notched-outline__trailing {
  /* @noflip */
  border-radius: 0 4px 4px 0;
}
[dir=rtl] .mdc-select--outlined .mdc-notched-outline .mdc-notched-outline__trailing, .mdc-select--outlined .mdc-notched-outline .mdc-notched-outline__trailing[dir=rtl] {
  /* @noflip */
  border-radius: 4px 0 0 4px;
}
.mdc-select--outlined .mdc-select__native-control {
  border-radius: 4px;
}
.mdc-select--outlined::before, .mdc-select--outlined::after {
  content: none;
}
.mdc-select--outlined:not(.mdc-select--disabled) {
  background-color: transparent;
}
.mdc-select--outlined .mdc-floating-label--float-above {
  transform: translateY(-144%) scale(1);
}
.mdc-select--outlined .mdc-floating-label--float-above {
  font-size: 0.75rem;
}
.mdc-select--outlined.mdc-notched-outline--upgraded .mdc-floating-label--float-above,
.mdc-select--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  transform: translateY(-130%) scale(0.75);
}
.mdc-select--outlined.mdc-notched-outline--upgraded .mdc-floating-label--float-above,
.mdc-select--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  font-size: 1rem;
}
.mdc-select--outlined .mdc-select__native-control,
.mdc-select--outlined .mdc-select__selected-text {
  /* @noflip */
  padding-left: 16px;
  /* @noflip */
  padding-right: 52px;
  display: flex;
  padding-top: 12px;
  padding-bottom: 12px;
  border: none;
  background-color: transparent;
  z-index: 1;
}
[dir=rtl] .mdc-select--outlined .mdc-select__native-control, .mdc-select--outlined .mdc-select__native-control[dir=rtl],
[dir=rtl] .mdc-select--outlined .mdc-select__selected-text,
.mdc-select--outlined .mdc-select__selected-text[dir=rtl] {
  /* @noflip */
  padding-left: 52px;
  /* @noflip */
  padding-right: 16px;
}
.mdc-select--outlined .mdc-select__selected-text {
  padding-top: 14px;
}
.mdc-select--outlined .mdc-select__icon {
  z-index: 2;
}
.mdc-select--outlined .mdc-floating-label {
  line-height: 1.15rem;
  pointer-events: auto;
}

.mdc-select--invalid:not(.mdc-select--disabled) .mdc-floating-label {
  color: #b00020;
  /* @alternate */
  color: var(--mdc-theme-error, #b00020);
}
.mdc-select--invalid:not(.mdc-select--disabled) .mdc-select__native-control,
.mdc-select--invalid:not(.mdc-select--disabled) .mdc-select__selected-text {
  border-bottom-color: #b00020;
  /* @alternate */
  border-bottom-color: var(--mdc-theme-error, #b00020);
}
.mdc-select--invalid:not(.mdc-select--disabled).mdc-select--focused .mdc-line-ripple {
  background-color: #b00020;
  /* @alternate */
  background-color: var(--mdc-theme-error, #b00020);
}
.mdc-select--invalid:not(.mdc-select--disabled).mdc-select--focused .mdc-floating-label {
  color: #b00020;
}
.mdc-select--invalid:not(.mdc-select--disabled).mdc-select--invalid + .mdc-select-helper-text--validation-msg {
  color: #b00020;
  /* @alternate */
  color: var(--mdc-theme-error, #b00020);
}
.mdc-select--invalid:not(.mdc-select--disabled) .mdc-select__native-control:hover {
  border-bottom-color: #b00020;
  /* @alternate */
  border-bottom-color: var(--mdc-theme-error, #b00020);
}
.mdc-select--invalid.mdc-select--outlined:not(.mdc-select--disabled) .mdc-notched-outline__leading,
.mdc-select--invalid.mdc-select--outlined:not(.mdc-select--disabled) .mdc-notched-outline__notch,
.mdc-select--invalid.mdc-select--outlined:not(.mdc-select--disabled) .mdc-notched-outline__trailing {
  border-color: #b00020;
  /* @alternate */
  border-color: var(--mdc-theme-error, #b00020);
}
.mdc-select--invalid.mdc-select--outlined:not(.mdc-select--disabled):not(.mdc-select--focused) .mdc-select__native-control:hover ~ .mdc-notched-outline .mdc-notched-outline__leading,
.mdc-select--invalid.mdc-select--outlined:not(.mdc-select--disabled):not(.mdc-select--focused) .mdc-select__native-control:hover ~ .mdc-notched-outline .mdc-notched-outline__notch,
.mdc-select--invalid.mdc-select--outlined:not(.mdc-select--disabled):not(.mdc-select--focused) .mdc-select__native-control:hover ~ .mdc-notched-outline .mdc-notched-outline__trailing, .mdc-select--invalid.mdc-select--outlined:not(.mdc-select--disabled):not(.mdc-select--focused) .mdc-select__selected-text:hover ~ .mdc-notched-outline .mdc-notched-outline__leading,
.mdc-select--invalid.mdc-select--outlined:not(.mdc-select--disabled):not(.mdc-select--focused) .mdc-select__selected-text:hover ~ .mdc-notched-outline .mdc-notched-outline__notch,
.mdc-select--invalid.mdc-select--outlined:not(.mdc-select--disabled):not(.mdc-select--focused) .mdc-select__selected-text:hover ~ .mdc-notched-outline .mdc-notched-outline__trailing {
  border-color: #b00020;
  /* @alternate */
  border-color: var(--mdc-theme-error, #b00020);
}
.mdc-select--invalid.mdc-select--outlined:not(.mdc-select--disabled).mdc-select--focused .mdc-notched-outline .mdc-notched-outline__leading,
.mdc-select--invalid.mdc-select--outlined:not(.mdc-select--disabled).mdc-select--focused .mdc-notched-outline .mdc-notched-outline__notch,
.mdc-select--invalid.mdc-select--outlined:not(.mdc-select--disabled).mdc-select--focused .mdc-notched-outline .mdc-notched-outline__trailing {
  border-width: 2px;
}
.mdc-select--invalid.mdc-select--outlined:not(.mdc-select--disabled).mdc-select--focused .mdc-notched-outline .mdc-notched-outline__leading,
.mdc-select--invalid.mdc-select--outlined:not(.mdc-select--disabled).mdc-select--focused .mdc-notched-outline .mdc-notched-outline__notch,
.mdc-select--invalid.mdc-select--outlined:not(.mdc-select--disabled).mdc-select--focused .mdc-notched-outline .mdc-notched-outline__trailing {
  border-color: #b00020;
  /* @alternate */
  border-color: var(--mdc-theme-error, #b00020);
}
.mdc-select--invalid .mdc-select__dropdown-icon {
  background: url("data:image/svg+xml,%3Csvg%20width%3D%2210px%22%20height%3D%225px%22%20viewBox%3D%227%2010%2010%205%22%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%3E%0A%20%20%20%20%3Cpolygon%20id%3D%22Shape%22%20stroke%3D%22none%22%20fill%3D%22%23b00020%22%20fill-rule%3D%22evenodd%22%20opacity%3D%221%22%20points%3D%227%2010%2012%2015%2017%2010%22%3E%3C%2Fpolygon%3E%0A%3C%2Fsvg%3E") no-repeat center;
}
.mdc-select--invalid + .mdc-select-helper-text--validation-msg {
  opacity: 1;
}

.mdc-select--required .mdc-floating-label::after {
  content: "*";
}

.mdc-select--disabled {
  background-color: #fafafa;
  cursor: default;
  pointer-events: none;
}
.mdc-select--disabled .mdc-floating-label {
  color: rgba(0, 0, 0, 0.37);
}
.mdc-select--disabled .mdc-select__dropdown-icon {
  background: url("data:image/svg+xml,%3Csvg%20width%3D%2210px%22%20height%3D%225px%22%20viewBox%3D%227%2010%2010%205%22%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%3E%0A%20%20%20%20%3Cpolygon%20id%3D%22Shape%22%20stroke%3D%22none%22%20fill%3D%22%23000%22%20fill-rule%3D%22evenodd%22%20opacity%3D%220.37%22%20points%3D%227%2010%2012%2015%2017%2010%22%3E%3C%2Fpolygon%3E%0A%3C%2Fsvg%3E") no-repeat center;
}
.mdc-select--disabled .mdc-line-ripple {
  display: none;
}
.mdc-select--disabled .mdc-select__icon {
  color: rgba(0, 0, 0, 0.37);
}
.mdc-select--disabled .mdc-select__native-control,
.mdc-select--disabled .mdc-select__selected-text {
  color: rgba(0, 0, 0, 0.37);
  border-bottom-style: dotted;
}
.mdc-select--disabled .mdc-select__selected-text {
  pointer-events: none;
}
.mdc-select--disabled.mdc-select--outlined {
  background-color: transparent;
}
.mdc-select--disabled.mdc-select--outlined .mdc-select__native-control,
.mdc-select--disabled.mdc-select--outlined .mdc-select__selected-text {
  border-bottom-style: none;
}
.mdc-select--disabled.mdc-select--outlined .mdc-notched-outline__leading,
.mdc-select--disabled.mdc-select--outlined .mdc-notched-outline__notch,
.mdc-select--disabled.mdc-select--outlined .mdc-notched-outline__trailing {
  border-color: rgba(0, 0, 0, 0.16);
}

.mdc-select--with-leading-icon .mdc-select__icon {
  /* @noflip */
  left: 16px;
  /* @noflip */
  right: initial;
}
[dir=rtl] .mdc-select--with-leading-icon .mdc-select__icon, .mdc-select--with-leading-icon .mdc-select__icon[dir=rtl] {
  /* @noflip */
  left: initial;
  /* @noflip */
  right: 16px;
}
.mdc-select--with-leading-icon .mdc-select__native-control,
.mdc-select--with-leading-icon .mdc-select__selected-text {
  /* @noflip */
  padding-left: 48px;
  /* @noflip */
  padding-right: 32px;
}
[dir=rtl] .mdc-select--with-leading-icon .mdc-select__native-control, .mdc-select--with-leading-icon .mdc-select__native-control[dir=rtl],
[dir=rtl] .mdc-select--with-leading-icon .mdc-select__selected-text,
.mdc-select--with-leading-icon .mdc-select__selected-text[dir=rtl] {
  /* @noflip */
  padding-left: 32px;
  /* @noflip */
  padding-right: 48px;
}
.mdc-select--with-leading-icon.mdc-select--outlined .mdc-floating-label--float-above {
  transform: translateY(-144%) translateX(-32px) scale(1);
}
[dir=rtl] .mdc-select--with-leading-icon.mdc-select--outlined .mdc-floating-label--float-above, .mdc-select--with-leading-icon.mdc-select--outlined .mdc-floating-label--float-above[dir=rtl] {
  transform: translateY(-144%) translateX(32px) scale(1);
}
.mdc-select--with-leading-icon.mdc-select--outlined .mdc-floating-label--float-above {
  font-size: 0.75rem;
}
.mdc-select--with-leading-icon.mdc-select--outlined.mdc-notched-outline--upgraded .mdc-floating-label--float-above,
.mdc-select--with-leading-icon.mdc-select--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  transform: translateY(-130%) translateX(-32px) scale(0.75);
}
[dir=rtl] .mdc-select--with-leading-icon.mdc-select--outlined.mdc-notched-outline--upgraded .mdc-floating-label--float-above, .mdc-select--with-leading-icon.mdc-select--outlined.mdc-notched-outline--upgraded .mdc-floating-label--float-above[dir=rtl],
[dir=rtl] .mdc-select--with-leading-icon.mdc-select--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above,
.mdc-select--with-leading-icon.mdc-select--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above[dir=rtl] {
  transform: translateY(-130%) translateX(32px) scale(0.75);
}
.mdc-select--with-leading-icon.mdc-select--outlined.mdc-notched-outline--upgraded .mdc-floating-label--float-above,
.mdc-select--with-leading-icon.mdc-select--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  font-size: 1rem;
}
.mdc-select--with-leading-icon.mdc-select--outlined .mdc-floating-label--shake {
  animation: mdc-floating-label-shake-float-above-select-outlined-leading-icon 250ms 1;
}
[dir=rtl] .mdc-select--with-leading-icon.mdc-select--outlined .mdc-floating-label--shake, .mdc-select--with-leading-icon.mdc-select--outlined[dir=rtl] .mdc-floating-label--shake {
  animation: mdc-floating-label-shake-float-above-select-outlined-leading-icon-rtl 250ms 1;
}
.mdc-select--with-leading-icon.mdc-select__menu .mdc-list-item__text {
  /* @noflip */
  padding-left: 32px;
  /* @noflip */
  padding-right: 32px;
}
[dir=rtl] .mdc-select--with-leading-icon.mdc-select__menu .mdc-list-item__text, .mdc-select--with-leading-icon.mdc-select__menu .mdc-list-item__text[dir=rtl] {
  /* @noflip */
  padding-left: 32px;
  /* @noflip */
  padding-right: 32px;
}

.mdc-select__menu .mdc-list .mdc-list-item--selected {
  color: #000;
  /* @alternate */
  color: var(--mdc-theme-on-surface, #000);
}
.mdc-select__menu .mdc-list .mdc-list-item--selected::before, .mdc-select__menu .mdc-list .mdc-list-item--selected::after {
  background-color: #000;
}
@supports not (-ms-ime-align: auto) {
  .mdc-select__menu .mdc-list .mdc-list-item--selected::before, .mdc-select__menu .mdc-list .mdc-list-item--selected::after {
    /* @alternate */
    background-color: var(--mdc-theme-on-surface, #000);
  }
}
.mdc-select__menu .mdc-list .mdc-list-item--selected:hover::before {
  opacity: 0.04;
}
.mdc-select__menu .mdc-list .mdc-list-item--selected:not(.mdc-ripple-upgraded):focus::before, .mdc-select__menu .mdc-list .mdc-list-item--selected.mdc-ripple-upgraded--background-focused::before {
  transition-duration: 75ms;
  opacity: 0.12;
}
.mdc-select__menu .mdc-list .mdc-list-item--selected:not(.mdc-ripple-upgraded)::after {
  transition: opacity 150ms linear;
}
.mdc-select__menu .mdc-list .mdc-list-item--selected:not(.mdc-ripple-upgraded):active::after {
  transition-duration: 75ms;
  opacity: 0.12;
}
.mdc-select__menu .mdc-list .mdc-list-item--selected.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: 0.12;
}

@keyframes mdc-floating-label-shake-float-above-select-outlined-leading-icon {
  /* clean-css ignore:start */
  0% {
    transform: translateX(calc(0 - 32px)) translateY(-130%) scale(0.75);
  }
  33% {
    animation-timing-function: cubic-bezier(0.5, 0, 0.701732, 0.495819);
    transform: translateX(calc(4% - 32px)) translateY(-130%) scale(0.75);
  }
  66% {
    animation-timing-function: cubic-bezier(0.302435, 0.381352, 0.55, 0.956352);
    transform: translateX(calc(-4% - 32px)) translateY(-130%) scale(0.75);
  }
  100% {
    transform: translateX(calc(0 - 32px)) translateY(-130%) scale(0.75);
  }
  /* clean-css ignore:end */
}
@keyframes mdc-floating-label-shake-float-above-select-outlined-leading-icon-rtl {
  /* clean-css ignore:start */
  0% {
    transform: translateX(calc(0 - -32px)) translateY(-130%) scale(0.75);
  }
  33% {
    animation-timing-function: cubic-bezier(0.5, 0, 0.701732, 0.495819);
    transform: translateX(calc(4% - -32px)) translateY(-130%) scale(0.75);
  }
  66% {
    animation-timing-function: cubic-bezier(0.302435, 0.381352, 0.55, 0.956352);
    transform: translateX(calc(-4% - -32px)) translateY(-130%) scale(0.75);
  }
  100% {
    transform: translateX(calc(0 - -32px)) translateY(-130%) scale(0.75);
  }
  /* clean-css ignore:end */
}
@keyframes mdc-slider-emphasize {
  0% {
    animation-timing-function: ease-out;
  }
  50% {
    animation-timing-function: ease-in;
    transform: scale(0.85);
  }
  100% {
    transform: scale(0.571);
  }
}
.mdc-slider {
  position: relative;
  width: 100%;
  height: 48px;
  cursor: pointer;
  touch-action: pan-x;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.mdc-slider:not(.mdc-slider--disabled) .mdc-slider__track {
  background-color: #1A73E8;
  /* @alternate */
  background-color: var(--mdc-theme-secondary, #1A73E8);
}
.mdc-slider:not(.mdc-slider--disabled) .mdc-slider__track-container {
  background-color: rgba(26, 115, 232, 0.26);
}
.mdc-slider:not(.mdc-slider--disabled) .mdc-slider__track-marker::after,
.mdc-slider:not(.mdc-slider--disabled) .mdc-slider__track-marker-container::after {
  background-color: #1A73E8;
  /* @alternate */
  background-color: var(--mdc-theme-secondary, #1A73E8);
}
.mdc-slider:not(.mdc-slider--disabled) .mdc-slider__thumb {
  fill: #1A73E8;
  /* @alternate */
  fill: var(--mdc-theme-secondary, #1A73E8);
  stroke: #1A73E8;
  /* @alternate */
  stroke: var(--mdc-theme-secondary, #1A73E8);
}
.mdc-slider:not(.mdc-slider--disabled) .mdc-slider__focus-ring {
  background-color: #1A73E8;
  /* @alternate */
  background-color: var(--mdc-theme-secondary, #1A73E8);
}
.mdc-slider:not(.mdc-slider--disabled) .mdc-slider__pin {
  background-color: #1A73E8;
  /* @alternate */
  background-color: var(--mdc-theme-secondary, #1A73E8);
}
.mdc-slider:not(.mdc-slider--disabled) .mdc-slider__pin {
  color: white;
  /* @alternate */
  color: var(--mdc-theme-text-primary-on-dark, white);
}
.mdc-slider--disabled {
  cursor: auto;
}
.mdc-slider--disabled .mdc-slider__track {
  background-color: #9a9a9a;
}
.mdc-slider--disabled .mdc-slider__track-container {
  background-color: rgba(154, 154, 154, 0.26);
}
.mdc-slider--disabled .mdc-slider__track-marker::after,
.mdc-slider--disabled .mdc-slider__track-marker-container::after {
  background-color: #9a9a9a;
}
.mdc-slider--disabled .mdc-slider__thumb {
  fill: #9a9a9a;
  stroke: #9a9a9a;
}
.mdc-slider--disabled .mdc-slider__thumb {
  /* @alternate */
  stroke: white;
  stroke: var(--mdc-slider-bg-color-behind-component, white);
}
.mdc-slider:focus {
  outline: none;
}
.mdc-slider__track-container {
  position: absolute;
  top: 50%;
  width: 100%;
  height: 2px;
  overflow: hidden;
}
.mdc-slider__track {
  position: absolute;
  width: 100%;
  height: 100%;
  transform-origin: left top;
  will-change: transform;
}
.mdc-slider[dir=rtl] .mdc-slider__track, [dir=rtl] .mdc-slider .mdc-slider__track {
  transform-origin: right top;
}

.mdc-slider__track-marker-container {
  display: flex;
  margin-right: 0;
  margin-left: -1px;
  visibility: hidden;
}
.mdc-slider[dir=rtl] .mdc-slider__track-marker-container, [dir=rtl] .mdc-slider .mdc-slider__track-marker-container {
  margin-right: -1px;
  margin-left: 0;
}

.mdc-slider__track-marker-container::after {
  display: block;
  width: 2px;
  height: 2px;
  content: "";
}
.mdc-slider__track-marker {
  flex: 1;
}
.mdc-slider__track-marker::after {
  display: block;
  width: 2px;
  height: 2px;
  content: "";
}
.mdc-slider__track-marker:first-child::after {
  width: 3px;
}
.mdc-slider__thumb-container {
  position: absolute;
  top: 15px;
  left: 0;
  width: 21px;
  height: 100%;
  user-select: none;
  will-change: transform;
}
.mdc-slider__thumb {
  position: absolute;
  top: 0;
  left: 0;
  transform: scale(0.571);
  transition: transform 100ms ease-out, fill 100ms ease-out, stroke 100ms ease-out;
  stroke-width: 3.5;
}
.mdc-slider__focus-ring {
  width: 21px;
  height: 21px;
  transition: transform 266.67ms ease-out, opacity 266.67ms ease-out, background-color 266.67ms ease-out;
  border-radius: 50%;
  opacity: 0;
}
.mdc-slider__pin {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  align-items: center;
  justify-content: center;
  width: 26px;
  height: 26px;
  margin-top: -2px;
  margin-left: -2px;
  transform: rotate(-45deg) scale(0) translate(0, 0);
  transition: transform 100ms ease-out;
  border-radius: 50% 50% 50% 0%;
  z-index: 1;
}
.mdc-slider__pin-value-marker {
  font-family: Roboto, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 400;
  letter-spacing: 0.0178571429em;
  text-decoration: inherit;
  text-transform: inherit;
  transform: rotate(45deg);
}

.mdc-slider--active .mdc-slider__thumb {
  transform: scale3d(1, 1, 1);
}

.mdc-slider--focus .mdc-slider__thumb {
  animation: mdc-slider-emphasize 266.67ms linear;
}
.mdc-slider--focus .mdc-slider__focus-ring {
  transform: scale3d(1.55, 1.55, 1.55);
  opacity: 0.25;
}

.mdc-slider--in-transit .mdc-slider__thumb {
  transition-delay: 140ms;
}

.mdc-slider--in-transit .mdc-slider__thumb-container,
.mdc-slider--in-transit .mdc-slider__track,
.mdc-slider:focus:not(.mdc-slider--active) .mdc-slider__thumb-container,
.mdc-slider:focus:not(.mdc-slider--active) .mdc-slider__track {
  transition: transform 80ms ease;
}

.mdc-slider--discrete.mdc-slider--active .mdc-slider__thumb {
  transform: scale(calc(12 / 21));
}
.mdc-slider--discrete.mdc-slider--active .mdc-slider__pin {
  transform: rotate(-45deg) scale(1) translate(19px, -20px);
}
.mdc-slider--discrete.mdc-slider--focus .mdc-slider__thumb {
  animation: none;
}
.mdc-slider--discrete.mdc-slider--display-markers .mdc-slider__track-marker-container {
  visibility: visible;
}

.mdc-snackbar {
  z-index: 8;
  margin: 8px;
  display: none;
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  pointer-events: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.mdc-snackbar__surface {
  background-color: #333333;
}

.mdc-snackbar__label {
  color: rgba(255, 255, 255, 0.87);
}

.mdc-snackbar__surface {
  min-width: 344px;
}
@media (max-width: 480px), (max-width: 344px) {
  .mdc-snackbar__surface {
    min-width: 100%;
  }
}

.mdc-snackbar__surface {
  max-width: 672px;
}

.mdc-snackbar__surface {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}

.mdc-snackbar__surface {
  border-radius: 4px;
}

.mdc-snackbar--opening,
.mdc-snackbar--open,
.mdc-snackbar--closing {
  display: flex;
}

.mdc-snackbar--leading {
  justify-content: flex-start;
}

.mdc-snackbar--stacked .mdc-snackbar__surface {
  flex-direction: column;
  align-items: flex-start;
}
.mdc-snackbar--stacked .mdc-snackbar__actions {
  align-self: flex-end;
  margin-bottom: 8px;
}

.mdc-snackbar__surface {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  box-sizing: border-box;
  transform: scale(0.8);
  opacity: 0;
}
.mdc-snackbar--open .mdc-snackbar__surface {
  transform: scale(1);
  transition: opacity 150ms 0ms cubic-bezier(0, 0, 0.2, 1), transform 150ms 0ms cubic-bezier(0, 0, 0.2, 1);
  opacity: 1;
  pointer-events: auto;
}
.mdc-snackbar--closing .mdc-snackbar__surface {
  transform: scale(1);
  transition: opacity 75ms 0ms cubic-bezier(0.4, 0, 1, 1);
}

.mdc-snackbar__label {
  font-family: Roboto, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 400;
  letter-spacing: 0.0178571429em;
  text-decoration: inherit;
  text-transform: inherit;
  flex-grow: 1;
  box-sizing: border-box;
  margin: 0;
  padding: 14px 16px;
}

.mdc-snackbar__label::before {
  display: inline;
  content: attr(data-mdc-snackbar-label-text);
}

.mdc-snackbar__actions {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 8px;
  display: flex;
  flex-shrink: 0;
  align-items: center;
  box-sizing: border-box;
}
[dir=rtl] .mdc-snackbar__actions, .mdc-snackbar__actions[dir=rtl] {
  /* @noflip */
  margin-left: 8px;
  /* @noflip */
  margin-right: 0;
}

.mdc-snackbar__action:not(:disabled) {
  color: #bb86fc;
}
.mdc-snackbar__action::before, .mdc-snackbar__action::after {
  background-color: #bb86fc;
}
.mdc-snackbar__action:hover::before {
  opacity: 0.08;
}
.mdc-snackbar__action:not(.mdc-ripple-upgraded):focus::before, .mdc-snackbar__action.mdc-ripple-upgraded--background-focused::before {
  transition-duration: 75ms;
  opacity: 0.24;
}
.mdc-snackbar__action:not(.mdc-ripple-upgraded)::after {
  transition: opacity 150ms linear;
}
.mdc-snackbar__action:not(.mdc-ripple-upgraded):active::after {
  transition-duration: 75ms;
  opacity: 0.24;
}
.mdc-snackbar__action.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: 0.24;
}

.mdc-snackbar__dismiss {
  color: rgba(255, 255, 255, 0.87);
}
.mdc-snackbar__dismiss::before, .mdc-snackbar__dismiss::after {
  background-color: rgba(255, 255, 255, 0.87);
}
.mdc-snackbar__dismiss:hover::before {
  opacity: 0.08;
}
.mdc-snackbar__dismiss:not(.mdc-ripple-upgraded):focus::before, .mdc-snackbar__dismiss.mdc-ripple-upgraded--background-focused::before {
  transition-duration: 75ms;
  opacity: 0.24;
}
.mdc-snackbar__dismiss:not(.mdc-ripple-upgraded)::after {
  transition: opacity 150ms linear;
}
.mdc-snackbar__dismiss:not(.mdc-ripple-upgraded):active::after {
  transition-duration: 75ms;
  opacity: 0.24;
}
.mdc-snackbar__dismiss.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: 0.24;
}

.mdc-snackbar__dismiss.mdc-snackbar__dismiss {
  width: 36px;
  height: 36px;
  padding: 9px;
  font-size: 18px;
}
.mdc-snackbar__dismiss.mdc-snackbar__dismiss svg,
.mdc-snackbar__dismiss.mdc-snackbar__dismiss img {
  width: 18px;
  height: 18px;
}

.mdc-snackbar__action + .mdc-snackbar__dismiss {
  /* @noflip */
  margin-left: 8px;
  /* @noflip */
  margin-right: 0;
}
[dir=rtl] .mdc-snackbar__action + .mdc-snackbar__dismiss, .mdc-snackbar__action + .mdc-snackbar__dismiss[dir=rtl] {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 8px;
}

.mdc-switch {
  display: inline-block;
  position: relative;
  outline: none;
  user-select: none;
}
.mdc-switch.mdc-switch--checked .mdc-switch__track {
  background-color: #1A73E8;
  /* @alternate */
  background-color: var(--mdc-theme-secondary, #1A73E8);
  border-color: #1A73E8;
  /* @alternate */
  border-color: var(--mdc-theme-secondary, #1A73E8);
}
.mdc-switch.mdc-switch--checked .mdc-switch__thumb {
  background-color: #1A73E8;
  /* @alternate */
  background-color: var(--mdc-theme-secondary, #1A73E8);
  border-color: #1A73E8;
  /* @alternate */
  border-color: var(--mdc-theme-secondary, #1A73E8);
}
.mdc-switch:not(.mdc-switch--checked) .mdc-switch__track {
  background-color: #000;
  border-color: #000;
}
.mdc-switch:not(.mdc-switch--checked) .mdc-switch__thumb {
  background-color: #fff;
  border-color: #fff;
}

.mdc-switch__native-control {
  /* @noflip */
  left: 0;
  /* @noflip */
  right: initial;
  position: absolute;
  top: 0;
  width: 68px;
  height: 48px;
  margin: 0;
  opacity: 0;
  cursor: pointer;
  pointer-events: auto;
}
[dir=rtl] .mdc-switch__native-control, .mdc-switch__native-control[dir=rtl] {
  /* @noflip */
  left: initial;
  /* @noflip */
  right: 0;
}

.mdc-switch__track {
  box-sizing: border-box;
  width: 32px;
  height: 14px;
  border: 1px solid;
  border-radius: 7px;
  opacity: 0.38;
  transition: opacity 90ms cubic-bezier(0.4, 0, 0.2, 1), background-color 90ms cubic-bezier(0.4, 0, 0.2, 1), border-color 90ms cubic-bezier(0.4, 0, 0.2, 1);
}

.mdc-switch__thumb-underlay {
  /* @noflip */
  left: -18px;
  /* @noflip */
  right: initial;
  display: flex;
  position: absolute;
  top: -17px;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  transform: translateX(0);
  transition: transform 90ms cubic-bezier(0.4, 0, 0.2, 1), background-color 90ms cubic-bezier(0.4, 0, 0.2, 1), border-color 90ms cubic-bezier(0.4, 0, 0.2, 1);
}
[dir=rtl] .mdc-switch__thumb-underlay, .mdc-switch__thumb-underlay[dir=rtl] {
  /* @noflip */
  left: initial;
  /* @noflip */
  right: -18px;
}

.mdc-switch__thumb {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  box-sizing: border-box;
  width: 20px;
  height: 20px;
  border: 10px solid;
  border-radius: 50%;
  pointer-events: none;
  z-index: 1;
}

.mdc-switch--checked .mdc-switch__track {
  opacity: 0.54;
}
.mdc-switch--checked .mdc-switch__thumb-underlay {
  transform: translateX(20px);
}
[dir=rtl] .mdc-switch--checked .mdc-switch__thumb-underlay, .mdc-switch--checked .mdc-switch__thumb-underlay[dir=rtl] {
  transform: translateX(-20px);
}
.mdc-switch--checked .mdc-switch__native-control {
  transform: translateX(-20px);
}
[dir=rtl] .mdc-switch--checked .mdc-switch__native-control, .mdc-switch--checked .mdc-switch__native-control[dir=rtl] {
  transform: translateX(20px);
}

.mdc-switch--disabled {
  opacity: 0.38;
  pointer-events: none;
}
.mdc-switch--disabled .mdc-switch__thumb {
  border-width: 1px;
}
.mdc-switch--disabled .mdc-switch__native-control {
  cursor: default;
  pointer-events: none;
}

.mdc-switch:not(.mdc-switch--checked) .mdc-switch__thumb-underlay::before, .mdc-switch:not(.mdc-switch--checked) .mdc-switch__thumb-underlay::after {
  background-color: #9e9e9e;
}
.mdc-switch:not(.mdc-switch--checked) .mdc-switch__thumb-underlay:hover::before {
  opacity: 0.08;
}
.mdc-switch:not(.mdc-switch--checked) .mdc-switch__thumb-underlay:not(.mdc-ripple-upgraded):focus::before, .mdc-switch:not(.mdc-switch--checked) .mdc-switch__thumb-underlay.mdc-ripple-upgraded--background-focused::before {
  transition-duration: 75ms;
  opacity: 0.24;
}
.mdc-switch:not(.mdc-switch--checked) .mdc-switch__thumb-underlay:not(.mdc-ripple-upgraded)::after {
  transition: opacity 150ms linear;
}
.mdc-switch:not(.mdc-switch--checked) .mdc-switch__thumb-underlay:not(.mdc-ripple-upgraded):active::after {
  transition-duration: 75ms;
  opacity: 0.24;
}
.mdc-switch:not(.mdc-switch--checked) .mdc-switch__thumb-underlay.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: 0.24;
}

.mdc-switch__thumb-underlay {
  --mdc-ripple-fg-size: 0;
  --mdc-ripple-left: 0;
  --mdc-ripple-top: 0;
  --mdc-ripple-fg-scale: 1;
  --mdc-ripple-fg-translate-end: 0;
  --mdc-ripple-fg-translate-start: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.mdc-switch__thumb-underlay::before, .mdc-switch__thumb-underlay::after {
  position: absolute;
  border-radius: 50%;
  opacity: 0;
  pointer-events: none;
  content: "";
}
.mdc-switch__thumb-underlay::before {
  transition: opacity 15ms linear, background-color 15ms linear;
  z-index: 1;
}
.mdc-switch__thumb-underlay.mdc-ripple-upgraded::before {
  transform: scale(var(--mdc-ripple-fg-scale, 1));
}
.mdc-switch__thumb-underlay.mdc-ripple-upgraded::after {
  top: 0;
  /* @noflip */
  left: 0;
  transform: scale(0);
  transform-origin: center center;
}
.mdc-switch__thumb-underlay.mdc-ripple-upgraded--unbounded::after {
  top: var(--mdc-ripple-top, 0);
  /* @noflip */
  left: var(--mdc-ripple-left, 0);
}
.mdc-switch__thumb-underlay.mdc-ripple-upgraded--foreground-activation::after {
  animation: mdc-ripple-fg-radius-in 225ms forwards, mdc-ripple-fg-opacity-in 75ms forwards;
}
.mdc-switch__thumb-underlay.mdc-ripple-upgraded--foreground-deactivation::after {
  animation: mdc-ripple-fg-opacity-out 150ms;
  transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1));
}
.mdc-switch__thumb-underlay::before, .mdc-switch__thumb-underlay::after {
  top: calc(50% - 50%);
  /* @noflip */
  left: calc(50% - 50%);
  width: 100%;
  height: 100%;
}
.mdc-switch__thumb-underlay.mdc-ripple-upgraded::before, .mdc-switch__thumb-underlay.mdc-ripple-upgraded::after {
  top: var(--mdc-ripple-top, calc(50% - 50%));
  /* @noflip */
  left: var(--mdc-ripple-left, calc(50% - 50%));
  width: var(--mdc-ripple-fg-size, 100%);
  height: var(--mdc-ripple-fg-size, 100%);
}
.mdc-switch__thumb-underlay.mdc-ripple-upgraded::after {
  width: var(--mdc-ripple-fg-size, 100%);
  height: var(--mdc-ripple-fg-size, 100%);
}
.mdc-switch__thumb-underlay::before, .mdc-switch__thumb-underlay::after {
  background-color: #1A73E8;
}
@supports not (-ms-ime-align: auto) {
  .mdc-switch__thumb-underlay::before, .mdc-switch__thumb-underlay::after {
    /* @alternate */
    background-color: var(--mdc-theme-secondary, #1A73E8);
  }
}
.mdc-switch__thumb-underlay:hover::before {
  opacity: 0.04;
}
.mdc-switch__thumb-underlay:not(.mdc-ripple-upgraded):focus::before, .mdc-switch__thumb-underlay.mdc-ripple-upgraded--background-focused::before {
  transition-duration: 75ms;
  opacity: 0.12;
}
.mdc-switch__thumb-underlay:not(.mdc-ripple-upgraded)::after {
  transition: opacity 150ms linear;
}
.mdc-switch__thumb-underlay:not(.mdc-ripple-upgraded):active::after {
  transition-duration: 75ms;
  opacity: 0.12;
}
.mdc-switch__thumb-underlay.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: 0.12;
}

/**
 * @license
 * Copyright 2018 Google Inc.
 *
 * Permission is hereby granted, free of charge, to any person obtaining a copy
 * of this software and associated documentation files (the "Software"), to deal
 * in the Software without restriction, including without limitation the rights
 * to use, copy, modify, merge, publish, distribute, sublicense, and/or sell
 * copies of the Software, and to permit persons to whom the Software is
 * furnished to do so, subject to the following conditions:
 *
 * The above copyright notice and this permission notice shall be included in
 * all copies or substantial portions of the Software.
 *
 * THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR
 * IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY,
 * FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT. IN NO EVENT SHALL THE
 * AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM, DAMAGES OR OTHER
 * LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR OTHERWISE, ARISING FROM,
 * OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE USE OR OTHER DEALINGS IN
 * THE SOFTWARE.
 */
.mdc-tab {
  position: relative;
  font-family: Roboto, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 0.875rem;
  line-height: 2.25rem;
  font-weight: 500;
  letter-spacing: 0.0892857143em;
  text-decoration: none;
  text-transform: uppercase;
  padding-right: 24px;
  padding-left: 24px;
  display: flex;
  flex: 1 0 auto;
  justify-content: center;
  box-sizing: border-box;
  height: 48px;
  margin: 0;
  padding-top: 0;
  padding-bottom: 0;
  border: none;
  outline: none;
  background: none;
  text-align: center;
  white-space: nowrap;
  cursor: pointer;
  -webkit-appearance: none;
  z-index: 1;
}
.mdc-tab .mdc-tab__text-label {
  color: rgba(0, 0, 0, 0.6);
}
.mdc-tab .mdc-tab__icon {
  color: rgba(0, 0, 0, 0.54);
  fill: currentColor;
}
.mdc-tab::-moz-focus-inner {
  padding: 0;
  border: 0;
}

.mdc-tab--min-width {
  flex: 0 1 auto;
}

.mdc-tab__ripple {
  --mdc-ripple-fg-size: 0;
  --mdc-ripple-left: 0;
  --mdc-ripple-top: 0;
  --mdc-ripple-fg-scale: 1;
  --mdc-ripple-fg-translate-end: 0;
  --mdc-ripple-fg-translate-start: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.mdc-tab__ripple::before, .mdc-tab__ripple::after {
  position: absolute;
  border-radius: 50%;
  opacity: 0;
  pointer-events: none;
  content: "";
}
.mdc-tab__ripple::before {
  transition: opacity 15ms linear, background-color 15ms linear;
  z-index: 1;
}
.mdc-tab__ripple.mdc-ripple-upgraded::before {
  transform: scale(var(--mdc-ripple-fg-scale, 1));
}
.mdc-tab__ripple.mdc-ripple-upgraded::after {
  top: 0;
  /* @noflip */
  left: 0;
  transform: scale(0);
  transform-origin: center center;
}
.mdc-tab__ripple.mdc-ripple-upgraded--unbounded::after {
  top: var(--mdc-ripple-top, 0);
  /* @noflip */
  left: var(--mdc-ripple-left, 0);
}
.mdc-tab__ripple.mdc-ripple-upgraded--foreground-activation::after {
  animation: mdc-ripple-fg-radius-in 225ms forwards, mdc-ripple-fg-opacity-in 75ms forwards;
}
.mdc-tab__ripple.mdc-ripple-upgraded--foreground-deactivation::after {
  animation: mdc-ripple-fg-opacity-out 150ms;
  transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1));
}
.mdc-tab__ripple::before, .mdc-tab__ripple::after {
  top: calc(50% - 100%);
  /* @noflip */
  left: calc(50% - 100%);
  width: 200%;
  height: 200%;
}
.mdc-tab__ripple.mdc-ripple-upgraded::after {
  width: var(--mdc-ripple-fg-size, 100%);
  height: var(--mdc-ripple-fg-size, 100%);
}
.mdc-tab__ripple::before, .mdc-tab__ripple::after {
  background-color: #808080;
}
@supports not (-ms-ime-align: auto) {
  .mdc-tab__ripple::before, .mdc-tab__ripple::after {
    /* @alternate */
    background-color: var(--mdc-theme-primary, #808080);
  }
}
.mdc-tab__ripple:hover::before {
  opacity: 0.04;
}
.mdc-tab__ripple:not(.mdc-ripple-upgraded):focus::before, .mdc-tab__ripple.mdc-ripple-upgraded--background-focused::before {
  transition-duration: 75ms;
  opacity: 0.12;
}
.mdc-tab__ripple:not(.mdc-ripple-upgraded)::after {
  transition: opacity 150ms linear;
}
.mdc-tab__ripple:not(.mdc-ripple-upgraded):active::after {
  transition-duration: 75ms;
  opacity: 0.12;
}
.mdc-tab__ripple.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: 0.12;
}

.mdc-tab__content {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: inherit;
  pointer-events: none;
}

.mdc-tab__text-label,
.mdc-tab__icon {
  transition: 150ms color linear;
  z-index: 2;
}

.mdc-tab__text-label {
  display: inline-block;
  line-height: 1;
}

.mdc-tab__icon {
  width: 24px;
  height: 24px;
  font-size: 24px;
}

.mdc-tab--stacked {
  height: 72px;
}

.mdc-tab--stacked .mdc-tab__content {
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.mdc-tab--stacked .mdc-tab__icon {
  padding-top: 12px;
}

.mdc-tab--stacked .mdc-tab__text-label {
  padding-bottom: 16px;
}

.mdc-tab--active .mdc-tab__text-label {
  color: #808080;
  /* @alternate */
  color: var(--mdc-theme-primary, #808080);
}
.mdc-tab--active .mdc-tab__icon {
  color: #808080;
  /* @alternate */
  color: var(--mdc-theme-primary, #808080);
  fill: currentColor;
}
.mdc-tab--active .mdc-tab__text-label,
.mdc-tab--active .mdc-tab__icon {
  transition-delay: 100ms;
}

.mdc-tab:not(.mdc-tab--stacked) .mdc-tab__icon + .mdc-tab__text-label {
  /* @noflip */
  padding-left: 8px;
  /* @noflip */
  padding-right: 0;
}
[dir=rtl] .mdc-tab:not(.mdc-tab--stacked) .mdc-tab__icon + .mdc-tab__text-label, .mdc-tab:not(.mdc-tab--stacked) .mdc-tab__icon + .mdc-tab__text-label[dir=rtl] {
  /* @noflip */
  padding-left: 0;
  /* @noflip */
  padding-right: 8px;
}

/**
 * @license
 * Copyright 2018 Google Inc.
 *
 * Permission is hereby granted, free of charge, to any person obtaining a copy
 * of this software and associated documentation files (the "Software"), to deal
 * in the Software without restriction, including without limitation the rights
 * to use, copy, modify, merge, publish, distribute, sublicense, and/or sell
 * copies of the Software, and to permit persons to whom the Software is
 * furnished to do so, subject to the following conditions:
 *
 * The above copyright notice and this permission notice shall be included in
 * all copies or substantial portions of the Software.
 *
 * THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR
 * IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY,
 * FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT. IN NO EVENT SHALL THE
 * AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM, DAMAGES OR OTHER
 * LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR OTHERWISE, ARISING FROM,
 * OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE USE OR OTHER DEALINGS IN
 * THE SOFTWARE.
 */
/**
 * @license
 * Copyright 2018 Google Inc.
 *
 * Permission is hereby granted, free of charge, to any person obtaining a copy
 * of this software and associated documentation files (the "Software"), to deal
 * in the Software without restriction, including without limitation the rights
 * to use, copy, modify, merge, publish, distribute, sublicense, and/or sell
 * copies of the Software, and to permit persons to whom the Software is
 * furnished to do so, subject to the following conditions:
 *
 * The above copyright notice and this permission notice shall be included in
 * all copies or substantial portions of the Software.
 *
 * THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR
 * IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY,
 * FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT. IN NO EVENT SHALL THE
 * AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM, DAMAGES OR OTHER
 * LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR OTHERWISE, ARISING FROM,
 * OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE USE OR OTHER DEALINGS IN
 * THE SOFTWARE.
 */
.mdc-tab-bar {
  width: 100%;
}

/**
 * @license
 * Copyright 2018 Google Inc.
 *
 * Permission is hereby granted, free of charge, to any person obtaining a copy
 * of this software and associated documentation files (the "Software"), to deal
 * in the Software without restriction, including without limitation the rights
 * to use, copy, modify, merge, publish, distribute, sublicense, and/or sell
 * copies of the Software, and to permit persons to whom the Software is
 * furnished to do so, subject to the following conditions:
 *
 * The above copyright notice and this permission notice shall be included in
 * all copies or substantial portions of the Software.
 *
 * THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR
 * IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY,
 * FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT. IN NO EVENT SHALL THE
 * AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM, DAMAGES OR OTHER
 * LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR OTHERWISE, ARISING FROM,
 * OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE USE OR OTHER DEALINGS IN
 * THE SOFTWARE.
 */
/**
 * @license
 * Copyright 2018 Google Inc.
 *
 * Permission is hereby granted, free of charge, to any person obtaining a copy
 * of this software and associated documentation files (the "Software"), to deal
 * in the Software without restriction, including without limitation the rights
 * to use, copy, modify, merge, publish, distribute, sublicense, and/or sell
 * copies of the Software, and to permit persons to whom the Software is
 * furnished to do so, subject to the following conditions:
 *
 * The above copyright notice and this permission notice shall be included in
 * all copies or substantial portions of the Software.
 *
 * THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR
 * IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY,
 * FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT. IN NO EVENT SHALL THE
 * AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM, DAMAGES OR OTHER
 * LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR OTHERWISE, ARISING FROM,
 * OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE USE OR OTHER DEALINGS IN
 * THE SOFTWARE.
 */
.mdc-tab-indicator {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 1;
}
.mdc-tab-indicator .mdc-tab-indicator__content--underline {
  border-color: #808080;
  /* @alternate */
  border-color: var(--mdc-theme-primary, #808080);
}
.mdc-tab-indicator .mdc-tab-indicator__content--underline {
  border-top-width: 2px;
}
.mdc-tab-indicator .mdc-tab-indicator__content--icon {
  color: #1A73E8;
  /* @alternate */
  color: var(--mdc-theme-secondary, #1A73E8);
}
.mdc-tab-indicator .mdc-tab-indicator__content--icon {
  height: 34px;
  font-size: 34px;
}

.mdc-tab-indicator__content {
  transform-origin: left;
  opacity: 0;
}

.mdc-tab-indicator__content--underline {
  align-self: flex-end;
  box-sizing: border-box;
  width: 100%;
  border-top-style: solid;
}

.mdc-tab-indicator__content--icon {
  align-self: center;
  margin: 0 auto;
}

.mdc-tab-indicator--active .mdc-tab-indicator__content {
  opacity: 1;
}

.mdc-tab-indicator .mdc-tab-indicator__content {
  transition: 250ms transform cubic-bezier(0.4, 0, 0.2, 1);
}

.mdc-tab-indicator--no-transition .mdc-tab-indicator__content {
  transition: none;
}

.mdc-tab-indicator--fade .mdc-tab-indicator__content {
  transition: 150ms opacity linear;
}

.mdc-tab-indicator--active.mdc-tab-indicator--fade .mdc-tab-indicator__content {
  transition-delay: 100ms;
}

/**
 * @license
 * Copyright 2018 Google Inc.
 *
 * Permission is hereby granted, free of charge, to any person obtaining a copy
 * of this software and associated documentation files (the "Software"), to deal
 * in the Software without restriction, including without limitation the rights
 * to use, copy, modify, merge, publish, distribute, sublicense, and/or sell
 * copies of the Software, and to permit persons to whom the Software is
 * furnished to do so, subject to the following conditions:
 *
 * The above copyright notice and this permission notice shall be included in
 * all copies or substantial portions of the Software.
 *
 * THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR
 * IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY,
 * FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT. IN NO EVENT SHALL THE
 * AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM, DAMAGES OR OTHER
 * LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR OTHERWISE, ARISING FROM,
 * OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE USE OR OTHER DEALINGS IN
 * THE SOFTWARE.
 */
.mdc-tab-scroller {
  overflow-y: hidden;
}

.mdc-tab-scroller__test {
  position: absolute;
  top: -9999px;
  width: 100px;
  height: 100px;
  overflow-x: scroll;
}

.mdc-tab-scroller__scroll-area {
  -webkit-overflow-scrolling: touch;
  display: flex;
  overflow-x: hidden;
}

.mdc-tab-scroller__scroll-area::-webkit-scrollbar,
.mdc-tab-scroller__test::-webkit-scrollbar {
  display: none;
}

.mdc-tab-scroller__scroll-area--scroll {
  overflow-x: scroll;
}

.mdc-tab-scroller__scroll-content {
  position: relative;
  display: flex;
  flex: 1 0 auto;
  transform: none;
  will-change: transform;
}

.mdc-tab-scroller--align-start .mdc-tab-scroller__scroll-content {
  justify-content: flex-start;
}

.mdc-tab-scroller--align-end .mdc-tab-scroller__scroll-content {
  justify-content: flex-end;
}

.mdc-tab-scroller--align-center .mdc-tab-scroller__scroll-content {
  justify-content: center;
}

.mdc-tab-scroller--animating .mdc-tab-scroller__scroll-area {
  -webkit-overflow-scrolling: auto;
}

.mdc-tab-scroller--animating .mdc-tab-scroller__scroll-content {
  transition: 250ms transform cubic-bezier(0.4, 0, 0.2, 1);
}

.mdc-text-field-helper-text {
  font-family: Roboto, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 0.75rem;
  line-height: 1.25rem;
  font-weight: 400;
  letter-spacing: 0.0333333333em;
  text-decoration: inherit;
  text-transform: inherit;
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
  margin: 0;
  transition: opacity 150ms cubic-bezier(0.4, 0, 0.2, 1);
  opacity: 0;
  will-change: opacity;
}
.mdc-text-field-helper-text::before {
  display: inline-block;
  width: 0;
  height: 16px;
  content: "";
  vertical-align: 0;
}

.mdc-text-field-helper-text--persistent {
  transition: none;
  opacity: 1;
  will-change: initial;
}

.mdc-text-field-character-counter {
  font-family: Roboto, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 0.75rem;
  line-height: 1.25rem;
  font-weight: 400;
  letter-spacing: 0.0333333333em;
  text-decoration: inherit;
  text-transform: inherit;
  display: block;
  margin-top: 0;
  /* @alternate */
  line-height: normal;
  /* @noflip */
  margin-left: auto;
  /* @noflip */
  margin-right: 0;
  /* @noflip */
  padding-left: 16px;
  /* @noflip */
  padding-right: 0;
  white-space: nowrap;
}
.mdc-text-field-character-counter::before {
  display: inline-block;
  width: 0;
  height: 16px;
  content: "";
  vertical-align: 0;
}
[dir=rtl] .mdc-text-field-character-counter, .mdc-text-field-character-counter[dir=rtl] {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: auto;
}
[dir=rtl] .mdc-text-field-character-counter, .mdc-text-field-character-counter[dir=rtl] {
  /* @noflip */
  padding-left: 0;
  /* @noflip */
  padding-right: 16px;
}

.mdc-text-field--with-leading-icon .mdc-text-field__icon,
.mdc-text-field--with-trailing-icon .mdc-text-field__icon {
  position: absolute;
  bottom: 16px;
  cursor: pointer;
}

.mdc-text-field__icon:not([tabindex]),
.mdc-text-field__icon[tabindex="-1"] {
  cursor: default;
  pointer-events: none;
}

.mdc-text-field {
  --mdc-ripple-fg-size: 0;
  --mdc-ripple-left: 0;
  --mdc-ripple-top: 0;
  --mdc-ripple-fg-scale: 1;
  --mdc-ripple-fg-translate-end: 0;
  --mdc-ripple-fg-translate-start: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  border-radius: 4px 4px 0 0;
  display: inline-flex;
  position: relative;
  box-sizing: border-box;
  height: 56px;
  overflow: hidden;
  /* @alternate */
  will-change: opacity, transform, color;
}
.mdc-text-field::before, .mdc-text-field::after {
  position: absolute;
  border-radius: 50%;
  opacity: 0;
  pointer-events: none;
  content: "";
}
.mdc-text-field::before {
  transition: opacity 15ms linear, background-color 15ms linear;
  z-index: 1;
}
.mdc-text-field.mdc-ripple-upgraded::before {
  transform: scale(var(--mdc-ripple-fg-scale, 1));
}
.mdc-text-field.mdc-ripple-upgraded::after {
  top: 0;
  /* @noflip */
  left: 0;
  transform: scale(0);
  transform-origin: center center;
}
.mdc-text-field.mdc-ripple-upgraded--unbounded::after {
  top: var(--mdc-ripple-top, 0);
  /* @noflip */
  left: var(--mdc-ripple-left, 0);
}
.mdc-text-field.mdc-ripple-upgraded--foreground-activation::after {
  animation: mdc-ripple-fg-radius-in 225ms forwards, mdc-ripple-fg-opacity-in 75ms forwards;
}
.mdc-text-field.mdc-ripple-upgraded--foreground-deactivation::after {
  animation: mdc-ripple-fg-opacity-out 150ms;
  transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1));
}
.mdc-text-field::before, .mdc-text-field::after {
  background-color: rgba(0, 0, 0, 0.87);
}
.mdc-text-field:hover::before {
  opacity: 0.04;
}
.mdc-text-field:not(.mdc-ripple-upgraded):focus::before, .mdc-text-field.mdc-ripple-upgraded--background-focused::before {
  transition-duration: 75ms;
  opacity: 0.12;
}
.mdc-text-field::before, .mdc-text-field::after {
  top: calc(50% - 100%);
  /* @noflip */
  left: calc(50% - 100%);
  width: 200%;
  height: 200%;
}
.mdc-text-field.mdc-ripple-upgraded::after {
  width: var(--mdc-ripple-fg-size, 100%);
  height: var(--mdc-ripple-fg-size, 100%);
}
.mdc-text-field:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: rgba(0, 0, 0, 0.6);
}
.mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input {
  color: rgba(0, 0, 0, 0.87);
}
.mdc-text-field .mdc-text-field__input {
  caret-color: #808080;
  /* @alternate */
  caret-color: var(--mdc-theme-primary, #808080);
}
.mdc-text-field:not(.mdc-text-field--disabled):not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mdc-text-field__input {
  border-bottom-color: rgba(0, 0, 0, 0.42);
}
.mdc-text-field:not(.mdc-text-field--disabled):not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mdc-text-field__input:hover {
  border-bottom-color: rgba(0, 0, 0, 0.87);
}
.mdc-text-field .mdc-line-ripple {
  background-color: #808080;
  /* @alternate */
  background-color: var(--mdc-theme-primary, #808080);
}
.mdc-text-field:not(.mdc-text-field--disabled):not(.mdc-text-field--textarea) {
  border-bottom-color: rgba(0, 0, 0, 0.12);
}
.mdc-text-field:not(.mdc-text-field--disabled) + .mdc-text-field-helper-line .mdc-text-field-helper-text {
  color: rgba(0, 0, 0, 0.6);
}
.mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field-character-counter,
.mdc-text-field:not(.mdc-text-field--disabled) + .mdc-text-field-helper-line .mdc-text-field-character-counter {
  color: rgba(0, 0, 0, 0.6);
}
.mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__icon {
  color: rgba(0, 0, 0, 0.54);
}
.mdc-text-field:not(.mdc-text-field--disabled) {
  background-color: whitesmoke;
}
.mdc-text-field .mdc-floating-label {
  /* @noflip */
  left: 16px;
  /* @noflip */
  right: initial;
  top: 18px;
  pointer-events: none;
}
[dir=rtl] .mdc-text-field .mdc-floating-label, .mdc-text-field .mdc-floating-label[dir=rtl] {
  /* @noflip */
  left: initial;
  /* @noflip */
  right: 16px;
}
.mdc-text-field--textarea .mdc-floating-label {
  /* @noflip */
  left: 4px;
  /* @noflip */
  right: initial;
}
[dir=rtl] .mdc-text-field--textarea .mdc-floating-label, .mdc-text-field--textarea .mdc-floating-label[dir=rtl] {
  /* @noflip */
  left: initial;
  /* @noflip */
  right: 4px;
}
.mdc-text-field--outlined .mdc-floating-label {
  /* @noflip */
  left: 4px;
  /* @noflip */
  right: initial;
  top: 17px;
}
[dir=rtl] .mdc-text-field--outlined .mdc-floating-label, .mdc-text-field--outlined .mdc-floating-label[dir=rtl] {
  /* @noflip */
  left: initial;
  /* @noflip */
  right: 4px;
}
.mdc-text-field--outlined--with-leading-icon .mdc-floating-label {
  /* @noflip */
  left: 36px;
  /* @noflip */
  right: initial;
}
[dir=rtl] .mdc-text-field--outlined--with-leading-icon .mdc-floating-label, .mdc-text-field--outlined--with-leading-icon .mdc-floating-label[dir=rtl] {
  /* @noflip */
  left: initial;
  /* @noflip */
  right: 36px;
}
.mdc-text-field--outlined--with-leading-icon .mdc-floating-label--float-above {
  /* @noflip */
  left: 40px;
  /* @noflip */
  right: initial;
}
[dir=rtl] .mdc-text-field--outlined--with-leading-icon .mdc-floating-label--float-above, .mdc-text-field--outlined--with-leading-icon .mdc-floating-label--float-above[dir=rtl] {
  /* @noflip */
  left: initial;
  /* @noflip */
  right: 40px;
}

.mdc-text-field__input {
  font-family: Roboto, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 1rem;
  line-height: 1.75rem;
  font-weight: 400;
  letter-spacing: 0.009375em;
  text-decoration: inherit;
  text-transform: inherit;
  align-self: flex-end;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding: 20px 16px 6px;
  transition: opacity 150ms cubic-bezier(0.4, 0, 0.2, 1);
  border: none;
  border-bottom: 1px solid;
  border-radius: 0;
  background: none;
  appearance: none;
}
.mdc-text-field__input::placeholder {
  transition: opacity 67ms cubic-bezier(0.4, 0, 0.2, 1);
  opacity: 0;
  color: rgba(0, 0, 0, 0.54);
}
.mdc-text-field__input:-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.54) !important;
}
.mdc-text-field--fullwidth .mdc-text-field__input::placeholder, .mdc-text-field--no-label .mdc-text-field__input::placeholder, .mdc-text-field--focused .mdc-text-field__input::placeholder {
  transition-delay: 40ms;
  transition-duration: 110ms;
  opacity: 1;
}
.mdc-text-field__input:focus {
  outline: none;
}
.mdc-text-field__input:invalid {
  box-shadow: none;
}
.mdc-text-field__input:-webkit-autofill {
  z-index: auto !important;
}
.mdc-text-field--no-label:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mdc-text-field__input {
  padding-top: 16px;
  padding-bottom: 16px;
}

.mdc-text-field__input:-webkit-autofill + .mdc-floating-label {
  transform: translateY(-50%) scale(0.75);
  cursor: auto;
}

.mdc-text-field--outlined {
  border: none;
  overflow: visible;
}
.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-color: rgba(0, 0, 0, 0.24);
}
.mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused) .mdc-text-field__input:hover ~ .mdc-notched-outline .mdc-notched-outline__leading,
.mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused) .mdc-text-field__input:hover ~ .mdc-notched-outline .mdc-notched-outline__notch,
.mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused) .mdc-text-field__input:hover ~ .mdc-notched-outline .mdc-notched-outline__trailing,
.mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused) .mdc-text-field__icon:hover ~ .mdc-notched-outline .mdc-notched-outline__leading,
.mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused) .mdc-text-field__icon:hover ~ .mdc-notched-outline .mdc-notched-outline__notch,
.mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused) .mdc-text-field__icon:hover ~ .mdc-notched-outline .mdc-notched-outline__trailing {
  border-color: rgba(0, 0, 0, 0.87);
}
.mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: #808080;
  /* @alternate */
  border-color: var(--mdc-theme-primary, #808080);
}
.mdc-text-field--outlined .mdc-floating-label--shake {
  animation: mdc-floating-label-shake-float-above-text-field-outlined 250ms 1;
}
.mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__leading {
  /* @noflip */
  border-radius: 4px 0 0 4px;
}
[dir=rtl] .mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__leading, .mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__leading[dir=rtl] {
  /* @noflip */
  border-radius: 0 4px 4px 0;
}
.mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__trailing {
  /* @noflip */
  border-radius: 0 4px 4px 0;
}
[dir=rtl] .mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__trailing, .mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__trailing[dir=rtl] {
  /* @noflip */
  border-radius: 4px 0 0 4px;
}
.mdc-text-field--outlined .mdc-floating-label--float-above {
  transform: translateY(-144%) scale(1);
}
.mdc-text-field--outlined .mdc-floating-label--float-above {
  font-size: 0.75rem;
}
.mdc-text-field--outlined.mdc-notched-outline--upgraded .mdc-floating-label--float-above,
.mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  transform: translateY(-130%) scale(0.75);
}
.mdc-text-field--outlined.mdc-notched-outline--upgraded .mdc-floating-label--float-above,
.mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  font-size: 1rem;
}
.mdc-text-field--outlined::before, .mdc-text-field--outlined::after {
  content: none;
}
.mdc-text-field--outlined:not(.mdc-text-field--disabled) {
  background-color: transparent;
}
.mdc-text-field--outlined .mdc-text-field__input {
  display: flex;
  padding: 12px 16px 14px;
  border: none !important;
  background-color: transparent;
  z-index: 1;
}
.mdc-text-field--outlined .mdc-text-field__icon {
  z-index: 2;
}

.mdc-text-field--outlined.mdc-text-field--focused .mdc-notched-outline__leading,
.mdc-text-field--outlined.mdc-text-field--focused .mdc-notched-outline__notch,
.mdc-text-field--outlined.mdc-text-field--focused .mdc-notched-outline__trailing {
  border-width: 2px;
}

.mdc-text-field--outlined.mdc-text-field--disabled {
  background-color: transparent;
}
.mdc-text-field--outlined.mdc-text-field--disabled .mdc-notched-outline__leading,
.mdc-text-field--outlined.mdc-text-field--disabled .mdc-notched-outline__notch,
.mdc-text-field--outlined.mdc-text-field--disabled .mdc-notched-outline__trailing {
  border-color: rgba(0, 0, 0, 0.06);
}
.mdc-text-field--outlined.mdc-text-field--disabled .mdc-text-field__input {
  border-bottom: none;
}

.mdc-text-field--outlined.mdc-text-field--dense {
  height: 48px;
}
.mdc-text-field--outlined.mdc-text-field--dense .mdc-floating-label--float-above {
  transform: translateY(-134%) scale(1);
}
.mdc-text-field--outlined.mdc-text-field--dense .mdc-floating-label--float-above {
  font-size: 0.8rem;
}
.mdc-text-field--outlined.mdc-text-field--dense.mdc-notched-outline--upgraded .mdc-floating-label--float-above,
.mdc-text-field--outlined.mdc-text-field--dense .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  transform: translateY(-120%) scale(0.8);
}
.mdc-text-field--outlined.mdc-text-field--dense.mdc-notched-outline--upgraded .mdc-floating-label--float-above,
.mdc-text-field--outlined.mdc-text-field--dense .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  font-size: 1rem;
}
.mdc-text-field--outlined.mdc-text-field--dense .mdc-floating-label--shake {
  animation: mdc-floating-label-shake-float-above-text-field-outlined-dense 250ms 1;
}
.mdc-text-field--outlined.mdc-text-field--dense .mdc-text-field__input {
  padding: 12px 12px 7px;
}
.mdc-text-field--outlined.mdc-text-field--dense .mdc-floating-label {
  top: 14px;
}
.mdc-text-field--outlined.mdc-text-field--dense .mdc-text-field__icon {
  top: 12px;
}

.mdc-text-field--with-leading-icon .mdc-text-field__icon {
  /* @noflip */
  left: 16px;
  /* @noflip */
  right: initial;
}
[dir=rtl] .mdc-text-field--with-leading-icon .mdc-text-field__icon, .mdc-text-field--with-leading-icon .mdc-text-field__icon[dir=rtl] {
  /* @noflip */
  left: initial;
  /* @noflip */
  right: 16px;
}
.mdc-text-field--with-leading-icon .mdc-text-field__input {
  /* @noflip */
  padding-left: 48px;
  /* @noflip */
  padding-right: 16px;
}
[dir=rtl] .mdc-text-field--with-leading-icon .mdc-text-field__input, .mdc-text-field--with-leading-icon .mdc-text-field__input[dir=rtl] {
  /* @noflip */
  padding-left: 16px;
  /* @noflip */
  padding-right: 48px;
}
.mdc-text-field--with-leading-icon .mdc-floating-label {
  /* @noflip */
  left: 48px;
  /* @noflip */
  right: initial;
}
[dir=rtl] .mdc-text-field--with-leading-icon .mdc-floating-label, .mdc-text-field--with-leading-icon .mdc-floating-label[dir=rtl] {
  /* @noflip */
  left: initial;
  /* @noflip */
  right: 48px;
}

.mdc-text-field--with-leading-icon.mdc-text-field--outlined .mdc-text-field__icon {
  /* @noflip */
  left: 16px;
  /* @noflip */
  right: initial;
}
[dir=rtl] .mdc-text-field--with-leading-icon.mdc-text-field--outlined .mdc-text-field__icon, .mdc-text-field--with-leading-icon.mdc-text-field--outlined .mdc-text-field__icon[dir=rtl] {
  /* @noflip */
  left: initial;
  /* @noflip */
  right: 16px;
}
.mdc-text-field--with-leading-icon.mdc-text-field--outlined .mdc-text-field__input {
  /* @noflip */
  padding-left: 48px;
  /* @noflip */
  padding-right: 16px;
}
[dir=rtl] .mdc-text-field--with-leading-icon.mdc-text-field--outlined .mdc-text-field__input, .mdc-text-field--with-leading-icon.mdc-text-field--outlined .mdc-text-field__input[dir=rtl] {
  /* @noflip */
  padding-left: 16px;
  /* @noflip */
  padding-right: 48px;
}
.mdc-text-field--with-leading-icon.mdc-text-field--outlined .mdc-floating-label--float-above {
  transform: translateY(-144%) translateX(-32px) scale(1);
}
[dir=rtl] .mdc-text-field--with-leading-icon.mdc-text-field--outlined .mdc-floating-label--float-above, .mdc-text-field--with-leading-icon.mdc-text-field--outlined .mdc-floating-label--float-above[dir=rtl] {
  transform: translateY(-144%) translateX(32px) scale(1);
}
.mdc-text-field--with-leading-icon.mdc-text-field--outlined .mdc-floating-label--float-above {
  font-size: 0.75rem;
}
.mdc-text-field--with-leading-icon.mdc-text-field--outlined.mdc-notched-outline--upgraded .mdc-floating-label--float-above,
.mdc-text-field--with-leading-icon.mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  transform: translateY(-130%) translateX(-32px) scale(0.75);
}
[dir=rtl] .mdc-text-field--with-leading-icon.mdc-text-field--outlined.mdc-notched-outline--upgraded .mdc-floating-label--float-above, .mdc-text-field--with-leading-icon.mdc-text-field--outlined.mdc-notched-outline--upgraded .mdc-floating-label--float-above[dir=rtl],
[dir=rtl] .mdc-text-field--with-leading-icon.mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above,
.mdc-text-field--with-leading-icon.mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above[dir=rtl] {
  transform: translateY(-130%) translateX(32px) scale(0.75);
}
.mdc-text-field--with-leading-icon.mdc-text-field--outlined.mdc-notched-outline--upgraded .mdc-floating-label--float-above,
.mdc-text-field--with-leading-icon.mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  font-size: 1rem;
}
.mdc-text-field--with-leading-icon.mdc-text-field--outlined .mdc-floating-label--shake {
  animation: mdc-floating-label-shake-float-above-text-field-outlined-leading-icon 250ms 1;
}
[dir=rtl] .mdc-text-field--with-leading-icon.mdc-text-field--outlined .mdc-floating-label--shake, .mdc-text-field--with-leading-icon.mdc-text-field--outlined[dir=rtl] .mdc-floating-label--shake {
  animation: mdc-floating-label-shake-float-above-text-field-outlined-leading-icon-rtl 250ms 1;
}
.mdc-text-field--with-leading-icon.mdc-text-field--outlined .mdc-floating-label {
  /* @noflip */
  left: 36px;
  /* @noflip */
  right: initial;
}
[dir=rtl] .mdc-text-field--with-leading-icon.mdc-text-field--outlined .mdc-floating-label, .mdc-text-field--with-leading-icon.mdc-text-field--outlined .mdc-floating-label[dir=rtl] {
  /* @noflip */
  left: initial;
  /* @noflip */
  right: 36px;
}

.mdc-text-field--with-leading-icon.mdc-text-field--outlined.mdc-text-field--dense .mdc-floating-label--float-above {
  transform: translateY(-134%) translateX(-21px) scale(1);
}
[dir=rtl] .mdc-text-field--with-leading-icon.mdc-text-field--outlined.mdc-text-field--dense .mdc-floating-label--float-above, .mdc-text-field--with-leading-icon.mdc-text-field--outlined.mdc-text-field--dense .mdc-floating-label--float-above[dir=rtl] {
  transform: translateY(-134%) translateX(21px) scale(1);
}
.mdc-text-field--with-leading-icon.mdc-text-field--outlined.mdc-text-field--dense .mdc-floating-label--float-above {
  font-size: 0.8rem;
}
.mdc-text-field--with-leading-icon.mdc-text-field--outlined.mdc-text-field--dense.mdc-notched-outline--upgraded .mdc-floating-label--float-above,
.mdc-text-field--with-leading-icon.mdc-text-field--outlined.mdc-text-field--dense .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  transform: translateY(-120%) translateX(-21px) scale(0.8);
}
[dir=rtl] .mdc-text-field--with-leading-icon.mdc-text-field--outlined.mdc-text-field--dense.mdc-notched-outline--upgraded .mdc-floating-label--float-above, .mdc-text-field--with-leading-icon.mdc-text-field--outlined.mdc-text-field--dense.mdc-notched-outline--upgraded .mdc-floating-label--float-above[dir=rtl],
[dir=rtl] .mdc-text-field--with-leading-icon.mdc-text-field--outlined.mdc-text-field--dense .mdc-notched-outline--upgraded .mdc-floating-label--float-above,
.mdc-text-field--with-leading-icon.mdc-text-field--outlined.mdc-text-field--dense .mdc-notched-outline--upgraded .mdc-floating-label--float-above[dir=rtl] {
  transform: translateY(-120%) translateX(21px) scale(0.8);
}
.mdc-text-field--with-leading-icon.mdc-text-field--outlined.mdc-text-field--dense.mdc-notched-outline--upgraded .mdc-floating-label--float-above,
.mdc-text-field--with-leading-icon.mdc-text-field--outlined.mdc-text-field--dense .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  font-size: 1rem;
}
.mdc-text-field--with-leading-icon.mdc-text-field--outlined.mdc-text-field--dense .mdc-floating-label--shake {
  animation: mdc-floating-label-shake-float-above-text-field-outlined-leading-icon-dense 250ms 1;
}
[dir=rtl] .mdc-text-field--with-leading-icon.mdc-text-field--outlined.mdc-text-field--dense .mdc-floating-label--shake, .mdc-text-field--with-leading-icon.mdc-text-field--outlined.mdc-text-field--dense[dir=rtl] .mdc-floating-label--shake {
  animation: mdc-floating-label-shake-float-above-text-field-outlined-leading-icon-dense-rtl 250ms 1;
}
.mdc-text-field--with-leading-icon.mdc-text-field--outlined.mdc-text-field--dense .mdc-floating-label {
  /* @noflip */
  left: 32px;
  /* @noflip */
  right: initial;
}
[dir=rtl] .mdc-text-field--with-leading-icon.mdc-text-field--outlined.mdc-text-field--dense .mdc-floating-label, .mdc-text-field--with-leading-icon.mdc-text-field--outlined.mdc-text-field--dense .mdc-floating-label[dir=rtl] {
  /* @noflip */
  left: initial;
  /* @noflip */
  right: 32px;
}

.mdc-text-field--with-trailing-icon .mdc-text-field__icon {
  /* @noflip */
  left: initial;
  /* @noflip */
  right: 12px;
}
[dir=rtl] .mdc-text-field--with-trailing-icon .mdc-text-field__icon, .mdc-text-field--with-trailing-icon .mdc-text-field__icon[dir=rtl] {
  /* @noflip */
  left: 12px;
  /* @noflip */
  right: initial;
}
.mdc-text-field--with-trailing-icon .mdc-text-field__input {
  /* @noflip */
  padding-left: 16px;
  /* @noflip */
  padding-right: 48px;
}
[dir=rtl] .mdc-text-field--with-trailing-icon .mdc-text-field__input, .mdc-text-field--with-trailing-icon .mdc-text-field__input[dir=rtl] {
  /* @noflip */
  padding-left: 48px;
  /* @noflip */
  padding-right: 16px;
}
.mdc-text-field--with-trailing-icon.mdc-text-field--outlined .mdc-text-field__icon {
  /* @noflip */
  left: initial;
  /* @noflip */
  right: 16px;
}
[dir=rtl] .mdc-text-field--with-trailing-icon.mdc-text-field--outlined .mdc-text-field__icon, .mdc-text-field--with-trailing-icon.mdc-text-field--outlined .mdc-text-field__icon[dir=rtl] {
  /* @noflip */
  left: 16px;
  /* @noflip */
  right: initial;
}
.mdc-text-field--with-trailing-icon.mdc-text-field--outlined .mdc-text-field__input {
  /* @noflip */
  padding-left: 16px;
  /* @noflip */
  padding-right: 48px;
}
[dir=rtl] .mdc-text-field--with-trailing-icon.mdc-text-field--outlined .mdc-text-field__input, .mdc-text-field--with-trailing-icon.mdc-text-field--outlined .mdc-text-field__input[dir=rtl] {
  /* @noflip */
  padding-left: 48px;
  /* @noflip */
  padding-right: 16px;
}

.mdc-text-field--with-leading-icon.mdc-text-field--with-trailing-icon .mdc-text-field__icon {
  /* @noflip */
  left: 16px;
  /* @noflip */
  right: auto;
}
[dir=rtl] .mdc-text-field--with-leading-icon.mdc-text-field--with-trailing-icon .mdc-text-field__icon, .mdc-text-field--with-leading-icon.mdc-text-field--with-trailing-icon .mdc-text-field__icon[dir=rtl] {
  /* @noflip */
  left: auto;
  /* @noflip */
  right: 16px;
}
.mdc-text-field--with-leading-icon.mdc-text-field--with-trailing-icon .mdc-text-field__icon ~ .mdc-text-field__icon {
  /* @noflip */
  right: 12px;
  /* @noflip */
  left: auto;
}
[dir=rtl] .mdc-text-field--with-leading-icon.mdc-text-field--with-trailing-icon .mdc-text-field__icon ~ .mdc-text-field__icon, .mdc-text-field--with-leading-icon.mdc-text-field--with-trailing-icon .mdc-text-field__icon ~ .mdc-text-field__icon[dir=rtl] {
  /* @noflip */
  right: auto;
  /* @noflip */
  left: 12px;
}
.mdc-text-field--with-leading-icon.mdc-text-field--with-trailing-icon .mdc-text-field__input {
  /* @noflip */
  padding-left: 48px;
  /* @noflip */
  padding-right: 48px;
}
[dir=rtl] .mdc-text-field--with-leading-icon.mdc-text-field--with-trailing-icon .mdc-text-field__input, .mdc-text-field--with-leading-icon.mdc-text-field--with-trailing-icon .mdc-text-field__input[dir=rtl] {
  /* @noflip */
  padding-left: 48px;
  /* @noflip */
  padding-right: 48px;
}

.mdc-text-field--with-leading-icon.mdc-text-field--dense .mdc-text-field__icon,
.mdc-text-field--with-trailing-icon.mdc-text-field--dense .mdc-text-field__icon {
  bottom: 16px;
  transform: scale(0.8);
}

.mdc-text-field--with-leading-icon.mdc-text-field--dense .mdc-text-field__icon {
  /* @noflip */
  left: 12px;
  /* @noflip */
  right: initial;
}
[dir=rtl] .mdc-text-field--with-leading-icon.mdc-text-field--dense .mdc-text-field__icon, .mdc-text-field--with-leading-icon.mdc-text-field--dense .mdc-text-field__icon[dir=rtl] {
  /* @noflip */
  left: initial;
  /* @noflip */
  right: 12px;
}
.mdc-text-field--with-leading-icon.mdc-text-field--dense .mdc-text-field__input {
  /* @noflip */
  padding-left: 44px;
  /* @noflip */
  padding-right: 16px;
}
[dir=rtl] .mdc-text-field--with-leading-icon.mdc-text-field--dense .mdc-text-field__input, .mdc-text-field--with-leading-icon.mdc-text-field--dense .mdc-text-field__input[dir=rtl] {
  /* @noflip */
  padding-left: 16px;
  /* @noflip */
  padding-right: 44px;
}
.mdc-text-field--with-leading-icon.mdc-text-field--dense .mdc-floating-label {
  /* @noflip */
  left: 44px;
  /* @noflip */
  right: initial;
}
[dir=rtl] .mdc-text-field--with-leading-icon.mdc-text-field--dense .mdc-floating-label, .mdc-text-field--with-leading-icon.mdc-text-field--dense .mdc-floating-label[dir=rtl] {
  /* @noflip */
  left: initial;
  /* @noflip */
  right: 44px;
}

.mdc-text-field--with-trailing-icon.mdc-text-field--dense .mdc-text-field__icon {
  /* @noflip */
  left: initial;
  /* @noflip */
  right: 12px;
}
[dir=rtl] .mdc-text-field--with-trailing-icon.mdc-text-field--dense .mdc-text-field__icon, .mdc-text-field--with-trailing-icon.mdc-text-field--dense .mdc-text-field__icon[dir=rtl] {
  /* @noflip */
  left: 12px;
  /* @noflip */
  right: initial;
}
.mdc-text-field--with-trailing-icon.mdc-text-field--dense .mdc-text-field__input {
  /* @noflip */
  padding-left: 16px;
  /* @noflip */
  padding-right: 44px;
}
[dir=rtl] .mdc-text-field--with-trailing-icon.mdc-text-field--dense .mdc-text-field__input, .mdc-text-field--with-trailing-icon.mdc-text-field--dense .mdc-text-field__input[dir=rtl] {
  /* @noflip */
  padding-left: 44px;
  /* @noflip */
  padding-right: 16px;
}

.mdc-text-field--with-leading-icon.mdc-text-field--with-trailing-icon.mdc-text-field--dense .mdc-text-field__icon {
  /* @noflip */
  left: 12px;
  /* @noflip */
  right: auto;
}
[dir=rtl] .mdc-text-field--with-leading-icon.mdc-text-field--with-trailing-icon.mdc-text-field--dense .mdc-text-field__icon, .mdc-text-field--with-leading-icon.mdc-text-field--with-trailing-icon.mdc-text-field--dense .mdc-text-field__icon[dir=rtl] {
  /* @noflip */
  left: auto;
  /* @noflip */
  right: 12px;
}
.mdc-text-field--with-leading-icon.mdc-text-field--with-trailing-icon.mdc-text-field--dense .mdc-text-field__icon ~ .mdc-text-field__icon {
  /* @noflip */
  right: 12px;
  /* @noflip */
  left: auto;
}
[dir=rtl] .mdc-text-field--with-leading-icon.mdc-text-field--with-trailing-icon.mdc-text-field--dense .mdc-text-field__icon ~ .mdc-text-field__icon, .mdc-text-field--with-leading-icon.mdc-text-field--with-trailing-icon.mdc-text-field--dense .mdc-text-field__icon ~ .mdc-text-field__icon[dir=rtl] {
  /* @noflip */
  right: auto;
  /* @noflip */
  left: 12px;
}
.mdc-text-field--with-leading-icon.mdc-text-field--with-trailing-icon.mdc-text-field--dense .mdc-text-field__input {
  /* @noflip */
  padding-left: 44px;
  /* @noflip */
  padding-right: 44px;
}
[dir=rtl] .mdc-text-field--with-leading-icon.mdc-text-field--with-trailing-icon.mdc-text-field--dense .mdc-text-field__input, .mdc-text-field--with-leading-icon.mdc-text-field--with-trailing-icon.mdc-text-field--dense .mdc-text-field__input[dir=rtl] {
  /* @noflip */
  padding-left: 44px;
  /* @noflip */
  padding-right: 44px;
}

.mdc-text-field--dense .mdc-floating-label--float-above {
  transform: translateY(-70%) scale(0.8);
}
.mdc-text-field--dense .mdc-floating-label--shake {
  animation: mdc-floating-label-shake-float-above-text-field-dense 250ms 1;
}
.mdc-text-field--dense .mdc-text-field__input {
  padding: 12px 12px 0;
}
.mdc-text-field--dense .mdc-floating-label {
  font-size: 0.813rem;
}
.mdc-text-field--dense .mdc-floating-label--float-above {
  font-size: 0.813rem;
}

.mdc-text-field__input:required ~ .mdc-floating-label::after,
.mdc-text-field__input:required ~ .mdc-notched-outline .mdc-floating-label::after {
  margin-left: 1px;
  content: "*";
}

.mdc-text-field--textarea {
  display: inline-flex;
  width: auto;
  height: auto;
  transition: none;
  overflow: visible;
}
.mdc-text-field--textarea:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.mdc-text-field--textarea:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.mdc-text-field--textarea:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-color: rgba(0, 0, 0, 0.24);
}
.mdc-text-field--textarea:not(.mdc-text-field--disabled):not(.mdc-text-field--focused) .mdc-text-field__input:hover ~ .mdc-notched-outline .mdc-notched-outline__leading,
.mdc-text-field--textarea:not(.mdc-text-field--disabled):not(.mdc-text-field--focused) .mdc-text-field__input:hover ~ .mdc-notched-outline .mdc-notched-outline__notch,
.mdc-text-field--textarea:not(.mdc-text-field--disabled):not(.mdc-text-field--focused) .mdc-text-field__input:hover ~ .mdc-notched-outline .mdc-notched-outline__trailing,
.mdc-text-field--textarea:not(.mdc-text-field--disabled):not(.mdc-text-field--focused) .mdc-text-field__icon:hover ~ .mdc-notched-outline .mdc-notched-outline__leading,
.mdc-text-field--textarea:not(.mdc-text-field--disabled):not(.mdc-text-field--focused) .mdc-text-field__icon:hover ~ .mdc-notched-outline .mdc-notched-outline__notch,
.mdc-text-field--textarea:not(.mdc-text-field--disabled):not(.mdc-text-field--focused) .mdc-text-field__icon:hover ~ .mdc-notched-outline .mdc-notched-outline__trailing {
  border-color: rgba(0, 0, 0, 0.87);
}
.mdc-text-field--textarea:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.mdc-text-field--textarea:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.mdc-text-field--textarea:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: #808080;
  /* @alternate */
  border-color: var(--mdc-theme-primary, #808080);
}
.mdc-text-field--textarea .mdc-floating-label--shake {
  animation: mdc-floating-label-shake-float-above-text-field-outlined 250ms 1;
}
.mdc-text-field--textarea .mdc-notched-outline .mdc-notched-outline__leading {
  /* @noflip */
  border-radius: 4px 0 0 4px;
}
[dir=rtl] .mdc-text-field--textarea .mdc-notched-outline .mdc-notched-outline__leading, .mdc-text-field--textarea .mdc-notched-outline .mdc-notched-outline__leading[dir=rtl] {
  /* @noflip */
  border-radius: 0 4px 4px 0;
}
.mdc-text-field--textarea .mdc-notched-outline .mdc-notched-outline__trailing {
  /* @noflip */
  border-radius: 0 4px 4px 0;
}
[dir=rtl] .mdc-text-field--textarea .mdc-notched-outline .mdc-notched-outline__trailing, .mdc-text-field--textarea .mdc-notched-outline .mdc-notched-outline__trailing[dir=rtl] {
  /* @noflip */
  border-radius: 4px 0 0 4px;
}
.mdc-text-field--textarea::before, .mdc-text-field--textarea::after {
  content: none;
}
.mdc-text-field--textarea:not(.mdc-text-field--disabled) {
  background-color: transparent;
}
.mdc-text-field--textarea .mdc-floating-label--float-above {
  transform: translateY(-144%) scale(1);
}
.mdc-text-field--textarea .mdc-floating-label--float-above {
  font-size: 0.75rem;
}
.mdc-text-field--textarea.mdc-notched-outline--upgraded .mdc-floating-label--float-above,
.mdc-text-field--textarea .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  transform: translateY(-130%) scale(0.75);
}
.mdc-text-field--textarea.mdc-notched-outline--upgraded .mdc-floating-label--float-above,
.mdc-text-field--textarea .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  font-size: 1rem;
}
.mdc-text-field--textarea .mdc-text-field-character-counter {
  /* @noflip */
  left: initial;
  /* @noflip */
  right: 16px;
  position: absolute;
  bottom: 13px;
}
[dir=rtl] .mdc-text-field--textarea .mdc-text-field-character-counter, .mdc-text-field--textarea .mdc-text-field-character-counter[dir=rtl] {
  /* @noflip */
  left: 16px;
  /* @noflip */
  right: initial;
}
.mdc-text-field--textarea .mdc-text-field__input {
  align-self: auto;
  box-sizing: border-box;
  height: auto;
  margin: 8px 1px 1px 0;
  padding: 0 16px 16px;
  border: none;
}
.mdc-text-field--textarea .mdc-text-field-character-counter + .mdc-text-field__input {
  margin-bottom: 28px;
  padding-bottom: 0;
}
.mdc-text-field--textarea .mdc-floating-label {
  top: 17px;
  bottom: auto;
  width: auto;
  pointer-events: none;
}
.mdc-text-field--textarea.mdc-text-field--focused .mdc-notched-outline__leading,
.mdc-text-field--textarea.mdc-text-field--focused .mdc-notched-outline__notch,
.mdc-text-field--textarea.mdc-text-field--focused .mdc-notched-outline__trailing {
  border-width: 2px;
}

.mdc-text-field--fullwidth {
  width: 100%;
}
.mdc-text-field--fullwidth:not(.mdc-text-field--textarea) {
  display: block;
}
.mdc-text-field--fullwidth:not(.mdc-text-field--textarea)::before, .mdc-text-field--fullwidth:not(.mdc-text-field--textarea)::after {
  content: none;
}
.mdc-text-field--fullwidth:not(.mdc-text-field--textarea):not(.mdc-text-field--disabled) {
  background-color: transparent;
}
.mdc-text-field--fullwidth:not(.mdc-text-field--textarea) .mdc-text-field__input {
  padding: 0;
}
.mdc-text-field--fullwidth.mdc-text-field--textarea .mdc-text-field__input {
  resize: vertical;
}

.mdc-text-field--fullwidth.mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--textarea) {
  border-bottom-color: #b00020;
  /* @alternate */
  border-bottom-color: var(--mdc-theme-error, #b00020);
}

.mdc-text-field-helper-line {
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
}
.mdc-text-field--dense + .mdc-text-field-helper-line {
  margin-bottom: 4px;
}
.mdc-text-field + .mdc-text-field-helper-line {
  padding-right: 16px;
  padding-left: 16px;
}

.mdc-form-field > .mdc-text-field + label {
  align-self: flex-start;
}

.mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: rgba(128, 128, 128, 0.87);
}
.mdc-text-field--focused .mdc-text-field__input:required ~ .mdc-floating-label::after,
.mdc-text-field--focused .mdc-text-field__input:required ~ .mdc-notched-outline .mdc-floating-label::after {
  color: rgba(128, 128, 128, 0.87);
}
.mdc-text-field--focused + .mdc-text-field-helper-line .mdc-text-field-helper-text:not(.mdc-text-field-helper-text--validation-msg) {
  opacity: 1;
}

.mdc-text-field--textarea.mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.mdc-text-field--textarea.mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.mdc-text-field--textarea.mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-color: #808080;
  /* @alternate */
  border-color: var(--mdc-theme-primary, #808080);
}

.mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mdc-text-field__input {
  border-bottom-color: #b00020;
  /* @alternate */
  border-bottom-color: var(--mdc-theme-error, #b00020);
}
.mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mdc-text-field__input:hover {
  border-bottom-color: #b00020;
  /* @alternate */
  border-bottom-color: var(--mdc-theme-error, #b00020);
}
.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple {
  background-color: #b00020;
  /* @alternate */
  background-color: var(--mdc-theme-error, #b00020);
}
.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: #b00020;
  /* @alternate */
  color: var(--mdc-theme-error, #b00020);
}
.mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--invalid + .mdc-text-field-helper-line .mdc-text-field-helper-text--validation-msg {
  color: #b00020;
  /* @alternate */
  color: var(--mdc-theme-error, #b00020);
}
.mdc-text-field--invalid .mdc-text-field__input {
  caret-color: #b00020;
  /* @alternate */
  caret-color: var(--mdc-theme-error, #b00020);
}
.mdc-text-field--invalid .mdc-text-field__input:required ~ .mdc-floating-label::after,
.mdc-text-field--invalid .mdc-text-field__input:required ~ .mdc-notched-outline .mdc-floating-label::after {
  color: #b00020;
  /* @alternate */
  color: var(--mdc-theme-error, #b00020);
}
.mdc-text-field--invalid.mdc-text-field--with-trailing-icon:not(.mdc-text-field--with-leading-icon):not(.mdc-text-field--disabled) .mdc-text-field__icon {
  color: #b00020;
  /* @alternate */
  color: var(--mdc-theme-error, #b00020);
}
.mdc-text-field--invalid.mdc-text-field--with-trailing-icon.mdc-text-field--with-leading-icon:not(.mdc-text-field--disabled) .mdc-text-field__icon ~ .mdc-text-field__icon {
  color: #b00020;
  /* @alternate */
  color: var(--mdc-theme-error, #b00020);
}
.mdc-text-field--invalid + .mdc-text-field-helper-line .mdc-text-field-helper-text--validation-msg {
  opacity: 1;
}

.mdc-text-field--textarea.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.mdc-text-field--textarea.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.mdc-text-field--textarea.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-color: #b00020;
  /* @alternate */
  border-color: var(--mdc-theme-error, #b00020);
}
.mdc-text-field--textarea.mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused) .mdc-text-field__input:hover ~ .mdc-notched-outline .mdc-notched-outline__leading,
.mdc-text-field--textarea.mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused) .mdc-text-field__input:hover ~ .mdc-notched-outline .mdc-notched-outline__notch,
.mdc-text-field--textarea.mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused) .mdc-text-field__input:hover ~ .mdc-notched-outline .mdc-notched-outline__trailing,
.mdc-text-field--textarea.mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused) .mdc-text-field__icon:hover ~ .mdc-notched-outline .mdc-notched-outline__leading,
.mdc-text-field--textarea.mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused) .mdc-text-field__icon:hover ~ .mdc-notched-outline .mdc-notched-outline__notch,
.mdc-text-field--textarea.mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused) .mdc-text-field__icon:hover ~ .mdc-notched-outline .mdc-notched-outline__trailing {
  border-color: #b00020;
  /* @alternate */
  border-color: var(--mdc-theme-error, #b00020);
}
.mdc-text-field--textarea.mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.mdc-text-field--textarea.mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.mdc-text-field--textarea.mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: #b00020;
  /* @alternate */
  border-color: var(--mdc-theme-error, #b00020);
}

.mdc-text-field--outlined.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.mdc-text-field--outlined.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.mdc-text-field--outlined.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-color: #b00020;
  /* @alternate */
  border-color: var(--mdc-theme-error, #b00020);
}
.mdc-text-field--outlined.mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused) .mdc-text-field__input:hover ~ .mdc-notched-outline .mdc-notched-outline__leading,
.mdc-text-field--outlined.mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused) .mdc-text-field__input:hover ~ .mdc-notched-outline .mdc-notched-outline__notch,
.mdc-text-field--outlined.mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused) .mdc-text-field__input:hover ~ .mdc-notched-outline .mdc-notched-outline__trailing,
.mdc-text-field--outlined.mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused) .mdc-text-field__icon:hover ~ .mdc-notched-outline .mdc-notched-outline__leading,
.mdc-text-field--outlined.mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused) .mdc-text-field__icon:hover ~ .mdc-notched-outline .mdc-notched-outline__notch,
.mdc-text-field--outlined.mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused) .mdc-text-field__icon:hover ~ .mdc-notched-outline .mdc-notched-outline__trailing {
  border-color: #b00020;
  /* @alternate */
  border-color: var(--mdc-theme-error, #b00020);
}
.mdc-text-field--outlined.mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.mdc-text-field--outlined.mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.mdc-text-field--outlined.mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: #b00020;
  /* @alternate */
  border-color: var(--mdc-theme-error, #b00020);
}

.mdc-text-field--disabled {
  background-color: #fafafa;
  border-bottom: none;
  pointer-events: none;
}
.mdc-text-field--disabled .mdc-text-field__input {
  border-bottom-color: rgba(0, 0, 0, 0.06);
}
.mdc-text-field--disabled .mdc-text-field__input {
  color: rgba(0, 0, 0, 0.37);
}
.mdc-text-field--disabled .mdc-floating-label {
  color: rgba(0, 0, 0, 0.37);
}
.mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-helper-text {
  color: rgba(0, 0, 0, 0.37);
}
.mdc-text-field--disabled .mdc-text-field-character-counter,
.mdc-text-field--disabled + .mdc-text-field-helper-line .mdc-text-field-character-counter {
  color: rgba(0, 0, 0, 0.37);
}
.mdc-text-field--disabled .mdc-text-field__icon {
  color: rgba(0, 0, 0, 0.3);
}
.mdc-text-field--disabled:not(.mdc-text-field--textarea) {
  border-bottom-color: rgba(0, 0, 0, 0.12);
}
.mdc-text-field--disabled .mdc-floating-label {
  cursor: default;
}

.mdc-text-field--textarea.mdc-text-field--disabled {
  background-color: transparent;
  /* @alternate */
  background-color: #f9f9f9;
}
.mdc-text-field--textarea.mdc-text-field--disabled .mdc-notched-outline__leading,
.mdc-text-field--textarea.mdc-text-field--disabled .mdc-notched-outline__notch,
.mdc-text-field--textarea.mdc-text-field--disabled .mdc-notched-outline__trailing {
  border-color: rgba(0, 0, 0, 0.06);
}
.mdc-text-field--textarea.mdc-text-field--disabled .mdc-text-field__input {
  border-bottom: none;
}

@keyframes mdc-floating-label-shake-float-above-text-field-dense {
  /* clean-css ignore:start */
  0% {
    transform: translateX(calc(0 - 0%)) translateY(-70%) scale(0.8);
  }
  33% {
    animation-timing-function: cubic-bezier(0.5, 0, 0.701732, 0.495819);
    transform: translateX(calc(4% - 0%)) translateY(-70%) scale(0.8);
  }
  66% {
    animation-timing-function: cubic-bezier(0.302435, 0.381352, 0.55, 0.956352);
    transform: translateX(calc(-4% - 0%)) translateY(-70%) scale(0.8);
  }
  100% {
    transform: translateX(calc(0 - 0%)) translateY(-70%) scale(0.8);
  }
  /* clean-css ignore:end */
}
@keyframes mdc-floating-label-shake-float-above-text-field-outlined {
  /* clean-css ignore:start */
  0% {
    transform: translateX(calc(0 - 0%)) translateY(-130%) scale(0.75);
  }
  33% {
    animation-timing-function: cubic-bezier(0.5, 0, 0.701732, 0.495819);
    transform: translateX(calc(4% - 0%)) translateY(-130%) scale(0.75);
  }
  66% {
    animation-timing-function: cubic-bezier(0.302435, 0.381352, 0.55, 0.956352);
    transform: translateX(calc(-4% - 0%)) translateY(-130%) scale(0.75);
  }
  100% {
    transform: translateX(calc(0 - 0%)) translateY(-130%) scale(0.75);
  }
  /* clean-css ignore:end */
}
@keyframes mdc-floating-label-shake-float-above-text-field-outlined-dense {
  /* clean-css ignore:start */
  0% {
    transform: translateX(calc(0 - 0%)) translateY(-120%) scale(0.8);
  }
  33% {
    animation-timing-function: cubic-bezier(0.5, 0, 0.701732, 0.495819);
    transform: translateX(calc(4% - 0%)) translateY(-120%) scale(0.8);
  }
  66% {
    animation-timing-function: cubic-bezier(0.302435, 0.381352, 0.55, 0.956352);
    transform: translateX(calc(-4% - 0%)) translateY(-120%) scale(0.8);
  }
  100% {
    transform: translateX(calc(0 - 0%)) translateY(-120%) scale(0.8);
  }
  /* clean-css ignore:end */
}
@keyframes mdc-floating-label-shake-float-above-text-field-outlined-leading-icon {
  /* clean-css ignore:start */
  0% {
    transform: translateX(calc(0 - 0)) translateY(-130%) scale(0.75);
  }
  33% {
    animation-timing-function: cubic-bezier(0.5, 0, 0.701732, 0.495819);
    transform: translateX(calc(4% - 0)) translateY(-130%) scale(0.75);
  }
  66% {
    animation-timing-function: cubic-bezier(0.302435, 0.381352, 0.55, 0.956352);
    transform: translateX(calc(-4% - 0)) translateY(-130%) scale(0.75);
  }
  100% {
    transform: translateX(calc(0 - 0)) translateY(-130%) scale(0.75);
  }
  /* clean-css ignore:end */
}
@keyframes mdc-floating-label-shake-float-above-text-field-outlined-leading-icon-dense {
  /* clean-css ignore:start */
  0% {
    transform: translateX(calc(0 - 21px)) translateY(-120%) scale(0.8);
  }
  33% {
    animation-timing-function: cubic-bezier(0.5, 0, 0.701732, 0.495819);
    transform: translateX(calc(4% - 21px)) translateY(-120%) scale(0.8);
  }
  66% {
    animation-timing-function: cubic-bezier(0.302435, 0.381352, 0.55, 0.956352);
    transform: translateX(calc(-4% - 21px)) translateY(-120%) scale(0.8);
  }
  100% {
    transform: translateX(calc(0 - 21px)) translateY(-120%) scale(0.8);
  }
  /* clean-css ignore:end */
}
@keyframes mdc-floating-label-shake-float-above-text-field-outlined-leading-icon-rtl {
  /* clean-css ignore:start */
  0% {
    transform: translateX(calc(0 - 0)) translateY(-130%) scale(0.75);
  }
  33% {
    animation-timing-function: cubic-bezier(0.5, 0, 0.701732, 0.495819);
    transform: translateX(calc(4% - 0)) translateY(-130%) scale(0.75);
  }
  66% {
    animation-timing-function: cubic-bezier(0.302435, 0.381352, 0.55, 0.956352);
    transform: translateX(calc(-4% - 0)) translateY(-130%) scale(0.75);
  }
  100% {
    transform: translateX(calc(0 - 0)) translateY(-130%) scale(0.75);
  }
  /* clean-css ignore:end */
}
@keyframes mdc-floating-label-shake-float-above-text-field-outlined-leading-icon-dense-rtl {
  /* clean-css ignore:start */
  0% {
    transform: translateX(calc(0 - -21px)) translateY(-120%) scale(0.8);
  }
  33% {
    animation-timing-function: cubic-bezier(0.5, 0, 0.701732, 0.495819);
    transform: translateX(calc(4% - -21px)) translateY(-120%) scale(0.8);
  }
  66% {
    animation-timing-function: cubic-bezier(0.302435, 0.381352, 0.55, 0.956352);
    transform: translateX(calc(-4% - -21px)) translateY(-120%) scale(0.8);
  }
  100% {
    transform: translateX(calc(0 - -21px)) translateY(-120%) scale(0.8);
  }
  /* clean-css ignore:end */
}
@keyframes mdc-floating-label-shake-float-above-textarea {
  /* clean-css ignore:start */
  0% {
    transform: translateX(calc(0 - 0%)) translateY(-130%) scale(0.75);
  }
  33% {
    animation-timing-function: cubic-bezier(0.5, 0, 0.701732, 0.495819);
    transform: translateX(calc(4% - 0%)) translateY(-130%) scale(0.75);
  }
  66% {
    animation-timing-function: cubic-bezier(0.302435, 0.381352, 0.55, 0.956352);
    transform: translateX(calc(-4% - 0%)) translateY(-130%) scale(0.75);
  }
  100% {
    transform: translateX(calc(0 - 0%)) translateY(-130%) scale(0.75);
  }
  /* clean-css ignore:end */
}
:root {
  --mdc-theme-primary: #808080;
  --mdc-theme-secondary: #1A73E8;
  --mdc-theme-background: #fff;
  --mdc-theme-surface: #fff;
  --mdc-theme-error: #b00020;
  --mdc-theme-on-primary: #fff;
  --mdc-theme-on-secondary: #fff;
  --mdc-theme-on-surface: #000;
  --mdc-theme-on-error: #fff;
  --mdc-theme-text-primary-on-background: rgba(0, 0, 0, 0.87);
  --mdc-theme-text-secondary-on-background: rgba(0, 0, 0, 0.54);
  --mdc-theme-text-hint-on-background: rgba(0, 0, 0, 0.38);
  --mdc-theme-text-disabled-on-background: rgba(0, 0, 0, 0.38);
  --mdc-theme-text-icon-on-background: rgba(0, 0, 0, 0.38);
  --mdc-theme-text-primary-on-light: rgba(0, 0, 0, 0.87);
  --mdc-theme-text-secondary-on-light: rgba(0, 0, 0, 0.54);
  --mdc-theme-text-hint-on-light: rgba(0, 0, 0, 0.38);
  --mdc-theme-text-disabled-on-light: rgba(0, 0, 0, 0.38);
  --mdc-theme-text-icon-on-light: rgba(0, 0, 0, 0.38);
  --mdc-theme-text-primary-on-dark: white;
  --mdc-theme-text-secondary-on-dark: rgba(255, 255, 255, 0.7);
  --mdc-theme-text-hint-on-dark: rgba(255, 255, 255, 0.5);
  --mdc-theme-text-disabled-on-dark: rgba(255, 255, 255, 0.5);
  --mdc-theme-text-icon-on-dark: rgba(255, 255, 255, 0.5);
}

.mdc-theme--primary {
  color: #808080 !important;
  /* @alternate */
  color: var(--mdc-theme-primary, #808080) !important;
}

.mdc-theme--secondary {
  color: #1A73E8 !important;
  /* @alternate */
  color: var(--mdc-theme-secondary, #1A73E8) !important;
}

.mdc-theme--background {
  background-color: #fff;
  /* @alternate */
  background-color: var(--mdc-theme-background, #fff);
}

.mdc-theme--surface {
  background-color: #fff;
  /* @alternate */
  background-color: var(--mdc-theme-surface, #fff);
}

.mdc-theme--error {
  color: #b00020 !important;
  /* @alternate */
  color: var(--mdc-theme-error, #b00020) !important;
}

.mdc-theme--on-primary {
  color: #fff !important;
  /* @alternate */
  color: var(--mdc-theme-on-primary, #fff) !important;
}

.mdc-theme--on-secondary {
  color: #fff !important;
  /* @alternate */
  color: var(--mdc-theme-on-secondary, #fff) !important;
}

.mdc-theme--on-surface {
  color: #000 !important;
  /* @alternate */
  color: var(--mdc-theme-on-surface, #000) !important;
}

.mdc-theme--on-error {
  color: #fff !important;
  /* @alternate */
  color: var(--mdc-theme-on-error, #fff) !important;
}

.mdc-theme--text-primary-on-background {
  color: rgba(0, 0, 0, 0.87) !important;
  /* @alternate */
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87)) !important;
}

.mdc-theme--text-secondary-on-background {
  color: rgba(0, 0, 0, 0.54) !important;
  /* @alternate */
  color: var(--mdc-theme-text-secondary-on-background, rgba(0, 0, 0, 0.54)) !important;
}

.mdc-theme--text-hint-on-background {
  color: rgba(0, 0, 0, 0.38) !important;
  /* @alternate */
  color: var(--mdc-theme-text-hint-on-background, rgba(0, 0, 0, 0.38)) !important;
}

.mdc-theme--text-disabled-on-background {
  color: rgba(0, 0, 0, 0.38) !important;
  /* @alternate */
  color: var(--mdc-theme-text-disabled-on-background, rgba(0, 0, 0, 0.38)) !important;
}

.mdc-theme--text-icon-on-background {
  color: rgba(0, 0, 0, 0.38) !important;
  /* @alternate */
  color: var(--mdc-theme-text-icon-on-background, rgba(0, 0, 0, 0.38)) !important;
}

.mdc-theme--text-primary-on-light {
  color: rgba(0, 0, 0, 0.87) !important;
  /* @alternate */
  color: var(--mdc-theme-text-primary-on-light, rgba(0, 0, 0, 0.87)) !important;
}

.mdc-theme--text-secondary-on-light {
  color: rgba(0, 0, 0, 0.54) !important;
  /* @alternate */
  color: var(--mdc-theme-text-secondary-on-light, rgba(0, 0, 0, 0.54)) !important;
}

.mdc-theme--text-hint-on-light {
  color: rgba(0, 0, 0, 0.38) !important;
  /* @alternate */
  color: var(--mdc-theme-text-hint-on-light, rgba(0, 0, 0, 0.38)) !important;
}

.mdc-theme--text-disabled-on-light {
  color: rgba(0, 0, 0, 0.38) !important;
  /* @alternate */
  color: var(--mdc-theme-text-disabled-on-light, rgba(0, 0, 0, 0.38)) !important;
}

.mdc-theme--text-icon-on-light {
  color: rgba(0, 0, 0, 0.38) !important;
  /* @alternate */
  color: var(--mdc-theme-text-icon-on-light, rgba(0, 0, 0, 0.38)) !important;
}

.mdc-theme--text-primary-on-dark {
  color: white !important;
  /* @alternate */
  color: var(--mdc-theme-text-primary-on-dark, white) !important;
}

.mdc-theme--text-secondary-on-dark {
  color: rgba(255, 255, 255, 0.7) !important;
  /* @alternate */
  color: var(--mdc-theme-text-secondary-on-dark, rgba(255, 255, 255, 0.7)) !important;
}

.mdc-theme--text-hint-on-dark {
  color: rgba(255, 255, 255, 0.5) !important;
  /* @alternate */
  color: var(--mdc-theme-text-hint-on-dark, rgba(255, 255, 255, 0.5)) !important;
}

.mdc-theme--text-disabled-on-dark {
  color: rgba(255, 255, 255, 0.5) !important;
  /* @alternate */
  color: var(--mdc-theme-text-disabled-on-dark, rgba(255, 255, 255, 0.5)) !important;
}

.mdc-theme--text-icon-on-dark {
  color: rgba(255, 255, 255, 0.5) !important;
  /* @alternate */
  color: var(--mdc-theme-text-icon-on-dark, rgba(255, 255, 255, 0.5)) !important;
}

.mdc-theme--primary-bg {
  background-color: #808080 !important;
  /* @alternate */
  background-color: var(--mdc-theme-primary, #808080) !important;
}

.mdc-theme--secondary-bg {
  background-color: #1A73E8 !important;
  /* @alternate */
  background-color: var(--mdc-theme-secondary, #1A73E8) !important;
}

.mdc-top-app-bar {
  background-color: #808080;
  /* @alternate */
  background-color: var(--mdc-theme-primary, #808080);
  color: white;
  display: flex;
  position: fixed;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
  width: 100%;
  z-index: 4;
}
.mdc-top-app-bar .mdc-top-app-bar__action-item,
.mdc-top-app-bar .mdc-top-app-bar__navigation-icon {
  color: #fff;
  /* @alternate */
  color: var(--mdc-theme-on-primary, #fff);
}
.mdc-top-app-bar .mdc-top-app-bar__action-item::before, .mdc-top-app-bar .mdc-top-app-bar__action-item::after,
.mdc-top-app-bar .mdc-top-app-bar__navigation-icon::before,
.mdc-top-app-bar .mdc-top-app-bar__navigation-icon::after {
  background-color: #fff;
}
@supports not (-ms-ime-align: auto) {
  .mdc-top-app-bar .mdc-top-app-bar__action-item::before, .mdc-top-app-bar .mdc-top-app-bar__action-item::after,
.mdc-top-app-bar .mdc-top-app-bar__navigation-icon::before,
.mdc-top-app-bar .mdc-top-app-bar__navigation-icon::after {
    /* @alternate */
    background-color: var(--mdc-theme-on-primary, #fff);
  }
}
.mdc-top-app-bar .mdc-top-app-bar__action-item:hover::before,
.mdc-top-app-bar .mdc-top-app-bar__navigation-icon:hover::before {
  opacity: 0.08;
}
.mdc-top-app-bar .mdc-top-app-bar__action-item:not(.mdc-ripple-upgraded):focus::before, .mdc-top-app-bar .mdc-top-app-bar__action-item.mdc-ripple-upgraded--background-focused::before,
.mdc-top-app-bar .mdc-top-app-bar__navigation-icon:not(.mdc-ripple-upgraded):focus::before,
.mdc-top-app-bar .mdc-top-app-bar__navigation-icon.mdc-ripple-upgraded--background-focused::before {
  transition-duration: 75ms;
  opacity: 0.24;
}
.mdc-top-app-bar .mdc-top-app-bar__action-item:not(.mdc-ripple-upgraded)::after,
.mdc-top-app-bar .mdc-top-app-bar__navigation-icon:not(.mdc-ripple-upgraded)::after {
  transition: opacity 150ms linear;
}
.mdc-top-app-bar .mdc-top-app-bar__action-item:not(.mdc-ripple-upgraded):active::after,
.mdc-top-app-bar .mdc-top-app-bar__navigation-icon:not(.mdc-ripple-upgraded):active::after {
  transition-duration: 75ms;
  opacity: 0.24;
}
.mdc-top-app-bar .mdc-top-app-bar__action-item.mdc-ripple-upgraded,
.mdc-top-app-bar .mdc-top-app-bar__navigation-icon.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: 0.24;
}
.mdc-top-app-bar__row {
  display: flex;
  position: relative;
  box-sizing: border-box;
  width: 100%;
  height: 64px;
}
.mdc-top-app-bar__section {
  display: inline-flex;
  flex: 1 1 auto;
  align-items: center;
  min-width: 0;
  padding: 8px 12px;
  z-index: 1;
}
.mdc-top-app-bar__section--align-start {
  justify-content: flex-start;
  order: -1;
}
.mdc-top-app-bar__section--align-end {
  justify-content: flex-end;
  order: 1;
}
.mdc-top-app-bar__title {
  font-family: Roboto, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 1.25rem;
  line-height: 2rem;
  font-weight: 500;
  letter-spacing: 0.0125em;
  text-decoration: inherit;
  text-transform: inherit;
  /* @noflip */
  padding-left: 20px;
  /* @noflip */
  padding-right: 0;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  z-index: 1;
}
[dir=rtl] .mdc-top-app-bar__title, .mdc-top-app-bar__title[dir=rtl] {
  /* @noflip */
  padding-left: 0;
  /* @noflip */
  padding-right: 20px;
}
.mdc-top-app-bar__action-item, .mdc-top-app-bar__navigation-icon {
  --mdc-ripple-fg-size: 0;
  --mdc-ripple-left: 0;
  --mdc-ripple-top: 0;
  --mdc-ripple-fg-scale: 1;
  --mdc-ripple-fg-translate-end: 0;
  --mdc-ripple-fg-translate-start: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  display: flex;
  position: relative;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  width: 48px;
  height: 48px;
  padding: 12px;
  border: none;
  outline: none;
  background-color: transparent;
  fill: currentColor;
  color: inherit;
  text-decoration: none;
  cursor: pointer;
}
.mdc-top-app-bar__action-item::before, .mdc-top-app-bar__action-item::after, .mdc-top-app-bar__navigation-icon::before, .mdc-top-app-bar__navigation-icon::after {
  position: absolute;
  border-radius: 50%;
  opacity: 0;
  pointer-events: none;
  content: "";
}
.mdc-top-app-bar__action-item::before, .mdc-top-app-bar__navigation-icon::before {
  transition: opacity 15ms linear, background-color 15ms linear;
  z-index: 1;
}
.mdc-top-app-bar__action-item.mdc-ripple-upgraded::before, .mdc-top-app-bar__navigation-icon.mdc-ripple-upgraded::before {
  transform: scale(var(--mdc-ripple-fg-scale, 1));
}
.mdc-top-app-bar__action-item.mdc-ripple-upgraded::after, .mdc-top-app-bar__navigation-icon.mdc-ripple-upgraded::after {
  top: 0;
  /* @noflip */
  left: 0;
  transform: scale(0);
  transform-origin: center center;
}
.mdc-top-app-bar__action-item.mdc-ripple-upgraded--unbounded::after, .mdc-top-app-bar__navigation-icon.mdc-ripple-upgraded--unbounded::after {
  top: var(--mdc-ripple-top, 0);
  /* @noflip */
  left: var(--mdc-ripple-left, 0);
}
.mdc-top-app-bar__action-item.mdc-ripple-upgraded--foreground-activation::after, .mdc-top-app-bar__navigation-icon.mdc-ripple-upgraded--foreground-activation::after {
  animation: mdc-ripple-fg-radius-in 225ms forwards, mdc-ripple-fg-opacity-in 75ms forwards;
}
.mdc-top-app-bar__action-item.mdc-ripple-upgraded--foreground-deactivation::after, .mdc-top-app-bar__navigation-icon.mdc-ripple-upgraded--foreground-deactivation::after {
  animation: mdc-ripple-fg-opacity-out 150ms;
  transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1));
}
.mdc-top-app-bar__action-item::before, .mdc-top-app-bar__action-item::after, .mdc-top-app-bar__navigation-icon::before, .mdc-top-app-bar__navigation-icon::after {
  top: calc(50% - 50%);
  /* @noflip */
  left: calc(50% - 50%);
  width: 100%;
  height: 100%;
}
.mdc-top-app-bar__action-item.mdc-ripple-upgraded::before, .mdc-top-app-bar__action-item.mdc-ripple-upgraded::after, .mdc-top-app-bar__navigation-icon.mdc-ripple-upgraded::before, .mdc-top-app-bar__navigation-icon.mdc-ripple-upgraded::after {
  top: var(--mdc-ripple-top, calc(50% - 50%));
  /* @noflip */
  left: var(--mdc-ripple-left, calc(50% - 50%));
  width: var(--mdc-ripple-fg-size, 100%);
  height: var(--mdc-ripple-fg-size, 100%);
}
.mdc-top-app-bar__action-item.mdc-ripple-upgraded::after, .mdc-top-app-bar__navigation-icon.mdc-ripple-upgraded::after {
  width: var(--mdc-ripple-fg-size, 100%);
  height: var(--mdc-ripple-fg-size, 100%);
}

.mdc-top-app-bar--short-collapsed {
  /* @noflip */
  border-radius: 0 0 24px 0;
}
[dir=rtl] .mdc-top-app-bar--short-collapsed, .mdc-top-app-bar--short-collapsed[dir=rtl] {
  /* @noflip */
  border-radius: 0 0 0 24px;
}

.mdc-top-app-bar--short {
  top: 0;
  /* @noflip */
  right: auto;
  /* @noflip */
  left: 0;
  width: 100%;
  transition: width 250ms cubic-bezier(0.4, 0, 0.2, 1);
}
[dir=rtl] .mdc-top-app-bar--short, .mdc-top-app-bar--short[dir=rtl] {
  /* @noflip */
  right: 0;
  /* @noflip */
  left: auto;
}
.mdc-top-app-bar--short .mdc-top-app-bar__row {
  height: 56px;
}
.mdc-top-app-bar--short .mdc-top-app-bar__section {
  padding: 4px;
}
.mdc-top-app-bar--short .mdc-top-app-bar__title {
  transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1);
  opacity: 1;
}

.mdc-top-app-bar--short-collapsed {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  width: 56px;
  transition: width 300ms cubic-bezier(0.4, 0, 0.2, 1);
}
.mdc-top-app-bar--short-collapsed .mdc-top-app-bar__title {
  display: none;
}
.mdc-top-app-bar--short-collapsed .mdc-top-app-bar__action-item {
  transition: padding 150ms cubic-bezier(0.4, 0, 0.2, 1);
}

.mdc-top-app-bar--short-collapsed.mdc-top-app-bar--short-has-action-item {
  width: 112px;
}
.mdc-top-app-bar--short-collapsed.mdc-top-app-bar--short-has-action-item .mdc-top-app-bar__section--align-end {
  /* @noflip */
  padding-left: 0;
  /* @noflip */
  padding-right: 12px;
}
[dir=rtl] .mdc-top-app-bar--short-collapsed.mdc-top-app-bar--short-has-action-item .mdc-top-app-bar__section--align-end, .mdc-top-app-bar--short-collapsed.mdc-top-app-bar--short-has-action-item .mdc-top-app-bar__section--align-end[dir=rtl] {
  /* @noflip */
  padding-left: 12px;
  /* @noflip */
  padding-right: 0;
}

.mdc-top-app-bar--dense .mdc-top-app-bar__row {
  height: 48px;
}
.mdc-top-app-bar--dense .mdc-top-app-bar__section {
  padding: 0 4px;
}
.mdc-top-app-bar--dense .mdc-top-app-bar__title {
  /* @noflip */
  padding-left: 12px;
  /* @noflip */
  padding-right: 0;
}
[dir=rtl] .mdc-top-app-bar--dense .mdc-top-app-bar__title, .mdc-top-app-bar--dense .mdc-top-app-bar__title[dir=rtl] {
  /* @noflip */
  padding-left: 0;
  /* @noflip */
  padding-right: 12px;
}

.mdc-top-app-bar--prominent .mdc-top-app-bar__row {
  height: 128px;
}
.mdc-top-app-bar--prominent .mdc-top-app-bar__title {
  align-self: flex-end;
  padding-bottom: 2px;
}
.mdc-top-app-bar--prominent .mdc-top-app-bar__action-item,
.mdc-top-app-bar--prominent .mdc-top-app-bar__navigation-icon {
  align-self: flex-start;
}

.mdc-top-app-bar--fixed {
  transition: box-shadow 200ms linear;
}

.mdc-top-app-bar--fixed-scrolled {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  transition: box-shadow 200ms linear;
}

.mdc-top-app-bar--dense.mdc-top-app-bar--prominent .mdc-top-app-bar__row {
  height: 96px;
}
.mdc-top-app-bar--dense.mdc-top-app-bar--prominent .mdc-top-app-bar__section {
  padding: 0 12px;
}
.mdc-top-app-bar--dense.mdc-top-app-bar--prominent .mdc-top-app-bar__title {
  /* @noflip */
  padding-left: 20px;
  /* @noflip */
  padding-right: 0;
  padding-bottom: 9px;
}
[dir=rtl] .mdc-top-app-bar--dense.mdc-top-app-bar--prominent .mdc-top-app-bar__title, .mdc-top-app-bar--dense.mdc-top-app-bar--prominent .mdc-top-app-bar__title[dir=rtl] {
  /* @noflip */
  padding-left: 0;
  /* @noflip */
  padding-right: 20px;
}

.mdc-top-app-bar--fixed-adjust {
  padding-top: 64px;
}

.mdc-top-app-bar--dense-fixed-adjust {
  padding-top: 48px;
}

.mdc-top-app-bar--short-fixed-adjust {
  padding-top: 56px;
}

.mdc-top-app-bar--prominent-fixed-adjust {
  padding-top: 128px;
}

.mdc-top-app-bar--dense-prominent-fixed-adjust {
  padding-top: 96px;
}

@media (max-width: 599px) {
  .mdc-top-app-bar__row {
    height: 56px;
  }

  .mdc-top-app-bar__section {
    padding: 4px;
  }

  .mdc-top-app-bar--short {
    transition: width 200ms cubic-bezier(0.4, 0, 0.2, 1);
  }

  .mdc-top-app-bar--short-collapsed {
    transition: width 250ms cubic-bezier(0.4, 0, 0.2, 1);
  }
  .mdc-top-app-bar--short-collapsed .mdc-top-app-bar__section--align-end {
    /* @noflip */
    padding-left: 0;
    /* @noflip */
    padding-right: 12px;
  }
  [dir=rtl] .mdc-top-app-bar--short-collapsed .mdc-top-app-bar__section--align-end, .mdc-top-app-bar--short-collapsed .mdc-top-app-bar__section--align-end[dir=rtl] {
    /* @noflip */
    padding-left: 12px;
    /* @noflip */
    padding-right: 0;
  }

  .mdc-top-app-bar--prominent .mdc-top-app-bar__title {
    padding-bottom: 6px;
  }

  .mdc-top-app-bar--fixed-adjust {
    padding-top: 56px;
  }
}
.mdc-typography {
  font-family: Roboto, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

.mdc-typography--headline1 {
  font-family: Roboto, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 6rem;
  line-height: 6rem;
  font-weight: 300;
  letter-spacing: -0.015625em;
  text-decoration: inherit;
  text-transform: inherit;
}

.mdc-typography--headline2 {
  font-family: Roboto, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 3.75rem;
  line-height: 3.75rem;
  font-weight: 300;
  letter-spacing: -0.0083333333em;
  text-decoration: inherit;
  text-transform: inherit;
}

.mdc-typography--headline3 {
  font-family: Roboto, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 3rem;
  line-height: 3.125rem;
  font-weight: 400;
  letter-spacing: normal;
  text-decoration: inherit;
  text-transform: inherit;
}

.mdc-typography--headline4 {
  font-family: Roboto, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 2.125rem;
  line-height: 2.5rem;
  font-weight: 400;
  letter-spacing: 0.0073529412em;
  text-decoration: inherit;
  text-transform: inherit;
}

.mdc-typography--headline5 {
  font-family: Roboto, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 1.5rem;
  line-height: 2rem;
  font-weight: 400;
  letter-spacing: normal;
  text-decoration: inherit;
  text-transform: inherit;
}

.mdc-typography--headline6 {
  font-family: Roboto, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 1.25rem;
  line-height: 2rem;
  font-weight: 500;
  letter-spacing: 0.0125em;
  text-decoration: inherit;
  text-transform: inherit;
}

.mdc-typography--subtitle1 {
  font-family: Roboto, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 1rem;
  line-height: 1.75rem;
  font-weight: 400;
  letter-spacing: 0.009375em;
  text-decoration: inherit;
  text-transform: inherit;
}

.mdc-typography--subtitle2 {
  font-family: Roboto, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 0.875rem;
  line-height: 1.375rem;
  font-weight: 500;
  letter-spacing: 0.0071428571em;
  text-decoration: inherit;
  text-transform: inherit;
}

.mdc-typography--body1 {
  font-family: Roboto, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 400;
  letter-spacing: 0.03125em;
  text-decoration: inherit;
  text-transform: inherit;
}

.mdc-typography--body2 {
  font-family: Roboto, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 400;
  letter-spacing: 0.0178571429em;
  text-decoration: inherit;
  text-transform: inherit;
}

.mdc-typography--caption {
  font-family: Roboto, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 0.75rem;
  line-height: 1.25rem;
  font-weight: 400;
  letter-spacing: 0.0333333333em;
  text-decoration: inherit;
  text-transform: inherit;
}

.mdc-typography--button {
  font-family: Roboto, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 0.875rem;
  line-height: 2.25rem;
  font-weight: 500;
  letter-spacing: 0.0892857143em;
  text-decoration: none;
  text-transform: uppercase;
}

.mdc-typography--overline {
  font-family: Roboto, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 0.75rem;
  line-height: 2rem;
  font-weight: 500;
  letter-spacing: 0.1666666667em;
  text-decoration: none;
  text-transform: uppercase;
}

.ngx-mdc-form-field--fluid {
  display: flex;
  flex: auto;
  flex-direction: column;
}

.ngx-mdc-button--primary:not(:disabled) {
  background-color: #808080;
}
.ngx-mdc-button--primary:not(:disabled) {
  color: white;
  /* @alternate */
  color: var(--mdc-theme-text-primary-on-dark, white);
}
.ngx-mdc-button--primary::before, .ngx-mdc-button--primary::after {
  background-color: white;
}
@supports not (-ms-ime-align: auto) {
  .ngx-mdc-button--primary::before, .ngx-mdc-button--primary::after {
    /* @alternate */
    background-color: var(--mdc-theme-text-primary-on-dark, white);
  }
}
.ngx-mdc-button--primary:hover::before {
  opacity: 0.08;
}
.ngx-mdc-button--primary:not(.mdc-ripple-upgraded):focus::before, .ngx-mdc-button--primary.mdc-ripple-upgraded--background-focused::before {
  transition-duration: 75ms;
  opacity: 0.24;
}
.ngx-mdc-button--primary:not(.mdc-ripple-upgraded)::after {
  transition: opacity 150ms linear;
}
.ngx-mdc-button--primary:not(.mdc-ripple-upgraded):active::after {
  transition-duration: 75ms;
  opacity: 0.24;
}
.ngx-mdc-button--primary.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: 0.24;
}

.ngx-mdc-button--secondary:not(:disabled) {
  background-color: #1A73E8;
}
.ngx-mdc-button--secondary:not(:disabled) {
  color: white;
  /* @alternate */
  color: var(--mdc-theme-text-primary-on-dark, white);
}
.ngx-mdc-button--secondary::before, .ngx-mdc-button--secondary::after {
  background-color: white;
}
@supports not (-ms-ime-align: auto) {
  .ngx-mdc-button--secondary::before, .ngx-mdc-button--secondary::after {
    /* @alternate */
    background-color: var(--mdc-theme-text-primary-on-dark, white);
  }
}
.ngx-mdc-button--secondary:hover::before {
  opacity: 0.08;
}
.ngx-mdc-button--secondary:not(.mdc-ripple-upgraded):focus::before, .ngx-mdc-button--secondary.mdc-ripple-upgraded--background-focused::before {
  transition-duration: 75ms;
  opacity: 0.24;
}
.ngx-mdc-button--secondary:not(.mdc-ripple-upgraded)::after {
  transition: opacity 150ms linear;
}
.ngx-mdc-button--secondary:not(.mdc-ripple-upgraded):active::after {
  transition-duration: 75ms;
  opacity: 0.24;
}
.ngx-mdc-button--secondary.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: 0.24;
}

.ngx-mdc-chip--primary {
  background-color: #808080;
  color: white;
  /* @alternate */
  color: var(--mdc-theme-text-primary-on-dark, white);
}
.ngx-mdc-chip--primary::before, .ngx-mdc-chip--primary::after {
  background-color: white;
}
@supports not (-ms-ime-align: auto) {
  .ngx-mdc-chip--primary::before, .ngx-mdc-chip--primary::after {
    /* @alternate */
    background-color: var(--mdc-theme-text-primary-on-dark, white);
  }
}
.ngx-mdc-chip--primary:hover::before {
  opacity: 0.08;
}
.ngx-mdc-chip--primary:not(.mdc-ripple-upgraded):focus::before, .ngx-mdc-chip--primary.mdc-ripple-upgraded--background-focused::before {
  transition-duration: 75ms;
  opacity: 0.24;
}
.ngx-mdc-chip--primary:not(.mdc-ripple-upgraded)::after {
  transition: opacity 150ms linear;
}
.ngx-mdc-chip--primary:not(.mdc-ripple-upgraded):active::after {
  transition-duration: 75ms;
  opacity: 0.24;
}
.ngx-mdc-chip--primary.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: 0.24;
}
.ngx-mdc-chip--primary:hover {
  color: white;
  /* @alternate */
  color: var(--mdc-theme-text-primary-on-dark, white);
}
.ngx-mdc-chip--primary.mdc-chip--selected::before {
  opacity: 0.16;
}
.ngx-mdc-chip--primary.mdc-chip--selected::before, .ngx-mdc-chip--primary.mdc-chip--selected::after {
  background-color: white;
}
@supports not (-ms-ime-align: auto) {
  .ngx-mdc-chip--primary.mdc-chip--selected::before, .ngx-mdc-chip--primary.mdc-chip--selected::after {
    /* @alternate */
    background-color: var(--mdc-theme-text-primary-on-dark, white);
  }
}
.ngx-mdc-chip--primary.mdc-chip--selected:hover::before {
  opacity: 0.24;
}
.ngx-mdc-chip--primary.mdc-chip--selected:not(.mdc-ripple-upgraded):focus::before, .ngx-mdc-chip--primary.mdc-chip--selected.mdc-ripple-upgraded--background-focused::before {
  transition-duration: 75ms;
  opacity: 0.4;
}
.ngx-mdc-chip--primary.mdc-chip--selected:not(.mdc-ripple-upgraded)::after {
  transition: opacity 150ms linear;
}
.ngx-mdc-chip--primary.mdc-chip--selected:not(.mdc-ripple-upgraded):active::after {
  transition-duration: 75ms;
  opacity: 0.4;
}
.ngx-mdc-chip--primary.mdc-chip--selected.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: 0.4;
}
.ngx-mdc-chip--primary.mdc-chip--selected {
  color: white;
  /* @alternate */
  color: var(--mdc-theme-text-primary-on-dark, white);
}
.ngx-mdc-chip--primary.mdc-chip--selected .mdc-chip__icon--leading {
  color: rgba(255, 255, 255, 0.54);
}
.ngx-mdc-chip--primary.mdc-chip--selected:hover {
  color: white;
  /* @alternate */
  color: var(--mdc-theme-text-primary-on-dark, white);
}
.ngx-mdc-chip--primary .mdc-chip__checkmark-path {
  stroke: white;
  /* @alternate */
  stroke: var(--mdc-theme-text-primary-on-dark, white);
}
.ngx-mdc-chip--primary .mdc-chip__icon--leading {
  color: rgba(255, 255, 255, 0.54);
}
.ngx-mdc-chip--primary .mdc-chip__icon--trailing {
  color: rgba(255, 255, 255, 0.54);
}
.ngx-mdc-chip--primary .mdc-chip__icon--trailing:hover {
  color: rgba(255, 255, 255, 0.62);
}
.ngx-mdc-chip--primary .mdc-chip__icon--trailing:focus {
  color: rgba(255, 255, 255, 0.87);
}

.ngx-mdc-chip--secondary {
  background-color: #1A73E8;
  color: white;
  /* @alternate */
  color: var(--mdc-theme-text-primary-on-dark, white);
}
.ngx-mdc-chip--secondary::before, .ngx-mdc-chip--secondary::after {
  background-color: white;
}
@supports not (-ms-ime-align: auto) {
  .ngx-mdc-chip--secondary::before, .ngx-mdc-chip--secondary::after {
    /* @alternate */
    background-color: var(--mdc-theme-text-primary-on-dark, white);
  }
}
.ngx-mdc-chip--secondary:hover::before {
  opacity: 0.08;
}
.ngx-mdc-chip--secondary:not(.mdc-ripple-upgraded):focus::before, .ngx-mdc-chip--secondary.mdc-ripple-upgraded--background-focused::before {
  transition-duration: 75ms;
  opacity: 0.24;
}
.ngx-mdc-chip--secondary:not(.mdc-ripple-upgraded)::after {
  transition: opacity 150ms linear;
}
.ngx-mdc-chip--secondary:not(.mdc-ripple-upgraded):active::after {
  transition-duration: 75ms;
  opacity: 0.24;
}
.ngx-mdc-chip--secondary.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: 0.24;
}
.ngx-mdc-chip--secondary:hover {
  color: white;
  /* @alternate */
  color: var(--mdc-theme-text-primary-on-dark, white);
}
.ngx-mdc-chip--secondary.mdc-chip--selected::before {
  opacity: 0.16;
}
.ngx-mdc-chip--secondary.mdc-chip--selected::before, .ngx-mdc-chip--secondary.mdc-chip--selected::after {
  background-color: white;
}
@supports not (-ms-ime-align: auto) {
  .ngx-mdc-chip--secondary.mdc-chip--selected::before, .ngx-mdc-chip--secondary.mdc-chip--selected::after {
    /* @alternate */
    background-color: var(--mdc-theme-text-primary-on-dark, white);
  }
}
.ngx-mdc-chip--secondary.mdc-chip--selected:hover::before {
  opacity: 0.24;
}
.ngx-mdc-chip--secondary.mdc-chip--selected:not(.mdc-ripple-upgraded):focus::before, .ngx-mdc-chip--secondary.mdc-chip--selected.mdc-ripple-upgraded--background-focused::before {
  transition-duration: 75ms;
  opacity: 0.4;
}
.ngx-mdc-chip--secondary.mdc-chip--selected:not(.mdc-ripple-upgraded)::after {
  transition: opacity 150ms linear;
}
.ngx-mdc-chip--secondary.mdc-chip--selected:not(.mdc-ripple-upgraded):active::after {
  transition-duration: 75ms;
  opacity: 0.4;
}
.ngx-mdc-chip--secondary.mdc-chip--selected.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: 0.4;
}
.ngx-mdc-chip--secondary.mdc-chip--selected {
  color: white;
  /* @alternate */
  color: var(--mdc-theme-text-primary-on-dark, white);
}
.ngx-mdc-chip--secondary.mdc-chip--selected .mdc-chip__icon--leading {
  color: rgba(255, 255, 255, 0.54);
}
.ngx-mdc-chip--secondary.mdc-chip--selected:hover {
  color: white;
  /* @alternate */
  color: var(--mdc-theme-text-primary-on-dark, white);
}
.ngx-mdc-chip--secondary .mdc-chip__checkmark-path {
  stroke: white;
  /* @alternate */
  stroke: var(--mdc-theme-text-primary-on-dark, white);
}
.ngx-mdc-chip--secondary .mdc-chip__icon--leading {
  color: rgba(255, 255, 255, 0.54);
}
.ngx-mdc-chip--secondary .mdc-chip__icon--trailing {
  color: rgba(255, 255, 255, 0.54);
}
.ngx-mdc-chip--secondary .mdc-chip__icon--trailing:hover {
  color: rgba(255, 255, 255, 0.62);
}
.ngx-mdc-chip--secondary .mdc-chip__icon--trailing:focus {
  color: rgba(255, 255, 255, 0.87);
}

.ngx-mdc-fab-extended--fluid {
  width: 100%;
}

.ngx-mdc-fab--bottom-right {
  position: fixed;
  right: 1rem;
  bottom: 1rem;
}

@media (min-width: 1024px) {
  .ngx-mdc-fab--bottom-right {
    right: 1.5rem;
    bottom: 1.5rem;
  }
}
.ngx-mdc-fab--bottom-left {
  position: fixed;
  bottom: 1rem;
  left: 1rem;
}

@media (min-width: 1024px) {
  .ngx-mdc-fab--bottom-left {
    bottom: 1.5rem;
    left: 1.5rem;
  }
}
.ngx-mdc-icon--clickable,
a.ngx-mdc-icon {
  outline: none;
  text-decoration: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.ngx-mdc-icon--inline {
  font-size: inherit;
  height: inherit;
  line-height: inherit;
  width: inherit;
}

.mdc-image-list__item {
  display: block;
}

.mdc-linear-progress {
  display: block;
}

.mdc-linear-progress--secondary .mdc-linear-progress__bar-inner {
  background-color: #1A73E8;
}

.mdc-list,
.mdc-list-divider {
  display: block;
}

.ngx-mdc-list--border {
  /* Ensure adjacent borders don't collide with one another. */
}
.ngx-mdc-list--border .mdc-list-item {
  /* Add the list side padding padding to the list item. */
  padding: 0 16px;
  /* Add a border around each element. */
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.ngx-mdc-list--border .mdc-list-item:not(:first-child) {
  border-top: none;
}

.mdc-menu-surface--anchor {
  display: inline-block;
}

.mdc-select__native-control {
  -webkit-appearance: none;
}

.mdc-slider {
  display: inline-block;
}

@media (min-width: 480px) {
  .ngx-mdc-snackbar--trailing {
    justify-content: flex-end;
  }
}

.ngx-mdc-tab--disabled {
  cursor: default;
  pointer-events: none;
}
.ngx-mdc-tab--disabled .mdc-tab__text-label {
  color: rgba(0, 0, 0, 0.3);
}
.ngx-mdc-tab--disabled .mdc-tab__icon {
  color: rgba(0, 0, 0, 0.3);
  fill: currentColor;
}
.ngx-mdc-tab--disabled .mdc-tab-indicator .mdc-tab-indicator__content--underline {
  border-color: rgba(0, 0, 0, 0.3);
}

.mdc-tab-bar {
  display: block;
}

.mdc-tab-scroller {
  display: block;
}

.demo-layout__row {
  display: flex;
  flex-flow: row wrap;
  margin-top: 0.8rem;
}

.demo-layout__row--no-wrap {
  display: flex;
  flex-direction: row;
}

.demo-layout__column {
  display: flex;
  flex-direction: column;
}

.demo-layout--start {
  align-self: flex-start;
}

.demo-layout--end {
  flex-grow: 1;
  align-self: flex-end;
}

.demo-layout--center {
  display: flex;
  align-content: flex-end;
  align-items: center;
  justify-content: center;
}

.demo-panel {
  display: flex;
  position: relative;
  height: 93vh;
  overflow: hidden;
}

.demo-panel-section {
  width: 100%;
  max-width: 100%;
}

.demo-panel-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
  max-width: 100%;
  width: 100%;
  overflow: auto;
}
@media (max-width: 900px) {
  .demo-panel-content {
    width: unset;
    padding: 12px;
  }
}

.demo-panel-transition {
  width: 100%;
  max-width: 900px;
  padding-bottom: 60px;
}

.demo-panel-title {
  font-family: Roboto, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 2.125rem;
  line-height: 2.5rem;
  font-weight: 400;
  letter-spacing: 0.0073529412em;
  text-decoration: inherit;
  text-transform: inherit;
  margin: 16px 16px 16px 0;
  line-height: 1.25;
  padding-bottom: 0.3em;
  border-bottom: 1px solid #eaecef;
  font-weight: 500;
}

.demo-panel-references {
  font-family: Roboto, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 1.25rem;
  line-height: 2rem;
  font-weight: 500;
  letter-spacing: 0.0125em;
  text-decoration: inherit;
  text-transform: inherit;
  margin: 24px 16px 16px 0;
  line-height: 1.25;
  padding-bottom: 0.3em;
  border-bottom: 1px solid #eaecef;
}

.demo-container {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
  min-width: 200px;
  padding: 10px;
}

.hljs {
  border-radius: 0.45rem;
  padding: 9px;
}

.demo-content {
  border-radius: 12px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  margin: 1.5rem 1rem;
  padding: 1rem;
}
.demo-content__headline {
  font-family: Roboto, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 1.25rem;
  line-height: 2rem;
  font-weight: 500;
  letter-spacing: 0.0125em;
  text-decoration: inherit;
  text-transform: inherit;
  margin-top: 0.1em;
  margin-bottom: 0.3em;
}

.demo-top-app-bar {
  background-color: #363640;
  z-index: 7;
}

.demo-drawer {
  position: fixed;
}
.demo-drawer .mdc-list-item {
  cursor: pointer;
}

.big-round-shape-button {
  border-radius: 8px;
}
.big-round-shape-button.mdc-button--dense {
  border-radius: 8px;
}

.thick-outline-button {
  padding: 0 12px 0 12px;
  border-width: 4px;
}

.demo-button-ink-color:not(:disabled) {
  background-color: #9c27b0;
}
.demo-button-ink-color:not(:disabled) .mdc-button__icon {
  color: white;
}
.demo-button-ink-color:not(:disabled) {
  color: orange;
}
.demo-button-ink-color::before, .demo-button-ink-color::after {
  background-color: orange;
}
.demo-button-ink-color:hover::before {
  opacity: 0.08;
}
.demo-button-ink-color:not(.mdc-ripple-upgraded):focus::before, .demo-button-ink-color.mdc-ripple-upgraded--background-focused::before {
  transition-duration: 75ms;
  opacity: 0.24;
}
.demo-button-ink-color:not(.mdc-ripple-upgraded)::after {
  transition: opacity 150ms linear;
}
.demo-button-ink-color:not(.mdc-ripple-upgraded):active::after {
  transition-duration: 75ms;
  opacity: 0.24;
}
.demo-button-ink-color.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: 0.24;
}

.demo-button-icon-color:not(:disabled) .mdc-button__icon {
  color: orange;
}

.demo-card {
  width: 350px;
  margin: 28px;
}

.demo-card__primary {
  padding: 1rem;
}

.demo-card__title {
  margin: 0;
}

.demo-card__subtitle {
  color: rgba(0, 0, 0, 0.54);
  /* @alternate */
  color: var(--mdc-theme-text-secondary-on-background, rgba(0, 0, 0, 0.54));
  margin: 0;
}

.demo-card__secondary {
  color: rgba(0, 0, 0, 0.54);
  /* @alternate */
  color: var(--mdc-theme-text-secondary-on-background, rgba(0, 0, 0, 0.54));
  padding: 0 1rem 8px 1rem;
}

.demo-card-article {
  padding: 16px;
  text-decoration: none;
  color: inherit;
}

.demo-card-article-group-heading {
  color: rgba(0, 0, 0, 0.54);
  /* @alternate */
  color: var(--mdc-theme-text-secondary-on-light, rgba(0, 0, 0, 0.54));
  padding: 8px 16px;
}

.demo-card-article__title {
  margin: 0 0 4px 0;
}

.demo-card-article__snippet {
  color: rgba(0, 0, 0, 0.54);
  /* @alternate */
  color: var(--mdc-theme-text-secondary-on-light, rgba(0, 0, 0, 0.54));
  margin: 0;
}

.demo-card--photo {
  width: 200px;
}

.demo-card__media {
  background-image: url(https://material-components-web.appspot.com/images/1-1.jpg);
}

.demo-card__media--16-9 {
  background-image: url(https://material-components-web.appspot.com/images/16-9.jpg);
}

.demo-card__media-content--with-title {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.demo-card__media-title {
  padding: 8px 16px;
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 100%);
  color: white;
}

.demo-card--music {
  border-radius: 24px 4px;
}
[dir=rtl] .demo-card--music, .demo-card--music[dir=rtl] {
  border-radius: 4px 24px;
}

.demo-card__music-row {
  display: flex;
  border-top-left-radius: inherit;
}
[dir=rtl] .demo-card__music-row, .demo-card__music-row[dir=rtl] {
  border-top-left-radius: 0;
  border-top-right-radius: inherit;
}

.demo-card__media--music {
  width: 110px;
  border-top-left-radius: inherit;
}
[dir=rtl] .demo-card__media--music, .demo-card__media--music[dir=rtl] {
  border-top-left-radius: 0;
  border-top-right-radius: inherit;
}

.demo-card__music-info {
  display: flex;
  flex-direction: column;
  padding: 8px 16px;
}

.demo-card__action-buttons--text-only {
  margin-left: 8px;
}

.demo-checkbox--custom-all .mdc-checkbox__background::before {
  background-color: #f44336;
}
.demo-checkbox--custom-all::before, .demo-checkbox--custom-all::after {
  background-color: #f44336;
}
.demo-checkbox--custom-all:hover::before {
  opacity: 0.04;
}
.demo-checkbox--custom-all:not(.mdc-ripple-upgraded):focus::before, .demo-checkbox--custom-all.mdc-ripple-upgraded--background-focused::before {
  transition-duration: 75ms;
  opacity: 0.12;
}
.demo-checkbox--custom-all:not(.mdc-ripple-upgraded)::after {
  transition: opacity 150ms linear;
}
.demo-checkbox--custom-all:not(.mdc-ripple-upgraded):active::after {
  transition-duration: 75ms;
  opacity: 0.12;
}
.demo-checkbox--custom-all.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: 0.12;
}
.demo-checkbox--custom-all .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate) ~ .mdc-checkbox__background {
  border-color: #f44336;
  background-color: rgba(244, 67, 54, 0.25);
}
.demo-checkbox--custom-all .mdc-checkbox__native-control:enabled:checked ~ .mdc-checkbox__background,
.demo-checkbox--custom-all .mdc-checkbox__native-control:enabled:indeterminate ~ .mdc-checkbox__background {
  border-color: #1A73E8;
  /* @alternate */
  border-color: var(--mdc-theme-secondary, #1A73E8);
  background-color: #f44336;
}

.demo-checkbox--custom-stroke-and-fill .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate) ~ .mdc-checkbox__background {
  border-color: #2196f3;
  background-color: transparent;
}
.demo-checkbox--custom-stroke-and-fill .mdc-checkbox__native-control:enabled:checked ~ .mdc-checkbox__background,
.demo-checkbox--custom-stroke-and-fill .mdc-checkbox__native-control:enabled:indeterminate ~ .mdc-checkbox__background {
  border-color: #1A73E8;
  /* @alternate */
  border-color: var(--mdc-theme-secondary, #1A73E8);
  background-color: #9c27b0;
}

.custom-chip-secondary {
  background-color: white;
  color: #1A73E8;
  padding: 5px 10px;
  border-width: 2px;
  border-style: solid;
  border-color: #1A73E8;
}
.custom-chip-secondary::before, .custom-chip-secondary::after {
  background-color: #1A73E8;
}
.custom-chip-secondary:hover::before {
  opacity: 0.04;
}
.custom-chip-secondary:not(.mdc-ripple-upgraded):focus::before, .custom-chip-secondary.mdc-ripple-upgraded--background-focused::before {
  transition-duration: 75ms;
  opacity: 0.12;
}
.custom-chip-secondary:not(.mdc-ripple-upgraded)::after {
  transition: opacity 150ms linear;
}
.custom-chip-secondary:not(.mdc-ripple-upgraded):active::after {
  transition-duration: 75ms;
  opacity: 0.12;
}
.custom-chip-secondary.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: 0.12;
}
.custom-chip-secondary:hover {
  color: #1A73E8;
}
.custom-chip-secondary.mdc-chip--selected::before {
  opacity: 0.08;
}
.custom-chip-secondary.mdc-chip--selected::before, .custom-chip-secondary.mdc-chip--selected::after {
  background-color: #1A73E8;
}
.custom-chip-secondary.mdc-chip--selected:hover::before {
  opacity: 0.12;
}
.custom-chip-secondary.mdc-chip--selected:not(.mdc-ripple-upgraded):focus::before, .custom-chip-secondary.mdc-chip--selected.mdc-ripple-upgraded--background-focused::before {
  transition-duration: 75ms;
  opacity: 0.2;
}
.custom-chip-secondary.mdc-chip--selected:not(.mdc-ripple-upgraded)::after {
  transition: opacity 150ms linear;
}
.custom-chip-secondary.mdc-chip--selected:not(.mdc-ripple-upgraded):active::after {
  transition-duration: 75ms;
  opacity: 0.2;
}
.custom-chip-secondary.mdc-chip--selected.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: 0.2;
}
.custom-chip-secondary.mdc-chip--selected {
  color: #1A73E8;
}
.custom-chip-secondary.mdc-chip--selected .mdc-chip__icon--leading {
  color: rgba(26, 115, 232, 0.54);
}
.custom-chip-secondary.mdc-chip--selected:hover {
  color: #1A73E8;
}
.custom-chip-secondary .mdc-chip__checkmark-path {
  stroke: #1A73E8;
}

.custom-chip--shape-radius {
  border-radius: 4px;
}

.custom-chip--height {
  height: 40px;
}

.custom-chip--leading-icon-color .mdc-chip__icon--leading {
  color: rgba(244, 67, 54, 0.5);
}

.custom-chip--trailing-icon-color .mdc-chip__icon--trailing {
  color: rgba(244, 67, 54, 0.5);
}
.custom-chip--trailing-icon-color .mdc-chip__icon--trailing:hover {
  color: rgba(244, 67, 54, 0.62);
}
.custom-chip--trailing-icon-color .mdc-chip__icon--trailing:focus {
  color: rgba(244, 67, 54, 0.87);
}

.custom-chip--leading-icon-size .mdc-chip__icon.mdc-chip__icon--leading:not(.mdc-chip__icon--leading-hidden) {
  width: 25px;
  height: 25px;
  font-size: 25px;
}

.custom-chip--trailing-icon-size .mdc-chip__icon.mdc-chip__icon--trailing {
  width: 25px;
  height: 25px;
  font-size: 25px;
}

.custom-chip--leading-icon-margin.mdc-chip--selected .mdc-chip__checkmark,
.custom-chip--leading-icon-margin .mdc-chip__icon--leading:not(.mdc-chip__icon--leading-hidden) {
  /* @noflip */
  margin-left: 5px;
  /* @noflip */
  margin-right: 5px;
  margin-top: 5px;
  margin-bottom: 5px;
}
[dir=rtl] .custom-chip--leading-icon-margin.mdc-chip--selected .mdc-chip__checkmark, .custom-chip--leading-icon-margin.mdc-chip--selected .mdc-chip__checkmark[dir=rtl],
[dir=rtl] .custom-chip--leading-icon-margin .mdc-chip__icon--leading:not(.mdc-chip__icon--leading-hidden),
.custom-chip--leading-icon-margin .mdc-chip__icon--leading:not(.mdc-chip__icon--leading-hidden)[dir=rtl] {
  /* @noflip */
  margin-left: 5px;
  /* @noflip */
  margin-right: 5px;
}

.custom-chip--trailing-icon-margin .mdc-chip__icon--trailing {
  margin: 5px 5px 5px 5px;
}

.custom-chip--horizontal-padding {
  padding: 7px 25px;
}

.markdown-code {
  background-color: rgba(27, 31, 35, 0.05);
  border-radius: 3px;
  font-size: 105%;
  font-family: monospace;
  margin: 0;
  padding: 0.2em 0.4em;
}

.markdown-code:not(:first-child) {
  margin-left: 10px;
}

.docs-api {
  margin-bottom: 30px;
}
.docs-api h4 {
  font-size: 1rem;
}
.docs-api table {
  border: 1px solid #eee;
  border-collapse: collapse;
  border-spacing: 0;
}
.docs-api th {
  font-family: Roboto, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 0.875rem;
  line-height: 1.375rem;
  font-weight: 500;
  letter-spacing: 0.0071428571em;
  text-decoration: inherit;
  text-transform: inherit;
}
.docs-api tr:nth-child(2n) {
  background-color: #f6f8fa;
}
.docs-api td, .docs-api th {
  border: 1px solid #dfe2ef;
  padding: 12px;
}
.docs-api td {
  font-size: 0.9em;
}

.demo-drawer--custom {
  border-color: rgba(173, 216, 230, 0.12);
  background-color: black;
}
.demo-drawer--custom .mdc-list-group__subheader {
  color: rgba(85, 107, 47, 0.6);
}
.demo-drawer--custom .mdc-drawer__subtitle {
  color: rgba(85, 107, 47, 0.6);
}
.demo-drawer--custom .mdc-list-item__graphic {
  color: rgba(85, 107, 47, 0.6);
}
.demo-drawer--custom .mdc-drawer__title {
  color: rgba(85, 107, 47, 0.87);
}
.demo-drawer--custom :not(.mdc-list--non-interactive) > .mdc-list-item--activated::before {
  opacity: 0.12;
}
.demo-drawer--custom :not(.mdc-list--non-interactive) > .mdc-list-item--activated::before, .demo-drawer--custom :not(.mdc-list--non-interactive) > .mdc-list-item--activated::after {
  background-color: darkcyan;
}
.demo-drawer--custom :not(.mdc-list--non-interactive) > .mdc-list-item--activated:hover::before {
  opacity: 0.16;
}
.demo-drawer--custom :not(.mdc-list--non-interactive) > .mdc-list-item--activated:not(.mdc-ripple-upgraded):focus::before, .demo-drawer--custom :not(.mdc-list--non-interactive) > .mdc-list-item--activated.mdc-ripple-upgraded--background-focused::before {
  transition-duration: 75ms;
  opacity: 0.24;
}
.demo-drawer--custom :not(.mdc-list--non-interactive) > .mdc-list-item--activated:not(.mdc-ripple-upgraded)::after {
  transition: opacity 150ms linear;
}
.demo-drawer--custom :not(.mdc-list--non-interactive) > .mdc-list-item--activated:not(.mdc-ripple-upgraded):active::after {
  transition-duration: 75ms;
  opacity: 0.24;
}
.demo-drawer--custom :not(.mdc-list--non-interactive) > .mdc-list-item--activated.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: 0.24;
}
.demo-drawer--custom .mdc-list-item {
  color: rgba(85, 107, 47, 0.87);
}
.demo-drawer--custom .mdc-list-item--activated {
  color: rgba(0, 139, 139, 0.87);
}
.demo-drawer--custom .mdc-list-item--activated .mdc-list-item__graphic {
  color: darkcyan;
}

.demo-drawer--accessible {
  background-color: darkcyan;
}
.demo-drawer--accessible .mdc-drawer__title {
  color: rgba(255, 255, 255, 0.87);
}
.demo-drawer--accessible .mdc-list-group__subheader {
  color: rgba(255, 255, 255, 0.6);
}
.demo-drawer--accessible .mdc-drawer__subtitle {
  color: rgba(255, 255, 255, 0.6);
}
.demo-drawer--accessible .mdc-list-item {
  color: rgba(255, 255, 255, 0.87);
}
.demo-drawer--accessible .mdc-list-item__graphic {
  color: rgba(255, 255, 255, 0.6);
}
.demo-drawer--accessible :not(.mdc-list--non-interactive) > .mdc-list-item--activated::before {
  opacity: 0.24;
}
.demo-drawer--accessible :not(.mdc-list--non-interactive) > .mdc-list-item--activated::before, .demo-drawer--accessible :not(.mdc-list--non-interactive) > .mdc-list-item--activated::after {
  background-color: pink;
}
.demo-drawer--accessible :not(.mdc-list--non-interactive) > .mdc-list-item--activated:hover::before {
  opacity: 0.32;
}
.demo-drawer--accessible :not(.mdc-list--non-interactive) > .mdc-list-item--activated:not(.mdc-ripple-upgraded):focus::before, .demo-drawer--accessible :not(.mdc-list--non-interactive) > .mdc-list-item--activated.mdc-ripple-upgraded--background-focused::before {
  transition-duration: 75ms;
  opacity: 0.48;
}
.demo-drawer--accessible :not(.mdc-list--non-interactive) > .mdc-list-item--activated:not(.mdc-ripple-upgraded)::after {
  transition: opacity 150ms linear;
}
.demo-drawer--accessible :not(.mdc-list--non-interactive) > .mdc-list-item--activated:not(.mdc-ripple-upgraded):active::after {
  transition-duration: 75ms;
  opacity: 0.48;
}
.demo-drawer--accessible :not(.mdc-list--non-interactive) > .mdc-list-item--activated.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: 0.48;
}
.demo-drawer--accessible .mdc-list-item--activated {
  color: rgba(255, 192, 203, 0.87);
}
.demo-drawer--accessible .mdc-list-item--activated .mdc-list-item__graphic {
  color: pink;
}

.demo-drawer-app {
  border: 1px solid rgba(0, 0, 0, 0.15);
  min-height: 16.7rem;
  overflow: hidden;
  position: relative;
  z-index: 0;
}
.demo-drawer-app .mdc-drawer-scrim, .demo-drawer-app .mdc-drawer--modal {
  position: absolute;
}

.demo-elevation__surface {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 200px;
  height: 100px;
  border-radius: 3px;
}

.mdc-fab.red800Fab {
  background-color: #c62828;
  color: white;
  /* @alternate */
  color: var(--mdc-theme-text-primary-on-dark, white);
}
.mdc-fab.red800Fab::before, .mdc-fab.red800Fab::after {
  background-color: white;
}
@supports not (-ms-ime-align: auto) {
  .mdc-fab.red800Fab::before, .mdc-fab.red800Fab::after {
    /* @alternate */
    background-color: var(--mdc-theme-text-primary-on-dark, white);
  }
}
.mdc-fab.red800Fab:hover::before {
  opacity: 0.08;
}
.mdc-fab.red800Fab:not(.mdc-ripple-upgraded):focus::before, .mdc-fab.red800Fab.mdc-ripple-upgraded--background-focused::before {
  transition-duration: 75ms;
  opacity: 0.24;
}
.mdc-fab.red800Fab:not(.mdc-ripple-upgraded)::after {
  transition: opacity 150ms linear;
}
.mdc-fab.red800Fab:not(.mdc-ripple-upgraded):active::after {
  transition-duration: 75ms;
  opacity: 0.24;
}
.mdc-fab.red800Fab.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: 0.24;
}

.mdc-fab.yellow800Fab {
  background-color: #f9a825;
  color: rgba(0, 0, 0, 0.87);
  /* @alternate */
  color: var(--mdc-theme-text-primary-on-light, rgba(0, 0, 0, 0.87));
}
.mdc-fab.yellow800Fab::before, .mdc-fab.yellow800Fab::after {
  background-color: rgba(0, 0, 0, 0.87);
}
@supports not (-ms-ime-align: auto) {
  .mdc-fab.yellow800Fab::before, .mdc-fab.yellow800Fab::after {
    /* @alternate */
    background-color: var(--mdc-theme-text-primary-on-light, rgba(0, 0, 0, 0.87));
  }
}
.mdc-fab.yellow800Fab:hover::before {
  opacity: 0.04;
}
.mdc-fab.yellow800Fab:not(.mdc-ripple-upgraded):focus::before, .mdc-fab.yellow800Fab.mdc-ripple-upgraded--background-focused::before {
  transition-duration: 75ms;
  opacity: 0.12;
}
.mdc-fab.yellow800Fab:not(.mdc-ripple-upgraded)::after {
  transition: opacity 150ms linear;
}
.mdc-fab.yellow800Fab:not(.mdc-ripple-upgraded):active::after {
  transition-duration: 75ms;
  opacity: 0.12;
}
.mdc-fab.yellow800Fab.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: 0.12;
}

.mdc-fab.purple500Fab {
  background-color: #9c27b0;
  color: white;
  /* @alternate */
  color: var(--mdc-theme-text-primary-on-dark, white);
}
.mdc-fab.purple500Fab::before, .mdc-fab.purple500Fab::after {
  background-color: white;
}
@supports not (-ms-ime-align: auto) {
  .mdc-fab.purple500Fab::before, .mdc-fab.purple500Fab::after {
    /* @alternate */
    background-color: var(--mdc-theme-text-primary-on-dark, white);
  }
}
.mdc-fab.purple500Fab:hover::before {
  opacity: 0.08;
}
.mdc-fab.purple500Fab:not(.mdc-ripple-upgraded):focus::before, .mdc-fab.purple500Fab.mdc-ripple-upgraded--background-focused::before {
  transition-duration: 75ms;
  opacity: 0.24;
}
.mdc-fab.purple500Fab:not(.mdc-ripple-upgraded)::after {
  transition: opacity 150ms linear;
}
.mdc-fab.purple500Fab:not(.mdc-ripple-upgraded):active::after {
  transition-duration: 75ms;
  opacity: 0.24;
}
.mdc-fab.purple500Fab.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: 0.24;
}

.mdc-fab.blackFab {
  background-color: black;
  color: white;
  /* @alternate */
  color: var(--mdc-theme-text-primary-on-dark, white);
}
.mdc-fab.blackFab::before, .mdc-fab.blackFab::after {
  background-color: white;
}
@supports not (-ms-ime-align: auto) {
  .mdc-fab.blackFab::before, .mdc-fab.blackFab::after {
    /* @alternate */
    background-color: var(--mdc-theme-text-primary-on-dark, white);
  }
}
.mdc-fab.blackFab:hover::before {
  opacity: 0.08;
}
.mdc-fab.blackFab:not(.mdc-ripple-upgraded):focus::before, .mdc-fab.blackFab.mdc-ripple-upgraded--background-focused::before {
  transition-duration: 75ms;
  opacity: 0.24;
}
.mdc-fab.blackFab:not(.mdc-ripple-upgraded)::after {
  transition: opacity 150ms linear;
}
.mdc-fab.blackFab:not(.mdc-ripple-upgraded):active::after {
  transition-duration: 75ms;
  opacity: 0.24;
}
.mdc-fab.blackFab.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: 0.24;
}

.demo-fab-shaped--one:not(.mdc-fab--extended) {
  border-radius: 28px 0;
}

.demo-fab-shaped--two:not(.mdc-fab--extended) {
  border-radius: 8px;
}

.demo-fab-shape-radius:not(.mdc-fab--extended) {
  border-radius: 50% 0 0 0;
}

.demo-fab-extended-shape-radius {
  border-radius: 12px;
}

.demo-fab-icon-size .mdc-fab__icon {
  width: 36px;
  height: 36px;
  font-size: 36px;
}

.demo-fab-position-zindex {
  z-index: 7;
}

.demo-icon-custom-colors {
  color: #ff9800;
}

.temporary-workaround-for-text-field-svg {
  top: 0.9em;
}

.mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__icon {
  fill: rgba(0, 0, 0, 0.54);
}

.demo-svg-custom-color {
  fill: #ff9800;
}

.demo-icon-button-custom {
  color: #de442c;
}
.demo-icon-button-custom::before, .demo-icon-button-custom::after {
  background-color: #de442c;
}
.demo-icon-button-custom:hover::before {
  opacity: 0.04;
}
.demo-icon-button-custom:not(.mdc-ripple-upgraded):focus::before, .demo-icon-button-custom.mdc-ripple-upgraded--background-focused::before {
  transition-duration: 75ms;
  opacity: 0.12;
}
.demo-icon-button-custom:not(.mdc-ripple-upgraded)::after {
  transition: opacity 150ms linear;
}
.demo-icon-button-custom:not(.mdc-ripple-upgraded):active::after {
  transition-duration: 75ms;
  opacity: 0.12;
}
.demo-icon-button-custom.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: 0.12;
}
.demo-icon-button-custom::before, .demo-icon-button-custom::after {
  background-color: #de442c;
}
.demo-icon-button-custom:hover::before {
  opacity: 0.09;
}
.demo-icon-button-custom:not(.mdc-ripple-upgraded):focus::before, .demo-icon-button-custom.mdc-ripple-upgraded--background-focused::before {
  transition-duration: 75ms;
  opacity: 0.26;
}
.demo-icon-button-custom:not(.mdc-ripple-upgraded)::after {
  transition: opacity 150ms linear;
}
.demo-icon-button-custom:not(.mdc-ripple-upgraded):active::after {
  transition-duration: 75ms;
  opacity: 0.35;
}
.demo-icon-button-custom.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: 0.35;
}

.demo-icon-button-primary {
  color: #808080;
}
.demo-icon-button-primary::before, .demo-icon-button-primary::after {
  background-color: #808080;
}
.demo-icon-button-primary:hover::before {
  opacity: 0.04;
}
.demo-icon-button-primary:not(.mdc-ripple-upgraded):focus::before, .demo-icon-button-primary.mdc-ripple-upgraded--background-focused::before {
  transition-duration: 75ms;
  opacity: 0.12;
}
.demo-icon-button-primary:not(.mdc-ripple-upgraded)::after {
  transition: opacity 150ms linear;
}
.demo-icon-button-primary:not(.mdc-ripple-upgraded):active::after {
  transition-duration: 75ms;
  opacity: 0.12;
}
.demo-icon-button-primary.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: 0.12;
}

.demo-icon-button-secondary {
  color: #1A73E8;
}
.demo-icon-button-secondary::before, .demo-icon-button-secondary::after {
  background-color: #1A73E8;
}
.demo-icon-button-secondary:hover::before {
  opacity: 0.04;
}
.demo-icon-button-secondary:not(.mdc-ripple-upgraded):focus::before, .demo-icon-button-secondary.mdc-ripple-upgraded--background-focused::before {
  transition-duration: 75ms;
  opacity: 0.12;
}
.demo-icon-button-secondary:not(.mdc-ripple-upgraded)::after {
  transition: opacity 150ms linear;
}
.demo-icon-button-secondary:not(.mdc-ripple-upgraded):active::after {
  transition-duration: 75ms;
  opacity: 0.12;
}
.demo-icon-button-secondary.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: 0.12;
}

.demo-icon-button-large {
  width: 72px;
  height: 72px;
  padding: 18px;
  font-size: 36px;
}
.demo-icon-button-large svg,
.demo-icon-button-large img {
  width: 36px;
  height: 36px;
}

.standard-image-list .mdc-image-list__image-aspect-container {
  padding-bottom: calc(100% / 1.5);
}
.standard-image-list .mdc-image-list__item {
  width: calc(100% / 5 - 4.2px);
  margin: 2px;
}

.masonry-image-list {
  column-count: 5;
  column-gap: 16px;
}
.masonry-image-list .mdc-image-list__item {
  margin-bottom: 16px;
}

@media (max-width: 599px) {
  .standard-image-list .mdc-image-list__item {
    width: calc(100% / 3 - 4.3333333333px);
    margin: 2px;
  }

  .masonry-image-list {
    column-count: 3;
    column-gap: 16px;
  }
  .masonry-image-list .mdc-image-list__item {
    margin-bottom: 16px;
  }
}
.image-list--rounded-corners .mdc-image-list__image {
  border-radius: 8px;
}
.image-list--rounded-corners.mdc-image-list--with-text-protection .mdc-image-list__supporting {
  border-radius: 0 0 8px 8px;
}

.demo-landing {
  color: #fff;
  background-color: #363640;
  height: 40vh;
}
.demo-landing__title {
  font-family: Roboto, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 3.75rem;
  line-height: 3.75rem;
  font-weight: 300;
  letter-spacing: -0.0083333333em;
  text-decoration: inherit;
  text-transform: inherit;
  margin-top: 20px;
  margin-bottom: 0;
}
@media (max-width: 699px) {
  .demo-landing__title {
    font-size: 8vw;
  }
}
.demo-landing__label {
  font-family: Roboto, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 2.125rem;
  line-height: 2.5rem;
  font-weight: 400;
  letter-spacing: 0.0073529412em;
  text-decoration: inherit;
  text-transform: inherit;
  margin-top: 20px;
  margin-bottom: 0;
}
@media (max-width: 699px) {
  .demo-landing__label {
    font-size: 6vw;
    margin: 0;
  }
}
.demo-landing__subtitle {
  font-family: Roboto, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 400;
  letter-spacing: 0.03125em;
  text-decoration: inherit;
  text-transform: inherit;
  color: rgba(0, 0, 0, 0.54);
  /* @alternate */
  color: var(--mdc-theme-text-secondary-on-background, rgba(0, 0, 0, 0.54));
  padding-left: 25px;
  padding-right: 25px;
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: center;
}

.demo-linear-progress--custom .mdc-linear-progress__bar-inner {
  background-color: #f44336;
}
.demo-linear-progress--custom .mdc-linear-progress__buffering-dots {
  background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' enable-background='new 0 0 5 2' xml:space='preserve' viewBox='0 0 5 2' preserveAspectRatio='none slice'%3E%3Ccircle cx='1' cy='1' r='1' fill='%23ffcdd2'/%3E%3C/svg%3E");
}
.demo-linear-progress--custom .mdc-linear-progress__buffer {
  background-color: #ffcdd2;
}

.demo-list--custom {
  color: #1e88e5;
}
.demo-list--custom .mdc-list-item__graphic {
  color: white;
}
.demo-list--custom .mdc-list-item__graphic {
  background-color: rgba(0, 0, 0, 0.38);
  /* @alternate */
  background-color: var(--mdc-theme-text-icon-on-background, rgba(0, 0, 0, 0.38));
}
.demo-list--custom .mdc-list-divider {
  border-bottom-color: #fce4ec;
}
.demo-list--custom .mdc-list-item__secondary-text {
  color: #8e24aa;
}
.demo-list--custom .mdc-list-item__graphic {
  background-color: #ec407a;
}
.demo-list--custom .mdc-list-item__graphic {
  color: #fce4ec;
}
.demo-list--custom .mdc-list-item__meta {
  color: #ff9800;
}

.demo-list--shaped .mdc-list-item {
  border-radius: 0 50px 50px 0;
}

.demo-menu-surface--shaped {
  border-radius: 4px;
}

.menu-surface-image-list {
  width: 220px;
}
.menu-surface-image-list .mdc-image-list__image-aspect-container {
  padding-bottom: calc(100% / 1.5);
}
.menu-surface-image-list .mdc-image-list__item {
  width: calc(100% / 1 - 5px);
  margin: 2px;
}

.demo-radio--custom .mdc-radio__native-control:enabled:not(:checked) + .mdc-radio__background .mdc-radio__outer-circle {
  border-color: #f44336;
}
.demo-radio--custom .mdc-radio__native-control:enabled:checked + .mdc-radio__background .mdc-radio__outer-circle {
  border-color: #f44336;
}
.demo-radio--custom .mdc-radio__native-control:enabled + .mdc-radio__background .mdc-radio__inner-circle {
  border-color: #ff9800;
}
.demo-radio--custom .mdc-radio__background::before {
  background-color: #f44336;
}
.demo-radio--custom::before, .demo-radio--custom::after {
  background-color: #f44336;
}
.demo-radio--custom:hover::before {
  opacity: 0.04;
}
.demo-radio--custom:not(.mdc-ripple-upgraded):focus::before, .demo-radio--custom.mdc-ripple-upgraded--background-focused::before {
  transition-duration: 75ms;
  opacity: 0.12;
}
.demo-radio--custom:not(.mdc-ripple-upgraded)::after {
  transition: opacity 150ms linear;
}
.demo-radio--custom:not(.mdc-ripple-upgraded):active::after {
  transition-duration: 75ms;
  opacity: 0.12;
}
.demo-radio--custom.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: 0.12;
}

.custom-select-ink-color:not(.mdc-select--disabled) .mdc-select__native-control,
.custom-select-ink-color:not(.mdc-select--disabled) .mdc-select__selected-text {
  color: green;
}

.custom-select-container-fill-color:not(.mdc-select--disabled) {
  background-color: green;
}

.custom-select-label-color:not(.mdc-select--disabled) .mdc-floating-label {
  color: green;
}

.custom-select-bottom-line-color:not(.mdc-select--disabled) .mdc-select__native-control,
.custom-select-bottom-line-color:not(.mdc-select--disabled) .mdc-select__selected-text {
  border-bottom-color: green;
}

.custom-select-shape-radius, .custom-select-shape-radius__native-control {
  border-radius: 28px 28px 0 0;
}

.custom-select-outline-color:not(.mdc-select--disabled) .mdc-notched-outline__leading,
.custom-select-outline-color:not(.mdc-select--disabled) .mdc-notched-outline__notch,
.custom-select-outline-color:not(.mdc-select--disabled) .mdc-notched-outline__trailing {
  border-color: green;
}

.custom-select-outline-shape-radius .mdc-notched-outline .mdc-notched-outline__leading {
  /* @noflip */
  border-radius: 28px 0 0 28px;
  width: 28px;
}
[dir=rtl] .custom-select-outline-shape-radius .mdc-notched-outline .mdc-notched-outline__leading, .custom-select-outline-shape-radius .mdc-notched-outline .mdc-notched-outline__leading[dir=rtl] {
  /* @noflip */
  border-radius: 0 28px 28px 0;
}
.custom-select-outline-shape-radius .mdc-notched-outline .mdc-notched-outline__notch {
  max-width: calc(100% - 28px * 2);
}
.custom-select-outline-shape-radius .mdc-notched-outline .mdc-notched-outline__trailing {
  /* @noflip */
  border-radius: 0 28px 28px 0;
}
[dir=rtl] .custom-select-outline-shape-radius .mdc-notched-outline .mdc-notched-outline__trailing, .custom-select-outline-shape-radius .mdc-notched-outline .mdc-notched-outline__trailing[dir=rtl] {
  /* @noflip */
  border-radius: 28px 0 0 28px;
}
.custom-select-outline-shape-radius .mdc-select__native-control {
  border-radius: 28px;
}
.custom-select-outline-shape-radius .mdc-select__native-control {
  /* @noflip */
  padding-left: 32px;
  /* @noflip */
  padding-right: 52px;
}
[dir=rtl] .custom-select-outline-shape-radius .mdc-select__native-control, .custom-select-outline-shape-radius .mdc-select__native-control[dir=rtl] {
  /* @noflip */
  padding-left: 52px;
  /* @noflip */
  padding-right: 32px;
}
.custom-select-outline-shape-radius + .mdc-select-helper-text {
  /* @noflip */
  margin-left: 32px;
  /* @noflip */
  margin-right: 16px;
}
[dir=rtl] .custom-select-outline-shape-radius + .mdc-select-helper-text, .custom-select-outline-shape-radius + .mdc-select-helper-text[dir=rtl] {
  /* @noflip */
  margin-left: 16px;
  /* @noflip */
  margin-right: 32px;
}

.custom-select-shape-radius, .custom-select-shape-radius__native-control {
  border-radius: 28px 28px 0 0;
}

.custom-select-outline-shape-radius .mdc-notched-outline .mdc-notched-outline__leading {
  /* @noflip */
  border-radius: 28px 0 0 28px;
  width: 28px;
}
[dir=rtl] .custom-select-outline-shape-radius .mdc-notched-outline .mdc-notched-outline__leading, .custom-select-outline-shape-radius .mdc-notched-outline .mdc-notched-outline__leading[dir=rtl] {
  /* @noflip */
  border-radius: 0 28px 28px 0;
}
.custom-select-outline-shape-radius .mdc-notched-outline .mdc-notched-outline__notch {
  max-width: calc(100% - 28px * 2);
}
.custom-select-outline-shape-radius .mdc-notched-outline .mdc-notched-outline__trailing {
  /* @noflip */
  border-radius: 0 28px 28px 0;
}
[dir=rtl] .custom-select-outline-shape-radius .mdc-notched-outline .mdc-notched-outline__trailing, .custom-select-outline-shape-radius .mdc-notched-outline .mdc-notched-outline__trailing[dir=rtl] {
  /* @noflip */
  border-radius: 28px 0 0 28px;
}
.custom-select-outline-shape-radius .mdc-select__native-control {
  border-radius: 28px;
}
.custom-select-outline-shape-radius .mdc-select__native-control {
  /* @noflip */
  padding-left: 32px;
  /* @noflip */
  padding-right: 52px;
}
[dir=rtl] .custom-select-outline-shape-radius .mdc-select__native-control, .custom-select-outline-shape-radius .mdc-select__native-control[dir=rtl] {
  /* @noflip */
  padding-left: 52px;
  /* @noflip */
  padding-right: 32px;
}
.custom-select-outline-shape-radius + .mdc-select-helper-text {
  /* @noflip */
  margin-left: 32px;
  /* @noflip */
  margin-right: 16px;
}
[dir=rtl] .custom-select-outline-shape-radius + .mdc-select-helper-text, .custom-select-outline-shape-radius + .mdc-select-helper-text[dir=rtl] {
  /* @noflip */
  margin-left: 16px;
  /* @noflip */
  margin-right: 32px;
}

.demo-enhanced-width {
  width: 7rem;
}

.demo-slider--custom:not(.mdc-slider--disabled) .mdc-slider__track {
  background-color: #d32f2f;
}
.demo-slider--custom:not(.mdc-slider--disabled) .mdc-slider__track-container {
  background-color: #fdd835;
}
.demo-slider--custom:not(.mdc-slider--disabled) .mdc-slider__track-marker::after,
.demo-slider--custom:not(.mdc-slider--disabled) .mdc-slider__track-marker-container::after {
  background-color: white;
}
.demo-slider--custom:not(.mdc-slider--disabled) .mdc-slider__thumb {
  fill: #ff9800;
  stroke: #ff9800;
}
.demo-slider--custom:not(.mdc-slider--disabled) .mdc-slider__focus-ring {
  background-color: #f57f17;
}
.demo-slider--custom:not(.mdc-slider--disabled) .mdc-slider__pin {
  background-color: #e91e63;
}
.demo-slider--custom:not(.mdc-slider--disabled) .mdc-slider__pin {
  color: white;
  /* @alternate */
  color: var(--mdc-theme-text-primary-on-dark, white);
}

.custom-snackbar--shape-radius .mdc-snackbar__surface {
  border-radius: 10px;
}

.custom-snackbar--fill-color .mdc-snackbar__surface {
  background-color: #f44336;
}

.custom-snackbar--label-ink-color .mdc-snackbar__label {
  color: #ffeb3b;
}

.custom-snackbar--viewport-margin {
  margin: 50px;
}

.custom-snackbar--elevation .mdc-snackbar__surface {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}

.custom-snackbar--max-width .mdc-snackbar__surface {
  max-width: 200px;
}

.custom-snackbar--min-width .mdc-snackbar__surface {
  min-width: 800px;
}
@media (max-width: 480px), (max-width: 800px) {
  .custom-snackbar--min-width .mdc-snackbar__surface {
    min-width: 100%;
  }
}

.demo-switch--custom.mdc-switch--checked .mdc-switch__track {
  background-color: #f44336;
  border-color: #f44336;
}
.demo-switch--custom.mdc-switch--checked .mdc-switch__thumb {
  background-color: #f44336;
  border-color: #f44336;
}
.demo-switch--custom.mdc-switch--checked .mdc-switch__thumb-underlay::before, .demo-switch--custom.mdc-switch--checked .mdc-switch__thumb-underlay::after {
  background-color: #f44336;
}
.demo-switch--custom.mdc-switch--checked .mdc-switch__thumb-underlay:hover::before {
  opacity: 0.04;
}
.demo-switch--custom.mdc-switch--checked .mdc-switch__thumb-underlay:not(.mdc-ripple-upgraded):focus::before, .demo-switch--custom.mdc-switch--checked .mdc-switch__thumb-underlay.mdc-ripple-upgraded--background-focused::before {
  transition-duration: 75ms;
  opacity: 0.12;
}
.demo-switch--custom.mdc-switch--checked .mdc-switch__thumb-underlay:not(.mdc-ripple-upgraded)::after {
  transition: opacity 150ms linear;
}
.demo-switch--custom.mdc-switch--checked .mdc-switch__thumb-underlay:not(.mdc-ripple-upgraded):active::after {
  transition-duration: 75ms;
  opacity: 0.12;
}
.demo-switch--custom.mdc-switch--checked .mdc-switch__thumb-underlay.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: 0.12;
}

.custom-switch--thumb-color.mdc-switch--checked .mdc-switch__thumb {
  background-color: #f44336;
  border-color: #f44336;
}
.custom-switch--thumb-color:not(.mdc-switch--checked) .mdc-switch__thumb {
  background-color: #f44336;
  border-color: #f44336;
}

.custom-switch--track-color.mdc-switch--checked .mdc-switch__track {
  background-color: #f44336;
  border-color: #f44336;
}
.custom-switch--track-color:not(.mdc-switch--checked) .mdc-switch__track {
  background-color: #f44336;
  border-color: #f44336;
}

.custom-switch__label-left-margin .mdc-switch + label {
  margin-left: 10px;
}

.demo-text-field-custom-colors:not(.mdc-text-field--invalid):not(.mdc-text-field--disabled):not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mdc-text-field__input {
  border-bottom-color: rgba(0, 0, 255, 0.38);
}
.demo-text-field-custom-colors:not(.mdc-text-field--invalid):not(.mdc-text-field--disabled):not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mdc-text-field__input:hover {
  border-bottom-color: rgba(0, 0, 255, 0.6);
}
.demo-text-field-custom-colors:not(.mdc-text-field--invalid):not(.mdc-text-field--disabled) .mdc-line-ripple {
  background-color: blue;
}
.demo-text-field-custom-colors:not(.mdc-text-field--invalid):not(.mdc-text-field--disabled) .mdc-text-field__input {
  color: black;
}
.demo-text-field-custom-colors:not(.mdc-text-field--invalid):not(.mdc-text-field--disabled) .mdc-floating-label {
  color: rgba(0, 0, 255, 0.5);
}
.demo-text-field-custom-colors:not(.mdc-text-field--invalid):not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.demo-text-field-custom-colors:not(.mdc-text-field--invalid):not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.demo-text-field-custom-colors:not(.mdc-text-field--invalid):not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-color: rgba(0, 0, 255, 0.38);
}
.demo-text-field-custom-colors:not(.mdc-text-field--invalid):not(.mdc-text-field--disabled):not(.mdc-text-field--focused) .mdc-text-field__input:hover ~ .mdc-notched-outline .mdc-notched-outline__leading,
.demo-text-field-custom-colors:not(.mdc-text-field--invalid):not(.mdc-text-field--disabled):not(.mdc-text-field--focused) .mdc-text-field__input:hover ~ .mdc-notched-outline .mdc-notched-outline__notch,
.demo-text-field-custom-colors:not(.mdc-text-field--invalid):not(.mdc-text-field--disabled):not(.mdc-text-field--focused) .mdc-text-field__input:hover ~ .mdc-notched-outline .mdc-notched-outline__trailing,
.demo-text-field-custom-colors:not(.mdc-text-field--invalid):not(.mdc-text-field--disabled):not(.mdc-text-field--focused) .mdc-text-field__icon:hover ~ .mdc-notched-outline .mdc-notched-outline__leading,
.demo-text-field-custom-colors:not(.mdc-text-field--invalid):not(.mdc-text-field--disabled):not(.mdc-text-field--focused) .mdc-text-field__icon:hover ~ .mdc-notched-outline .mdc-notched-outline__notch,
.demo-text-field-custom-colors:not(.mdc-text-field--invalid):not(.mdc-text-field--disabled):not(.mdc-text-field--focused) .mdc-text-field__icon:hover ~ .mdc-notched-outline .mdc-notched-outline__trailing {
  border-color: rgba(0, 0, 255, 0.6);
}
.demo-text-field-custom-colors:not(.mdc-text-field--invalid):not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.demo-text-field-custom-colors:not(.mdc-text-field--invalid):not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.demo-text-field-custom-colors:not(.mdc-text-field--invalid):not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: blue;
}
.demo-text-field-custom-colors:not(.mdc-text-field--invalid):not(.mdc-text-field--disabled) + .mdc-text-field-helper-line .mdc-text-field-helper-text {
  color: rgba(0, 0, 255, 0.38);
}
.demo-text-field-custom-colors:not(.mdc-text-field--invalid):not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.demo-text-field-custom-colors:not(.mdc-text-field--invalid):not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.demo-text-field-custom-colors:not(.mdc-text-field--invalid):not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-color: rgba(0, 0, 255, 0.38);
}
.demo-text-field-custom-colors:not(.mdc-text-field--invalid):not(.mdc-text-field--disabled) .mdc-text-field__icon {
  color: rgba(0, 0, 255, 0.6);
}
.demo-text-field-custom-colors:not(.mdc-text-field--invalid).mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: rgba(0, 0, 255, 0.87);
}
.demo-text-field-custom-colors:not(.mdc-text-field--invalid).mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-text-field__icon {
  color: blue;
}

.demo-textarea:not(.mdc-text-field--invalid):not(.mdc-text-field--disabled) .mdc-text-field__input {
  color: black;
}
.demo-textarea:not(.mdc-text-field--invalid):not(.mdc-text-field--disabled) .mdc-floating-label {
  color: rgba(0, 0, 255, 0.5);
}
.demo-textarea:not(.mdc-text-field--invalid):not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.demo-textarea:not(.mdc-text-field--invalid):not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.demo-textarea:not(.mdc-text-field--invalid):not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-color: rgba(0, 0, 255, 0.38);
}
.demo-textarea:not(.mdc-text-field--invalid).mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: rgba(0, 0, 255, 0.87);
}
.demo-textarea:not(.mdc-text-field--invalid).mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.demo-textarea:not(.mdc-text-field--invalid).mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.demo-textarea:not(.mdc-text-field--invalid).mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-color: blue;
}

.demo-textarea.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-text-field__input {
  color: orange;
}
.demo-textarea.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: rgba(255, 165, 0, 0.5);
}
.demo-textarea.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.demo-textarea.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.demo-textarea.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-color: rgba(255, 165, 0, 0.38);
}
.demo-textarea.mdc-text-field--invalid.mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: rgba(255, 165, 0, 0.87);
}
.demo-textarea.mdc-text-field--invalid.mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.demo-textarea.mdc-text-field--invalid.mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.demo-textarea.mdc-text-field--invalid.mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-color: orange;
}

.demo-fullwidth-input:not(.mdc-text-field--invalid):not(.mdc-text-field--disabled):not(.mdc-text-field--textarea) {
  border-bottom-color: rgba(0, 0, 255, 0.38);
}
.demo-fullwidth-input:not(.mdc-text-field--invalid):not(.mdc-text-field--disabled) .mdc-text-field__input {
  color: black;
}
.demo-fullwidth-input:not(.mdc-text-field--invalid):not(.mdc-text-field--disabled) .mdc-floating-label {
  color: rgba(0, 0, 255, 0.5);
}
.demo-fullwidth-input:not(.mdc-text-field--invalid):not(.mdc-text-field--disabled) .mdc-line-ripple {
  background-color: blue;
}
.demo-fullwidth-input:not(.mdc-text-field--invalid).mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: rgba(0, 0, 255, 0.87);
}

.demo-fullwidth-input.mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--textarea) {
  border-bottom-color: rgba(255, 165, 0, 0.38);
}
.demo-fullwidth-input.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-text-field__input {
  color: orange;
}
.demo-fullwidth-input.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: rgba(255, 165, 0, 0.5);
}
.demo-fullwidth-input.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple {
  background-color: orange;
}
.demo-fullwidth-input.mdc-text-field--invalid.mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: rgba(255, 165, 0, 0.87);
}
.demo-fullwidth-input.mdc-text-field--invalid.mdc-text-field--focused:not(.mdc-text-field--disabled):not(.mdc-text-field--textarea) {
  border-bottom-color: orange;
}

.demo-shaped-text-field {
  border-radius: 28px 28px 0 0;
}

.demo-shaped-text-field--outline .mdc-notched-outline .mdc-notched-outline__leading {
  /* @noflip */
  border-radius: 28px 0 0 28px;
  width: 28px;
}
[dir=rtl] .demo-shaped-text-field--outline .mdc-notched-outline .mdc-notched-outline__leading, .demo-shaped-text-field--outline .mdc-notched-outline .mdc-notched-outline__leading[dir=rtl] {
  /* @noflip */
  border-radius: 0 28px 28px 0;
}
.demo-shaped-text-field--outline .mdc-notched-outline .mdc-notched-outline__notch {
  max-width: calc(100% - 28px * 2);
}
.demo-shaped-text-field--outline .mdc-notched-outline .mdc-notched-outline__trailing {
  /* @noflip */
  border-radius: 0 28px 28px 0;
}
[dir=rtl] .demo-shaped-text-field--outline .mdc-notched-outline .mdc-notched-outline__trailing, .demo-shaped-text-field--outline .mdc-notched-outline .mdc-notched-outline__trailing[dir=rtl] {
  /* @noflip */
  border-radius: 28px 0 0 28px;
}
.demo-shaped-text-field--outline .mdc-text-field__input {
  /* @noflip */
  padding-left: 32px;
  /* @noflip */
  padding-right: 0;
}
[dir=rtl] .demo-shaped-text-field--outline .mdc-text-field__input, .demo-shaped-text-field--outline .mdc-text-field__input[dir=rtl] {
  /* @noflip */
  padding-left: 0;
  /* @noflip */
  padding-right: 32px;
}
.demo-shaped-text-field--outline + .mdc-text-field-helper-line {
  /* @noflip */
  padding-left: 32px;
  /* @noflip */
  padding-right: 28px;
}
[dir=rtl] .demo-shaped-text-field--outline + .mdc-text-field-helper-line, .demo-shaped-text-field--outline + .mdc-text-field-helper-line[dir=rtl] {
  /* @noflip */
  padding-left: 28px;
  /* @noflip */
  padding-right: 32px;
}

.ngx-form-field-text-field,
.ngx-form-field-select {
  height: 87px;
}

.demo-layout__row .ngx-form-field-text-field + mdc-form-field,
.demo-layout__row .ngx-form-field-select + mdc-form-field {
  padding-left: 14px;
}

.mdc-form-field {
  align-self: flex-start;
}

.demo-app-bar-content {
  padding-left: 16px;
  padding-right: 16px;
  overflow: auto;
  height: 200px;
}

.demo-top-app-bar-themed {
  background-color: #f9a825;
}
.demo-top-app-bar-themed .mdc-top-app-bar__action-item,
.demo-top-app-bar-themed .mdc-top-app-bar__navigation-icon {
  color: #0d47a1;
}
.demo-top-app-bar-themed .mdc-top-app-bar__action-item::before, .demo-top-app-bar-themed .mdc-top-app-bar__action-item::after,
.demo-top-app-bar-themed .mdc-top-app-bar__navigation-icon::before,
.demo-top-app-bar-themed .mdc-top-app-bar__navigation-icon::after {
  background-color: #0d47a1;
}
.demo-top-app-bar-themed .mdc-top-app-bar__action-item:hover::before,
.demo-top-app-bar-themed .mdc-top-app-bar__navigation-icon:hover::before {
  opacity: 0.04;
}
.demo-top-app-bar-themed .mdc-top-app-bar__action-item:not(.mdc-ripple-upgraded):focus::before, .demo-top-app-bar-themed .mdc-top-app-bar__action-item.mdc-ripple-upgraded--background-focused::before,
.demo-top-app-bar-themed .mdc-top-app-bar__navigation-icon:not(.mdc-ripple-upgraded):focus::before,
.demo-top-app-bar-themed .mdc-top-app-bar__navigation-icon.mdc-ripple-upgraded--background-focused::before {
  transition-duration: 75ms;
  opacity: 0.12;
}
.demo-top-app-bar-themed .mdc-top-app-bar__action-item:not(.mdc-ripple-upgraded)::after,
.demo-top-app-bar-themed .mdc-top-app-bar__navigation-icon:not(.mdc-ripple-upgraded)::after {
  transition: opacity 150ms linear;
}
.demo-top-app-bar-themed .mdc-top-app-bar__action-item:not(.mdc-ripple-upgraded):active::after,
.demo-top-app-bar-themed .mdc-top-app-bar__navigation-icon:not(.mdc-ripple-upgraded):active::after {
  transition-duration: 75ms;
  opacity: 0.12;
}
.demo-top-app-bar-themed .mdc-top-app-bar__action-item.mdc-ripple-upgraded,
.demo-top-app-bar-themed .mdc-top-app-bar__navigation-icon.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: 0.12;
}

.demo-top-app-bar-with-tabs {
  background-color: #363640;
}
.demo-top-app-bar-with-tabs .mdc-tab-bar {
  margin-top: 1em;
}
@media (max-width: 599px) {
  .demo-top-app-bar-with-tabs .mdc-tab-bar {
    margin-top: 0.5em;
  }
}
.demo-top-app-bar-with-tabs .mdc-tab-bar .mdc-tab .mdc-tab__text-label {
  color: #fff;
  /* @alternate */
  color: var(--mdc-theme-on-primary, #fff);
}
.demo-top-app-bar-with-tabs .mdc-tab-bar .mdc-tab .mdc-tab__icon {
  color: #fff;
  /* @alternate */
  color: var(--mdc-theme-on-primary, #fff);
  fill: currentColor;
}
.demo-top-app-bar-with-tabs .mdc-tab-bar .mdc-tab__ripple::before, .demo-top-app-bar-with-tabs .mdc-tab-bar .mdc-tab__ripple::after {
  background-color: #1976d2;
}
.demo-top-app-bar-with-tabs .mdc-tab-bar .mdc-tab__ripple:hover::before {
  opacity: 0.04;
}
.demo-top-app-bar-with-tabs .mdc-tab-bar .mdc-tab__ripple:not(.mdc-ripple-upgraded):focus::before, .demo-top-app-bar-with-tabs .mdc-tab-bar .mdc-tab__ripple.mdc-ripple-upgraded--background-focused::before {
  transition-duration: 75ms;
  opacity: 0.12;
}
.demo-top-app-bar-with-tabs .mdc-tab-bar .mdc-tab__ripple:not(.mdc-ripple-upgraded)::after {
  transition: opacity 150ms linear;
}
.demo-top-app-bar-with-tabs .mdc-tab-bar .mdc-tab__ripple:not(.mdc-ripple-upgraded):active::after {
  transition-duration: 75ms;
  opacity: 0.12;
}
.demo-top-app-bar-with-tabs .mdc-tab-bar .mdc-tab__ripple.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: 0.12;
}
.demo-top-app-bar-with-tabs .mdc-tab-bar .mdc-tab-indicator .mdc-tab-indicator__content--underline {
  border-color: #1976d2;
}
.demo-top-app-bar-with-tabs .mdc-tab-bar .mdc-tab-indicator .mdc-tab-indicator__content--underline {
  border-top-width: 3px;
}

.demo-drawer-app-content {
  border: 1px solid rgba(0, 0, 0, 0.15);
  min-height: 16.7rem;
  overflow: hidden;
  position: relative;
}

.demo-top-app-bar-container {
  border: 1px solid rgba(0, 0, 0, 0.15);
  max-height: 16.7rem;
  overflow: auto;
}

.p-0 {
  padding: 0px !important;
}

.p-5 {
  padding: 5px !important;
}

.p-8 {
  padding: 8px !important;
}

.p-10 {
  padding: 10px !important;
}

.p-15 {
  padding: 15px !important;
}

.p-20 {
  padding: 20px !important;
}

.pb-24 {
  padding-bottom: 24px !important;
}

.pb-100 {
  padding-bottom: 100px !important;
}

.pb-5 {
  padding-bottom: 5px !important;
}

.pt-0 {
  padding-top: 0px !important;
}

.pt-10 {
  padding-top: 10px !important;
}

.pb-10 {
  padding-bottom: 10px !important;
}

.pt-30 {
  padding-top: 30px !important;
}

.pb-0 {
  padding-bottom: 0px !important;
}

.pb-4 {
  padding-bottom: 4px !important;
}

.pb-10 {
  padding-bottom: 10px !important;
}

.pt-12 {
  padding-top: 12px !important;
}

.py-5 {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}

.px-5 {
  padding-left: 5px !important;
  padding-right: 5px !important;
}

.pr-10 {
  padding-right: 10px !important;
}

.pr-16 {
  padding-right: 16px !important;
}

.pl-16 {
  padding-left: 16px !important;
}

.pl-0 {
  padding-left: 0px !important;
}

.pr-0 {
  padding-right: 0px !important;
}

.m-0 {
  margin: 0px !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto {
  margin-top: auto !important;
}

.mt-0 {
  margin-top: 0px;
}

.mt-1 {
  margin-top: 1px;
}

.mt-2 {
  margin-top: 2px;
}

.mt-3 {
  margin-top: 3px;
}

.mt-4 {
  margin-top: 4px;
}

.mt-5 {
  margin-top: 5px;
}

.mt-6 {
  margin-top: 6px;
}

.mt-7 {
  margin-top: 7px;
}

.mt-8 {
  margin-top: 8px;
}

.mt-9 {
  margin-top: 9px;
}

.mt-10 {
  margin-top: 10px !important;
}

.mt-20 {
  margin-top: 20px !important;
}

.mt-15 {
  margin-top: 15px !important;
}

.mt-16 {
  margin-top: 16px !important;
}

.ml-15 {
  margin-left: 15px !important;
}

.mt-6vh {
  margin-top: 6vh;
}

.mt-10vh {
  margin-top: 10vh;
}

.margin-top-0 {
  margin-top: 0px !important;
}

.margin-top-5 {
  margin-top: 5px !important;
}

.margin-top-8 {
  margin-top: 8px !important;
}

.margin-top-50 {
  margin-top: 50px !important;
}

.margin-top-30 {
  margin-top: 30px !important;
}

.margin-top-15 {
  margin-top: 15px !important;
}

.margin-top-12 {
  margin-top: 12px !important;
}

.margin-top-10 {
  margin-top: 10px !important;
}

.mt-minus-30 {
  margin-top: -30px !important;
}

.mt-minus-7 {
  margin-top: -7px !important;
}

.mt-minus-15 {
  margin-top: -15px !important;
}

.mt-minus-20 {
  margin-top: -20px !important;
}

.mb-0 {
  margin-bottom: 0px !important;
}

.mb-5 {
  margin-bottom: 5px !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.mb-10 {
  margin-bottom: 10px !important;
}

.mb-105 {
  margin-bottom: 105px !important;
}

.m-25 {
  margin: 25px !important;
}

.ml-0 {
  margin-left: 0px !important;
}

.ml-1 {
  margin-left: 1px;
}

.ml-2 {
  margin-left: 2px;
}

.ml-3 {
  margin-left: 3px;
}

.ml-4 {
  margin-left: 4px;
}

.ml-5 {
  margin-left: 5px;
}

.ml-6 {
  margin-left: 6px;
}

.ml-7 {
  margin-left: 7px;
}

.ml-8 {
  margin-left: 8px;
}

.ml-9 {
  margin-left: 9px;
}

.ml-10 {
  margin-left: 10px !important;
}

.ml-11 {
  margin-left: 11px;
}

.ml-12 {
  margin-left: 12px;
}

.ml-13 {
  margin-left: 13px;
}

.ml-14 {
  margin-left: 14px;
}

.ml-15 {
  margin-left: 15px;
}

.ml-42 {
  margin-left: 42px;
}

.margin-left-10 {
  margin-left: 10px !important;
}

.margin-left-15 {
  margin-left: 15px !important;
}

.ml-25 {
  margin-left: 25px;
}

.margin-left-30 {
  margin-left: 30px;
}

.ml-minus-12 {
  margin-left: -12px !important;
}

.mr-0 {
  margin-right: 0px !important;
}

.mr-1 {
  margin-right: 1px;
}

.mr-2 {
  margin-right: 2px;
}

.mr-3 {
  margin-right: 3px;
}

.mr-4 {
  margin-right: 4px;
}

.mr-5 {
  margin-right: 5px;
}

.mr-6 {
  margin-right: 6px;
}

.mr-7 {
  margin-right: 7px;
}

.mr-8 {
  margin-right: 8px;
}

.mr-9 {
  margin-right: 9px;
}

.mr-10 {
  margin-right: 10px;
}

.mr-11 {
  margin-right: 11px;
}

.mr-12 {
  margin-right: 12px;
}

.mr-13 {
  margin-right: 13px;
}

.mr-14 {
  margin-right: 14px;
}

.mr-15 {
  margin-right: 15px;
}

.margin-right-10 {
  margin-right: 10px !important;
}

.mb-10 {
  margin-bottom: 10px !important;
}

.mrl-0 {
  margin-right: 0px !important;
  margin-left: 0px !important;
}

.font-12 {
  font-size: 12px !important;
}

.font-13 {
  font-size: 13px !important;
}

.font-14 {
  font-size: 14px !important;
}

.font-15 {
  font-size: 15px !important;
}

.font-16 {
  font-size: 16px !important;
}

.font-17 {
  font-size: 17px !important;
}

.font-18 {
  font-size: 18px !important;
}

.font-19 {
  font-size: 19px !important;
}

.font-20 {
  font-size: 20px !important;
}

.font-wt-600 {
  font-weight: 600 !important;
}

.font-wt-400 {
  font-weight: 400 !important;
}

.bottom-15 {
  bottom: 15px !important;
}

.right-10 {
  right: 10px !important;
}

.right--25 {
  right: -25px !important;
}

.d-flex {
  display: flex !important;
}

.w-1 {
  width: 1% !important;
}

.w-2 {
  width: 2% !important;
}

.w-3 {
  width: 3% !important;
}

.w-4 {
  width: 4% !important;
}

.w-5 {
  width: 5% !important;
}

.w-6 {
  width: 6% !important;
}

.w-7 {
  width: 7% !important;
}

.w-8 {
  width: 8% !important;
}

.w-9 {
  width: 9% !important;
}

.w-10 {
  width: 10% !important;
}

.w-11 {
  width: 11% !important;
}

.w-12 {
  width: 12% !important;
}

.w-13 {
  width: 13% !important;
}

.w-14 {
  width: 14% !important;
}

.w-15 {
  width: 15% !important;
}

.w-16 {
  width: 16% !important;
}

.w-17 {
  width: 17% !important;
}

.w-18 {
  width: 18% !important;
}

.w-19 {
  width: 19% !important;
}

.w-20 {
  width: 20% !important;
}

.w-25 {
  width: 25% !important;
}

.w-30 {
  width: 30% !important;
}

.w-33 {
  width: 33% !important;
}

.w-35 {
  width: 35% !important;
}

.w-40 {
  width: 40% !important;
}

.w-45 {
  width: 45% !important;
}

.w-48 {
  width: 48% !important;
}

.w-50 {
  width: 50% !important;
}

.w-54 {
  width: 54% !important;
}

.w-55 {
  width: 55% !important;
}

.w-60 {
  width: 60% !important;
}

.w-62 {
  width: 62% !important;
}

.w-65 {
  width: 65% !important;
}

.w-70 {
  width: 70% !important;
}

.w-72 {
  width: 72% !important;
}

.w-75 {
  width: 75% !important;
}

.w-80 {
  width: 80% !important;
}

.w-85 {
  width: 85% !important;
}

.w-90 {
  width: 90% !important;
}

.w-95 {
  width: 95% !important;
}

.w-100 {
  width: 100% !important;
}

.w-150 {
  width: 150% !important;
}

.w-max-content {
  width: max-content !important;
}

.mw-100 {
  max-width: 100% !important;
}

.min-width-125 {
  min-width: 125px !important;
}

.mw-85 {
  min-width: 85px;
}

.min-height-34vh {
  min-height: 34vh !important;
}

.min-height-45vh {
  min-height: 45vh !important;
}

.min-height-90vh {
  min-height: 90vh !important;
}

.min-height-100 {
  min-height: 100px !important;
}

.min-height-155 {
  min-height: 155px !important;
}

.min-height-180 {
  min-height: 180px !important;
}

.max-height-34vh {
  max-height: 34vh !important;
}

.h-65 {
  height: 65% !important;
}

.h-35 {
  height: 35% !important;
}

.h-80 {
  height: 80% !important;
}

.h-100 {
  height: 100vh !important;
}

.height-5 {
  height: 5px !important;
}

.height-10 {
  height: 10px !important;
}

.height-20 {
  height: 20px !important;
}

.height-30 {
  height: 30px !important;
}

.height-40 {
  height: 40px !important;
}

.height-50 {
  height: 50px !important;
}

.height-56 {
  height: 56px !important;
}

.height-60 {
  height: 60px !important;
}

.height-70 {
  height: 70px !important;
}

.height-80 {
  height: 80px !important;
}

.height-90 {
  height: 90px !important;
}

.height-100 {
  height: 100px !important;
}

.height-490 {
  height: 490px !important;
}

.height-auto-view {
  height: auto !important;
}

.align-baseline {
  display: flex !important;
  align-items: flex-end !important;
}

.flex-direction-column {
  display: flex !important;
  flex-direction: column !important;
}

.float-left {
  float: left !important;
}

.text-left {
  text-align: left !important;
}

.text-center {
  text-align: center !important;
}

.text-right {
  text-align: right !important;
}

.valign-top {
  vertical-align: top !important;
}

.align-right {
  display: flex;
  justify-content: flex-end !important;
  align-items: center;
}

.align-center {
  display: flex !important;
  justify-content: center;
  align-items: center !important;
  text-align: center !important;
}

.align-left {
  display: flex;
  justify-content: flex-start;
}

.valign-center {
  display: flex;
  align-items: center;
}

.display-none {
  display: none;
}

.display-initial {
  display: initial !important;
}

.position-absolute {
  position: absolute !important;
}

.position-relative {
  position: relative !important;
}

.position-inital {
  position: initial !important;
}

.cursor-pointer {
  cursor: pointer !important;
}

.cursor-default {
  cursor: default !important;
}

.opacity-1 {
  opacity: 1;
}

.overflow-y-scroll {
  overflow-y: scroll;
}

.overflow-auto {
  overflow: auto;
}

.bg-light-green {
  background-color: #fbfaf6 !important;
}

.theme-secondary-background-color {
  background-color: #032349 !important;
}

.height-auto {
  height: 100% !important;
}

.line-height-36 {
  line-height: 36px !important;
}

.pointer-events-none {
  pointer-events: none;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.bg-color-light-effect {
  background-color: #fafafa !important;
}

.border-radius-5 {
  border-radius: 5px !important;
}

.border-radius-0 {
  border-radius: 0px !important;
}

.backgroud-color-router-outlet {
  background-color: #fff !important;
}

.heading-bg-color {
  background-color: #efefef !important;
}

.color-white {
  color: #fff !important;
}

.color-black {
  color: black !important;
}

.list-image-thumbnail {
  max-width: 70px !important;
  max-height: 70px !important;
  height: auto;
  width: auto;
}

.border-box {
  border: 1px solid #80808085;
  border-radius: 7px;
}

.border {
  border: 1px solid #80808085;
}

.add-image-container {
  position: relative;
  text-align: center;
}

.add-image-container:hover img {
  opacity: 0.3;
}

.add-image-container img {
  opacity: 1;
  width: auto;
  height: auto;
  max-width: 100px;
  max-height: 100px;
  cursor: pointer !important;
}

.upload-image-container {
  background-color: silver;
  padding: 5px;
  text-align: center;
  padding: 5px;
}

.change-image-container {
  transition: 0.5s ease;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0;
  position: absolute;
  text-align: center;
}

.change-image-container:hover {
  opacity: 1 !important;
}

.add-image-circle {
  height: 65px;
  width: 65px;
  background-color: white;
  border-radius: 100%;
  display: inline-block;
}

.change-image-text {
  color: black;
  font-size: 16px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
}

.top-app-Bar-icon {
  position: fixed;
  top: 0;
  z-index: 999;
  display: none;
}

.error-message {
  font-family: Roboto, sans-serif;
  -webkit-font-smoothing: antialiased;
  font-size: 0.75rem;
  font-weight: 400;
  letter-spacing: 0.0333333333em;
  line-height: normal;
  margin-left: 18px;
  color: var(--mdc-theme-error, #b00020);
  caret-color: #b00020;
}

.object-fit-contain {
  object-fit: contain !important;
}

.order-searchdiv {
  border: 1px solid gray;
  z-index: 2;
  background: #ffffff;
}

.order-list-div span {
  cursor: pointer;
}

.order-list-action-btn-container {
  width: 125px;
  float: left;
}

.order-list-action-btn-container span {
  float: left;
  margin-right: 10px;
  width: 24px;
}

.order-edit-action-item {
  float: left;
}

.cdk-global-scrollblock::-webkit-scrollbar {
  width: 0px;
}

.header-pxm-logo {
  max-width: 200px;
  width: auto;
  height: auto;
}

.omnichannel-heading {
  font-weight: 600;
  color: #032349;
}

.omnichannel-description {
  color: #032349;
}

.lets-create-btn {
  color: white !important;
  background-color: #032349 !important;
  border-radius: 4px !important;
}

.sign-in {
  width: auto;
  margin-right: 20px;
}

.eStore-categories-List .mdc-list-item--selected {
  color: rgba(255, 255, 255, 0.87);
  background: #1A73E8;
}

.eStore-chip {
  border-radius: 4px;
  height: 40px;
}

.eStore-drag-icon {
  position: absolute;
  -webkit-transform: translate(-50%, -50%);
  right: 0%;
  z-index: 10;
  top: 50%;
}

.eStore-reviewTable td:first-child {
  width: 35% !important;
  word-break: break-all;
}

.eStore-reviewTable td:nth-child(2) {
  width: 15% !important;
  word-break: break-all;
}

.eStore-reviewTable td:nth-child(3) {
  width: 15% !important;
  word-break: break-all;
}

.eStore-reviewTable th:nth-child(4) {
  text-align: center;
}

.eStore-reviewTable td:nth-child(4) {
  width: 5% !important;
  text-align: center;
}

.eStore-reviewTable th:nth-child(5) {
  text-align: center;
}

.eStore-reviewTable td:nth-child(5) {
  width: 10% !important;
  text-align: center;
}

.eStore-reviewTable td:nth-child(6) {
  width: 20% !important;
}

.mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-thumb {
  color: #1A73E8 !important;
}

.mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-bar {
  color: #1A73E8 !important;
}

.ngx-form-field-text-field, .ngx-form-field-select {
  height: auto;
  background: #fff !important;
  font-family: "poppin-medium", "Roboto", "Poppins", "sans-serif" !important;
}

.mdc-top-app-bar .mdc-top-app-bar__navigation-icon {
  color: black;
}

.mat-form-field-appearance-outline .mat-form-field-wrapper {
  margin: 0px !important;
}

.mat-form-field-appearance-outline .mat-form-field-outline {
  top: 2px !important;
}

.mat-form-field-appearance-outline .mat-form-field-flex {
  padding: 0px 10px 0px 15px !important;
}

.mat-form-field-appearance-outline .mat-form-field-label {
  top: 30px !important;
  margin-top: 0px !important;
}

.img-conatiner {
  height: 110px;
  widows: 110px;
  text-align: center;
}

.img-resonsive {
  max-width: 100px;
  max-height: 100px;
  height: auto;
  width: auto;
  text-align: center;
}

body {
  margin: 0px;
}

.overflowTableName {
  white-space: normal;
}

.cancel-button {
  background-color: #aeaeae7d !important;
  color: black !important;
  width: 100%;
}

.confirm-button {
  background-color: #1A73E8 !important;
  color: white !important;
  border-radius: 2px;
  width: 100%;
}

.Order_title {
  padding: 10px 0px !important;
  background: #ffffff;
  width: 100%;
  position: sticky !important;
  z-index: 2 !important;
  height: 30px;
}

.add-custom-btn {
  border-radius: 120px !important;
  background: #fff !important;
  height: auto;
  border: 1px solid #ddd !important;
  width: auto;
  text-transform: capitalize;
  font-size: 1.05rem !important;
  letter-spacing: 0px !important;
  color: black !important;
  padding: 17px 20px 34px 20px;
}

:focus {
  outline: none;
}

:root {
  --mdc-theme-primary: #808080;
  --mdc-theme-secondary: #1A73E8;
}

.mat-slide-toggle-bar {
  height: 12px !important;
}

.mdc-drawer--dismissible {
  z-index: 3;
}

.mat-input-wrapper {
  padding: 0px 0px 0px 0px !important;
  position: relative;
}

.mat-form-field-wrapper {
  padding: 0px 0px 0px 0px !important;
  position: relative;
}

.field-error {
  color: #b00020;
}

.headline {
  padding: 0px !important;
  height: 50px;
  margin-top: 50px;
}

.paxcom-child-content {
  overflow-x: hidden !important;
}

.textbox-search {
  background-color: white;
  box-shadow: 2px 3px 3px #ddd;
  border-radius: 4px;
}

.wpx-150 {
  width: 150px !important;
}

.wpx-140 {
  width: 140px !important;
}

.wpx-130 {
  width: 130px !important;
}

.wpx-120 {
  width: 120px !important;
}

.wpx-110 {
  width: 110px !important;
}

.wpx-100 {
  width: 100px !important;
}

.wpx-90 {
  width: 90px !important;
}

.wpx-80 {
  width: 80px !important;
}

.wpx-70 {
  width: 70px !important;
}

.wpx-60 {
  width: 60px !important;
}

.wpx-50 {
  width: 50px !important;
}

.wpx-40 {
  width: 40px !important;
}

.wpx-30 {
  width: 30px !important;
}

.wpx-25 {
  width: 25px !important;
}

.wpx-20 {
  width: 20px !important;
}

.wpx-10 {
  width: 10px !important;
}

tr.mat-header-row {
  height: 45px;
  background: #fff;
}

.custom-table-divbox {
  width: 100%;
  height: auto;
  float: left;
  margin: 0px;
  padding: 15px 0px;
  text-align: left;
}

.custom-table-divinnerbox {
  width: 150px;
  height: auto;
  margin: 5px 10px 0px 0px;
  padding: 0px;
  float: left;
}

.custom-table-divinnerbox h4 {
  font-size: 12px;
  margin: 0;
  padding: 0;
  line-height: normal;
  float: left;
  width: 100%;
}

.custom-table-divinnerbox h4 span {
  min-width: 40px;
  float: left;
  font-size: 12px;
  margin: 0px 5px 0px 0px;
  line-height: 20px;
  font-weight: 500;
}

.first {
  width: 90%;
}

.ellipsis {
  position: relative;
}

.ellipsis:before {
  content: "&nbsp;";
  visibility: hidden;
}

.ellipsis span {
  position: absolute;
  left: 0;
  right: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.mat-cell {
  padding-left: 10px !important;
  padding-right: 5px !important;
}

.action-cell {
  padding-left: 0px !important;
}

th.mat-header-cell {
  padding-left: 10px !important;
  padding-right: 5px;
}

.text-align-right {
  text-align: right !important;
  padding-right: 15px !important;
}

td.mat-cell:last-of-type, td.mat-footer-cell:last-of-type, th.mat-header-cell:last-of-type {
  padding-right: 0px;
}

.img-box {
  width: 100px;
  height: auto;
  justify-content: center;
  float: left;
  margin: 10px 0px 10px 0px;
  text-align: center;
}

.img-res {
  text-align: left;
  align-content: center;
  -o-object-fit: contain;
  object-fit: contain;
  max-width: 120px;
  max-height: 120px;
  height: auto;
  width: auto;
  padding: 5px;
}

.bg-color {
  background-color: #ffffff;
}

.mg-24 {
  margin: 24px;
}

a {
  color: #1A73E8;
  cursor: pointer;
}

.view-link {
  color: #1A73E8;
  cursor: pointer;
}

.mat-button.mat-accent, .mat-icon-button.mat-accent, .mat-stroked-button.mat-accent {
  color: gray;
}

.mat-18 {
  color: black;
  cursor: pointer;
}

.mat-elevation-z8 {
  box-shadow: 0 0px 0px -3px rgba(0, 0, 0, 0.2), 0 0px 0px 1px rgba(0, 0, 0, 0.14), 0 0px 0px -3px rgba(0, 0, 0, 0.12);
}

.table-container {
  display: flex;
  flex-direction: column;
  min-width: 300px;
}

.ngx-mdc-button--secondary:not(:disabled) {
  width: 100%;
}

table {
  width: 100%;
  overflow: auto;
}

.cdk-column-delete_icon {
  padding: 0px !important;
}

.cdk-column-edit_icon {
  padding-left: -1px !important;
}

.search {
  border-left: 1px solid rgba(0, 0, 0, 0.24);
  border-right: 1px solid rgba(0, 0, 0, 0.24);
  border-bottom: 1px solid rgba(0, 0, 0, 0.24);
  border-radius: 5px;
  padding: 24px;
}

.search-icon {
  margin: 4px 3px 0px 0px;
}

.mdc-text-field-helper-line {
  color: #b00020;
}

.load-more {
  color: #1A73E8 !important;
}

.mat-form-field-invalid {
  caret-color: #b00020 !important;
}

.mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
  color: #b00020;
}

.mat-form-field-appearance-outline .mat-form-field-infix {
  padding: 1.4em 0 1.4em 0;
  border-top: 0.32em solid transparent;
  border-radius: 4px;
}

.no-record {
  width: 100% !important;
  text-align: center !important;
  font-size: 13px !important;
  font-weight: 600;
}

.text-color {
  color: #1A73E8 !important;
}

.left-side {
  padding: 17px;
  margin: 15px;
}

.basic {
  display: none;
}

.advance {
  display: block;
}

.mdc-notched-outline__notch {
  /* Ignore the width set by script, and change it to be calculated from the width of `mdc-floating-label` by setting `auto`. */
  width: auto !important;
}

.mdc-notched-outline__notch > label.mdc-floating-label--float-above {
  /* Remove `scale(0.75)` to apply the correct width to `mdc-notched-outline__notch`. */
  transform: translateY(-130%);
  /* Calculate `scale(0.75)` with each property. */
  font-size: calc(1rem * 0.75);
  top: calc(17px * (1 - ((1 - 0.75) / 2)));
}

/* with leading-icon */
.mdc-text-field--with-leading-icon .mdc-notched-outline__notch > label.mdc-floating-label--float-above {
  transform: translateY(-130%) translateX(-32px);
  font-size: calc(1rem * 0.75);
  /* Re-apply the above selector because the score is low. */
}

.mdc-notched-outline__notch > label.mdc-floating-label {
  transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1), color 150ms cubic-bezier(0.4, 0, 0.2, 1), font-size 150ms cubic-bezier(0.4, 0, 0.2, 1), top 150ms cubic-bezier(0.4, 0, 0.2, 1);
}

.mdc-notched-outline--notched .mdc-notched-outline__notch {
  padding-left: 0;
  padding-right: 0px;
  border-top: none;
}

.mdc-list-item__secondary-text {
  margin-left: 4px !important;
}

.mat-primary .mat-pseudo-checkbox-checked, .mat-primary .mat-pseudo-checkbox-indeterminate {
  background: #1A73E8 !important;
}

.text-align-center {
  text-align: center !important;
}

.text-align-end {
  text-align: end !important;
}

.text-align-start {
  text-align: start !important;
}

.cursor-hand {
  cursor: pointer !important;
}

.theme-primary-background-color {
  background-color: #56C3FF !important;
}

.theme-child-order-header {
  padding: 0px !important;
  height: 50px;
}

.theme-child-product-header {
  margin: 0px !important;
  padding: 10px 15px !important;
  background: #ffffff;
  width: 100%;
}

.theme-header {
  margin: 0px !important;
  padding: 10px 15px !important;
  background: #ffffff;
  width: 100%;
}

.mat-tab-label {
  opacity: 1 !important;
  height: 45px !important;
}

.top-app-header .mat-tab-label-container {
  position: fixed;
  top: 0px;
  z-index: 10 !important;
}

.WMS_title {
  padding: 10px 0px !important;
  background: #ffffff;
  width: 100%;
  position: sticky !important;
  z-index: 2 !important;
  height: 30px;
}

.custom-snackbar-css {
  color: #ffff00bf;
}

.mat-simple-snackbar-action {
  color: white !important;
}

.top-app-header-bar {
  height: 48px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.top-app-header-bar .mdc-tab {
  min-width: 160px;
}

.top-app-header-bar .mdc-tab .mdc-tab__text-label {
  color: rgba(0, 0, 0, 0.87) !important;
  text-transform: initial;
  font-size: 1.01rem;
  font-weight: 500;
  letter-spacing: 0.25px;
}

.top-app-header-bar .mdc-tab-scroller {
  position: fixed;
  z-index: 10;
}

img {
  max-width: 100%;
}

.top-app-header-bar .mdc-tab__icon img {
  height: 20px;
  display: flex;
}

.admin-sidebar-highlightedBtn span {
  margin-top: 16px;
  font-size: 14px;
}

.display-none {
  display: none !important;
}

.done {
  background-color: green !important;
}

.display-inline-block {
  display: inline-block !important;
}

.display-block {
  display: block !important;
}

.display-flex {
  display: flex !important;
}

.mdc-drawer--modal {
  z-index: 1000;
  top: 0px;
}

.header-pxm-logo {
  max-width: 200px;
  width: auto;
  height: auto;
}

.searchdiv::-webkit-scrollbar {
  width: 5px;
}

.searchdiv::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
}

.searchdiv::-webkit-scrollbar-thumb {
  border-radius: 5px;
  -webkit-box-shadow: inset 0 0 6px #c1c1c1;
  box-shadow: inset 0 0 6px #c1c1c1;
}

.custom-dialog-container .mat-dialog-container {
  padding: 0;
}

.theme-hide-desktop {
  display: none;
}

.wrapper-header-app-bar {
  background-color: white;
}

@media screen and (min-width: 300px) and (max-width: 1024px) {
  .min-width-none {
    min-width: auto !important;
  }

  .mt-47 {
    margin-top: 47px !important;
  }

  .mt-45 {
    margin-top: 45px !important;
  }

  .mdc-top-app-bar {
    background-color: #eee;
  }

  .w-100-mobile {
    width: 100% !important;
  }

  .overflow-x {
    overflow-x: auto;
  }

  .height-auto {
    height: 50px !important;
  }

  .top-app-Bar-icon {
    display: block;
  }

  .wrapper-header-app-bar .mdc-top-app-bar__row {
    background-color: inherit;
  }

  .theme-configure-menu {
    border-bottom: 4px solid #efefef;
    padding: 0px 0px 20px 0px;
  }

  .theme-configure-custom-menu-left {
    text-align: right;
    position: fixed;
    z-index: 999;
    bottom: 0;
    background: #fafafa;
    padding: 5px 10px;
    left: 0;
    width: 45%;
  }

  .theme-configure-custom-menu-right {
    text-align: right;
    position: fixed;
    z-index: 999;
    bottom: 0;
    background: #1A73E8;
    padding: 5px 10px;
    right: 0;
    width: 45%;
  }
}
@media screen and (min-width: 300px) and (max-width: 479px) {
  .theme-hide-mobile {
    display: none;
  }

  .theme-hide-desktop {
    display: block;
  }

  .mdc-drawer__content {
    height: 90vh !important;
  }
}
@media screen and (min-width: 300px) and (max-width: 641px) {
  .mdc-drawer__content {
    height: 100vh !important;
  }

  .header-select-input-form-container .search-btn {
    right: -25px !important;
  }
}
@media only screen and (max-width: 480px) {
  .main_div {
    right: 20px !important;
    max-width: 85% !important;
  }
}
@font-face {
  font-family: "WorkSansBlack";
  src: url("./assets/fonts/WorkSans-ExtraBold.ttf");
}
@font-face {
  font-family: "BarlowCondensed";
  src: url("./assets//fonts/BarlowCondensed-Black.ttf");
  font-weight: 900;
}
@font-face {
  font-family: "BarlowCondensed";
  src: url("./assets//fonts/BarlowCondensed-Bold.ttf");
  font-weight: 500;
}
@font-face {
  font-family: "BarlowCondensed";
  src: url("./assets//fonts/BarlowCondensed-Medium.ttf");
  font-weight: 400;
}
@font-face {
  font-family: "Montserrat";
  src: url("./assets//fonts/Montserrat-Regular.ttf");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Montserrat";
  src: url("./assets//fonts/Montserrat-Medium.ttf");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Montserrat";
  src: url("./assets//fonts/Montserrat-Bold.ttf");
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: "Montserrat";
  src: url("./assets//fonts/Montserrat-Light.ttf");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "product-sans";
  src: url("./assets//fonts/ProductSans-Regular.ttf");
}
@font-face {
  font-family: "product-sans-Medium";
  src: url("./assets//fonts/ProductSans-Medium.ttf");
}
@font-face {
  font-family: "product-sans-bold";
  src: url("./assets//fonts/ProductSans-Bold.ttf");
}
@font-face {
  font-family: "poppin-regular";
  src: url("./assets//fonts/Poppins-Regular.ttf");
}
@font-face {
  font-family: "poppin-Medium";
  src: url("./assets//fonts/Poppins-Medium.ttf");
}
@font-face {
  font-family: "poppin-bold";
  src: url("./assets//fonts/Poppins-Bold.ttf");
}
.title-xxx-large {
  font-family: "Open Sans" !important;
  font-weight: 900 !important;
  color: #000000 !important;
  font-size: 64px !important;
}
.body-container {
  padding: 16px !important;
}

.body-normal-light {
  font-family: "Open Sans" !important;
  font-weight: 500 !important;
  color: #000000B2 !important;
  font-size: 16px !important;
}
@media (min-width: 1920px) and (max-width: 2359px) {
  .body-normal-light {
    font-size: 24px !important;
  }
}

.card-title {
  font-family: "Open Sans" !important;
  font-weight: 700;
  color: #0000007F !important;
  letter-spacing: 0.05em !important;
  text-overflow: ellipsis !important;
  overflow: hidden !important;
  padding-bottom: 5px !important;
}
@media (max-width: 768px) {
  .card-title {
    height: 1.2em !important;
    font-size: 18px !important;
  }
}
@media (min-width: 769px) and (max-width: 1920px) {
  .card-title {
    height: 2em !important;
    font-size: 18px !important;
  }
}
@media (min-width: 1920px) {
  .card-title {
    height: 1.2em !important;
    font-size: 24px !important;
  }
}

.card-title-unselected {
  transition: 0.5s;
  color: #0000007F !important;
}

.card-title-selected {
  transition: 0.5s;
  color: #053b7a !important;
}

.product-card-title-container {
  overflow-y: hidden;
  font-family: "Open Sans" !important;
  font-weight: 500 !important;
  padding-bottom: 5px !important;
}
@media (max-width: 768px) {
  .product-card-title-container {
    font-size: 20px !important;
    height: 2.3em !important;
  }
}
@media (min-width: 769px) and (max-width: 1920px) {
  .product-card-title-container {
    font-size: 18px !important;
    height: 2.3em !important;
  }
}
@media (min-width: 1920px) {
  .product-card-title-container {
    font-size: 24px !important;
    height: 2.3em !important;
  }
}

.product-card-price-title {
  font-family: "Open Sans";
  font-weight: 500;
  letter-spacing: 0.05em;
}
@media (max-width: 768px) {
  .product-card-price-title {
    font-size: 20px !important;
  }
}
@media (min-width: 769px) and (max-width: 1920px) {
  .product-card-price-title {
    font-size: 20px !important;
  }
}
@media (min-width: 1920px) {
  .product-card-price-title {
    font-size: 20px !important;
  }
}

.product-card-input-action-buttons {
  color: #0000007F !important;
}

@media (max-width: 767px) {
  .welcome-title {
    margin-top: 16px;
  }
}
@media (min-width: 769px) {
  .welcome-title {
    margin-top: 8px;
  }
}
@media (min-width: 1921px) {
  .welcome-title {
    margin-top: 8px;
  }
}

.shop-open-info-text {
  margin: 16px;
  margin-top: 32px;
  text-align: center;
  font-family: "Open Sans";
  color: #000000E5;
  letter-spacing: 0.05em;
  align-items: center;
  line-height: 32px;
  font-size: 16px;
}
@media (max-width: 767px) {
  .shop-open-info-text {
    font-size: 16px;
  }
}
@media (min-width: 769px) and (max-width: 1919px) {
  .shop-open-info-text {
    font-size: 16px;
    max-width: 60%;
  }
}
@media (min-width: 1920px) {
  .shop-open-info-text {
    font-size: 24px;
    max-width: 50%;
  }
}

.congrats-on-shop-open {
  text-align: center;
  margin-top: 32px;
  font-family: "Open Sans";
  font-weight: 900;
  align-items: center;
  color: #000000;
  font-size: 24px;
}
@media (max-width: 767px) {
  .congrats-on-shop-open {
    font-size: 20px;
  }
}
@media (min-width: 769px) and (max-width: 1919px) {
  .congrats-on-shop-open {
    font-size: 20px;
  }
}

.footerBarBGArt {
  position: fixed;
  width: 200px;
  bottom: 0;
  right: 0;
}
.footerBarBGArt svg {
  display: block;
  height: 100%;
  width: 100%;
}
.footerBarBGArt svg path {
  fill: #032349;
}

.dynamic-background-next-button-breathe svg path {
  transition: 2s ease-in-out !important;
  d: path("m0 18.52c7.9381 0.058507 28.179-18.343 37.039-18.521l0.0025 18.515z");
}

.dynamic-background-next-button-rest svg path {
  transition: 2s ease-in-out !important;
  d: path("m0 18.52c18.503-6.3604 19.186-18.528 37.039-18.521l0.0025 18.515z");
}

.card-with-image {
  cursor: pointer !important;
  margin: 16px 0px 16px 0px !important;
  padding: 0px !important;
  background-color: transparent !important;
  border-radius: 16px !important;
  box-shadow: none !important;
  filter: drop-shadow(5px 5px 4px rgba(0, 0, 0, 0.25)) !important;
}

.matCardClipperContainer {
  padding: 0px;
  border-radius: 16px;
  mask-image: url("https://inoutstorageqa.blob.core.windows.net/inoutcontent/assets/Onboard/masks/card-mask.png");
  mask-size: 100% auto;
  mask-repeat: no-repeat;
  mask-position: top;
}

.matCardContainer {
  margin-top: 0px;
  margin-bottom: 0px;
  padding-top: 0px;
}

.card-title-container {
  display: flex;
  flex-direction: row;
  width: 100%;
}
.card-title-container .toggle-button-container {
  align-self: center;
  width: unset;
}
.card-title-container .card-title {
  flex: 1;
}

.mat-card-actions, .mat-card-content, .mat-card-subtitle {
  margin-bottom: 0px !important;
}

.card-with-image-input-text {
  color: #000000 !important;
}

mat-card-content {
  padding: 16px !important;
}

.card-bottom-row {
  display: flex;
  justify-content: center;
  align-items: center;
}

.card-bottom-row-middle {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
}

.card-bottom-row-left {
  display: flex;
  flex: 1;
  align-items: center;
}

.card-bottom-row-right {
  display: flex;
  flex: 1;
  justify-content: flex-end;
}

.down-button-chavron path {
  fill: white;
}

mat-slide-toggle .mat-slide-toggle-bar {
  background: #00000033;
}

.card-image {
  margin-left: -16px;
  margin-bottom: 0px;
}

.card-bottom-row-left > h2 {
  margin-top: auto;
  margin-bottom: auto;
}

.card-extended-div-collapsed {
  display: none;
  height: 0px;
}

.card-extended-div-extended {
  display: block;
  height: auto;
}

.rotate0 {
  animation: rotate0 0.3s linear forwards;
}

@keyframes rotate0 {
  0% {
    transform: rotateZ(180deg);
  }
  100% {
    transform: rotateZ(0deg);
  }
}
.rotate180 {
  animation: rotate180 0.3s linear forwards;
}

@keyframes rotate180 {
  0% {
    transform: rotateZ(0deg);
  }
  100% {
    transform: rotateZ(180deg);
  }
}
.product-card-exapanded-fixed {
  opacity: 1;
  height: calc(100% - 16px - 2px);
}

.slide-out-bottom {
  transition: opacity, height, transform 0.2s cubic-bezier(0, 0, 0.2, 1);
  opacity: 1;
  height: calc(100% - 16px - 2px);
}

.slide-in-bottom {
  transition: opacity, height, transform 0.2s cubic-bezier(0, 0, 0.2, 1);
  opacity: 0;
  height: 0;
}

.light-travel {
  transition: 0.5s !important;
  transform: translateX(-200px) !important;
  opacity: 0.2 !important;
}

.light-travel-backwards {
  transition: 0.5s !important;
  transform: translateX(200px) !important;
  opacity: 0 !important;
}

.card-z-push {
  transition: 0.5s !important;
  transform: scale(1) translate(2px, 2px) !important;
  filter: drop-shadow(0px 0px 0px rgba(0, 0, 0, 0.25)) !important;
}

.card-z-push-back {
  transition: 0.5s !important;
  transform: scale(1) translate(0px, 0px) !important;
  filter: drop-shadow(5px 5px 4px rgba(0, 0, 0, 0.25)) !important;
}

.container-congrats {
  padding: 16px;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.shopSVGContainer {
  margin: 16px;
  align-items: center;
  text-align: center;
}

@media (max-width: 600px) {
  .shopSVGContainer img {
    margin-top: calc(8px * 15);
    width: calc(100% - 64px);
  }
}
@media (min-width: 601px) and (max-width: 1919px) {
  .shopSVGContainer img {
    margin-top: calc(8px * 8);
    width: 30vw;
  }
}
@media (min-width: 1920px) {
  .shopSVGContainer img {
    margin-top: calc(8px * 8);
    width: 40vw;
  }
}

.shopwithanim {
  width: calc(100% - 64px);
}

.canvas-congrats {
  position: absolute;
  left: 0px;
  top: 0px;
  z-index: 100;
  height: 100%;
  /* we need these for full-screen effect */
  width: 100%;
}

.step-payment-info-text {
  padding: 16px;
}

.step-payment-form-container {
  padding: 16px;
}

.step-payment-field-container {
  padding: 16px;
  padding-left: 0px;
  color: #000000;
}

.step-payment-button-container {
  padding: 16px;
  padding-left: 0px;
}

.step-payment-i-accept-span {
  padding-left: 8px;
}

mat-list {
  padding-bottom: 64px;
}

.welcomeText {
  color: #ffffff;
  min-height: 3.5em;
  flex: 1;
  font-size: xx-large;
  font-family: Arial;
  font-weight: 900;
  text-align: center;
  margin: 16px;
  margin-top: 64px;
}

.store-creating-progress-illustration {
  max-width: 30vw;
}
@media (max-width: 600px) {
  .store-creating-progress-illustration {
    max-width: 90vw;
  }
}

.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.8) !important;
}

.disappearAppear {
  animation: spin 2s linear;
}

@keyframes spin {
  0% {
    color: #ffffff;
  }
  50% {
    color: transparent;
  }
  100% {
    color: #ffffff;
  }
}
.jello-horizontal {
  -webkit-animation: jello-horizontal 2.5s infinite;
  animation: jello-horizontal 2.5s infinite;
}

@-webkit-keyframes jello-horizontal {
  0% {
    -webkit-transform: scale3d(1, 1, 1) translateX(-30vw);
    transform: scale3d(1, 1, 1) translateX(-30vw);
  }
  30% {
    -webkit-transform: scale3d(1.04, 0.9, 1) translateX(0px);
    transform: scale3d(1.04, 0.9, 1) translateX(0px);
    opacity: 1;
  }
  35% {
    -webkit-transform: scale3d(0.9, 1.04, 1);
    transform: scale3d(0.9, 1.04, 1);
  }
  40% {
    -webkit-transform: scale3d(1.05, 0.96, 1);
    transform: scale3d(1.05, 0.96, 1);
  }
  55% {
    -webkit-transform: scale3d(0.99, 1.05, 1);
    transform: scale3d(0.99, 1.05, 1);
  }
  65% {
    -webkit-transform: scale3d(1.01, 0.99, 1) translateX(0px);
    transform: scale3d(1.01, 0.99, 1) translateX(0px);
    opacity: 1;
  }
  85% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
    opacity: 0;
  }
  100% {
    -webkit-transform: scale3d(1, 1, 1) translateX(130vw);
    transform: scale3d(1, 1, 1) translateX(130vw);
    opacity: 0;
  }
}
@keyframes jello-horizontal {
  0% {
    -webkit-transform: scale3d(1, 1, 1) translateX(-30vw);
    transform: scale3d(1, 1, 1) translateX(-30vw);
  }
  20% {
    -webkit-transform: scale3d(1.04, 0.9, 1) translateX(0px);
    transform: scale3d(1.04, 0.9, 1) translateX(0px);
    opacity: 1;
  }
  30% {
    -webkit-transform: scale3d(0.9, 1.04, 1);
    transform: scale3d(0.9, 1.04, 1);
  }
  40% {
    -webkit-transform: scale3d(1.05, 0.96, 1);
    transform: scale3d(1.05, 0.96, 1);
  }
  55% {
    -webkit-transform: scale3d(0.99, 1.05, 1);
    transform: scale3d(0.99, 1.05, 1);
  }
  65% {
    -webkit-transform: scale3d(1.01, 0.99, 1) translateX(0px);
    transform: scale3d(1.01, 0.99, 1) translateX(0px);
    opacity: 1;
  }
  75% {
    -webkit-transform: scale3d(1, 1, 1) translateX(130vw);
    transform: scale3d(1, 1, 1) translateX(130vw);
    opacity: 0;
  }
  100% {
    -webkit-transform: scale3d(1, 1, 1) translateX(130vw);
    transform: scale3d(1, 1, 1) translateX(130vw);
    opacity: 0;
  }
}
.card-simple-payment {
  margin: 16px !important;
  padding: 0px !important;
  border-radius: 16px !important;
  box-shadow: none !important;
  background: #fafbfb;
  filter: drop-shadow(5px 5px 4px rgba(0, 0, 0, 0.25)) !important;
}

.thinScrollBars::-webkit-scrollbar-track {
  background-color: transparent;
}

.thinScrollBars::-webkit-scrollbar {
  height: 8px;
  background-color: transparent;
}

.thinScrollBars::-webkit-scrollbar-thumb {
  background-color: #0000001d;
}

.thinVertScrollBars::-webkit-scrollbar {
  width: 8px;
  background-color: red;
}

.thinVertScrollBars::-webkit-scrollbar-thumb {
  background-color: red;
}

.placeholder-form {
  min-width: 150px;
  max-width: 500px;
  width: 100%;
}

.placeholder-full-width {
  width: 100%;
}

.container-splash {
  padding: 16px;
  background-color: transparent;
  align-items: center;
  text-align: center;
  display: flex;
  flex-direction: column;
}

.image-hor-size {
  width: calc(100% - 64px);
}

.progress-container {
  width: 100%;
  background: transparent;
  height: 2px;
}

.progress {
  width: 24px;
  background-color: white;
  height: 2px;
}

.progress-catch-up {
  width: 24px;
  background-color: white;
  opacity: 0.3;
  height: 2px;
  box-shadow: 0 0 5px 10px #555;
  transform: translateX(-5px);
}

.progress-bar-base {
  width: calc(100% - 128px);
  background-color: #4d5257;
  height: 2px;
  margin-top: 16px;
  overflow: hidden;
}

.paxcom-logo-splash {
  margin-top: 124px;
  width: calc(100% - 128px);
}

.paxcom-copyright {
  padding: 16px;
  position: fixed;
  bottom: 0;
  text-align: center;
  width: calc(100% - 16px);
  color: white;
  opacity: 0.3;
}

.indeterminent-progress {
  animation: indeterminent-progress 3s infinite both;
}

@keyframes indeterminent-progress {
  0% {
    transform: translateX(-10%);
  }
  50% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-10%);
  }
}
.header-wizard-steps-container {
  display: flex;
  flex-direction: column;
  padding: 16px;
}
.header-wizard-steps-container .header-wizard-step-title {
  text-align: center;
  font-size: 14px;
  color: #0000007F;
}
.header-wizard-steps-container .header-wizard-steps {
  display: flex;
  align-self: center;
  flex-direction: row;
}

.side-wizard-steps-container {
  width: 100%;
  height: 100%;
}
.side-wizard-steps-container .side-wizard-steps {
  display: flex;
  align-self: center;
  flex-direction: column;
  margin-right: 16px;
}
.side-wizard-steps-container .side-wizard-steps .side-wizard-step {
  display: flex;
  row-gap: 16px;
  padding-bottom: 8px;
  padding-top: 8px;
}
.side-wizard-steps-container .side-wizard-steps .side-wizard-step.selected {
  background-color: white;
  margin-right: -16px;
  padding-right: 16px;
}
.side-wizard-steps-container .side-wizard-steps .side-wizard-step.selected .cover {
  opacity: 1;
}
.side-wizard-steps-container .side-wizard-steps .side-wizard-step .side-wizard-step-left-section {
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: flex-end;
  padding-right: 16px;
  width: 100%;
}

.flex-container .illustration,
.flex-container-single-col .illustration {
  flex: 1;
  text-align: center;
  height: 100%;
}

.flex-container .welcome,
.flex-container-single-col .welcome {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
.flex-container .welcome .welcome-content,
.flex-container-single-col .welcome .welcome-content {
  display: flex;
  flex-direction: column;
}
.flex-container .welcome .welcome-content .welcome-title,
.flex-container-single-col .welcome .welcome-content .welcome-title {
  flex: 1;
  text-align: center;
}
@media (min-width: 600px) {
  .flex-container .welcome .welcome-content .welcome-title .title-container,
.flex-container-single-col .welcome .welcome-content .welcome-title .title-container {
    padding: 16px !important;
  }
}
@media (max-height: 640px) {
  .flex-container .welcome .welcome-content .welcome-title .title-container .title-xxx-large,
.flex-container-single-col .welcome .welcome-content .welcome-title .title-container .title-xxx-large {
    font-size: 32px !important;
  }
}
@media (max-height: 731px) and (min-height: 641px) {
  .flex-container .welcome .welcome-content .welcome-title .title-container .title-xxx-large,
.flex-container-single-col .welcome .welcome-content .welcome-title .title-container .title-xxx-large {
    font-size: 48px !important;
  }
}
@media (max-height: 823px) and (min-height: 732px) {
  .flex-container .welcome .welcome-content .welcome-title .title-container .title-xxx-large,
.flex-container-single-col .welcome .welcome-content .welcome-title .title-container .title-xxx-large {
    font-size: 48px !important;
  }
}
.flex-container .welcome .welcome-content .welcome-text,
.flex-container-single-col .welcome .welcome-content .welcome-text {
  flex: 1;
  text-align: center;
  margin-top: 16px;
}
@media (min-width: 769px) and (max-width: 1920px) {
  .flex-container .welcome .welcome-content .welcome-text .body-container,
.flex-container-single-col .welcome .welcome-content .welcome-text .body-container {
    width: 100vh !important;
  }
}
@media (min-width: 1920px) {
  .flex-container .welcome .welcome-content .welcome-text .body-container,
.flex-container-single-col .welcome .welcome-content .welcome-text .body-container {
    width: 100vh !important;
  }
}
.flex-container .welcome .spacer,
.flex-container-single-col .welcome .spacer {
  flex: 1;
}
.flex-container .welcome .welcome-footer-container,
.flex-container-single-col .welcome .welcome-footer-container {
  margin-bottom: 32px;
}

.wizard-toolbar-container {
  display: flex;
  flex: 1;
  flex-direction: row;
  width: calc(100% + 16px);
}
.wizard-toolbar-container .wizard-toolbar-left {
  width: calc(33% - 16px);
}
.wizard-toolbar-container .wizard-toolbar-left .wizard-toolbar-logo-container {
  text-align: end;
  padding-right: 32px;
}
.wizard-toolbar-container .wizard-toolbar-left .wizard-toolbar-logo-container img {
  margin-top: 0px;
}
.wizard-toolbar-container .wizard-toolbar-right {
  display: flex;
  flex: 1;
  margin-left: -30px;
  justify-content: center;
}
.wizard-toolbar-container .wizard-toolbar-right .wizard-toolbar-right-text-container {
  align-self: center;
}

.product-card {
  margin: 16px 0px 16px 0px !important;
  padding: 0px !important;
  background-color: transparent !important;
  border-radius: 16px !important;
  box-shadow: none !important;
  filter: drop-shadow(5px 5px 4px rgba(0, 0, 0, 0.25)) !important;
  cursor: pointer !important;
}

.matCardClipperContainer {
  padding: 0px;
  border-radius: 16px;
  mask-image: url("https://inoutstorageqa.blob.core.windows.net/inoutcontent/assets/Onboard/masks/card-mask.png");
  mask-size: cover;
  mask-repeat: no-repeat;
  mask-position: top;
}

.matCardContainer {
  margin-top: 0px;
  margin-bottom: 0px;
  padding-top: 0px;
}

.mat-card-image {
  width: 100% !important;
  margin: 0px !important;
}
.mat-card-image :first-child {
  margin-top: 0px !important;
}

.product-card-title-container {
  width: 100%;
  margin-top: 8px;
}

mat-card-content {
  padding: 16px;
  padding-top: 8px;
  padding-bottom: 8px;
}

.card-bottom-row {
  display: flex;
  justify-content: center;
  align-items: center;
}

.card-bottom-row-middle {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
}

.card-bottom-row-left {
  display: flex;
  flex: 1;
  align-items: center;
}

.card-bottom-row-right {
  display: flex;
  flex: 1;
  justify-content: flex-end;
}

.product-image-container {
  width: 100%;
  background-color: white;
  padding-top: 12%;
}

.card-image {
  object-fit: contain;
  width: 100%;
  margin: 0px;
}

mat-card-content > .card-title {
  margin-bottom: 8px;
}

.card-bottom-row-left > .product-card-price-title {
  margin-top: auto;
  margin-bottom: auto;
}

.card-extended-div-collapsed {
  display: none;
  height: 0px;
}

.card-extended-div-extended {
  display: block;
  height: auto;
}

.product-card-input-text {
  color: #000000 !important;
}

.rotate0 {
  transition: 0.3s;
  transform: rotateZ(0deg);
}

.rotate180 {
  transition: 0.3s;
  transform: rotateZ(180deg);
}

@media screen and (min-device-width: 601px) and (max-device-width: 768px) {
  .side-wizard-step-left-section .side-wizard-step-left-section-title {
    font-size: 18px !important;
  }

  .side-wizard-step-left-section .side-wizard-step-left-section-description {
    width: 90% !important;
  }
}
@media screen and (min-device-width: 769px) and (max-device-width: 1366px) {
  .side-wizard-step-left-section .side-wizard-step-left-section-title {
    font-size: 18px !important;
  }

  .side-wizard-step-left-section .side-wizard-step-left-section-description {
    width: 90% !important;
  }
}
.category-image {
  object-fit: cover;
  width: 100%;
  height: 100%;
  margin: 0px;
  border-radius: 50%;
}

.cat-button-z-push {
  transition: 0.5s;
  transform: scale(0.99) translate(2px, 2px);
  filter: drop-shadow(0px 0px 0px rgba(0, 0, 0, 0.25)) !important;
}

.cat-button-z-push-back {
  transition: 0.5s;
  transform: scale(1) translate(0px, 0px);
  filter: drop-shadow(3px 3px 6px rgba(0, 0, 0, 0.25)) !important;
}

.light-travel {
  transition: 0.5s;
  transform: translateX(-200px);
  opacity: 1;
}

.light-travel-backwards {
  transition: 0.5s;
  transform: translateX(200px);
  opacity: 0;
}

.pax-button-purple-light-shiny-raised {
  font-family: "Open Sans" !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  background: #032349;
  color: #ffffff;
  opacity: 0.8;
  border-radius: 5px !important;
  box-shadow: 2px 2px 6px 2px #00000019 !important;
  border-color: #ffffff66 !important;
  border-width: 1px !important;
}

.pax-raised {
  box-shadow: 2px 2px 6px 2px #00000019 !important;
  border-color: #ffffff66 !important;
  border-width: 1px !important;
}

.button-icon-svg-image {
  height: 25px;
  width: 25px;
  opacity: 0.5;
}

.input-text-light {
  caret-color: #00000033;
  color: #00000033 !important;
  opacity: 0.8;
}

mat-icon.light {
  color: #00000033 !important;
  opacity: 0.8;
}

mat-form-field mat-label.light {
  color: #00000033;
  opacity: 0.7;
}

.textBoxIncrementDecrementButtons {
  color: #000000;
  font-size: xx-large !important;
  font-weight: 100 !important;
  opacity: 0.8 !important;
}

.x-wrap-cards-in-list {
  flex: 1;
  display: flex;
  justify-content: center;
}
@media (min-width: 769px) and (min-width: 769px) and (max-width: 1919px) {
  .x-wrap-cards-in-list mat-list {
    width: calc((24vw * 2) + (8px * 10));
  }
  .x-wrap-cards-in-list mat-list app-card-with-image,
.x-wrap-cards-in-list mat-list app-product-card {
    margin-right: 16px;
    margin-left: 16px;
    float: left;
  }
}
@media (min-width: 769px) and (min-width: 1920px) {
  .x-wrap-cards-in-list mat-list {
    width: calc((24vw * 2) + (8px * 20));
  }
  .x-wrap-cards-in-list mat-list app-card-with-image,
.x-wrap-cards-in-list mat-list app-product-card {
    margin-right: 16px;
    margin-left: 16px;
    float: left;
  }
}

@media (min-width: 769px) and (max-width: 1919px) {
  .card-with-image {
    width: 23vw;
    margin-right: 16px !important;
    margin-left: 16px !important;
    float: left !important;
  }
}
@media (min-width: 1920px) {
  .card-with-image {
    width: 500px;
    margin-right: 16px !important;
    margin-left: 16px !important;
    float: left !important;
  }
}

.card-background-selected {
  transition: 0.5s;
  background-color: #8ac8fe;
}

.card-background-unselected {
  transition: 0.5s;
  background-color: #f7f8f9;
}

.product-card img {
  max-height: 150px;
}
.product-card product-image-container {
  max-height: 150px;
}
@media (min-width: 769px) and (max-width: 1920px) {
  .product-card {
    width: 23vw;
  }
  .product-card img {
    max-height: 150px;
  }
  .product-card .product-image-container {
    max-height: 150px;
  }
}
@media (min-width: 1920px) {
  .product-card {
    width: 500px;
  }
  .product-card img {
    max-height: 300px;
  }
  .product-card .product-image-container {
    max-height: 300px;
  }
}

@media (min-width: 769px) and (max-width: 1920px) {
  .product-card-two-column-list {
    margin-right: 16px !important;
    margin-left: 16px !important;
    float: left !important;
  }
}
@media (min-width: 1920px) {
  .product-card-two-column-list {
    margin-right: 16px !important;
    margin-left: 16px !important;
    float: left !important;
  }
}

.mind-toolbar-and-search-header {
  padding-top: 164px !important;
}

@media (max-width: 600px) {
  .mind-toolbar-and-search-and-category-header {
    padding-top: 272px !important;
  }
}
@media (min-width: 601px) {
  .mind-toolbar-and-search-and-category-header {
    padding-top: 284px !important;
  }
}

.mind-toolbar {
  padding-top: calc(64px + 8px) !important;
}

.mind-footer {
  padding-bottom: calc(64px + 8px) !important;
}

.wizard-toolbar-logo-container .tool-bar-paxcon-logo-dark {
  display: block;
}
.wizard-toolbar-logo-container .tool-bar-paxcon-logo-light {
  display: none;
}

.pax-toolbar {
  position: absolute;
  z-index: 100;
  background: #e5e6e9;
}
@media (max-width: 600px) {
  .pax-toolbar {
    background: transparent;
  }
  .pax-toolbar .pax-toolbar-phone {
    margin: auto;
    display: unset;
  }
  .pax-toolbar .pax-toolbar-desktop {
    width: 100%;
    display: none;
  }
}
@media (min-width: 601px) {
  .pax-toolbar {
    box-shadow: 2px 2px 6px 2px #0000007F !important;
  }
  .pax-toolbar .pax-toolbar-phone {
    margin: auto;
    display: none;
  }
  .pax-toolbar .pax-toolbar-desktop {
    width: 100%;
    display: unset;
  }
}

.pax-sidenav {
  padding-top: calc(64px + 8px) !important;
  width: 33%;
  background: #f2f3f5;
}
@media (max-width: 600px) {
  .pax-sidenav {
    display: none !important;
  }
}
@media (min-width: 601px) {
  .pax-sidenav {
    display: unset !important;
  }
}

.pax-sidenav-container {
  background: transparent;
  width: 100%;
  height: 100%;
}

.pax-sidenav-content {
  background: transparent;
  height: 100%;
}
.pax-sidenav-content-header-product-cateogory-image-container {
  flex-shrink: 0;
  height: 80px;
  width: 80px;
  border-width: 3px;
  border-radius: 50%;
  border-style: solid;
  background-color: transparent;
  border-color: transparent;
  margin-left: auto;
  margin-right: auto;
}
.pax-sidenav-content-header-product-cateogory-image-container.selected {
  border-color: #032349;
  filter: drop-shadow(3px 3px 6px rgba(0, 0, 0, 0.25));
}

.pax-sidenav-content-header-product-cateogory-image {
  flex-shrink: 0;
  height: 70px;
  width: 70px;
  border-radius: 50%;
  margin-top: 5px;
  margin-left: auto;
  margin-right: auto;
}
.pax-sidenav-content-header-product-cateogory-image img {
  filter: drop-shadow(3px 3px 6px rgba(0, 0, 0, 0.25));
}

.pax-sidenav-content-header-product-cateogory-name {
  font-size: 14px;
  text-align: center;
  padding-top: 4px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.pax-sidenav-content-header-product-cateogory-name.selected {
  color: #032349;
}

.pax-sidenav-content-header-product-cateogory {
  flex-shrink: 0;
  height: 112px;
  width: 88px;
  margin: 0px;
  margin-left: 10px;
}

.pax-sidenav-content-side-product-category-container {
  height: 100%;
}

.pax-sidenav-content-header-product-category-container {
  height: 100%;
  width: 100%;
  overflow-x: auto;
  overflow-x: auto;
  background: #f7f7f7;
  padding: 0px 0px 5px 0px;
  border-bottom: 1px solid #efefef;
  border-top: 1px solid #efefef;
  margin: 15px 0px 0px -2px !important;
}
@media (min-width: 601px) {
  .pax-sidenav-content-header-product-category-container {
    width: 66%;
  }
}

.pax-sidenav-content-header-product-category-scrollable-container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}

.pax-sidenav-content-header-products {
  transition: top 0.3s;
  width: 100%;
  position: fixed;
  left: 0;
  top: 0;
  background-color: #fcfdfd;
  z-index: 100;
}
@media (max-width: 600px) {
  .pax-sidenav-content-header-products {
    height: 272px;
  }
}
@media (min-width: 601px) {
  .pax-sidenav-content-header-products {
    height: 284px;
  }
}
.pax-sidenav-content-header-products .contents {
  display: flex;
  flex-direction: column;
}
@media (max-width: 600px) {
  .pax-sidenav-content-header-products .contents {
    margin-left: 16px;
    width: calc(100% - 32px);
    max-width: 400px;
    padding-top: 80px;
  }
}
@media (min-width: 601px) {
  .pax-sidenav-content-header-products .contents {
    margin-left: calc(33% + 40px);
    width: calc(100% - 32px);
    padding-top: 84px;
  }
}
.pax-sidenav-content-header-products .contents .row-1-container {
  flex: 1;
  display: flex;
}
@media (min-width: 601px) {
  .pax-sidenav-content-header-products .contents .row-1-container {
    justify-content: center;
    width: calc(66% - 40px);
  }
}
.pax-sidenav-content-header-products .contents .row-1-container .row-1 {
  display: flex;
  flex-direction: row;
}
@media (max-width: 600px) {
  .pax-sidenav-content-header-products .contents .row-1-container .row-1 {
    width: 100%;
  }
}
.pax-sidenav-content-header-products .contents .row-1-container .row-1 .search-text-box {
  flex: 1;
}
@media (min-width: 601px) {
  .pax-sidenav-content-header-products .contents .row-1-container .row-1 .search-text-box {
    width: 30vw;
  }
}
.pax-sidenav-content-header-products .contents .row-1-container .row-1 .spacer {
  width: 16px;
}
.pax-sidenav-content-header-products .contents .row-1-container .row-1 .button-container {
  flex: 0;
  margin-top: 12px;
}
.pax-sidenav-content-header-products .contents .row-2 {
  flex: 1;
}
@media (max-width: 600px) {
  .pax-sidenav-content-header-products .contents .row-2 {
    margin-left: -16px;
    margin-right: -16px;
  }
}
@media (min-width: 601px) {
  .pax-sidenav-content-header-products .contents .row-2 {
    margin-left: -38px;
    margin-right: -16px;
  }
}

.pax-sidenav-content-header-categories {
  transition: top 0.3s;
  width: 100%;
  position: fixed;
  left: 0;
  top: 0;
  background-color: #fcfdfd;
  z-index: 100;
}
@media (max-width: 600px) {
  .pax-sidenav-content-header-categories {
    height: 160px;
  }
}
@media (min-width: 601px) {
  .pax-sidenav-content-header-categories {
    height: 164px;
  }
}
.pax-sidenav-content-header-categories .contents {
  display: flex;
  flex-direction: column;
}
@media (max-width: 600px) {
  .pax-sidenav-content-header-categories .contents {
    margin-left: 16px;
    width: calc(100% - 32px);
    max-width: 400px;
    padding-top: 80px;
  }
}
@media (min-width: 601px) {
  .pax-sidenav-content-header-categories .contents {
    margin-left: calc(33% + 40px);
    width: calc(100% - 32px);
    padding-top: 84px;
  }
}
.pax-sidenav-content-header-categories .contents .row-1-container {
  flex: 1;
  display: flex;
}
@media (min-width: 601px) {
  .pax-sidenav-content-header-categories .contents .row-1-container {
    justify-content: center;
    width: calc(66% - 40px);
  }
}
.pax-sidenav-content-header-categories .contents .row-1-container .row-1 {
  display: flex;
  flex-direction: row;
}
@media (max-width: 600px) {
  .pax-sidenav-content-header-categories .contents .row-1-container .row-1 {
    width: 100%;
  }
}
.pax-sidenav-content-header-categories .contents .row-1-container .row-1 .search-text-box {
  flex: 1;
}
@media (min-width: 601px) {
  .pax-sidenav-content-header-categories .contents .row-1-container .row-1 .search-text-box {
    width: 30vw;
  }
}
.pax-sidenav-content-header-categories .contents .row-1-container .row-1 .spacer {
  width: 16px;
}
.pax-sidenav-content-header-categories .contents .row-1-container .row-1 .button-container {
  flex: 0;
  margin-top: 12px;
}

.side-wizard-step-bullet {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  transition: background-color 1s;
}
.side-wizard-step-bullet.onboard-done {
  background-color: #032349;
}
.side-wizard-step-bullet.current {
  background-color: #032349;
}
.side-wizard-step-bullet.pending {
  background-color: #00000066;
}
@media (min-width: 769px) and (max-width: 1920px) {
  .side-wizard-step-bullet {
    width: 30px;
    height: 30px;
  }
}
@media (min-width: 1920px) {
  .side-wizard-step-bullet {
    width: 30px;
    height: 30px;
  }
}

.side-wizard-step-bullet-spacer {
  display: flex;
}
@media (min-width: 769px) and (max-width: 1920px) {
  .side-wizard-step-bullet-spacer {
    height: 16px;
  }
}
@media (min-width: 1920px) {
  .side-wizard-step-bullet-spacer {
    height: 20px;
  }
}

.side-wizard-step-left-section .side-wizard-step-left-section-title {
  text-align: right;
  font-family: "Open Sans" !important;
  font-weight: 500 !important;
  color: #000000 !important;
  letter-spacing: 0.05em !important;
}
@media (min-width: 769px) and (max-width: 1920px) {
  .side-wizard-step-left-section .side-wizard-step-left-section-title {
    font-size: 20px !important;
  }
}
@media (min-width: 1920px) {
  .side-wizard-step-left-section .side-wizard-step-left-section-title {
    font-size: 20px !important;
  }
}
.side-wizard-step-left-section .side-wizard-step-left-section-title.onboard-done {
  transition: 1s;
  color: #032349 !important;
}
.side-wizard-step-left-section .side-wizard-step-left-section-title.pending {
  transition: 1s;
  color: #00000066 !important;
}
.side-wizard-step-left-section .side-wizard-step-left-section-title.current {
  transition: 1s;
  color: #032349 !important;
}
.side-wizard-step-left-section .side-wizard-step-left-section-description {
  overflow: hidden;
  text-align: right;
  width: 60%;
  font-family: "Open Sans" !important;
  color: #00000066;
  padding-top: 8px;
}
@media (min-width: 769px) and (max-width: 1920px) {
  .side-wizard-step-left-section .side-wizard-step-left-section-description {
    font-size: 14px !important;
  }
}
@media (min-width: 1920px) {
  .side-wizard-step-left-section .side-wizard-step-left-section-description {
    font-size: 14px !important;
  }
}
.side-wizard-step-left-section .side-wizard-step-left-section-description.onboard-done {
  transition: 1s;
  color: rgba(3, 35, 73, 0.8) !important;
}
.side-wizard-step-left-section .side-wizard-step-left-section-description.pending {
  transition: 1s;
  color: rgba(0, 0, 0, 0.8) !important;
}
.side-wizard-step-left-section .side-wizard-step-left-section-description.current {
  transition: 1s;
  color: rgba(3, 35, 73, 0.8) !important;
}

.side-wizard-header-title {
  font-family: "Open Sans";
  font-weight: 500;
  color: #000000;
  letter-spacing: 0.05em;
}
@media (min-width: 769px) and (max-width: 1920px) {
  .side-wizard-header-title {
    height: 1em;
    font-size: 24px;
  }
}
@media (min-width: 1920px) {
  .side-wizard-header-title {
    height: 1em;
    font-size: 48px;
  }
}

.header-wizard-step {
  flex: 1;
  width: 15px;
  height: 15px;
  padding: 2px;
}

.header-wizard-step-bullet {
  border-radius: 50%;
  margin: 4px;
}
.header-wizard-step-bullet.onboard-done {
  transition: 1s;
  background-color: #032349 !important;
}
.header-wizard-step-bullet.current {
  transition: 1s;
  background-color: #032349 !important;
}
.header-wizard-step-bullet.pending {
  transition: 1s;
  background-color: #00000066 !important;
}
@media (max-width: 768px) {
  .header-wizard-step-bullet {
    width: 20px !important;
    height: 20px !important;
  }
}
@media (min-width: 769px) and (max-width: 1920px) {
  .header-wizard-step-bullet {
    width: 40px !important;
    height: 40px !important;
  }
}
@media (min-width: 1920px) {
  .header-wizard-step-bullet {
    width: 80px !important;
    height: 80px !important;
  }
}

.wizard-footer-back-next {
  z-index: 200;
  display: flex;
  flex-direction: row;
  height: 64px;
  width: calc(66% + 20px);
  position: fixed;
  bottom: 0;
}
@media (max-width: 599px) {
  .wizard-footer-back-next {
    width: calc(100% - 32px);
    padding-left: 16px;
    padding-right: 16px;
  }
}
@media (min-width: 769px) and (max-width: 1920px) {
  .wizard-footer-back-next {
    width: calc(66% + 20px);
  }
}
@media (min-width: 1920px) {
  .wizard-footer-back-next {
    width: calc(66% + 20px);
  }
}
.wizard-footer-back-next .back-button-container {
  padding-left: 16px;
  color: #eff7fe;
}
.wizard-footer-back-next .skip-button-container {
  padding-left: 16px;
  color: #eff7fe;
}
.wizard-footer-back-next .next-button-container {
  padding-right: 16px;
  color: #eff7fe;
}

.wizardStepsFooter {
  background-color: #f1f3f4;
  padding-top: 13px;
  display: flex;
  align-self: center;
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 200;
  height: 64px;
}

.mat-drawer-side {
  border-right: none;
}

.mat-drawer-content {
  overflow-x: hidden !important;
}

.side-wizard-step-bullet {
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
}

.box-shadow {
  box-shadow: 2px 2px 6px 2px #0000007f !important;
}

.primary-onboard-button {
  background: #032349 !important;
}

.secondary-onboard-button {
  color: #032349 !important;
  background: #fff !important;
  border: 1px solid #dadce0 !important;
  font-family: "poppin-medium", "Roboto", "Poppins", "sans-serif" !important;
  line-height: 1.25rem !important;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
}

.mr-27 {
  margin-right: 27px !important;
}

.ml-15 {
  margin-left: 15px !important;
}

.w-120 {
  width: 120% !important;
}

.side-border {
  border-right: 1px solid #ddd;
}

.app-screen {
  z-index: 1;
  display: flex;
  width: 100%;
  height: calc(100%);
  background-color: white;
}

.flex-container {
  display: flex;
  overflow-y: auto;
}
@media (max-width: 600px) {
  .flex-container {
    flex-direction: column;
    padding: 16px;
  }
}
@media (min-width: 601px) {
  .flex-container {
    width: 100%;
    flex-direction: row;
  }
}
@media (min-width: 769px) and (max-width: 1920px) {
  .flex-container {
    padding: 24px;
    width: calc(100% - (24px * 2));
  }
}
@media (min-width: 1920px) {
  .flex-container {
    width: 100%;
  }
}

.flex-container-single-col {
  display: flex;
  width: 100%;
}
@media (max-width: 600px) {
  .flex-container-single-col {
    flex-direction: column;
    padding: 16px;
    width: calc(100% - (16px * 2));
  }
}
@media (min-width: 601px) {
  .flex-container-single-col {
    flex-direction: column;
  }
}
@media (min-width: 601px) and (max-width: 1919px) {
  .flex-container-single-col {
    padding: 24px;
  }
  .flex-container-single-col .illustration img {
    max-width: 18vw;
  }
}
@media (min-width: 1920px) {
  .flex-container-single-col .illustration img {
    max-width: 30vw;
  }
}

@media (max-width: 600px) {
  .illustration img {
    width: 80%;
    height: unset;
    max-height: 25vh;
  }
}
@media (max-width: 600px) and (max-height: 570px) {
  .illustration img {
    max-height: 25vh;
  }
}
@media (max-width: 600px) and (max-height: 640px) and (min-height: 571px) {
  .illustration img {
    max-height: 30vh;
  }
}
@media (max-width: 600px) and (max-height: 731px) and (min-height: 641px) {
  .illustration img {
    max-height: 38vh;
  }
}
@media (max-width: 600px) and (max-height: 820px) and (min-height: 732px) {
  .illustration img {
    max-height: 60vh;
  }
}
@media (max-width: 600px) and (min-height: 821px) {
  .illustration img {
    max-height: 70vh;
  }
}
@media (max-width: 600px) {
  .illustration .green,
.illustration .blue,
.illustration .purple,
.illustration .paxcom-blue {
    display: none;
  }
}
@media (max-width: 600px) {
  .illustration .paxcom-blue {
    display: block;
  }
}
@media (min-width: 601px) {
  .green,
.blue,
.purple,
.paxcom-blue {
    display: none;
  }

  .paxcom-blue {
    display: block;
  }
}
.paxcom-logo.xlarge {
  text-align: center;
}
.paxcom-logo.xlarge.portrait img {
  width: 80vw;
}
@media (max-width: 600px) {
  .paxcom-logo.xlarge.portrait {
    padding-top: 5vh;
    display: block;
  }
}
@media (min-width: 601px) {
  .paxcom-logo.xlarge.portrait {
    display: none;
  }
}
.paxcom-logo.xlarge.landscape img {
  width: 300px;
}
@media (max-width: 600px) {
  .paxcom-logo.xlarge.landscape {
    display: none;
  }
}
@media (min-width: 601px) {
  .paxcom-logo.xlarge.landscape {
    padding: 16px;
    padding-top: 56px;
    display: block;
  }
}

.paxcom-logo.desktop.toolbar {
  padding: 16px;
}
.paxcom-logo.desktop.toolbar img {
  width: 12vw;
}

html,
body {
  height: 100%;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0;
  /* <-- Apparently some margin are still there even though it's hidden */
}

input[type=number] {
  -moz-appearance: textfield;
}

.history-background-unselected {
  background: transparent !important;
}

.bg-transparent {
  background: transparent !important;
}

.history-background-selected {
  background: #80808029 !important;
}

.fs-12 {
  font-size: 12px !important;
}

.fs-18 {
  font-size: 18px !important;
}

.text-dec-line-through {
  text-decoration: line-through !important;
}

.colorRed {
  color: red !important;
}

.colorBlack {
  color: black !important;
}

.colorGreen {
  color: green !important;
}

.colorBlue {
  color: blue !important;
}

.theme-secondary {
  color: #1A73E8 !important;
}

.ngxImageZoomContainer {
  position: relative;
  margin: auto;
  height: 100%;
  width: 100%;
  overflow: hidden;
  vertical-align: middle;
}
.ngxImageZoomContainer:hover {
  width: 100%;
}
.ngxImageZoomContainer img.ngxImageZoomThumbnail {
  width: auto;
  display: block;
  margin: auto;
}
.ngxImageZoomContainer .ngxImageZoomFullContainer {
  width: 100% !important;
}

.header-bg-underlying::after, .header-bg-underlying::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: inherit;
  top: 0;
  left: 0;
}
.header-bg-underlying::after {
  background: white;
  z-index: -1;
}

.total-display {
  float: right;
  margin-right: 26px;
  font-size: 13px !important;
  font-weight: bold;
}

.scrollbar-chiplist .mat-chip-list-wrapper {
  max-height: 80px;
  overflow-y: auto;
  overflow-x: hidden;
}

.display-total {
  padding: 5px 0px 5px 0px !important;
  text-align: left !important;
}

.admin-header .mdc-select--disabled.mdc-select--outlined .mdc-select__native-control,
.admin-header .mdc-select--disabled.mdc-select--outlined .mdc-select__selected-text,
.admin-header .mdc-select--disabled .mdc-select__native-control,
.admin-header .mdc-select--disabled .mdc-select__selected-text {
  color: rgba(0, 0, 0, 0.99) !important;
}
.admin-header .mat-input-element:disabled,
.admin-header .mdc-text-field--disabled .mdc-text-field__input {
  color: rgba(0, 0, 0, 0.89) !important;
}
.admin-header .mdc-select--disabled, .admin-header .mdc-text-field--disabled,
.admin-header .mat-input-element:disabled,
.admin-header .mat-form-field-disabled .mat-form-field-wrapper {
  background-color: #fafafa !important;
}

.word-wrap {
  word-wrap: break-word;
}

.break-word {
  word-break: break-all;
}

.fontwt {
  font-weight: bold;
}

.mobile-filter-hide-bottom-bar {
  width: 100% !important;
  border-radius: 10px 10px 0px 0px !important;
  position: fixed !important;
  height: 60vh !important;
  overflow-y: scroll !important;
  bottom: -60vh !important;
  transition: 0.5s !important;
}

.mobile-filter-show-bottom-bar {
  bottom: -1px !important;
  transition: 0.5s !important;
}

.mobile-filter-expand-arrow {
  margin-top: 10px;
  position: absolute;
  float: right;
  right: 7px;
  font-size: 21px;
  color: black;
  cursor: pointer;
}

.shop-loading-screen {
  width: 100%;
  height: auto;
  margin: 0px auto;
  padding: 0px;
  text-align: center;
  font-family: "Poppins", sans-serif;
}

.shop-load-progress {
  background: white;
  justify-content: flex-start;
  border-radius: 100px;
  align-items: center;
  position: relative;
  padding: 0 4px;
  display: flex;
  height: 10px;
  width: 150px;
  border: 2px solid #696969;
}

.shop-load-progress-value {
  animation: load 5s alternate forwards;
  box-shadow: 0 10px 40px -10px #fff;
  border-radius: 100px;
  background: #808080;
  height: 8px;
  width: 0;
}

.shop-loading-screen-center {
  text-align: center;
  margin: 20px auto !important;
  display: inline-block;
}

.shop-loading-screen-center p {
  margin-bottom: 0px !important;
}

.shop-wrappper {
  position: relative;
}

.shop-wrapper-container {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.shop-pre-loader-hide {
  animation: hideLoader 2s forwards;
  display: none;
}

.shop-pre-loader {
  line-height: 100vh;
  text-align: center;
  z-index: 99999;
}

.shop-show-main-content {
  animation: showMainContent 3s forwards;
}

@keyframes load {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}
@keyframes hideLoader {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes showMainContent {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.dyanmic-product-list {
  float: left !important;
  width: 100% !important;
}

.desktop-skeleton {
  float: left !important;
  width: 100% !important;
}

.vertical-align-top {
  vertical-align: top !important;
}

.visibility-hidden {
  visibility: hidden !important;
}

.visibility-visible {
  visibility: visible !important;
}

.display-grid {
  display: grid !important;
}

.border-none {
  border: none;
}

.border-top {
  border-top: 1px solid #80808085;
}

.border-bottom {
  border-bottom: 1px solid #80808085;
}

.border-right {
  border-right: 1px solid #80808085;
}

.border-left {
  border-left: 1px solid #80808085;
}

.addressFormAlignmentClass .form-group {
  margin-bottom: 25px;
}

.addressFormAlignmentClass .form-group .mdc-text-field-helper-line {
  position: absolute;
}

.addressFormAlignmentClass .form-group .error-message {
  margin-left: 0% !important;
}

.addressTextFieldPositionClass {
  position: relative;
  width: 100%;
  margin-left: 0px !important;
  line-height: 1;
}

.noUnderline {
  text-decoration: unset;
  color: inherit;
  display: contents;
}

.underline {
  text-decoration: underline;
}

.sticky {
  top: 0;
  position: fixed;
  z-index: 3;
}

.pim-setting {
  position: sticky;
  top: 0;
  z-index: 2;
}

.product-name-break {
  overflow: visible !important;
  line-height: initial !important;
  word-wrap: break-word !important;
  white-space: pre-wrap !important;
  height: 60px !important;
  padding: 0 10px !important;
  font-size: 15px !important;
}

body {
  font-family: "poppin-regular", "Roboto", "Poppins", "sans-serif" !important;
  background-color: #fff;
  font-weight: 500 !important;
  letter-spacing: 0.25px !important;
}

.mdc-text-field--with-leading-icon.mdc-text-field--outlined .mdc-floating-label {
  font-weight: 500 !important;
}

.mdc-text-field:not(.mdc-text-field--disabled) .mdc-floating-label {
  font-weight: 500 !important;
}

.mdc-floating-label {
  font-weight: 500 !important;
  font-family: "poppin-regular", "Roboto", "Poppins", "sans-serif" !important;
}

.mdc-drawer .mdc-list-item {
  font-size: 1.01rem !important;
  letter-spacing: 0.25px !important;
}

.mat-table {
  font-family: "poppin-regular", "Roboto", "Poppins", "sans-serif" !important;
}

.mdc-drawer .mdc-list-item {
  font-family: "poppin-regular", "Roboto", "Poppins", "sans-serif" !important;
}

.mdc-button {
  border-radius: 4px !important;
  font-family: "poppin-regular", "Roboto", "Poppins", "sans-serif" !important;
  text-transform: none !important;
  height: 36px;
  font-size: 1.01rem !important;
  letter-spacing: 0.25px !important;
}

.mdc-text-field__input {
  font-family: "poppin-regular", "Roboto", "Poppins", "sans-serif" !important;
}

.mat-tab-label, .mat-tab-link {
  font-family: "poppin-medium", "Roboto", "Poppins", "sans-serif" !important;
}

.mat-option {
  font-family: "poppin-regular", "Roboto", "Poppins", "sans-serif" !important;
  font-size: 14px !important;
}

.mat-tab-group {
  font-family: "poppin-regular", "Roboto", "Poppins", "sans-serif" !important;
}

.mdc-select__native-control, .mdc-select__selected-text {
  font-family: "poppin-regular", "Roboto", "Poppins", "sans-serif" !important;
}

.mdc-tab {
  font-family: "poppin-medium", "Roboto", "Poppins", "sans-serif" !important;
}

.mdc-form-field {
  font-family: "poppin-regular", "Roboto", "Poppins", "sans-serif" !important;
}

.mat-button, .mat-fab, .mat-flat-button, .mat-icon-button, .mat-mini-fab, .mat-raised-button, .mat-stroked-button {
  font-family: "poppin-regular", "Roboto", "Poppins", "sans-serif" !important;
}

h1, h2, h3, h4, h5, h6 {
  font-family: "poppin-regular", "Roboto", "Poppins", "sans-serif" !important;
}

.mat-button-toggle-button {
  font-family: "poppin-regular", "Roboto", "Poppins", "sans-serif" !important;
}

.mdc-typography--headline1 {
  font-family: "poppin-regular", "Roboto", "Poppins", "sans-serif" !important;
}

.mdc-typography--headline2 {
  font-family: "poppin-regular", "Roboto", "Poppins", "sans-serif" !important;
}

.mdc-typography--headline3 {
  font-family: "poppin-regular", "Roboto", "Poppins", "sans-serif" !important;
}

.mdc-typography--headline4 {
  font-family: "poppin-regular", "Roboto", "Poppins", "sans-serif" !important;
}

.mdc-typography--headline5 {
  font-family: "poppin-regular", "Roboto", "Poppins", "sans-serif" !important;
}

.mdc-typography--headline6 {
  font-family: "poppin-medium", "Roboto", "Poppins", "sans-serif" !important;
}

.mat-input-element {
  font-family: "poppin-regular", "Roboto", "Poppins", "sans-serif" !important;
}

.mat-dialog-title {
  font-family: "poppin-regular", "Roboto", "Poppins", "sans-serif" !important;
}

.mdc-top-app-bar__row {
  font-family: "poppin-regular", "Roboto", "Poppins", "sans-serif" !important;
}

button {
  font-family: "poppin-regular", "Roboto", "Poppins", "sans-serif" !important;
}

p {
  font-family: "poppin-regular", "Roboto", "Poppins", "sans-serif" !important;
}

a {
  font-family: "poppin-regular", "Roboto", "Poppins", "sans-serif" !important;
}

.mat-slide-toggle-content {
  font-family: "poppin-regular", "Roboto", "Poppins", "sans-serif" !important;
}

.mat-header-cell {
  color: black !important;
  font-size: 14px !important;
  font-family: "poppin-medium", "Roboto", "Poppins", "sans-serif" !important;
}

.primary-button {
  color: #fff !important;
  background: #1A73E8 !important;
  font-size: 1.01rem !important;
  letter-spacing: 0.25px !important;
  line-height: 1.25rem !important;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
}

.secondary-button {
  color: #1A73E8 !important;
  background: #fff !important;
  border: 1px solid #dadce0 !important;
  font-family: "poppin-medium", "Roboto", "Poppins", "sans-serif" !important;
  line-height: 1.25rem !important;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
}

.tertiary-button {
  color: #5f6368 !important;
  background: #fff !important;
  border: 1px solid #dadce0 !important;
  font-family: "poppin-medium", "Roboto", "Poppins", "sans-serif" !important;
  line-height: 1.25rem !important;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
}

.ngx-mdc-button--secondary {
  text-transform: none !important;
}

.display-space-between {
  display: flex !important;
  justify-content: space-between !important;
}

.display-space-around {
  display: flex !important;
  justify-content: space-around !important;
}

.grid-padding {
  padding: 0px 24px 0px 24px !important;
}

.grid-padding-left-right {
  padding: 24px 0px 24px 0px !important;
}

.mdc-drawer .mdc-list-item--activated {
  background: #d2e3fc;
  border-radius: 0px;
  padding: 10px 15px;
  height: auto;
  color: #1A73E8;
  font-family: "poppin-medium", "Roboto", "Poppins", "sans-serif" !important;
  border-right: 3px solid !important;
}

.mdc-drawer .mdc-list-item:hover {
  border-right: 3px solid !important;
  color: #1A73E8;
}

.selected-control {
  color: #1A73E8 !important;
  background: #d2e3fc !important;
}

.mat-menu-content {
  padding: 8px !important;
}

.mdc-card {
  box-shadow: 0px 2px 1px 0px rgba(0, 0, 0, 0.2), 0px 1px 6px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12) !important;
}

:not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item--activated::before, :not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item--activated::after {
  background-color: #fff !important;
}

.mdc-drawer.mdc-drawer--open:not(.mdc-drawer--closing) + .mdc-drawer-app-content {
  background: #80808012 !important;
}

.line-height-0 {
  line-height: 0px !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.header-search-field {
  display: block;
  width: -webkit-fill-available !important;
  padding: 0.85rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #4a4a4a;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 5px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  height: 20px;
}

.gap-10 {
  gap: 10px !important;
}

.gap-20 {
  gap: 20px !important;
}

.mat-tab-header {
  background: #fff !important;
}

.header-search-btn {
  width: 45px;
  height: 35px;
  padding: 10px;
  background: #fff;
  font-size: 1.65rem;
  border: none;
  cursor: pointer;
  position: absolute;
  right: 6px;
  top: 1px;
  color: rgba(0, 0, 0, 0.4);
}

.header-search-field-box {
  display: block;
  width: -webkit-fill-available !important;
  padding: 0 0.85rem;
  position: relative;
}

.header-search-field-box input {
  display: block;
  width: 100%;
  padding: 0.85rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #4a4a4a;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 5px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  height: 20px;
}

.header-search-field-box .search-btn {
  width: 45px;
  height: 35px;
  padding: 10px;
  background: #fff;
  font-size: 1.65rem;
  border: none;
  cursor: pointer;
  position: absolute;
  right: -12px;
  top: 1px;
  color: rgba(0, 0, 0, 0.4);
}

.header-advnace-search-container {
  background: #F8F8F9;
  width: -webkit-fill-available !important;
  position: relative;
}

.mat-tab-header {
  background-color: white !important;
  border-bottom: 0px !important;
}

.list-header-padding {
  padding: 7px 15px !important;
}

.estore-admin-card {
  border-radius: 5px 5px 5px 5px;
  background: #fff;
}

.search-box-container {
  background: #F8F8F9;
  padding: 0rem;
  width: -webkit-fill-available !important;
  margin-top: 46px;
  position: relative;
}

.header-select-container {
  width: 22%;
  float: left;
  border-right: 1px solid #c3d4da;
  margin: 0;
  padding: 0;
}

.header-select-container select {
  display: block;
  width: 100%;
  padding: 0.85rem;
  color: #4a4a4a;
  border: 0;
  height: 44px;
  border-radius: 5px;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-image: url(https://inoutstorageqa.blob.core.windows.net/inoutcontent/assets/Order/images/downarrow.svg);
}

.header-select-input-top-container {
  border: 1px solid #ced4da;
  border-radius: 5px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  display: flex;
}

.header-select-input-form-container {
  width: 74%;
  float: left;
  position: relative;
}

.header-select-input-form-container input {
  display: block;
  width: 100%;
  padding: 0.85rem;
  color: #4a4a4a;
  border: 0;
  border-radius: 5px;
  height: 20px;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
}

.header-select-input-form-container .search-btn {
  width: 45px;
  height: 35px;
  padding: 10px;
  font-size: 1.65rem;
  border: none;
  cursor: pointer;
  position: absolute;
  background-color: #fff;
  right: -36px;
  top: 0px;
  color: rgba(0, 0, 0, 0.4);
}

.header-select-input-advance-form-container {
  position: absolute;
  z-index: 10;
  width: -webkit-fill-available !important;
}

.mat-menu-item .mat-icon-no-color {
  color: black !important;
}

button.mat-menu-item {
  color: black !important;
}

.theme-border {
  border: 1px solid #ced4da !important;
  border-radius: 4px !important;
}

.justify-content-center {
  justify-content: center !important;
}

.ovelay-cdk {
  margin-left: 210px;
  background-color: #ffffff;
}

.mat-simple-snackbar > span {
  color: white;
}

.autocompleteCategoryName {
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}

.text-ellipsis {
  display: -webkit-box !important;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
}

.line-clamp-2 {
  -webkit-line-clamp: 2 !important;
}

.line-clamp-3 {
  -webkit-line-clamp: 3 !important;
}

.max-length-ellipsis-3 {
  display: -webkit-box;
  -webkit-line-clamp: 3 !important;
  -webkit-box-orient: vertical !important;
  overflow: hidden;
  text-overflow: ellipsis;
}

.max-length-ellipsis-4 {
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical !important;
  overflow: hidden;
  text-overflow: ellipsis;
}

.veg-item-icon {
  fill: #457917 !important;
  stroke: #457917 !important;
}

.egg-item-icon {
  fill: #ffb821 !important;
  stroke: #ffb821 !important;
}

.non-veg-item-icon {
  fill: #c52031 !important;
  stroke: #c52031 !important;
}

.loader-dialog .mat-dialog-container {
  background: transparent !important;
  box-shadow: none !important;
  animation: none;
}

.glapto-container {
  box-sizing: border-box;
}

.glapto-custom-row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
}

.glapto-custom-row > * {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * .1);
  padding-left: calc(var(--bs-gutter-x) * .1);
  margin-top: var(--bs-gutter-y);
}

.glapto-custom-container-fluid {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--bs-gutter-x) * .5);
  padding-left: calc(var(--bs-gutter-x) * .5);
  margin-right: auto;
  margin-left: auto;
}

.glapto-topbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: transparent;
  padding: 10px 0;
}

.back i {
  color: #000;
}

.glapto-topbar .logo {
  display: block;
  margin: 0 auto;
  width: 110px;
  padding-top: 7px;
}

.glapto-custom-container {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--bs-gutter-x) * .5);
  padding-left: calc(var(--bs-gutter-x) * .5);
  margin-right: auto;
  margin-left: auto;
}

.w-auto {
  width: auto !important;
}

.active-blink {
  animation: blink 2s infinite;
}

.info-icon {
  color: #666666;
}

@keyframes blink {
  0% {
    color: white;
  }
  50% {
    color: #1A73E8;
  }
  100% {
    color: white;
  }
}