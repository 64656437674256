.markdown-code {
  background-color: rgba(27, 31, 35, .05);
  border-radius: 3px;
  font-size: 105%;
  font-family: monospace;
  margin: 0;
  padding: .2em .4em;
}

.markdown-code:not(:first-child) {
  margin-left: 10px;
}

//
// API table
//

.docs-api  {
  margin-bottom: 30px;

  h4 {
    font-size: 1rem;
  }

  table {
    border: 1px solid #eee;
    border-collapse: collapse;
    border-spacing: 0;
  }

  th {
    @include mdc-typography(subtitle2);
  }

  tr:nth-child(2n) {
    background-color: #f6f8fa;
  }

  td, th {
    border: 1px solid #dfe2ef;
    padding: 12px;
  }

  td {
    font-size: .9em;
  }
}
