// Review [internal|intermittent] (2021-10-31T12:44:38) : Move all px/em/rem/percent values to var/mixin

@import "../../environments/environment.scss";
$assetsBaseUrl:$path;
$margin-normal: 16px;

.product-card {
  margin: 16px 0px 16px 0px !important;
  padding: 0px !important;
  background-color: transparent !important;
  border-radius: 16px !important;
  box-shadow: none !important;
  filter: drop-shadow(5px 5px 4px rgba(0, 0, 0, 0.25)) !important;
  cursor: pointer !important;
}

.matCardClipperContainer {
  padding: 0px;
  border-radius: 16px;
  mask-image: url($assetsBaseUrl+"assets/Onboard/masks/card-mask.png");
  mask-size:cover;
  mask-repeat: no-repeat;
  mask-position: top;
}

.matCardContainer {
  margin-top: 0px;
  margin-bottom: 0px;
  padding-top: 0px;
}

.mat-card-image {
  width: 100% !important;
  margin: 0px !important;
  

  :first-child {
    margin-top: 0px !important;
  }
}

.product-card-title-container {
  width: 100%;
  margin-top: 8px;
}

mat-card-content {
  padding: 16px;
  padding-top: 8px;
  padding-bottom: 8px;
}

.card-bottom-row {
  display: flex;
  justify-content: center;
  align-items: center;
}

.card-bottom-row-middle {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
}

.card-bottom-row-left {
  display: flex;
  flex: 1;
  align-items: center;
}

.card-bottom-row-right {
  display: flex;
  flex: 1;
  justify-content: flex-end;
}

// mat-slide-toggle .mat-slide-toggle-bar {
//   background: linear-gradient(145deg, #88888888, #dddddd55);
// }

.product-image-container {
  width: 100%;
  background-color: white;
  padding-top: 12%;
}

.card-image {
  object-fit: contain;
  width: 100%;
  margin: 0px;
}


mat-card-content>.card-title {
  margin-bottom: 8px;
}

.card-bottom-row-left>.product-card-price-title {
  margin-top: auto;
  margin-bottom: auto;
}

.card-extended-div-collapsed {
  display: none;
  height: 0px;
}

.card-extended-div-extended {
  display: block;
  height: auto;
}

.product-card-input-text{
  color: $card-input-text-color !important;
}

// mat-form-field mat-label {
//   color: white;
//   opacity: 0.7;
// }


.rotate0 {
  transition: 0.3s;
  transform: rotateZ(0deg);
}


.rotate180 {
  transition: 0.3s;
  transform: rotateZ(180deg);
}


@media screen and (min-device-width:601px) and (max-device-width:768px) { 
  .side-wizard-step-left-section .side-wizard-step-left-section-title{font-size:18px !important;}
  .side-wizard-step-left-section .side-wizard-step-left-section-description{width:90% !important;}
}
@media screen and (min-device-width:769px) and (max-device-width:1366px) { 
  .side-wizard-step-left-section .side-wizard-step-left-section-title{font-size:18px !important;}
  .side-wizard-step-left-section .side-wizard-step-left-section-description{width:90% !important;}
}