.footerBarBGArt {
    // position: absolute;
    // width: calc(100%);
    // margin-top: -50px;
    position: fixed;
    width: 200px;
    bottom: 0;
    right: 0;

    svg {
        display: block;
        height: 100%;
        width: 100%;

        path {
            fill: $paxcom-theme-color-primary;
        }
    }
}

.dynamic-background-next-button-breathe {
    svg {
        path {
            transition: 2s ease-in-out !important;
            d: path("m0 18.52c7.9381 0.058507 28.179-18.343 37.039-18.521l0.0025 18.515z");
        }
    }
}

.dynamic-background-next-button-rest {
    svg {
        path {
            transition: 2s ease-in-out  !important;
            d: path("m0 18.52c18.503-6.3604 19.186-18.528 37.039-18.521l0.0025 18.515z");
        }
    }
}