// Review [internal|intermittent] (2021-10-31T12:43:12) : Move all px/em/rem/percent values to var/mixin
.placeholder-form {
    min-width: 150px;
    max-width: 500px;
    width: 100%;
}

.placeholder-full-width {
    width: 100%;
    // background-color: #ffffff66;
}

