// Review [internal|intermittent] (2021-10-31T12:27:00) : move all hardcoded px/em/percent values to vars or mixins

$max-card-width-x-small : 300px;
$max-card-width-small : 400px;
$max-card-width-small-dynamic : 23vw;
$max-card-width-medium : 500px;
$max-card-width-large : 600px;
$max-card-width-x-large : 800px;

@mixin two-column-list {
    app-card-with-image,
    app-product-card {
        margin-right: 16px;
        margin-left: 16px;
        float: left;
    }
}

.x-wrap-cards-in-list {
    flex: 1;
    display: flex;
    justify-content: center;

    @include media(">tabs-xxphones") {
        mat-list {
            @include media(">tabs-xxphones", "<desktop-xx") {
                @include two-column-list;
                width: calc((24vw * 2) + (8px * 10));
                //width:calc(100% - (8px * 10.5));
            }

            @include media(">=desktop-xx") {
                @include two-column-list;
                width: calc((24vw * 2) + (8px * 20));
                //width:calc(100% - (8px * 21));
            }
        }
    }
}


.card-with-image {
    // max-width: $max-card-width-small;
    @include media("<=tabs-xxphones") {
        // max-width: calc(100% - 32px);
        // width: $max-card-width-small;
    }

    @include media(">tabs-xxphones", "<desktop-xx") {
        //max-width: $max-card-width-small;
        width: $max-card-width-small-dynamic;
        margin-right: 16px !important;
        margin-left: 16px !important;
        float: left !important;
    }

    @include media(">=desktop-xx") {
        // max-width: $max-card-width-medium;
        width: $max-card-width-medium;
        margin-right: 16px !important;
        margin-left: 16px !important;
        float: left !important;
    }
}

.card-background-selected {
    transition: 0.5s;
    background-color: $card-image-background-color-selected;
}

.card-background-unselected {
    transition: 0.5s;
    background-color: $card-image-background-color
}

.product-card {
    // max-width: $max-card-width-small;

    img {
        max-height: 150px;
    }

    product-image-container {
        max-height: 150px;
    }

    @include media("<=tabs-xxphones") {
        // max-width: calc(100% - 32px);
        // width: $max-card-width-small;
    }

    @include media(">tabs-xxphones", "<=desktop-xx") {
        // max-width: $max-card-width-small;
        width: $max-card-width-small-dynamic;
        img {
            max-height: 150px;
        }

        .product-image-container {
            max-height: 150px;
        }
    }

    @include media(">=desktop-xx") {
        // max-width: $max-card-width-medium;
        width: $max-card-width-medium;
        img {
            max-height: 300px;
        }

        .product-image-container {
            max-height: 300px;
        }
    }
}
.product-card-two-column-list {
    @include media("<=tabs-xxphones") {
        // max-width: calc(100% - 32px);
        // width: $max-card-width-small;
    }

    @include media(">tabs-xxphones", "<=desktop-xx") {
        margin-right: 16px !important;
        margin-left: 16px !important;
        float: left !important;
    }

    @include media(">=desktop-xx") {
        margin-right: 16px !important;
        margin-left: 16px !important;
        float: left !important;
    }
}