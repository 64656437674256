// Review [internal|intermittent] (2021-10-31T12:43:54) : Move all px/em/rem/percent values to var/mixin
.side-wizard-steps-container {
    width: 100%;
    height: 100%;

    .side-wizard-steps {
        display: flex;
        align-self: center;
        flex-direction: column;
        margin-right: 16px;

        .side-wizard-step {
            display: flex;
            row-gap:16px;
            padding-bottom: 8px;
            padding-top: 8px;
            &.selected {
                background-color: $wizard-step-bg-color-current;
                margin-right: -16px;
                padding-right: 16px;
                .cover{
                    opacity:1;
                }
            }

            .side-wizard-step-left-section {
                display: flex;
                flex-direction: column;
                flex: 1;
                align-items: flex-end;
                padding-right: 16px;
                width: 100%
            }
        }
    }
}