// Review [internal|intermittent] (2021-10-31T12:44:21) : Move all px/em/rem/percent values to var/mixin

.wizard-toolbar-container {
    display: flex;
    flex: 1;
    flex-direction: row;
    width: calc(100% + 16px); //if you change this, the title in wizard toolbar will go off.

    .wizard-toolbar-left {
        width: calc(33% - 16px);
        .wizard-toolbar-logo-container {
            text-align: end;
            padding-right: 32px;

            img {
                margin-top: 0px;
            }
        }
    }

    .wizard-toolbar-right {
        display: flex;
        flex: 1;
        margin-left: -30px;
        justify-content: center;

        .wizard-toolbar-right-text-container {
            align-self: center;
        }
    }
}