
@font-face {
  font-family: "WorkSansBlack";
  src: url("./assets/fonts/WorkSans-ExtraBold.ttf");

}

@font-face {
  font-family: "BarlowCondensed";
  src: url("./assets//fonts/BarlowCondensed-Black.ttf");
  font-weight: 900;
}

@font-face {
  font-family: "BarlowCondensed";
  src: url("./assets//fonts/BarlowCondensed-Bold.ttf");
  font-weight: 500;
}

@font-face {
  font-family: "BarlowCondensed";
  src: url("./assets//fonts/BarlowCondensed-Medium.ttf");
  font-weight: 400;
}


@font-face {
  font-family: "Montserrat";
  src: url("./assets//fonts/Montserrat-Regular.ttf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Montserrat";
  src: url("./assets//fonts/Montserrat-Medium.ttf");
  font-weight: 500; //medium
  font-style: normal;
}

@font-face {
  font-family: "Montserrat";
  src: url("./assets//fonts/Montserrat-Bold.ttf");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Montserrat";
  src: url("./assets//fonts/Montserrat-Light.ttf");
  font-weight: 300; //light
  font-style: normal;
}


@font-face {
  font-family: "product-sans";
  src: url('./assets//fonts/ProductSans-Regular.ttf');
}
@font-face {
  font-family: "product-sans-Medium";
  src: url('./assets//fonts/ProductSans-Medium.ttf');
}
@font-face {
  font-family: "product-sans-bold";
  src: url('./assets//fonts/ProductSans-Bold.ttf');
}

@font-face {
  font-family: "poppin-regular";
  src: url('./assets//fonts/Poppins-Regular.ttf');
}
@font-face {
  font-family: "poppin-Medium";
  src: url('./assets//fonts/Poppins-Medium.ttf');
}
@font-face {
  font-family: "poppin-bold";
  src: url('./assets//fonts/Poppins-Bold.ttf');
}

@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,500,700,900);

$title-font-name: 'Open Sans';
$title-font-weight-black: 900;
$title-font-weight-light: 500;
$title-font-weight-medium: 700;

$body-font-name: 'Open Sans';
$body-font-weight-light: 300;
$body-font-weight-medium: 500;
