// Review [internal|intermittent] (2021-10-31T12:23:52) : replace hardcoded colors with pax-theme color
// Review [internal|intermittent] (2021-10-31T12:24:37) : move all hardcoded px/em/percent values to vars or mixins

@mixin pax-shadow-with-shiny-edge() {
    box-shadow: 2px 2px 6px 2px $paxcom-color-black-transparent-90 !important;
    // border-style: solid !important;
    border-color: #ffffff66 !important;
    border-width: 1px !important;
}

@mixin pax-raised-shiny-edge() {
    border-radius: 5px !important;
    @include pax-shadow-with-shiny-edge()
}

.pax-button-purple-light-shiny-raised {
    @include paxcomTypographyButtonLight;
    @include pax-raised-shiny-edge;
}

.pax-raised {
    @include pax-shadow-with-shiny-edge;
}

.button-icon-svg-image {
    height: 25px;
    width: 25px;
    opacity: 0.5;
}


.input-text-light {
    caret-color: $search-header-color;
    color: $search-header-color !important;
    opacity: 0.8;
}

mat-icon.light {
    color: $search-header-color !important;
    opacity: 0.8;
}

mat-form-field mat-label.light {
    color: $search-header-color;
    opacity: 0.7;
}

.textBoxIncrementDecrementButtons {
    color: $paxcom-color-black;
    font-size: xx-large !important;
    font-weight: 100 !important;
    opacity: 0.8 !important;
}