// Review [internal|intermittent] (2021-10-31T12:42:50) : Move all px/em/rem/percent values to var/mixin
.step-payment-info-text{
    padding:16px;
}
.step-payment-form-container{
    padding:16px;
}
.step-payment-field-container{
    padding:16px;
    padding-left:0px;
    color:$input-element-text-color;
}
.step-payment-button-container{
    padding:16px;
    padding-left:0px;
}
.step-payment-i-accept-span{
    padding-left:8px;
}