@import './styles/app.scss';
@import '~highlight.js/styles/atom-one-dark.css';
$mdc-theme-primary: #808080;
$mdc-theme-secondary: #1A73E8; // secondary color example
$mdc-theme--primary-bg:#e23030;

$font-family : 'poppin-regular','Roboto','Poppins', 'sans-serif';
$font-family-medium : 'poppin-medium','Roboto','Poppins', 'sans-serif';
$font-family-bold : 'poppin-bold','Roboto','Poppins', 'sans-serif';
@import "./app/mat-theme-custom.css";
.p-0{padding:0px !important;}
.p-5{padding:5px !important;}
.p-8{padding: 8px !important;}
.p-10{padding:10px !important;}
.p-15{padding:15px !important;} 
.p-20{padding:20px !important;} 
.pb-24{padding-bottom: 24px !important;}
.pb-100{padding-bottom: 100px !important;}
.pb-5{padding-bottom: 5px !important;}
.pt-0 { padding-top: 0px !important;}
.pt-10{padding-top:10px !important;}

.pb-10{padding-bottom:10px !important;}

.pt-30 { padding-top: 30px !important; }

.pb-0 { padding-bottom: 0px !important;}
.pb-4 { padding-bottom: 4px !important;}
.pb-10 { padding-bottom: 10px !important; }
.pt-12 { padding-top: 12px !important; }
.py-5 { padding-top: 5px !important; padding-bottom: 5px !important; }
.px-5 { padding-left: 5px !important; padding-right: 5px !important; }
.pr-10 { padding-right: 10px !important}
.pr-16 {padding-right: 16px !important }
.pl-16 {padding-left: 16px !important }
.pl-0 {padding-left: 0px !important;}
.pr-0 {padding-right: 0px !important;}
.m-0{ margin: 0px !important;}
.m-auto{margin: auto !important;}
.mt-auto{margin-top: auto !important;}
.mt-0 {margin-top: 0px;}
.mt-1 {margin-top: 1px;}
.mt-2 {margin-top: 2px;}
.mt-3 {margin-top: 3px;}
.mt-4 {margin-top: 4px;}
.mt-5 {margin-top: 5px;}
.mt-6 {margin-top: 6px;}
.mt-7 {margin-top: 7px;}
.mt-8 {margin-top: 8px;}
.mt-9 {margin-top: 9px;}
.mt-10{margin-top:10px !important;}
.mt-20{margin-top:20px !important;}
.mt-15{margin-top:15px !important;}
.mt-16{margin-top:16px !important;}
.ml-15{margin-left:15px !important;}
.mt-6vh {margin-top: 6vh;}
.mt-10vh {margin-top: 10vh;}
.margin-top-0 { margin-top: 0px !important; }
.margin-top-5 { margin-top: 5px !important; }
.margin-top-8 { margin-top: 8px !important; }
.margin-top-50 { margin-top: 50px !important; }
.margin-top-30 { margin-top: 30px !important; }
.margin-top-15 { margin-top: 15px !important; }
.margin-top-12 { margin-top: 12px !important; }
.margin-top-10 { margin-top: 10px !important; }
.mt-minus-30 { margin-top: -30px !important; }
.mt-minus-7 {margin-top:-7px !important;}
.mt-minus-15 {margin-top:-15px !important;}
.mt-minus-20 {margin-top:-20px !important;}
.mb-0{margin-bottom: 0px!important}
.mb-5{margin-bottom: 5px!important}
.mb-auto{margin-bottom: auto !important;}
.mb-20{margin-bottom: 20px!important}
.mb-10{margin-bottom: 10px!important}
.mb-105{margin-bottom: 105px !important;}
.m-25{margin:25px !important}
.ml-0 {margin-left: 0px !important;}
.ml-1 {margin-left: 1px;}
.ml-2 {margin-left: 2px;}
.ml-3 {margin-left: 3px;}
.ml-4 {margin-left: 4px;}
.ml-5 {margin-left: 5px;}
.ml-6 {margin-left: 6px;}
.ml-7 {margin-left: 7px;}
.ml-8 {margin-left: 8px;}
.ml-9 {margin-left: 9px;}
.ml-10 {margin-left: 10px !important;}
.ml-11 {margin-left: 11px;}
.ml-12 {margin-left: 12px;}
.ml-13 {margin-left: 13px;}
.ml-14 {margin-left: 14px;}
.ml-15 {margin-left: 15px;}
.ml-42 {margin-left: 42px;}
.margin-left-10 { margin-left : 10px !important; }
.margin-left-15 { margin-left : 15px !important; }
.ml-25 { margin-left : 25px; }						 
.margin-left-30 { margin-left : 30px; }
.ml-minus-12 {margin-left: -12px !important;}
.mr-0 {margin-right: 0px !important;}
.mr-1 {margin-right: 1px;}
.mr-2 {margin-right: 2px;}
.mr-3 {margin-right: 3px;}
.mr-4 {margin-right: 4px;}
.mr-5 {margin-right: 5px;}
.mr-6 {margin-right: 6px;}
.mr-7 {margin-right: 7px;}
.mr-8 {margin-right: 8px;}
.mr-9 {margin-right: 9px;}
.mr-10 {margin-right: 10px;}
.mr-11 {margin-right: 11px;}
.mr-12 {margin-right: 12px;}
.mr-13 {margin-right: 13px;}
.mr-14 {margin-right: 14px;}
.mr-15 {margin-right: 15px;}
.margin-right-10 { margin-right : 10px !important; }
.mb-10 { margin-bottom: 10px !important;}
.mrl-0 { margin-right: 0px !important;margin-left: 0px !important; }
.font-12 { font-size: 12px !important;}
.font-13 { font-size: 13px !important;}
.font-14 { font-size: 14px !important; }
.font-15 { font-size: 15px !important; }
.font-16 { font-size: 16px !important }
.font-17 { font-size: 17px !important; }
.font-18 { font-size: 18px !important; }
.font-19 { font-size: 19px !important; }
.font-20 { font-size: 20px !important; }
.font-wt-600 { font-weight: 600 !important; }
.font-wt-400{ font-weight:400 !important;}
.bottom-15{ bottom: 15px !important;}

.right-10{ right: 10px !important;}
.right--25{right:-25px !important;}
.d-flex{display: flex !important;}
//------- width----------------
.w-1{width: 1% !important;} 
.w-2{width: 2% !important;}
.w-3{width: 3% !important;}  
.w-4{width: 4% !important;}
.w-5{width: 5% !important;}
.w-6{width: 6% !important;}
.w-7{width: 7% !important;}
.w-8{width: 8% !important;}
.w-9{width: 9% !important;}
.w-10{width: 10% !important;}
.w-11{width: 11% !important;}
.w-12{width: 12% !important;}
.w-13{width: 13% !important;}
.w-14{width: 14% !important;}
.w-15{width: 15% !important;}
.w-16{width: 16% !important;}
.w-17{width: 17% !important;}
.w-18{width: 18% !important;}
.w-19{width: 19% !important;}
.w-20{width: 20% !important;}
.w-25{width: 25% !important;} 
.w-30{width: 30% !important;}
.w-33{width: 33% !important;}
.w-35{width: 35% !important;}
.w-40{width: 40% !important;} 
.w-45{width: 45% !important;} 
.w-48{width: 48% !important;}
.w-50{width: 50% !important;}
.w-54{width: 54% !important;} 
.w-55{width: 55% !important;} 
.w-60{width: 60% !important;} 
.w-62{width: 62% !important;} 
.w-65{width: 65% !important;} 
.w-70{width: 70% !important;} 
.w-72{width: 72% !important;}
.w-75{width: 75% !important;} 
.w-80{width: 80% !important;} 
.w-85{width: 85% !important;} 
.w-90{width: 90% !important;} 
.w-95{width: 95% !important;} 
.w-100{width: 100% !important;}
.w-150{width: 150% !important;}
.w-max-content{width: max-content !important}

//-------Min width----------------
.mw-100 {max-width: 100% !important;} 
.min-width-125{min-width: 125px !important}
.mw-85{min-width: 85px;}

//-------Min Height----------------
.min-height-34vh {min-height: 34vh !important;}
.min-height-45vh {min-height: 45vh !important;}
.min-height-90vh{min-height: 90vh !important;}
.min-height-100{min-height: 100px !important;}
.min-height-155{min-height:155px !important;}
.min-height-180{min-height:180px !important;}


//-------Max Height----------------
.max-height-34vh {max-height: 34vh !important;}

//-------Height----------------

.h-65{height:65% !important;}
.h-35{height: 35% !important;}
.h-80{height:80% !important;}
.h-100 {height: 100vh !important; }
.height-5{ height: 5px !important;} 
.height-10{ height: 10px !important;} 
.height-20{ height: 20px !important;} 
.height-30{ height: 30px !important;} 
.height-40{ height: 40px !important;} 
.height-50{ height: 50px !important;}
.height-56{ height: 56px !important;} 
.height-60{ height: 60px !important;} 
.height-70{ height: 70px !important;} 
.height-80{ height: 80px !important;}
.height-90{ height: 90px !important;} 
.height-100{ height: 100px !important;}
.height-490{height: 490px !important;}
.height-auto-view{ height: auto !important;}

.align-baseline{display: flex !important;align-items: flex-end !important;}
.flex-direction-column{display: flex !important;flex-direction: column !important;}
.float-left { float: left !important }
.text-left { text-align: left!important;}
.text-center { text-align: center!important;}
.text-right { text-align: right!important;}
.valign-top { vertical-align: top !important; }
.align-right{display: flex; justify-content: flex-end !important;align-items: center;}
.align-center{  display: flex !important;justify-content: center; align-items: center !important;text-align: center !important;}
.align-left{ display: flex; justify-content: flex-start;}
.valign-center {display: flex; align-items: center;}
.display-none {display: none;}
.display-initial {display: initial !important;}
.position-absolute { position: absolute!important;}
.position-relative { position: relative!important;}
.position-inital { position: initial!important;}
.cursor-pointer { cursor: pointer !important;}
.cursor-default{cursor: default !important;}
.opacity-1 { opacity: 1; }
.overflow-y-scroll {overflow-y: scroll;}
.overflow-auto { overflow: auto; }
$theme-secondary-color: #032349;
$theme-primary-color: #56C3FF;
$theme-white-color: white;
.bg-light-green {background-color: #fbfaf6 !important;}
.theme-secondary-background-color{ background-color:$theme-secondary-color !important;} 
.height-auto{ height: 100% !important;}
.line-height-36{ line-height: 36px !important;}
.pointer-events-none{pointer-events: none;}
.flex-wrap{flex-wrap: wrap !important;}
.bg-color-light-effect{background-color: #fafafa !important;}
.border-radius-5{border-radius: 5px !important;}
.border-radius-0 {border-radius: 0px !important;}
.backgroud-color-router-outlet { background-color: #fff !important; }
.heading-bg-color {background-color: #efefef !important;}
.color-white {color: #fff !important;}
.color-black {color: black !important;}
//--------------Common css----------
.list-image-thumbnail {max-width: 70px !important; max-height: 70px !important; height: auto; width: auto;}
.border-box{ border: 1px solid #80808085; border-radius: 7px;}
.border{ border: 1px solid #80808085;}
.add-image-container { position: relative; text-align: center; }
.add-image-container:hover img{ opacity: 0.3;}
.add-image-container img { opacity: 1; width: auto; height: auto; max-width: 100px; max-height: 100px; cursor: pointer !important;}
.upload-image-container { background-color: silver; padding:5px; text-align: center; padding: 5px;}
.change-image-container { transition: .5s ease; top: 0; bottom: 0; left: 0; right: 0; opacity: 0; position: absolute; text-align: center; }
.change-image-container:hover {opacity: 1 !important;}
.add-image-circle{ height: 65px;width: 65px; background-color: white;border-radius: 100%;display: inline-block;}
.change-image-text { color: black; font-size: 16px; position: absolute; top: 50%; left: 50%; -webkit-transform: translate(-50%, -50%); -ms-transform: translate(-50%, -50%); transform: translate(-50%, -50%); text-align: center; }
.top-app-Bar-icon{position: fixed; top: 0; z-index: 999;display: none;}
.error-message { font-family: Roboto, sans-serif; -webkit-font-smoothing: antialiased; font-size: 0.75rem; font-weight: 400;  letter-spacing: 0.0333333333em; line-height: normal; margin-left: 18px; color: var(--mdc-theme-error, #b00020); caret-color:#b00020;
}
.object-fit-contain{object-fit: contain !important;}
//-------------Common css end-----------------

//--------------------Order App----------------
.order-searchdiv{ 
  border: 1px solid gray;
  // padding: 20px; 
  // position:absolute; 
  z-index: 2; 
  background: #ffffff; }
.order-list-div span { cursor: pointer; }
.order-list-action-btn-container{width:125px;float:left}
.order-list-action-btn-container span{float: left;margin-right:10px;width:24px}
.order-edit-action-item{float: left; 
  // width: 100px;margin-left: -15px;
}
//-----------Order App end---------
.cdk-global-scrollblock::-webkit-scrollbar{width: 0px;} 


//------------Public login Page-------------------------
.header-pxm-logo { max-width: 200px; width: auto; height: auto;}
.omnichannel-heading {font-weight: 600; color: $theme-secondary-color;}
.omnichannel-description {color: $theme-secondary-color;}
.lets-create-btn{color: white !important; background-color: #032349!important; border-radius: 4px !important;}
.sign-in{ width: auto; margin-right: 20px; }

//-----------Public page end---------

//-------------eStore Admin App ------------
.eStore-categories-List .mdc-list-item--selected { color: rgba(255, 255, 255, 0.87); background : #1A73E8; }
.eStore-chip { border-radius: 4px; height: 40px;}
.eStore-drag-icon{ position: absolute; -webkit-transform: translate(-50%,-50%); right: 0%; z-index: 10; top: 50%; }

.eStore-reviewTable td:first-child { width: 35% !important;word-break: break-all }
.eStore-reviewTable td:nth-child(2) { width: 15% !important;word-break: break-all}
.eStore-reviewTable td:nth-child(3) { width: 15% !important;word-break: break-all}
.eStore-reviewTable th:nth-child(4) { text-align: center;}
.eStore-reviewTable td:nth-child(4) { width: 5% !important;text-align: center;}
.eStore-reviewTable th:nth-child(5) { text-align: center;}
.eStore-reviewTable td:nth-child(5) { width: 10% !important;text-align: center;}
.eStore-reviewTable td:nth-child(6) { width: 20% !important;}
//-------------eStore Admin end---------------------


.mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-thumb
{
    color:#1A73E8 !important;
}
.mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-bar{
    color:#1A73E8 !important;
}
.ngx-form-field-text-field, .ngx-form-field-select {
    height: auto; 
    background: #fff !important;
    font-family: $font-family-medium !important;
}

.mdc-top-app-bar .mdc-top-app-bar__navigation-icon {
    color: black;
}
// set css of mat text field  
.mat-form-field-appearance-outline .mat-form-field-wrapper {
  margin: 0px !important;
}
.mat-form-field-appearance-outline .mat-form-field-outline {
  top: 2px !important;
}
.mat-form-field-appearance-outline .mat-form-field-flex {
  padding: 0px 10px 0px 15px !important;
}
.mat-form-field-appearance-outline .mat-form-field-label {
  top: 30px !important;
   margin-top: 0px !important; 
}
// end 
.img-conatiner
{
  height: 110px;
  widows: 110px;
  text-align : center;
}
.img-resonsive{
  max-width: 100px;
  max-height: 100px;
  height: auto;
  width: auto;
  text-align: center;
}
body{
    margin: 0px;
}
.overflowTableName
{
  white-space: normal
}
.cancel-button
{
    background-color: #aeaeae7d !important;
    color: black !important;
    width: 100%;
}

.confirm-button{
    background-color: #1A73E8  !important;
    color: white !important;
    border-radius: 2px;
    width: 100%;
}
.Order_title {
    padding: 10px 0px !important;
    background: #ffffff;
    width: 100%;
    position: sticky !important;
    z-index: 2 !important;
    height: 30px;
    }
.add-custom-btn{
    border-radius: 120px !important;
    background: #fff !important;
    height: auto;
    border: 1px solid #ddd !important;
    width: auto;
    text-transform: capitalize;
    font-size: 1.05rem !important;
    letter-spacing: 0px !important;
    color: black !important;
    padding: 17px 20px 34px 20px;
}
:focus {
    outline: none;
}
 :root {
     --mdc-theme-primary: #808080;
     --mdc-theme-secondary: #1A73E8;
}
.mat-slide-toggle-bar {
    height: 12px !important;
}
.mdc-drawer--dismissible {
    z-index: 3;
}
.mat-input-wrapper{
    padding: 0px 0px 0px 0px !important;
    position: relative;
  }
  .mat-form-field-wrapper{
    padding: 0px 0px 0px 0px !important;
    position: relative;
  }
.field-error {
    color: #b00020;
}
.headline
{
  padding: 0px !important;
  height: 50px;
  margin-top: 50px;
}

.paxcom-child-content {
    overflow-x: hidden !important;
}
.textbox-search
{
    background-color: white;
     box-shadow: 2px 3px 3px #ddd;
     border-radius: 4px;
}




.wpx-150{width:150px !important;} .wpx-140{width: 140px !important;} .wpx-130{width: 130px !important;} .wpx-120{width: 120px !important;} .wpx-110{width: 110px !important;} .wpx-100{width: 100px !important;} .wpx-90{width: 90px !important;} .wpx-80{width: 80px !important;} .wpx-70{width: 70px !important;} .wpx-60{width: 60px !important;} .wpx-50{width: 50px !important;} .wpx-40{width: 40px !important;} .wpx-30{width: 30px !important;} .wpx-25{width: 25px !important;} .wpx-20{width: 20px !important;} .wpx-10{width: 10px !important;}
tr.mat-header-row { height: 45px; background: #fff;}
.custom-table-divbox{ width: 100%; height: auto; float: left; margin: 0px; padding:15px 0px; text-align: left;}
.custom-table-divinnerbox{width:150px;height: auto;margin:5px 10px 0px 0px; padding: 0px; float: left;}
.custom-table-divinnerbox h4{ font-size: 12px; margin: 0; padding: 0; line-height: normal; float: left; width: 100%;}
.custom-table-divinnerbox h4 span{ min-width:40px; float: left; font-size:12px; margin: 0px 5px 0px 0px; line-height: 20px; font-weight:500;}
.first { width: 90%;}
.ellipsis { position: relative;}
.ellipsis:before {  content: '&nbsp;';  visibility: hidden;}
.ellipsis span {  position: absolute;  left: 0;  right: 0;  white-space: nowrap;  overflow: hidden;  text-overflow: ellipsis;}
.mat-cell {padding-left: 10px !important; padding-right: 5px !important;}
.action-cell{padding-left: 0px !important;}
th.mat-header-cell { padding-left: 10px !important; padding-right: 5px ;}
.text-align-right{ text-align: right !important; padding-right: 15px !important;}
td.mat-cell:last-of-type, td.mat-footer-cell:last-of-type, th.mat-header-cell:last-of-type { padding-right: 0px;}
.img-box{ width: 100px; height: auto; justify-content: center; float: left; margin: 10px 0px 10px 0px; text-align: center;}
.img-res{ text-align: left; align-content: center; -o-object-fit: contain; object-fit: contain; max-width:120px; max-height: 120px; height: auto; width: auto;  padding: 5px;}


.bg-color{
    background-color:#ffffff;
  }

  .mg-24{
    margin: 24px;
  }

  a{
    color:#1A73E8;
    cursor: pointer;
    }

    .view-link{
      color:#1A73E8;
      cursor: pointer;     
      }
      


      
      .mat-button.mat-accent, .mat-icon-button.mat-accent, .mat-stroked-button.mat-accent {
        color: gray;
      }
      
      .mat-18 {
        color: black;
        cursor: pointer;
      }
    
      
      
      .mat-elevation-z8 {
        box-shadow: 0 0px 0px -3px rgba(0,0,0,.2), 0 0px 0px 1px rgba(0,0,0,0.14), 0 0px 0px -3px rgba(0,0,0,.12);
      }
      
      .table-container {
        display: flex;
        flex-direction: column;
        min-width: 300px;
      }
      
      .ngx-mdc-button--secondary:not(:disabled) {
        width: 100%;
      }
      
      table {
        width: 100%;
        overflow: auto;
      }
      
      .cdk-column-delete_icon{
        padding: 0px !important;
      }
      
      .cdk-column-edit_icon{
        padding-left: -1px !important;
      }
      
      .search {
        border-left: 1px solid rgba(0, 0, 0, 0.24);
        border-right: 1px solid rgba(0, 0, 0, 0.24);
        border-bottom: 1px solid rgba(0, 0, 0, 0.24);
        border-radius: 5px;  
        padding: 24px;     
        
      }
      
      .search-icon{
        margin: 4px 3px 0px 0px;
      }

      .mdc-text-field-helper-line {      
        color: #b00020;  
    }

    .load-more{
      color: #1A73E8 !important;
    }

    .mat-form-field-invalid {
      caret-color: #b00020 !important;
  }


  .mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
    color: #b00020;
}



  

.mat-form-field-appearance-outline .mat-form-field-infix {
  padding: 1.4em 0 1.4em 0; border-top: .32em solid transparent;border-radius: 4px;
}

.no-record{
width: 100% !important;
text-align: center !important;
font-size: 13px !important;
font-weight: 600;
}

.text-color{
  color:#1A73E8 !important;
}

.left-side{
  padding: 17px;
  margin: 15px;
 }

 .basic{
  display: none;
}

.advance{
  display: block;
}
.mdc-notched-outline__notch {
  /* Ignore the width set by script, and change it to be calculated from the width of `mdc-floating-label` by setting `auto`. */
  width: auto !important;
}

.mdc-notched-outline__notch > label.mdc-floating-label--float-above {
  /* Remove `scale(0.75)` to apply the correct width to `mdc-notched-outline__notch`. */
  transform: translateY(-130%);

  /* Calculate `scale(0.75)` with each property. */
  font-size: calc(1rem * 0.75);
  top: calc(17px * (1 - ((1 - 0.75) / 2)));
}

/* with leading-icon */
.mdc-text-field--with-leading-icon .mdc-notched-outline__notch > label.mdc-floating-label--float-above {
  transform: translateY(-130%) translateX(-32px);
  font-size: calc(1rem * 0.75); /* Re-apply the above selector because the score is low. */
}

.mdc-notched-outline__notch > label.mdc-floating-label {
  transition:
    transform 150ms cubic-bezier(0.4, 0, 0.2, 1),
    color 150ms cubic-bezier(0.4, 0, 0.2, 1),
    /* Now that we calculate `scale(0.75)` for each property, we add each property to the target of the animation. */
    font-size 150ms cubic-bezier(0.4, 0, 0.2, 1),
    top 150ms cubic-bezier(0.4, 0, 0.2, 1);
}

.mdc-notched-outline--notched .mdc-notched-outline__notch {
  padding-left: 0;
  padding-right: 0px;
  border-top: none;
}
.mdc-list-item__secondary-text {
  margin-left: 4px !important;
}
.mat-primary .mat-pseudo-checkbox-checked, .mat-primary .mat-pseudo-checkbox-indeterminate
{
  background : #1A73E8 !important;
}

.text-align-center{text-align: center !important}
.text-align-end{text-align: end !important}
.text-align-start{text-align: start !important}
.cursor-hand{cursor: pointer !important;} 
.theme-primary-background-color{ background-color: $theme-primary-color !important;} 
.theme-child-order-header{
  padding: 0px !important;height: 50px;
}
.theme-child-product-header{
  margin: 0px !important;
  padding: 10px 15px !important;
  background: #ffffff;
  width: 100%;
}
.theme-header{
  margin: 0px !important;
  padding: 10px 15px !important;
  background: #ffffff;
  width: 100%;
}
.mat-tab-label
{
  opacity: 1 !important;
  height: 45px !important;
}

.top-app-header .mat-tab-label-container{
  position: fixed;
  top: 0px;
  z-index: 10 !important;
}
.WMS_title {
  padding: 10px 0px !important;
  background: #ffffff;
  width: 100%;
  position: sticky !important;
  z-index: 2 !important;
  height: 30px;
  }
.custom-snackbar-css
{
  color:#ffff00bf;
}
.mat-simple-snackbar-action {
  color: white !important;
}

.top-app-header-bar{
  height: 48px;border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.top-app-header-bar .mdc-tab {
  min-width: 160px;
}
.top-app-header-bar .mdc-tab .mdc-tab__text-label{
  color: rgba(0, 0, 0, 0.87) !important;
  text-transform: initial;
  font-size: 1.01rem;
  font-weight: 500;
  letter-spacing: 0.25px;
}

.top-app-header-bar .mdc-tab-scroller {position: fixed;z-index: 10;}
img{max-width: 100%;}
.top-app-header-bar .mdc-tab__icon img{ height: 20px; display: flex;}
.admin-sidebar-highlightedBtn span{margin-top: 16px;font-size: 14px;}
.display-none{display: none !important;}
.done{background-color: green !important;}
.display-inline-block{ display: inline-block !important;}
.display-block{ display: block !important;}
.display-flex{ display: flex !important;}

.mdc-drawer--modal{z-index: 1000; top: 0px;}
.header-pxm-logo
{
  max-width: 200px;
  width: auto;
  height: auto;
}
.searchdiv::-webkit-scrollbar { width: 5px; }
.searchdiv::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1); border-radius: 5px; box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1)
}

.searchdiv::-webkit-scrollbar-thumb {
  border-radius: 5px; -webkit-box-shadow: inset 0 0 6px #c1c1c1; box-shadow: inset 0 0 6px #c1c1c1
}
.custom-dialog-container .mat-dialog-container {
  padding: 0;
}
.theme-hide-desktop{ display: none;}
.wrapper-header-app-bar {background-color: white;}

@media screen and (min-width:300px) and (max-width:1024px) {
  .min-width-none{ min-width:auto !important;}
  .mt-47{ margin-top: 47px !important;}
  .mt-45{ margin-top:45px !important;}
  .mdc-top-app-bar { background-color: #eee}
  .w-100-mobile{ width: 100% !important;}
  .overflow-x{ overflow-x: auto;}
  .height-auto {  height: 50px!important;} 
  .top-app-Bar-icon { display: block; }
  .wrapper-header-app-bar .mdc-top-app-bar__row {background-color: inherit;}

  .theme-configure-menu { border-bottom: 4px solid #efefef; padding: 0px 0px 20px 0px;}
  .theme-configure-custom-menu-left{ text-align: right; position: fixed; z-index: 999; bottom: 0; background: #fafafa; padding: 5px 10px; left: 0;width: 45%;}
  .theme-configure-custom-menu-right{ text-align: right; position: fixed; z-index: 999; bottom: 0; background: #1A73E8; padding: 5px 10px; right: 0; width: 45%;}
}

@media screen and (min-width:300px) and (max-width:479px) {
  .theme-hide-mobile{ display: none;}
  .theme-hide-desktop{ display: block;}
  .mdc-drawer__content{ height: 90vh !important;}
}

@media screen and (min-width:300px) and (max-width:641px) {
  .mdc-drawer__content{ height: 100vh !important;}
  .header-select-input-form-container .search-btn{right:-25px !important;}
}

@media only screen and (max-width : 480px){
  .main_div{right: 20px !important;max-width: 85% !important}
}
// @media screen and (min-width: 1496px) {
//   .order-searchdiv { width: 92%; padding-right: 31px; }
// }
// @media screen and (min-width: 1600px) {
//   .order-searchdiv { width: 95%; padding-right: 24px; }								  				   
// }

// @media screen and (max-width: 1486px) and (min-width: 1000px) {
//   .order-searchdiv { width: 91%; padding-right: 24px; }
// }


// onboard styling files
// @import '..src/app/modules/onboard/styles/0-base/all_base_dir';
@import './onboard-styles/0-base/all_base_dir';
@import './onboard-styles/1-components/all-component-dir';
@import './onboard-styles/2-sections/all_sections_dir';
@import './onboard-styles/3-illustrations/all_illustration_dir';
@import './onboard-styles/4-logos/all_logo_dir';

html,
body {
  height: 100%;
}


input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}
input[type=number] {
    -moz-appearance: textfield;
}

.history-background-unselected{
  background: transparent !important;
}
.bg-transparent { background: transparent !important;}
.history-background-selected{
  background: #80808029 !important;
}
.fs-12{ font-size: 12px !important;}
.fs-18{ font-size: 18px !important;}
.text-dec-line-through{ text-decoration: line-through !important;}
.colorRed{color: red !important;}
.colorBlack{color: black !important;}
.colorGreen{color: green !important;}
.colorBlue{color:blue !important;}

.theme-secondary{
  color: $mdc-theme-secondary !important;
}
.ngxImageZoomContainer {
  // position: absolute !important;
  // left: 50%;
  // // // top: 0;
  // top: 50%;
  // -webkit-transform: translate(-50%,-50%);
  // transform: translate(-50%,-50%);
  // transform: translateX(-50%);


  position: relative;
  margin: auto;
  height: 100%;
  width:100%;
  overflow: hidden;
  vertical-align: middle;


  &:hover {
    width: 100%;
  }

  img.ngxImageZoomThumbnail {
    width: auto;
    display: block;
    margin: auto;
  }

  .ngxImageZoomFullContainer {
    width: 100% !important;
  }
}

.header-bg-underlying {
  &::after, &::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: inherit;
    top: 0;
    left: 0;
  }

  &::after {
    background: $theme-white-color;
    z-index: -1;
  }
}

.total-display {
  float: right;
  margin-right: 26px;
  font-size: 13px !important;
  font-weight: bold;
}

.scrollbar-chiplist .mat-chip-list-wrapper{
  max-height: 80px;
  overflow-y: auto;
  overflow-x: hidden;
  }

  .display-total{
    padding: 5px 0px 5px 0px !important;
    text-align: left !important;
  }
  
.admin-header{
  .mdc-select--disabled.mdc-select--outlined .mdc-select__native-control, 
  .mdc-select--disabled.mdc-select--outlined .mdc-select__selected-text,
  .mdc-select--disabled .mdc-select__native-control,
  .mdc-select--disabled .mdc-select__selected-text {
    color: rgba(0,0,0,.99) !important;

  }

  .mat-input-element:disabled,
  .mdc-text-field--disabled .mdc-text-field__input{
    color: rgba(0,0,0,.89) !important;
  }

  .mdc-select--disabled,.mdc-text-field--disabled,
  .mat-input-element:disabled,
  .mat-form-field-disabled .mat-form-field-wrapper {
    background-color: #fafafa !important; 
  }

}

.word-wrap{
  word-wrap: break-word;
}

.break-word{
  word-break: break-all;
}

.fontwt{
  font-weight: bold;
}
.mobile-filter-hide-bottom-bar{width: 100% !important;border-radius: 10px 10px 0px 0px !important; position: fixed !important; height: 60vh !important;overflow-y: scroll !important; bottom: -60vh !important; transition: 0.5s !important;}
.mobile-filter-show-bottom-bar{bottom: -1px !important;transition: 0.5s !important;}
.mobile-filter-expand-arrow{ margin-top: 10px;  position: absolute;  float: right;  right: 7px;  font-size: 21px;  color: black;  cursor: pointer;}

.shop-loading-screen{width:100%;height: auto; margin:0px auto; padding:0px; text-align: center; font-family: 'Poppins', sans-serif;}
.shop-load-progress { background: rgb(255 255 255); justify-content: flex-start; border-radius: 100px; align-items: center;
   position: relative; padding: 0 4px; display: flex; height:10px; width:150px; border: 2px solid #696969;}
.shop-load-progress-value { animation: load 5s alternate forwards; box-shadow: 0 10px 40px -10px #fff; border-radius: 100px; 
  background: #808080; height:8px; width: 0;}
.shop-loading-screen-center{text-align: center; margin: 20px auto !important; display: inline-block;}
.shop-loading-screen-center p {margin-bottom: 0px !important;}
.shop-wrappper{ position: relative;}
.shop-wrapper-container{ width: 100%; height: 100%; position: absolute; top: 0; left: 0;}
.shop-pre-loader-hide{ animation: hideLoader 2s forwards;display: none;}
.shop-pre-loader{line-height: 100vh; text-align: center;z-index:99999;}
.shop-show-main-content{animation: showMainContent 3s forwards;}

@keyframes load { 
    0% { width: 0; } 
    100% { width:100%; }
}


@keyframes hideLoader{
    0%{
        opacity: 1;
    }
    100%{
        opacity: 0;
    }
}
@keyframes showMainContent{
    0%{
        opacity: 0;
    }
    100%{
        opacity: 1;
    }
}
.dyanmic-product-list { float: left !important; width: 100% !important;}
.desktop-skeleton { float: left !important; width: 100% !important;}
.vertical-align-top { vertical-align: top !important;}
.visibility-hidden { visibility: hidden !important;}
.visibility-visible { visibility: visible !important;}
.display-grid { display: grid !important;}
.border-none { border: none; }
.border-top { border-top: 1px solid #80808085;}
.border-bottom { border-bottom: 1px solid #80808085;}
.border-right { border-right: 1px solid #80808085;}
.border-left { border-left: 1px solid #80808085;}

// .mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__leading { border-radius: 10px 0 0 10px !important; }
// .mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__trailing { border-radius: 0 10px 10px 0 !important;}

// .mdc-select--outlined .mdc-notched-outline .mdc-notched-outline__leading { border-radius: 10px 0 0 10px !important; }
// .mdc-select--outlined .mdc-notched-outline .mdc-notched-outline__trailing { border-radius:  0 10px 10px 0 !important; }


// .mat-form-field-appearance-outline .mat-form-field-outline-start { border-radius: 10px 0 0 10px !important }
// .mat-form-field-appearance-outline .mat-form-field-outline-end { border-radius: 0 10px 10px 0 !important; }

// .mat-menu-content { border-radius: 10px;}
// .mat-button { border-radius: 10px !important;}


.addressFormAlignmentClass .form-group {
  margin-bottom:25px;
}

.addressFormAlignmentClass .form-group .mdc-text-field-helper-line{
  position: absolute;
}

.addressFormAlignmentClass .form-group .error-message{
  margin-left: 0% !important;
}

.addressTextFieldPositionClass{
  position: relative; 
  width: 100%;
  margin-left: 0px !important;
  line-height: 1;
}
.noUnderline{
  text-decoration: unset;
  color: inherit;
  display: contents;
}
.underline{text-decoration: underline;}

.sticky{
  top:0;
  position :fixed;
  // width: calc(100% - 20px);
  z-index: 3;
}
.pim-setting{position: sticky; top:0;z-index:2;}
.product-name-break { overflow: visible !important;line-height: initial !important;word-wrap: break-word !important;white-space: pre-wrap !important;height: 60px !important;padding: 0 10px !important;font-size: 15px !important;}
body{font-family: $font-family !important; background-color: #fff ;font-weight: 500 !important;letter-spacing: 0.25px !important;}
.mdc-text-field--with-leading-icon.mdc-text-field--outlined .mdc-floating-label { font-weight: 500 !important;}
.mdc-text-field:not(.mdc-text-field--disabled) .mdc-floating-label { font-weight: 500 !important; }
.mdc-floating-label { font-weight: 500 !important;font-family: $font-family !important; }
.mdc-drawer .mdc-list-item { font-size: 1.01rem !important;letter-spacing: 0.25px !important;}

.mat-table { font-family: $font-family !important}
.mdc-drawer .mdc-list-item { font-family: $font-family !important;  }
.mdc-button{ border-radius: 4px !important; font-family: $font-family !important; text-transform: none !important; height: 36px; font-size: 1.01rem !important;letter-spacing: 0.25px !important; }
.mdc-text-field__input { font-family: $font-family !important;}
.mat-tab-label, .mat-tab-link { font-family: $font-family-medium !important; }
.mat-option { font-family: $font-family !important; font-size: 14px !important; }
.mat-tab-group { font-family: $font-family !important;}
.mdc-select__native-control, .mdc-select__selected-text { font-family: $font-family !important;}
.mdc-tab { font-family: $font-family-medium !important; }
.mdc-form-field { font-family: $font-family !important;}
.mat-button, .mat-fab, .mat-flat-button, .mat-icon-button, .mat-mini-fab, .mat-raised-button, .mat-stroked-button { font-family: $font-family !important; }
h1 , h2 , h3 , h4 ,h5 , h6 { font-family: $font-family !important; }
.mat-button-toggle-button { font-family: $font-family !important; }
.mdc-typography--headline1 { font-family: $font-family !important; }
.mdc-typography--headline2 { font-family: $font-family !important; }
.mdc-typography--headline3 { font-family: $font-family !important; }
.mdc-typography--headline4 { font-family: $font-family !important; }
.mdc-typography--headline5 { font-family: $font-family !important; }
.mdc-typography--headline6 { font-family: $font-family-medium !important; }
.mat-input-element { font-family: $font-family !important; }
.mat-dialog-title { font-family: $font-family !important; }
.mdc-top-app-bar__row { font-family: $font-family !important; }
button { font-family: $font-family !important;}
p { font-family: $font-family !important;}
a { font-family: $font-family !important;}
.mat-slide-toggle-content  { font-family: $font-family !important;}
.mat-header-cell { color: black !important; font-size: 14px !important; font-family: $font-family-medium !important }
.primary-button { color:  #fff !important; background : #1A73E8 !important; font-size: 1.01rem !important;letter-spacing: 0.25px !important;line-height: 1.25rem !important;box-shadow: 1px 1px 1px rgb(0 0 0 / 20%)}
.secondary-button { color: #1A73E8 !important; background:  #fff !important;border: 1px solid #dadce0 !important;font-family: $font-family-medium !important; line-height: 1.25rem !important;box-shadow: 1px 1px 1px rgb(0 0 0 / 20%) }
.tertiary-button { color: #5f6368 !important; background:  #fff !important;border: 1px solid #dadce0 !important;font-family: $font-family-medium !important; line-height: 1.25rem !important; box-shadow: 1px 1px 1px rgb(0 0 0 / 20%); }
.ngx-mdc-button--secondary { text-transform: none !important; }
.display-space-between { display: flex !important;justify-content: space-between !important;}
.display-space-around{display: flex!important;justify-content: space-around !important;}
.grid-padding { padding: 0px 24px 0px 24px !important;}
.grid-padding-left-right { padding: 24px 0px 24px 0px !important;}

.mdc-drawer .mdc-list-item--activated { background: #d2e3fc;border-radius: 0px;padding: 10px 15px;height: auto;color: #1A73E8;font-family: $font-family-medium !important;border-right: 3px solid !important }
.mdc-drawer .mdc-list-item:hover { border-right: 3px solid !important;color: #1A73E8;}
.selected-control { color: #1A73E8 !important;background: #d2e3fc !important;  }
.mat-menu-content { padding: 8px !important;}
// #E8F0FE
.mdc-card {
  box-shadow: 0px 2px 1px 0px rgb(0 0 0 / 20%), 0px 1px 6px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%) !important;
}

:not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item--activated::before, :not(.mdc-list--non-interactive) > :not(.mdc-list-item--disabled).mdc-list-item--activated::after {
  background-color: #fff !important;
}

.mdc-drawer.mdc-drawer--open:not(.mdc-drawer--closing) + .mdc-drawer-app-content { background: #80808012 !important; }
.line-height-0 { line-height: 0px !important;}
.align-self-end { align-self: flex-end !important;}
.align-self-baseline { align-self: baseline !important;}
.header-search-field { 
  display: block;
  width: -webkit-fill-available !important;
  padding: 0.85rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #4a4a4a;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 5px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  height: 20px
 }
 .gap-10{ gap: 10px !important;}
 .gap-20{ gap: 20px !important;}
 .mat-tab-header { background: #fff !important ;}
 .header-search-btn {
    width: 45px;
    height: 35px;
    padding: 10px;
    background: #fff;
    font-size: 1.65rem;
    border: none;
    cursor: pointer;
    position: absolute;
    right: 6px;
    top: 1px;
    color: rgba(0, 0, 0, 0.4);
 }

.header-search-field-box {display: block; width: -webkit-fill-available !important; padding: 0 0.85rem; position: relative;}
.header-search-field-box input {display: block;width: 100%;padding: 0.85rem;font-size: 1rem;font-weight: 400;line-height: 1.5;
  color: #4a4a4a;background-color: #fff;border: 1px solid #ced4da;border-radius: 5px;transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  height: 20px; }
.header-search-field-box .search-btn{width: 45px;height: 35px;padding: 10px;background: #fff;font-size: 1.65rem;border: none;
    cursor: pointer;position: absolute;right: -12px;top: 1px;color: rgba(0, 0, 0, 0.4); }
.header-advnace-search-container {background: #F8F8F9; width: -webkit-fill-available !important;
  position: relative;}
.mat-tab-header{background-color: white!important; border-bottom: 0px !important;}
.list-header-padding { padding:  7px 15px !important}

.estore-admin-card{
  border-radius: 5px 5px 5px 5px; background: #fff;
}
.search-box-container { background: #F8F8F9; padding: 0rem; width: -webkit-fill-available !important; margin-top: 46px;position: relative; }



.header-select-container {
  width: 22%;
  float: left;
  border-right: 1px solid #c3d4da;
  margin: 0;
  padding: 0;
}


.header-select-container select {
  display: block;
  width: 100%;
  padding: 0.85rem;
  color: #4a4a4a;
  border: 0;
  height: 44px;
  border-radius: 5px;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-image: url(https://inoutstorageqa.blob.core.windows.net/inoutcontent/assets/Order/images/downarrow.svg);
}


.header-select-input-top-container {
  border: 1px solid #ced4da;
  border-radius: 5px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  display: flex;
}

.header-select-input-form-container {
  width: 74%;
  float: left;
  position: relative;
}

.header-select-input-form-container input {
  display: block;
  width: 100%;
  padding: 0.85rem;
  color: #4a4a4a;
  border: 0;
  border-radius: 5px;
  height: 20px;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
}

.header-select-input-form-container .search-btn {
  width: 45px;
  height: 35px;
  padding: 10px;
  font-size: 1.65rem;
  border: none;
  cursor: pointer;
  position: absolute;
  background-color: #fff;
  right: -36px;
  top: 0px;
  color: rgba(0, 0, 0, 0.4);
}


.header-select-input-advance-form-container {
  position: absolute;
  z-index: 10;
  width: -webkit-fill-available !important;
}
.mat-menu-item .mat-icon-no-color { color: black !important;}
button.mat-menu-item { color: black !important;}
.theme-border {   border: 1px solid #ced4da !important;;border-radius: 4px !important;}
.justify-content-center { justify-content: center !important;}

.ovelay-cdk{margin-left: 210px; background-color:#ffffff;}

.mat-simple-snackbar >span{color: $theme-white-color;}
.autocompleteCategoryName { overflow: hidden !important;text-overflow: ellipsis !important;}
.text-ellipsis{
  display: -webkit-box !important;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
}
  .line-clamp-2{ -webkit-line-clamp: 2 !important;}
  .line-clamp-3{ -webkit-line-clamp: 3 !important;}
.max-length-ellipsis-3{
  display: -webkit-box;
  -webkit-line-clamp: 3 !important;
  -webkit-box-orient: vertical !important;
  overflow: hidden;
  text-overflow: ellipsis;
}
.max-length-ellipsis-4{
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical !important;
  overflow: hidden;
  text-overflow: ellipsis;
}

.veg-item-icon{
  fill:#457917 !important;
  stroke:#457917 !important;
}
.egg-item-icon{
  fill:rgb(100%,72.156863%,12.941176%) !important;
  stroke:rgb(100%,72.156863%,12.941176%) !important;
}
.non-veg-item-icon{
  fill:rgb(77.254902%,12.54902%,19.215686%) !important;
  stroke:rgb(77.254902%,12.54902%,19.215686%) !important;
}
.loader-dialog .mat-dialog-container
{background: transparent!important;
box-shadow: none !important;
animation: none;
}

.glapto-container { 
  box-sizing: border-box;
}

.glapto-custom-row{
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  // margin-right: calc(-.5 * var(--bs-gutter-x));
  // margin-left: calc(-.5 * var(--bs-gutter-x));
}

.glapto-custom-row>* {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * .1);
  padding-left: calc(var(--bs-gutter-x) * .1);
  margin-top: var(--bs-gutter-y);
}

.glapto-custom-container-fluid{
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--bs-gutter-x) * .5);
  padding-left: calc(var(--bs-gutter-x) * .5);
  margin-right: auto;
  margin-left: auto;
}

.glapto-topbar{
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: transparent;
  padding: 10px 0;
}
.back i{
  color: #000;
}
.glapto-topbar .logo{
  display: block;
  margin: 0 auto;
  width: 110px;
  padding-top: 7px;
}
.glapto-custom-container {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--bs-gutter-x) * .5);
  padding-left: calc(var(--bs-gutter-x) * .5);
  margin-right: auto;
  margin-left: auto;
}
.w-auto{width: auto !important;}

.active-blink{
  animation: blink 2s infinite;
}
.info-icon{
  color:#666666;
}

@keyframes blink {
  0%{color: white;}
  50%{color: #1A73E8;}
  100%{color: white;}
}
