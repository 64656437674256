// Review [internal|intermittent] (2021-10-31T12:41:32) : Move all px/em/rem/percent values to var/mixin

.container-congrats {
  padding: 16px;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.shopSVGContainer {
  margin: 16px;
  align-items: center;
  text-align: center;
}

.shopSVGContainer img {

  @include media("<=phone") {
    margin-top: calc(8px * 15);
    width: calc(100% - 64px);
  }

  @include media(">phone","<desktop-xx") {
    margin-top: calc(8px * 8);
    width: 30vw;
  }

  @include media(">=desktop-xx") {
    margin-top: calc(8px * 8);
    width: 40vw;
  }
}



.shopwithanim {
  width: calc(100% - 64px);
}

.canvas-congrats {
  position: absolute;
  left: 0px;
  top: 0px;
  z-index: 100; //May get conflicted with back next footer, which is z-indexed at 200. Review both together. Back next should be higher up.
  height: 100%;
  /* we need these for full-screen effect */
  width: 100%;
}


