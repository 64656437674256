.demo-text-field-custom-colors:not(.mdc-text-field--invalid) {
  $idle-border: rgba(blue, .38);
  $hover-border: rgba(blue, .6);
  $focused-border: rgba(blue, 1);

  @include mdc-text-field-bottom-line-color($idle-border);
  @include mdc-text-field-hover-bottom-line-color($hover-border);
  @include mdc-text-field-line-ripple-color($focused-border);
  @include mdc-text-field-ink-color(black);
  @include mdc-text-field-label-color(rgba(blue, .5));
  @include mdc-text-field-outline-color($idle-border);
  @include mdc-text-field-hover-outline-color($hover-border);
  @include mdc-text-field-focused-outline-color($focused-border);
  @include mdc-text-field-helper-text-color($idle-border);
  @include mdc-text-field-textarea-stroke-color($idle-border);
  @include mdc-text-field-icon-color($hover-border);

  &.mdc-text-field--focused {
    @include mdc-text-field-label-color(rgba(blue, .87));
    @include mdc-text-field-icon-color($focused-border);
  }
}

.demo-textarea:not(.mdc-text-field--invalid) {
  $idle-border: rgba(blue, .38);
  $hover-border: rgba(blue, .6);
  $focused-border: rgba(blue, 1);

  @include mdc-text-field-ink-color(black);
  @include mdc-text-field-label-color(rgba(blue, .5));
  @include mdc-text-field-textarea-stroke-color($idle-border);

  &.mdc-text-field--focused {
    @include mdc-text-field-label-color(rgba(blue, .87));
    @include mdc-text-field-textarea-stroke-color($focused-border);
  }
}

.demo-textarea.mdc-text-field--invalid {
  @include mdc-text-field-ink-color(orange);
  @include mdc-text-field-label-color(rgba(orange, .5));
  @include mdc-text-field-textarea-stroke-color(rgba(orange, .38));

  &.mdc-text-field--focused {
    @include mdc-text-field-label-color(rgba(orange, .87));
    @include mdc-text-field-textarea-stroke-color(orange);
  }
}

.demo-fullwidth-input:not(.mdc-text-field--invalid) {
  @include mdc-text-field-fullwidth-bottom-line-color(rgba(blue, .38));
  @include mdc-text-field-ink-color(black);
  @include mdc-text-field-label-color(rgba(blue, .5));
  @include mdc-text-field-line-ripple-color(blue);

  &.mdc-text-field--focused {
    @include mdc-text-field-label-color(rgba(blue, .87));
  }
}

.demo-fullwidth-input.mdc-text-field--invalid {
  @include mdc-text-field-fullwidth-bottom-line-color(rgba(orange, .38));
  @include mdc-text-field-ink-color(orange);
  @include mdc-text-field-label-color(rgba(orange, .5));
  @include mdc-text-field-line-ripple-color(orange);

  &.mdc-text-field--focused {
    @include mdc-text-field-label-color(rgba(orange, .87));
    @include mdc-text-field-fullwidth-bottom-line-color(orange);
  }
}

.demo-shaped-text-field {
  @include mdc-text-field-shape-radius(50%);
}

.demo-shaped-text-field--outline {
  @include mdc-text-field-outline-shape-radius(50%);
}

//
// Form spacing
//

@mixin mdc-form-field-padding($padding) {
	padding-left: $padding;
}

.ngx-form-field-text-field,
.ngx-form-field-select {
	height: 87px;
}

.demo-layout__row {
  .ngx-form-field-text-field + mdc-form-field,
  .ngx-form-field-select + mdc-form-field {
    @include mdc-form-field-padding(14px);
 } 
}

.mdc-form-field {
	> .mdc-checkbox {
		@at-root .mdc-form-field {
			align-self: flex-start;
		}
	}
}
