// Review [internal|intermittent] (2021-10-31T12:43:43) : Move all px/em/rem/percent values to var/mixin

.header-wizard-steps-container {
    display:flex;
    flex-direction: column;
    padding:16px;
    .header-wizard-step-title{
        text-align: center;
        font-size:14px;
        color: $wizard-step-phone-title-color
    }
    .header-wizard-steps {
        display:flex; 
        align-self: center; 
        flex-direction: row;        
    }
}