.category-image {
    object-fit: cover;
    width: 100%;
    height: 100%;
    margin: 0px;
    border-radius: 50%;
}

.cat-button-z-push {
    transition: 0.5s;
    transform: scale(0.99) translate(2px, 2px);
    filter: drop-shadow(0px 0px 0px rgba(0, 0, 0, 0.25)) !important;
}

.cat-button-z-push-back {
    transition: 0.5s;
    transform: scale(1) translate(0px, 0px);
    filter: drop-shadow(3px 3px 6px rgba(0, 0, 0, 0.25)) !important;
}

.light-travel {
    transition: 0.5s;
    transform: translateX(-200px);
    opacity: 1;
  }
  
  .light-travel-backwards {
    transition: 0.5s;
    transform: translateX(200px);
    opacity: 0;
  }