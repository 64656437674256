.big-round-shape-button {
  @include mdc-button-shape-radius(8px);
}

.thick-outline-button {
  @include mdc-button-outline-width(4px);
}

.demo-button-ink-color {
  @include mdc-button-container-fill-color($material-color-purple-500);
  @include mdc-button-icon-color(white);
  @include mdc-button-ink-color(orange);
  @include mdc-states(orange);
}

.demo-button-icon-color {
  @include mdc-button-icon-color(orange);
}
