$mdc-theme-primary: #808080;
$mdc-theme-secondary: #1A73E8; // secondary color example

body {
  margin: 0;
}

// Remove clear button for IE inputs
::-ms-clear {
  display: none;
}

// Remove pseudo-element for password field from IE
::-ms-reveal {
  display: none;
}
