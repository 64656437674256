// Review [internal|intermittent] (2021-10-31T12:30:15) : replace hardcoded colors with pax-theme color
// Review [internal|intermittent] (2021-10-31T12:30:20) : move all hardcoded px/em/percent values to vars or mixins


$cat__img__height:70px;
$cat__img_container__inner__padding:2px;
$cat__img_container__border__thickness:3px;
$cat__img_container__height:$cat__img__height+($cat__img_container__inner__padding*2)+($cat__img_container__border__thickness*2);
$cat__name__text__height:24px;
$cat__name__vert__padding:4px;
$cat__container__height:$cat__img_container__height+$cat__name__text__height+($cat__name__vert__padding*2);
$cat__container__width:$cat__img_container__height+($cat__name__vert__padding*2);


//---------phone
$sideNav__toolbar__phone__wizardsteps__height: 48px; //we are not defining the height of the toolbar but for papdding etc. for content header, we are using it.
$sideNav__toolbar__phone__wizardtitle__height: 32px;
$sideNav__content__header__phone__searchrow__height: 80px;
$sideNav__content__header__phone__category__height: $cat__container__height;
$sideNav__content__header__phone__category__width: $cat__container__width;
$sideNav__content__header__phone__image__width: $cat__img__height;
$sideNav__content__header__phone__image__height: $cat__img__height;
$sideNav__content__header__phone__category__margins: 0px;
$sideNav__content__header__phone__categoryrow__height: $sideNav__content__header__phone__category__height+($sideNav__content__header__phone__category__margins*2);
//---------desktop
$dyna__header__hor__scrollbar__height : 8px;
$sideNav__toolbar__desktop__title__height: 84px;
$sideNav__content__header__desktop__searchrow__height:80px;
$sideNav__content__header__desktop__category__height: $cat__container__height;
$sideNav__content__header__desktop__category__width: $cat__container__width;
$sideNav__content__header__desktop__image__width: $cat__img__height;
$sideNav__content__header__desktop__image__height: $cat__img__height;
$sideNav__content__header__desktop__category__margins: 0px;
$sideNav__content__header__desktop__categoryrow__height: $sideNav__content__header__desktop__category__height+($sideNav__content__header__desktop__category__margins*2);


//heights & vert paddings
//-------phone
$dyna__header__phone__search__height : $sideNav__toolbar__phone__wizardsteps__height+$sideNav__toolbar__phone__wizardtitle__height+$sideNav__content__header__phone__searchrow__height;
$dyna__header__phone__search__nd__cat__height : $sideNav__toolbar__phone__wizardsteps__height+$sideNav__toolbar__phone__wizardtitle__height+$sideNav__content__header__phone__searchrow__height+$sideNav__content__header__phone__categoryrow__height;
$dyna__header__phone__toolbar__avoidance__padding: $sideNav__toolbar__phone__wizardsteps__height+$sideNav__toolbar__phone__wizardtitle__height;
$content__phone__dyna__header__avoidance__padding: $dyna__header__phone__search__nd__cat__height;
//-------desktop
$dyna__header__desktop__search__height : $sideNav__toolbar__desktop__title__height+$sideNav__content__header__desktop__searchrow__height;
$dyna__header__desktop__search__nd__cat__height : $sideNav__toolbar__desktop__title__height+$sideNav__content__header__desktop__searchrow__height+$sideNav__content__header__desktop__categoryrow__height+$dyna__header__hor__scrollbar__height;
$dyna__header__desktop__toolbar__avoidance__padding: $sideNav__toolbar__desktop__title__height;
$content__desktop__dyna__header__avoidance__padding: $dyna__header__desktop__search__nd__cat__height;



@mixin toolbarHeaderSpacer() {
    padding-top: calc(64px + 8px) !important;
}

@mixin toolbarFooterSpacer() {
    padding-bottom: calc(64px + 8px) !important;
}

@mixin toolbarSearchHeaderSpacer() {
    padding-top: $dyna__header__desktop__search__height !important;
    // padding-top: calc(80px + 64px + 8px) !important;
}

@mixin toolbarSearchProdCatSpacer() {

    @include media("<=phone") {
        padding-top: $dyna__header__phone__search__nd__cat__height !important;
    }

    @include media(">phone") {
        padding-top: $dyna__header__desktop__search__nd__cat__height !important;
        // padding-top: calc(80px + 104px + 8px) !important;
    }
}



.mind-toolbar-and-search-header {
    @include toolbarSearchHeaderSpacer();
}

.mind-toolbar-and-search-and-category-header {
    @include toolbarSearchProdCatSpacer();
}

.mind-toolbar {
    @include toolbarHeaderSpacer();
}

.mind-footer {
    @include toolbarFooterSpacer();
}

@mixin toolbarlogovalue($value: 'light') {

    @if $value == 'light' {
        .tool-bar-paxcon-logo-dark{
            display:none;
        }
        .tool-bar-paxcon-logo-light{
            display:block;
        }
    } @else {
        .tool-bar-paxcon-logo-dark{
            display:block;
        }
        .tool-bar-paxcon-logo-light{
            display:none;
        }
    }
}

.wizard-toolbar-logo-container{
    @include toolbarlogovalue($toolbar-paxcom-logo-value);
}




.pax-toolbar {
    position: absolute;
    z-index: 100;
    background: $mat-toolbar-background-color;

    @include media("<=phone") {
        background: transparent;

        .pax-toolbar-phone {
            margin: auto;
            display: unset;
        }

        .pax-toolbar-desktop {
            width: 100%;
            display: none;
        }

    }

    @include media(">phone") {
        box-shadow: 2px 2px 6px 2px $paxcom-color-black-transparent-50 !important;

        .pax-toolbar-phone {
            margin: auto;
            display: none;
        }

        .pax-toolbar-desktop {
            width: 100%;
            display: unset;
        }
    }
}

.pax-sidenav {
    @include toolbarHeaderSpacer();
    width: 33%;
    background: $mat-sidenav-background-color;

    @include media("<=phone") {
        display: none !important;
    }

    @include media(">phone") {
        display: unset !important;
    }
}

.pax-sidenav-container {
    background: transparent;
    width: 100%;
    height: 100%;
    // padding-top: calc(64px + 32px);
}

.pax-sidenav-content {
    background: transparent;
    // display: flex;
    // justify-content: center;
    height: 100%;

    @include media("<=phone") {}

    @include media(">phone") {
        //padding-left: $padding-02-00x;
    }
}

@mixin pax-sidenav-content-search-and-advanced-header() {
    transition: top 0.3s;
    width: 100%;
    position: fixed;
    left: 0;
    top: 0;
    background-color: $search-header-background;
    z-index: 100;
}


.pax-sidenav-content-header-product-cateogory-image-container {
    flex-shrink: 0;
    height: $cat__img_container__height;
    width: $cat__img_container__height;
    border-width: $cat__img_container__border__thickness;
    border-radius: 50%;
    border-style: solid;
    background-color: transparent;
    border-color: transparent;
    margin-left: auto;
    margin-right: auto;

    &.selected {
        border-color: #032349;
        // border-color: $paxcom-selected-category-highlight;
        filter: drop-shadow(3px 3px 6px rgba(0, 0, 0, 0.25));
    }
}

.pax-sidenav-content-header-product-cateogory-image {
    flex-shrink: 0;
    height: $cat__img__height;
    width: $cat__img__height;
    border-radius: 50%;
    margin-top: $cat__img_container__inner__padding+$cat__img_container__border__thickness;
    margin-left: auto;
    margin-right: auto;

    img {
        filter: drop-shadow(3px 3px 6px rgba(0, 0, 0, 0.25));
    }
}

.pax-sidenav-content-header-product-cateogory-name {
    font-size: $font-size-x-small; // update font size of selected category
    text-align: center;
    padding-top: 4px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    &.selected {
        color: #032349;
        // color: $paxcom-selected-category-highlight;
    }
}



.pax-sidenav-content-header-product-cateogory {
    flex-shrink: 0;
    height: $sideNav__content__header__phone__category__height;
    width: $sideNav__content__header__phone__category__width;
    margin: $sideNav__content__header__phone__category__margins;
    margin-left: 10px;
}

.pax-sidenav-content-side-product-category-container {
    height: 100%;
    // @include media("<=phone") {
    //     display: none;
    // }

    // @include media(">phone") {
    //     display: block;
    // }
}


.pax-sidenav-content-header-product-category-container {
    height: 100%;
    width: 100%;
    overflow-x: auto;
    overflow-x: auto;
    background: #f7f7f7;
    padding: 0px 0px 5px 0px;
    border-bottom: 1px solid #efefef;
    border-top: 1px solid #efefef;
    margin: 15px 0px 0px -2px !important;

    @include media("<=phone") {}

    @include media(">phone") {
        width:66%;
    }
}

.pax-sidenav-content-header-product-category-scrollable-container {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
}

$sidenav-toolbar-left-column-width-desktop: calc(33% + 40px); //interrelated with width of right column below
$sidenav-toolbar-right-column-width-desktop: calc(66% - 40px); //interrelated with width of left column above

.pax-sidenav-content-header-products {
    @include pax-sidenav-content-search-and-advanced-header();

    @include media("<=phone") {
        // height: calc(180px + #{$additionalPixelsForTitleInToolbarOnPhone});
        height: $dyna__header__phone__search__nd__cat__height;
    }

    @include media(">phone") {
        height: $dyna__header__desktop__search__nd__cat__height;
        // height: 200px;
    }

    .contents {
        display: flex;
        flex-direction: column;

        @include media("<=phone") {
            margin-left: 16px;
            width: calc(100% - 32px);
            max-width: 400px;
            padding-top: $dyna__header__phone__toolbar__avoidance__padding;
        }

        @include media(">phone") {

            margin-left: #{$sidenav-toolbar-left-column-width-desktop};
            width: calc(100% - 32px);
            // max-width: 400px;
            padding-top: $dyna__header__desktop__toolbar__avoidance__padding;
            // padding-top: 78px;
        }

        .row-1-container {
            flex: 1;
            display: flex;

            @include media(">phone") {
                justify-content: center;
                width: #{$sidenav-toolbar-right-column-width-desktop};
            }

            .row-1 {
                display: flex;
                flex-direction: row;
                // align-items: center;

                @include media("<=phone") {
                    width: 100%;
                }

                .search-text-box {
                    flex: 1;

                    @include media("<=phone") {
                        // max-width: 200px;
                    }

                    @include media(">phone") {
                        width: 30vw;
                    }
                }

                .spacer {
                    width: 16px;
                }

                .button-container {
                    flex: 0;
                    margin-top: 12px;                    
                }
            }

        }

        .row-2 {
            flex: 1;

            @include media("<=phone") {
                margin-left: -16px;
                margin-right: -16px;
            }

            @include media(">phone") {
                margin-left: -38px;
                margin-right: -16px;
            }
        }
    }
}


.pax-sidenav-content-header-categories {
    @include pax-sidenav-content-search-and-advanced-header();

    @include media("<=phone") {
        height: $dyna__header__phone__search__height;
    }

    @include media(">phone") {
        height: $dyna__header__desktop__search__height;
    }

    .contents {
        display: flex;
        flex-direction: column;

        @include media("<=phone") {
            margin-left: 16px;
            width: calc(100% - 32px);
            max-width: 400px;
            padding-top: $dyna__header__phone__toolbar__avoidance__padding;
        }

        @include media(">phone") {
            margin-left: #{$sidenav-toolbar-left-column-width-desktop};
            width: calc(100% - 32px);
            // max-width: 400px;
            padding-top: $dyna__header__desktop__toolbar__avoidance__padding;
            // padding-top: 78px;
        }


        .row-1-container {
            flex: 1;
            display: flex;

            @include media(">phone") {
                justify-content: center;
                width: #{$sidenav-toolbar-right-column-width-desktop};
            }

            .row-1 {
                display: flex;
                flex-direction: row;
                // align-items: center;

                @include media("<=phone") {
                    width: 100%;
                }

                .search-text-box {
                    flex: 1;
                    // padding-right: 16px;

                    @include media("<=phone") {
                        // max-width: 200px;
                    }

                    @include media(">phone") {
                        width: 30vw;
                    }
                }

                .spacer {
                    width: 16px;
                }

                .button-container {
                    flex: 0;
                    margin-top: 12px;                    
                }
            }
        }
    }
}


.side-wizard-step-bullet {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    transition: background-color 1s;

    &.onboard-done {
        background-color: $wizard-step-color-done;
    }

    &.current {
        background-color: $wizard-step-color-current;
    }

    &.pending {
        background-color: $wizard-step-color-pending;
    }

    @include media(">tabs-xxphones", "<=desktop-xx") {
        width: 30px;
        height: 30px;
    }

    @include media(">=desktop-xx") {
        width: 30px;
        height: 30px;
    }
}

.side-wizard-step-bullet-spacer {
    display: flex;

    @include media(">tabs-xxphones", "<=desktop-xx") {
        height: 16px;
    }

    @include media(">=desktop-xx") {
        height: 20px;
    }
}

.side-wizard-step-left-section {
    .side-wizard-step-left-section-title {
        text-align: right;
        @include paxcomTypographyWizardTitle;

        &.onboard-done {
            transition: 1s;
            color: $wizard-step-color-done !important;
        }

        &.pending {
            transition: 1s;
            color: $wizard-step-color-pending !important;
        }

        &.current {
            transition: 1s;
            color: $wizard-step-color-current !important;
        }
    }

    .side-wizard-step-left-section-description {
        overflow: hidden;
        text-align: right;
        width: 60%;
        @include paxcomTypographyWizardDescription;
        &.onboard-done {
            transition: 1s;
            color: rgb($wizard-step-color-done,0.8) !important;
        }

        &.pending {
            transition: 1s;
            color: rgb($wizard-step-color-pending,0.8) !important;
        }

        &.current {
            transition: 1s;
            color: rgb($wizard-step-color-current,0.8) !important;
        }
    }

}

.side-wizard-header-title {
    @include paxcomTypographyWizardToolbarTitle;
}

.header-wizard-step {
    flex: 1;
    width: 15px;
    height: 15px;
    padding: 2px
}

.header-wizard-step-bullet {
    border-radius: 50%;
    margin: 4px;

    &.onboard-done {
        transition: 1s;
        background-color: $wizard-step-color-done !important;
    }

    &.current {
        transition: 1s;
        background-color: $wizard-step-color-current !important;
    }

    &.pending {
        transition: 1s;
        background-color: $wizard-step-color-pending !important;
    }

    @include media("<=tabs-xxphones") {
        width: 20px !important;
        height: 20px !important;
    }

    @include media(">tabs-xxphones", "<=desktop-xx") {
        width: 40px !important;
        height: 40px !important;
    }

    @include media(">=desktop-xx") {
        width: 80px !important;
        height: 80px !important;
    }
}


.wizard-footer-back-next {
    z-index: 200; //Gets conflicted with confetti canvas, which is z-indexed at 100. Review both together.
    display: flex;
    flex-direction: row;
    height: 64px;
    width: calc(66% + 20px);
    position: fixed;
    bottom: 0;

    @include media("<phone") {
        width: calc(100% - 32px);
        padding-left: 16px;
        padding-right: 16px;
    }

    @include media(">tabs-xxphones", "<=desktop-xx") {
        width: calc(66% + 20px);
    }

    @include media(">=desktop-xx") {
        width: calc(66% + 20px);
        // margin-left: -48px !important;
    }

    .back-button-container {
        padding-left: 16px;
        color: $paxcom-wizard-steps-footer-button-color;
    }

    .skip-button-container{
        padding-left: 16px;
        color: $paxcom-wizard-steps-footer-button-color;
    }

    .next-button-container {
        padding-right: 16px;
        color: $paxcom-wizard-steps-footer-button-color;
    }
}

.wizardStepsFooter {
    background-color: #f1f3f4;
    padding-top: 13px;
    display: flex;
    align-self: center;
    position: absolute;
    width: 100%;
    left: 0;
    top: 0;
    z-index: 200;
    height: 64px;
}


.mat-drawer-side{
    border-right:none;
}

.mat-drawer-content{ 
    overflow-x: hidden !important;
  }

  .side-wizard-step-bullet{color: #fff; display: flex; align-items: center; justify-content: center; font-size: 12px; }
.box-shadow{
    box-shadow: 2px 2px 6px 2px #0000007f !important;
}
.primary-onboard-button{
    background: #032349 !important
}
.secondary-onboard-button { color: #032349 !important; background:  #fff !important;border: 1px solid #dadce0 !important;font-family: $font-family-medium !important; line-height: 1.25rem !important;box-shadow: 1px 1px 1px rgb(0 0 0 / 20%) }


.mr-27{
    margin-right:27px !important;
}
.ml-15{
    margin-left: 15px !important;
}
.w-120{
    width: 120% !important;
}
.side-border{
    border-right: 1px solid #ddd;
}
